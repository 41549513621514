import React from 'react';
import { QUESTIONNAIRE_SCREENS } from 'navigationConfig';
import routes from 'shared/routes';
import { GuideProfileContextProvider } from 'guideProfile/GuideProfileContext';
import HeaderLayout from 'shared/layouts/HeaderLayout/HeaderLayout';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import makeWizardNavigator, { layoutPropTypes } from 'shared/wizardNavigation';
import makeStepTitle from 'shared/wizardNavigation/helpers';
import SaveAndExitLink from 'shared/components/ProgressHeader/SaveAndExitLink';
import { QuestionnaireSidebar } from './styled';

function LayoutComponent({
  content,
  sidebar,
  globalStep,
  globalTotalSteps,
  parentStep,
}) {
  return (
    <GuideProfileContextProvider>
      <HeaderLayout
        title={makeStepTitle(parentStep, 'Questionnaire')}
        progressPercent={((globalStep - 1) / globalTotalSteps) * 100}
        enableProgressBar
        renderActions={() => <SaveAndExitLink />}
      >
        <SplitLayout showLogo={false}>
          <SplitLayout.Main>
            <SplitLayout.MainContent>{content}</SplitLayout.MainContent>
          </SplitLayout.Main>

          <QuestionnaireSidebar bg>{sidebar}</QuestionnaireSidebar>
        </SplitLayout>
      </HeaderLayout>
    </GuideProfileContextProvider>
  );
}

LayoutComponent.propTypes = layoutPropTypes;

export default makeWizardNavigator({
  entryRoute: routes.summaryBase,
  exitRoute: routes.summaryBase,
  screens: QUESTIONNAIRE_SCREENS,
  Layout: LayoutComponent,
});
