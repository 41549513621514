import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useHistory, useLocation } from 'react-router-dom';
import media from 'theme/media';
import routes from 'shared/routes';
import LoadingSpinner from 'shared/components/LoadingSpinner/LoadingSpinner';

export default function WaitForExternalData({ children, external }) {
  const history = useHistory();
  const location = useLocation();
  const requests = Array.isArray(external) ? external : [external];
  const isLoading = !!requests.find(r => r.loading);
  const failedRequest = requests.find(r => r.error);

  // Failed requests redirect to a dedicated error screen
  useEffect(() => {
    if (failedRequest) {
      history.replace(routes.error, {
        // Pass along the request that failed
        error: failedRequest.error,

        // Return to this screen. This allows the error screen to replace instead of going back,
        // when trying again.
        returnTo: location.pathname,
      });
    }
  }, [failedRequest, history, location.pathname]);

  // Failed requests should not display any children components while we wait
  // for the redirect to take place. Display loading instead of flashing 'null'.
  if (isLoading || failedRequest) {
    return (
      <Container>
        <LoadingSpinner />
      </Container>
    );
  }

  return children;
}

const Container = styled.div`
  margin: auto;
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;

  /* Hack to offset SplitLayout left padding to horizontally center the container */
  ${media.up('medium')} {
    transform: translateX(-31px);
  }
  ${media.up('huge')} {
    transform: translateX(-82px);
  }
`;
