import { useCallback, useState } from 'react';

export default function useFocusField({ onBlur = false, onFocus = false }) {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = useCallback(
    e => {
      setIsFocused(true);
      if (onFocus) {
        onFocus(e);
      }
    },
    [setIsFocused, onFocus]
  );

  const handleBlur = useCallback(
    e => {
      setIsFocused(false);
      if (onBlur) {
        onBlur(e);
      }
    },
    [setIsFocused, onBlur]
  );

  return {
    isFocused,
    handleFocus,
    handleBlur,
  };
}
