/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { H3 } from 'shared/components/styled/typography';
import { ProfilePreviewPropType } from './propTypes';
import ProfilePhoto from './ProfilePreviewPhoto';
import {
  RoundedBox,
  Wrapper,
  About,
  Name,
  List,
  FieldFocusItem,
  Quote,
  TestimonialAuthor,
} from './styled';
import ProfilePreviewList from './ProfilePreviewList';

// Returns cert[<name>, ...] regardless whether fullCerts field's
// type is [<string>] or [{id, name, url}]
function certNames(certs) {
  if (!Array.isArray(certs)) {
    return certs;
  }

  return certs.map(cert => {
    return typeof cert === 'object' ? cert.name : cert;
  });
}

const ProfilePreview = ({ profile, children }) => {
  const {
    first_name: firstName,
    header_image: headerImage,
    fields,
    bio,
    benefits,
    experience,
    communication_style: communicationStyle,
    fullCerts,
    allTestimonials,
  } = profile;

  const bioParagraphs = bio ? bio.split('\n\n') : [];

  return (
    <>
      {children}
      <ProfilePhoto image={headerImage}>
        <Name>{firstName}</Name>
        {fields && (
          <List>
            {fields.map((focus, fieldIndex) => (
              <FieldFocusItem key={fieldIndex}>{focus}.</FieldFocusItem>
            ))}
          </List>
        )}
      </ProfilePhoto>
      <Wrapper>
        {bioParagraphs && (
          <About>
            <H3>About</H3>
            {bioParagraphs.map((bioP, bioParIndex) => (
              <p key={bioParIndex}>{bioP}</p>
            ))}
          </About>
        )}

        <ProfilePreviewList title="What You'll Get" items={benefits} />
        <ProfilePreviewList
          title="Experience"
          items={experience}
          checklistItemColor="sunflowerYellow"
        />
        <ProfilePreviewList
          title="Training &amp; Qualifications"
          items={certNames(fullCerts)}
        />
        <ProfilePreviewList
          title="Style &amp; Approach"
          items={communicationStyle}
        />

        {allTestimonials && (
          <>
            <H3>What Seekers Say</H3>
            <List>
              {allTestimonials.map((testimonial, i) => (
                <RoundedBox key={i}>
                  <Quote>{testimonial.text}</Quote>
                  <TestimonialAuthor>{testimonial.author}</TestimonialAuthor>
                </RoundedBox>
              ))}
            </List>
          </>
        )}
      </Wrapper>
    </>
  );
};

ProfilePreview.propTypes = {
  profile: PropTypes.shape(ProfilePreviewPropType).isRequired,
  children: PropTypes.node,
};

ProfilePreview.defaultProps = {
  children: [],
};

export default ProfilePreview;
