import styled from 'styled-components';
import { rem, color } from 'theme/lib';
import media from 'theme/media';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import Sidebar from 'shared/layouts/SplitLayout/Sidebar';

export const HorizontalRule = styled.hr`
  border: none;
  border-top: solid 1px ${color('grays.200')};
  margin: ${rem(50)} 0 ${rem(10)};
`;

export const Subheading = styled.span`
  font-weight: 500;
  font-size: ${rem(18)};
  margin-bottom: ${rem(25)};
`;

export const RestrictedContainer = styled.div`
  max-width: ${rem(350)};
`;

export const Apps = styled.div`
  margin: ${rem(40)} 0;
  display: flex;
  justify-content: space-between;
`;

export const ContentFooter = styled.div`
  font-size: ${rem(14)};
`;

export const StyledSplitLayoutMain = styled(SplitLayout.Main)`
  flex: 1 1 100%;
  ${media.up('large')} {
    flex: 1 2 100%;
  }
`;

export const StyledSplitLayoutMainContent = styled(SplitLayout.MainContent)`
  padding-bottom: ${rem(120)};
  ${media.up('medium')} {
    margin-top: 11vh;
    padding-right: ${rem(30)};
  }

  ${media.up('large')} {
    margin-top: 6vh;
  }
`;

export const StyledSidebar = styled(Sidebar)`
  flex: 2 1 90%;
  max-width: none;
  display: flex;
  margin-top: ${rem(20)};
  padding-bottom: ${rem(95)};
  ${media.up('huge')} {
    margin-right: ${rem(20)};
  }
`;

export const ImageContainer = styled.div`
  display: none;
  position: relative;
  overflow: hidden;
  flex: 1 2 70%;
  border-top-right-radius: ${rem(10)};
  border-bottom-right-radius: ${rem(10)};
  ${media.up('large')} {
    display: block;
    height: 100%;
  }
`;

export const Image = styled.img`
  min-width: 100%;
  min-height: 100%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
`;

export const SecondaryContentContainer = {
  Default: styled.div`
    padding: ${rem(20)} 0 0;
  `,
  Large: styled.div`
    flex: 2 1 30%;
    display: flex;
    flex-direction: column;

    ${media.up('medium')} {
      height: calc(100% - ${rem(115)});
      margin: ${rem(80)} ${rem(40)} 0;
      height: 100%;
    }

    ${media.up('large')} {
      background: ${color('offWhite')};
      margin: 0;
      justify-content: flex-end;
      border-top-left-radius: ${rem(10)};
      border-bottom-left-radius: ${rem(10)};
    }
  `,
};

export const Link = styled.a`
  max-height: 50px;
`;

export const LinkImages = styled.img`
  height: 50px;
  width: auto;
`;
