import { useCallback, useState, useMemo } from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useCurrentUser } from 'auth/hooks/queries';
import { removeTypename, removeNullElements } from 'shared/utils/object';
import { chainedQueryResponse } from 'shared/utils/apollo';

export const GET_GUIDE_INFO = gql`
  query GetGuideInfo($id: ID!) {
    guideInfo(id: $id) {
      guide_id
      first_name
      last_name
      header_image
      bio
      fields
      benefits
      experience
      diversity
      communication_style
      certs
      testimonials {
        author
        first_name
        last_initial
        text
      }
      fullCerts @client {
        id
        name
        url
      }
      allTestimonials @client {
        author
        first_name
        last_initial
        text
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query GetProfile($id: ID!) {
    guide(id: $id) {
      id
      name
      first_name
      last_name
      header_image
      hero_image
      bio
      fields
      lifestyle
      diversity
      fullCerts {
        id
        name
        url
      }
      experience
      allTestimonials {
        id
        author
        first_name
        last_initial
        text
      }
      communication_style
      keyword
      family_status
      competencies
      approved
      benefits
      impact {
        years
        seekers
      }
      references {
        first_name
        last_initial
        num_sessions
        phone
        email
      }
      birthdate
      practice
      updated_at
      phone {
        mobile
        work
      }
      socials {
        facebook
        linkedin
        website
        instagram
      }
      approach
      mailing_address {
        address_1
        address_2
        city
        stprv
        postal_code
      }
      other {
        competencies
        keywords
      }
      application_status
      interview_booked
      cost
      country

      applicationProgress @client {
        questionnaire {
          progress
          launchRoute
        }
        profilePhotos {
          progress
          launchRoute
        }
        aboutYou {
          progress
          launchRoute
        }
        credentials {
          progress
          launchRoute
        }
      }
    }
  }
`;

export function useGuideInfo(guideId) {
  const guideInfo = useQuery(GET_GUIDE_INFO, {
    variables: {
      id: guideId,
    },
    skip: !guideId,
  });
  return {
    ...guideInfo, //
    data: get(guideInfo, 'data.guideInfo'),
  };
}

export function useGuideById(id) {
  const guideQuery = useQuery(GET_PROFILE, {
    variables: {
      id,
    },
    skip: !id, // Don't issue the query if we don't know the current user id
  });

  return useMemo(() => {
    const guide = get(guideQuery, 'data.guide');
    if (guide) {
      // Change the format of the birthdate from iso to YYYY-MM-DD
      const { birthdate } = guide;
      if (birthdate) {
        guide.birthdate = birthdate.slice(0, 10);
      }
    }

    return {
      ...guideQuery,
      data: guide,
    };
  }, [guideQuery]);
}

export function useGuideProfile() {
  const currentUser = useCurrentUser();
  const currentUserId = get(currentUser, 'data.id');
  const guideProfile = useGuideById(currentUserId);

  return chainedQueryResponse(guideProfile, currentUser);
}

export const UPDATE_PROFILE_FIELD = gql`
  mutation UpdateProfileField($id: ID!, $fieldName: String!, $value: String!) {
    updateProfileField(id: $id, field: $fieldName, value: $value)
  }
`;

export const SAVE_CERT = gql`
  mutation SaveCert($id: ID, $guideId: ID, $certName: String!, $url: String!) {
    saveCerts(id: $id, guideId: $guideId, name: $certName, url: $url)
  }
`;

export const SAVE_TESTIMONIAL = gql`
  mutation SaveTestimonial(
    $id: ID
    $guideId: ID
    $firstName: String!
    $lastInitial: String!
    $text: String!
  ) {
    saveTestimonial(
      id: $id
      guideId: $guideId
      first_name: $firstName
      last_initial: $lastInitial
      text: $text
    )
  }
`;

function useSaveCerts(userId) {
  const currentUserId = get(useCurrentUser(), 'data.id');

  const [saveCert] = useMutation(SAVE_CERT);
  return async certs => {
    if (!certs.length) {
      return;
    }

    // Execute a separate mutation for each field in the values object. Apollo
    // Client should automatically batch these mutations together into a single network request.
    await Promise.all(
      certs.map(async ({ id, name: certName, url }) => {
        await saveCert({
          variables: {
            id,
            guideId: currentUserId !== userId ? userId : '', // Only provide user id when updating a different user than yourself.
            certName,
            url,
          },
        });
      })
    );
  };
}

function useSaveTestimonials(userId) {
  const currentUserId = get(useCurrentUser(), 'data.id');
  const [saveTestimonial] = useMutation(SAVE_TESTIMONIAL);
  return async allTestimonials => {
    if (!allTestimonials.length) {
      return;
    }

    // Execute a separate mutation for each field in the values object. Apollo
    // Client should automatically batch these mutations together into a single network request.
    await Promise.all(
      allTestimonials.map(
        async ({
          id,
          first_name: firstName,
          last_initial: lastInitial,
          text,
        }) => {
          await saveTestimonial({
            variables: {
              id,
              guideId: currentUserId !== userId ? userId : '', // Only provide user id when updating a different user than yourself.
              firstName,
              lastInitial,
              text,
            },
          });
        }
      )
    );
  };
}

function useUpdateField(userId) {
  const [updateField] = useMutation(UPDATE_PROFILE_FIELD);
  const saveCerts = useSaveCerts(userId);
  const saveTestimonials = useSaveTestimonials(userId);

  return async (fieldName, value) => {
    switch (fieldName) {
      case 'allTestimonials': {
        await saveTestimonials(value);
        break;
      }
      case 'fullCerts': {
        await saveCerts(value);
        break;
      }
      default: {
        let transValue = removeTypename(value);

        // JSON stringify any non string value
        if (typeof transValue !== 'string') {
          transValue = JSON.stringify(transValue);
        }

        await updateField({
          variables: {
            id: userId,
            fieldName,
            value: transValue,
          },
        });
      }
    }
  };
}

export function useUpdateProfile() {
  const currentUser = useCurrentUser();
  const currentUserId = get(currentUser, 'data.id');
  const guideQuery = useGuideById(currentUserId);
  const refetchProfile = guideQuery.refetch;
  const existingGuide = removeNullElements(get(guideQuery, 'data', {}), true);

  // Ignore the state of each mutation because we are only concerned with the batch as a whole.
  const updateField = useUpdateField(currentUserId);
  const [state, setState] = useState({
    loading: false,
    error: null,
  });

  const updateProfile = useCallback(
    async (values, onSuccess) => {
      if (!currentUserId) {
        // This should never happen. Only here as a safety.
        console.error(
          'Trying to update a user profile without knowing the user id'
        );

        setState({ error: new Error('Can not update anonymous user profile') });
        return;
      }

      const handleSuccess = () => {
        if (onSuccess) {
          // This allows a component to OPTIONALLY wait for success before continuing.
          // Example: Wait for the profile to update before moving to the next step in the questionnaire
          onSuccess();
        }
      };

      // Trim all the values that won't actually change
      const trimmedValues = Object.keys(values).reduce((acc, fieldName) => {
        let transValue = values[fieldName];
        if (Array.isArray(transValue)) {
          // Remove falsy array values
          transValue = transValue.filter(v => v);
        }

        switch (fieldName) {
          case 'allTestimonials':
            transValue = transValue.filter(
              ({
                id,
                first_name: firstName,
                last_initial: lastInitial,
                text,
              }) => id || firstName || lastInitial || text
            );
            break;
          case 'fullCerts':
            transValue = transValue.filter(
              ({ id, name: certName, url }) => id || certName || url
            );
            break;
          default:
        }

        if (!isEqual(transValue, existingGuide[fieldName])) {
          acc[fieldName] = transValue;
        }
        return acc;
      }, {});

      // If there is nothing to do, just trigger the success handler
      if (!Object.keys(trimmedValues).length) {
        handleSuccess();
        return;
      }

      try {
        setState({ loading: true });

        // Execute a separate mutation for each field in the values object. Apollo
        // Client should automatically batch these mutations together into a single network request.
        await Promise.all(
          Object.keys(trimmedValues).map(async fieldName => {
            await updateField(fieldName, trimmedValues[fieldName]);
          })
        );

        // After all the mutations are successful, refetch the guide profile.
        await refetchProfile();

        setState({ loading: false, error: null });
        handleSuccess();
      } catch (err) {
        setState({ loading: false, error: err });
      }
    },
    [currentUserId, existingGuide, refetchProfile, updateField]
  );

  return [updateProfile, state];
}

export const GET_FIELD_OPTIONS = gql`
  query GetFieldOptions($fieldName: String!) {
    getFields(field: $fieldName)
  }
`;

export function useProfileFieldOptions(fieldName, fieldLabelsMap = {}) {
  const query = useQuery(GET_FIELD_OPTIONS, {
    variables: {
      fieldName,
    },
  });

  return {
    ...query,
    data: get(query, 'data.getFields', []).map(f => ({
      id: f,

      // Convert label from id to readable string if a map is provided. Otherwise,
      // is the id as the label as well.
      label: get(fieldLabelsMap, f, f),
    })),
  };
}
