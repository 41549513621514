import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import Instructions from './Instructions';
import { ReactComponent as IconCircleStar } from './circle-star.svg';

function DropzoneInput({
  className,
  icon,
  title,
  accept,
  onFiles,
  getFilesFromEvent,
  files,
  uploadedImage,
}) {
  const hasAddedFiles = !!uploadedImage || files.length > 0;
  const canShowInstructions = !hasAddedFiles;

  return (
    <StyledDropzoneInput className={className} hasAddedFiles={hasAddedFiles}>
      <StyledInput
        type="file"
        accept={accept}
        id="dropzone-input"
        multiple
        onChange={e => {
          getFilesFromEvent(e).then(chosenFiles => {
            onFiles(chosenFiles);
          });
        }}
      />
      {canShowInstructions && (
        <Instructions
          icon={icon}
          title={title}
          acceptedTypes="JPEG or 24-bit PNG"
        />
      )}
    </StyledDropzoneInput>
  );
}

DropzoneInput.propTypes = {
  /** Title displayed with the upload instructions */
  title: PropTypes.string,
  accept: PropTypes.string.isRequired,
  onFiles: PropTypes.func.isRequired,
  getFilesFromEvent: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
  icon: PropTypes.element,
  uploadedImage: PropTypes.string,
};

DropzoneInput.defaultProps = {
  title: 'Upload Photo',
  icon: <IconCircleStar />,
  uploadedImage: '',
};

const StyledDropzoneInput = styled.label`
  /* Override .dzu-input styles */
  && {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    cursor: pointer;

    /* Prevent the label from triggering another upload so user must choose the re-upload button control. */
    ${({ hasAddedFiles }) =>
      hasAddedFiles &&
      css`
        cursor: default;
        pointer-events: none;
      `}
  }
`;

const StyledInput = styled.input`
  display: none;
`;

export default DropzoneInput;
