import React from 'react';
import PropTypes from 'prop-types';
import { animationControlsPropTypes } from 'shared/motion/propTypes';
import { H3 } from 'shared/components/styled/typography';
import NumberedList from 'shared/components/NumberedList/NumberedList';
import EmbeddedImage from '../EmbeddedImage';
import { HeadingWordGroup, ListHeading } from './styled';
import imgRequirementsEmbedded1x from './requirements-embedded.jpg';
import imgRequirementsEmbedded2x from './requirements-embedded@2x.jpg';
import imgRequirementsEmbedded3x from './requirements-embedded@3x.jpg';
import MotionContent from './MotionContent';

function RequirementsContent({ isDirectionReversed, animate }) {
  return (
    <MotionContent isDirectionReversed={isDirectionReversed} animate={animate}>
      <H3 as="h1">
        <HeadingWordGroup>Let’s democratize</HeadingWordGroup>{' '}
        <HeadingWordGroup>coaching together</HeadingWordGroup>
      </H3>

      <EmbeddedImage
        src={imgRequirementsEmbedded1x}
        srcSet={`${imgRequirementsEmbedded3x} 3x, ${imgRequirementsEmbedded2x} 2x, ${imgRequirementsEmbedded1x} 1x`}
        alt="Astronaut"
      />

      <p>
        We carefully curate our community of coaches to build long-term
        relationships that are powerful, mission-centric, and mutually
        gratifying. As such we personally vet all coaches we bring into Sphere.
      </p>

      <ListHeading as="h2">
        To become a Sphere Guide you should have:
      </ListHeading>

      <NumberedList>
        <NumberedList.Item>
          Experience, qualifications, certification, and/or training related to
          your coaching practice.
        </NumberedList.Item>
        <NumberedList.Item>
          A pre-existing professional practice.
        </NumberedList.Item>
        <NumberedList.Item>
          Strong client and community referrals.
        </NumberedList.Item>
        <NumberedList.Item>
          A track record of success helping clients make powerful progress.
        </NumberedList.Item>
        <NumberedList.Item>
          An insatiable passion for this work.
        </NumberedList.Item>
        <NumberedList.Item>
          A commitment to their continued learning and development (the best
          coaches are always growing too!)
        </NumberedList.Item>
      </NumberedList>
    </MotionContent>
  );
}

RequirementsContent.propTypes = {
  /** Is navigating back through history. */
  isDirectionReversed: PropTypes.bool,
  /** Framer Motion AnimationControls. */
  animate: animationControlsPropTypes.isRequired,
};

RequirementsContent.defaultProps = {
  isDirectionReversed: false,
};

export default RequirementsContent;
