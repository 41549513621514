import React from 'react';
import get from 'lodash/get';
import trim from 'lodash/trim';
import styled from 'styled-components';
import { Field } from 'formik';
import * as Yup from 'yup';
import { rem } from 'theme/lib';
import CloudSelectorField from 'shared/components/formik/CloudSelectorField';
import TextField from 'shared/components/formik/TextField/TextField';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import { useProfileFieldOptions } from 'guideProfile/hooks/queries';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
} from 'shared/components/WizardQuestionForm';

// eslint-disable-next-line consistent-return
const validationSchema = Yup.object().test('', '', value => {
  if (
    (!value.competencies || !value.competencies.length) &&
    !trim(get(value, 'other.competencies'))
  ) {
    return new Yup.ValidationError(
      'Please specify at least one option',
      null,
      'other.competencies'
    );
  }
});

export default function Clientele(props) {
  const fieldOptions = useProfileFieldOptions('competencies').data;
  return (
    <WizardQuestionForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValues={{
        competencies: [],
        other: {
          competencies: '',
        },
      }}
      validationSchema={validationSchema}
    >
      {() => {
        return (
          <FormContainer>
            <FormContentHeader>
              <QuestionHeading as="h1">
                What areas of coaching do you specialize in?
              </QuestionHeading>

              <QuestionDescription width={410}>
                This plays a part in our matching algorithm, and helps us
                surface your profile to the right Seekers. Select all that
                apply.
              </QuestionDescription>
            </FormContentHeader>

            <FormContent width={620}>
              <Field
                name="competencies"
                component={CloudSelectorField}
                items={fieldOptions}
              />

              <TextFieldWrapper>
                <Field
                  name="other.competencies"
                  component={TextField}
                  placeholder="Other (write-in)"
                />
              </TextFieldWrapper>
            </FormContent>
          </FormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

Clientele.propTypes = withNavigationPropTypes;

const TextFieldWrapper = styled.div`
  max-width: ${rem(470)};
`;
