import React, { useEffect, useRef, useState } from 'react';
import AstronautScene from './AstronautScene/AstronautScene';
import SidebarScene from './SidebarScene/SidebarScene';
import SceneProgress from './SceneProgress';
import useNavigateScenes from './useNavigateScenes';
import {
  useAstronautScene,
  useRequirementsScene,
  useJoinScene,
} from './useAnimationScenes';
import {
  ScreenWrapper,
  StyledLayout,
  StyledFooterLayout,
  Footer,
} from './styled';

function IntroScreen() {
  const [footerWidth, setFooterWidth] = useState(undefined);
  const astronautScene = useAstronautScene();
  const requirementsScene = useRequirementsScene();
  const joinScene = useJoinScene();
  const totalScenesCount = 3;

  const {
    sceneIndex,
    isDirectionReversed,
    onGoBack,
    onGoForward,
  } = useNavigateScenes({
    runAstronautScene: astronautScene.runScene,
    runRequirementsScene: requirementsScene.runScene,
    runJoinScene: joinScene.runScene,
  });

  // When this screen first loads, the astronaut section has no transition.
  // Animations are only applied when the user navigates back to that section.
  const motionInitial = useRef('in');
  useEffect(() => {
    motionInitial.current = 'goBackInitial';
  });

  const handleContentLayoutChange = width => setFooterWidth(width);

  const showAstronautSection = sceneIndex === 1;

  return (
    <ScreenWrapper>
      <StyledLayout>
        {showAstronautSection && (
          <AstronautScene
            motionInitial={motionInitial.current}
            contentAnimation={astronautScene.contentAnimation}
            imageAnimation={astronautScene.imageAnimation}
          />
        )}

        {!showAstronautSection && (
          <SidebarScene
            motionInitial={motionInitial.current}
            requirementsContentAnimation={requirementsScene.contentAnimation}
            requirementsImageAnimation={requirementsScene.imageAnimation}
            joinContentAnimation={joinScene.contentAnimation}
            joinImageAnimation={joinScene.imageAnimation}
            sceneIndex={sceneIndex}
            isDirectionReversed={isDirectionReversed}
            onContentLayoutChange={handleContentLayoutChange}
          />
        )}
      </StyledLayout>

      <StyledFooterLayout>
        <Footer width={footerWidth}>
          <SceneProgress
            sceneIndex={sceneIndex}
            isLastScene={sceneIndex === totalScenesCount}
            percent={(sceneIndex / totalScenesCount) * 100}
            onGoBack={onGoBack}
            onGoForward={onGoForward}
          />
        </Footer>
      </StyledFooterLayout>
    </ScreenWrapper>
  );
}

IntroScreen.propTypes = {};

IntroScreen.defaultProps = {};

export default IntroScreen;
