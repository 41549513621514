import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  Row,
  Col,
  CountWrapper,
  Count,
  FlexTextField,
  StyledReferenceFields,
  FlexPhoneField,
} from './styled';
import { REFERENCES_COUNT_MAX_VALUE } from '../constants';

function ReferenceFields({ name, index, focusedIndex, onFocus }) {
  const handleFocus = useCallback(() => {
    onFocus(index);
  }, [index, onFocus]);

  const nameIndex = `${name}.${index}`;

  return (
    <StyledReferenceFields role="group">
      <CountWrapper>
        <Count
          variant={index === focusedIndex ? 'contained' : 'outlined'}
          color={index === focusedIndex ? 'link' : undefined}
        >
          {index + 1}
        </Count>
      </CountWrapper>

      <div>
        <Row>
          <Col name="firstName">
            <Field
              label="First Name"
              labelVariant="noMargin"
              name={`${nameIndex}.first_name`}
              type="text"
              component={FlexTextField}
              errorSize="small"
              onFocus={handleFocus}
            />
          </Col>
          <Col name="lastInitial">
            <Field
              label="Last Initial"
              labelVariant="noMargin"
              name={`${nameIndex}.last_initial`}
              type="text"
              component={FlexTextField}
              maxLength={1}
              errorSize="small"
              onFocus={handleFocus}
            />
          </Col>
          <Col name="numSessions">
            <Field
              label="# of sessions (roughly)"
              labelVariant="noMargin"
              name={`${nameIndex}.num_sessions`}
              type="number"
              component={FlexTextField}
              errorSize="small"
              onFocus={handleFocus}
              min={0}
              max={REFERENCES_COUNT_MAX_VALUE}
            />
          </Col>
        </Row>

        <Row name="contact">
          <Col name="phone">
            <Field
              label="Phone Number"
              labelVariant="noMargin"
              name={`${nameIndex}.phone`}
              type="text"
              component={FlexPhoneField}
              errorSize="small"
              onFocus={handleFocus}
            />
          </Col>
          <Col name="email">
            <Field
              label="Email Address"
              labelVariant="noMargin"
              name={`${nameIndex}.email`}
              type="text"
              component={FlexTextField}
              errorSize="small"
              onFocus={handleFocus}
            />
          </Col>
        </Row>
      </div>
    </StyledReferenceFields>
  );
}

ReferenceFields.propTypes = {
  /** FieldArray name. */
  name: PropTypes.string.isRequired,
  /** FieldArray index. */
  index: PropTypes.number.isRequired,
  /** Array index of the currently focused FieldArray item. */
  focusedIndex: PropTypes.number,
  /** Event handler for field. */
  onFocus: PropTypes.func,
};

ReferenceFields.defaultProps = {
  focusedIndex: 0,
  onFocus: () => {},
};

export default ReferenceFields;
