import styled, { css } from 'styled-components';
import { fontSize } from 'theme/lib';

const headingCSS = css`
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 500;
`;

export const H1 = styled.h1`
  font-size: ${fontSize(7)};
  line-height: ${54 / 48};
  ${headingCSS}
`;

export const H2 = styled.h2`
  font-size: ${fontSize(6)};
  line-height: ${40 / 36};
  ${headingCSS}
`;

export const H3 = styled.h3`
  font-size: ${fontSize(5)};
  line-height: ${34 / 24};
  ${headingCSS}
`;

export const H4 = styled.h4`
  font-size: ${fontSize(4)};
  line-height: ${28 / 20};
  ${headingCSS}
`;

export const H5 = styled.h5`
  font-size: ${fontSize(3)};
  line-height: ${20 / 16};
  ${headingCSS}
`;

export const H6 = styled.h6`
  font-size: ${fontSize(2)};
  line-height: ${18 / 14};
  ${headingCSS}
`;
