/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import { animationControlsPropTypes } from 'shared/motion/propTypes';
import WizardNavigation from './WizardNavigation';

const stepProps = {
  /** Current step */
  localStep: PropTypes.number.isRequired,

  /** Current step in the overall wizard navigation scheme */
  globalStep: PropTypes.number.isRequired,

  /** Current step in the parent wizard navigation */
  parentStep: PropTypes.number.isRequired,

  /** Total number of steps in the global wizard */
  globalTotalSteps: PropTypes.number.isRequired,

  /** Total number of steps in the local wizard */
  localTotalSteps: PropTypes.number.isRequired,
};

// These are the prop types that will be passed to the content components
export const withNavigationPropTypes = {
  ...stepProps,

  /** Func to route to the previous screen in the wizard */
  goBack: PropTypes.func.isRequired,

  /** Func to route to the next screen in the wizard */
  goNext: PropTypes.func.isRequired,

  /** The route that the content will render on  */
  route: PropTypes.string.isRequired,

  /** Is the user navigating forward or back */
  isDirectionReversed: PropTypes.bool.isRequired,
};

// These are the prop types that will be passed to the layout component
export const layoutPropTypes = {
  ...stepProps,

  /** Content React Component */
  content: PropTypes.arrayOf(PropTypes.element).isRequired,

  /** Sidebar React Component */
  sidebar: PropTypes.arrayOf(PropTypes.element),

  /** Framer Motion animation controls for sidebar content. */
  sidebarControls: animationControlsPropTypes.isRequired,

  /** Func to route to the previous screen in the wizard */
  goBack: PropTypes.func.isRequired,

  /** Func to route to the next screen in the wizard */
  goNext: PropTypes.func.isRequired,
};

/**
 * Make a new wizard navigator based on configuration.
 * @param {Object} config
 * @param {any} config.Layout - React component to control the layout of the screen
 * @param {string} config.baseRoute - Base route of the navigator
 * @param {string} config.entryRoute - Where to go if the user exhausts going BACKWARD
 * @param {string} config.exitRoute - Where to go if the user exhausts going FORWARD
 * @param {Array} config.screens - The screens in the wizard
 */
export default function makeWizardNavigator(config = {}) {
  function navigator({
    route: baseRoute,
    localStep: parentStep,
    globalStep: initialStep,
    globalTotalSteps,
  }) {
    return (
      <WizardNavigation
        baseRoute={baseRoute}
        globalTotalSteps={globalTotalSteps}
        initialStep={initialStep}
        parentStep={parentStep}
        Layout={config.Layout}
        entryRoute={config.entryRoute}
        exitRoute={config.exitRoute}
        screens={config.screens}
      />
    );
  }

  navigator.propTypes = {
    route: PropTypes.string,
    globalStep: PropTypes.number,
    localStep: PropTypes.number,
    globalTotalSteps: PropTypes.number,
  };
  navigator.defaultProps = {
    route: config.baseRoute,
  };
  navigator.screens = config.screens;

  return navigator;
}
