import styled from 'styled-components/macro';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import { H5 } from 'shared/components/styled/typography';
import { color, rem, zIndex } from 'theme/lib';
import media from 'theme/media';

export const MainInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  ${media.up('medium')} {
    margin-right: ${rem(30)};
  }

  ${media.up('large')} {
    max-width: ${rem(540)};
  }
`;

export const ContentContainer = styled.div`
  ${media.up('medium')} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }
`;

export const Content = styled.div`
  margin-bottom: ${rem(40)};

  ${media.up('medium')} {
    margin-top: ${rem(100)};
    margin-bottom: ${rem(60)};
  }
`;

export const LayoutSidebar = styled(SplitLayout.Sidebar).attrs({
  enableScroll: false,
})`
  z-index: ${zIndex('introScreenSidebar')};

  ${media.up('huge')} {
    margin-right: ${rem(20)};
  }
`;

export const ListHeading = styled(H5)`
  color: ${color('brand.primary')};
  margin-top: 2em;
  margin-bottom: 1em;
`;

/* Force these words in the header to always be grouped together */
export const HeadingWordGroup = styled.span`
  display: inline-block;
`;
