// Allow empty default props because id is generated by uuid
/* eslint-disable react/require-default-props */
import React, { useRef } from 'react';
import uuid from 'uuid/v4';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem, color, fontSize } from 'theme/lib';
import media from 'theme/media';

function CloudSelectorItem({ label, checked, disabled, onChange, id }) {
  const ref = useRef(uuid()); // Create a globally unique id for the input field
  return (
    <StyledCloudSelectorItem>
      <StyledInput
        id={ref.current}
        checked={checked}
        onChange={onChange}
        type="checkbox"
        disabled={disabled}
        value={id}
        name={label}
      />
      <StyledLabel htmlFor={ref.current}>{label}</StyledLabel>
    </StyledCloudSelectorItem>
  );
}

CloudSelectorItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

CloudSelectorItem.defaultProps = {
  label: '',
  checked: false,
  disabled: false,
  onChange: () => {},
};

const StyledCloudSelectorItem = styled.li`
  margin-right: ${rem(10)};
  margin-bottom: ${rem(10)};
`;

const StyledLabel = styled.label`
  white-space: nowrap;
  cursor: pointer;
  color: ${color('black')};
  background: ${color('offWhite')};
  display: block;
  height: ${rem(36)};
  line-height: ${20 / 20};
  font-size: ${fontSize(4)};
  padding: ${rem(6)} ${rem(12)};
  user-select: none;

  ${media.up('medium')} {
    font-size: ${fontSize(5)};
  }
`;

const StyledInput = styled.input`
  display: none;
  &:checked + label {
    color: ${color('white')};
    background: ${color('brand.primary')};
  }
`;

export default CloudSelectorItem;
