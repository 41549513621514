import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { color, rem } from 'theme/lib';
import PhoneContainer from 'shared/components/PhoneContainer/PhoneContainer';
import ProfilePreview from 'shared/components/ProfilePreview/ProfilePreview';
import useScroll from 'shared/hooks/useScroll/useScroll';
import ScrollPrompt, {
  Container as ScrollPromptContainer,
} from 'shared/components/ProfilePreview/ScrollPrompt';
import { TextLinkButton } from 'shared/components/styled/buttons';
import useProfilePreview from 'shared/components/ProfilePreview/useProfilePreview';

const SCROLLPROMPT_FADEOUT_DURATION = 500;

function ProfilePreviewSidebarContent() {
  const [scrollPromptVisibility, setScrollPromptVisibility] = useState(true);
  const contentEl = useRef();
  const [scrollWatch] = useScroll(contentEl);
  const {
    profileToShow,
    switchPreviewButtonLabel,
    canShowOwnProfile,
    handleSwitchPreviewButtonClick,
  } = useProfilePreview();

  useEffect(() => {
    if (scrollWatch) {
      setTimeout(() => {
        setScrollPromptVisibility(false);
      }, SCROLLPROMPT_FADEOUT_DURATION);
    }
  }, [scrollWatch]);

  // Show title in the header only when the user
  // isn't far enough in the process to see own profile
  const showHeaderTitle = !canShowOwnProfile;

  return (
    <PhoneContainer.Wrapper>
      <Header centered={showHeaderTitle}>
        {showHeaderTitle && (
          <HeaderTitle>Guide Profile Example - Scroll to View</HeaderTitle>
        )}
        {switchPreviewButtonLabel && (
          <StyledTextLinkButton
            type="button"
            onClick={handleSwitchPreviewButtonClick}
          >
            {switchPreviewButtonLabel}
          </StyledTextLinkButton>
        )}
      </Header>
      <PhoneContainer.Bezel>
        <PhoneContainer.Screen>
          <PhoneContainer.Content ref={contentEl}>
            {profileToShow && (
              <ProfilePreview profile={profileToShow}>
                {scrollPromptVisibility && (
                  <ScrollPromptContainer
                    fadeDuration={SCROLLPROMPT_FADEOUT_DURATION}
                    style={{ opacity: scrollWatch ? 0 : 1 }}
                  >
                    <ScrollPrompt visibility>
                      Scroll to view your profile, explore and fine tune
                      sections.
                    </ScrollPrompt>
                  </ScrollPromptContainer>
                )}
              </ProfilePreview>
            )}
          </PhoneContainer.Content>
        </PhoneContainer.Screen>
      </PhoneContainer.Bezel>
    </PhoneContainer.Wrapper>
  );
}

export const StyledTextLinkButton = styled(TextLinkButton)`
  color: ${color('white')};
`;

export const HeaderTitle = styled.div`
  font-size: inherit;
  font-weight: inherit;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: ${rem(95)};
  padding-bottom: ${rem(32)};
  color: ${color('white')};
  align-items: ${props => (props.centered ? 'center' : 'flex-end')};
`;

export default ProfilePreviewSidebarContent;
