import styled from 'styled-components/macro';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import { color, rem, zIndex } from 'theme/lib';
import media from 'theme/media';
import { IconBigArrowDown } from 'assets/svg';

export const MainContentLayout = styled(SplitLayout.MainContent)`
  ${media.up('medium')} {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
  }

  ${media.up('large')} {
    max-width: ${rem(1440)};
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  z-index: ${zIndex('base')};
  order: 2;
  line-height: ${24 / 16};

  ${media.up('medium')} {
    order: 1;
    flex: none;
    width: ${rem(405)};
    max-width: 57%; /* Ensure that content does not overlap the image for
                       viewports smaller than an iPad. */
    margin-right: ${rem(30)};
    margin-top: 20vh; /* Create offset to align with design. */
    /**
     * KNOWN: Content will not scroll under the logo content blocker.
     * Shouldn't happen in real-world tablet scenarios but the text needs to
     * appear over top of the image, which needs to be over top of the blocker.
     */
    z-index: ${zIndex('fixedHeader', 20)};
  }

  ${media.up('large')} {
    max-width: none;
    z-index: ${zIndex('base')};
    margin-top: 10vh; /* Create offset to align with design. */
  }

  ${media.up('huge')} {
    width: auto;
    flex: 0 1 ${rem(537)};
    margin-right: 0;
  }
`;

export const IntroText = styled.span`
  display: block;

  ${media.up('small')} {
    display: inline;
  }
`;

export const AstronautImageSmall = styled.img`
  position: relative;
  display: block;
  width: calc(100% + ${rem(100)});
  left: ${rem(-50)};
  right: ${rem(-50)};
`;

export const AstronautImageLarge = styled.img`
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
`;

export const ImageContainer = styled.div`
  position: relative;
  order: 1;
  z-index: ${zIndex('base')};
  overflow: hidden;
  margin-left: ${rem(-15)}; /* Make flush with edges of viewport. */
  margin-right: ${rem(-15)};
  padding-top: ${rem(30)}; /* Push image below the fixed header background. */

  ${media.up('medium')} {
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    z-index: ${zIndex('fixedHeader', 10)};
  }

  ${media.between('medium', 'large')} {
    position: absolute;
    top: 0;
    right: 0;
    flex: none;
    width: ${rem(578)};

    ${AstronautImageLarge} {
      position: relative;
      margin-right: ${rem(-170)};
      float: right;
      width: 100%;
      height: 100%;
    }
  }

  ${media.up('large')} {
    order: 2;
    flex: 1 1 auto;
    position: relative;
    top: auto;
    right: auto;
    overflow: visible;
  }

  ${media.up('huge')} {
    flex-basis: ${rem(739)};
  }
`;

export const StyledIconBigArrowDown = styled(IconBigArrowDown)`
  color: ${color('brand.primary')};
`;
