import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { rem } from 'theme/lib';
import media from 'theme/media';

function FormContainer({ padding, ...rest }) {
  return (
    <FormContainerBase
      padding={padding}
      {...rest} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
}

FormContainer.propTypes = {
  /** Variants to change the amount of top padding. */
  padding: PropTypes.oneOf(['small', 'large']),
};

FormContainer.defaultProps = {
  padding: 'large',
};

const FormContainerBase = styled.div`
  ${media.up('medium')} {
    padding-top: 7vh;
    min-height: ${rem(425)};
  }

  ${media.up('large')} {
    ${({ padding }) =>
      padding === 'large' &&
      css`
        padding-top: 16vh;
      `}
  }
`;

export default FormContainer;
