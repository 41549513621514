import pick from 'lodash/pick';

/**
 * Detect whether or not a query has ever been issued.
 * @param {any} query
 * @returns {boolean}
 */
export function hasQueryStarted(query) {
  return query.loading || query.error || query.data !== undefined;
}

/**
 * Combine the loading & error properties of dependent apollo queries.
 * Once the final query has been issued, dependent properties will be ignored.
 * @param {any} query - The parent query
 * @param  {...any} dependentQueries - All dependent child queries
 */
export function chainedQueryResponse(query, ...dependentQueries) {
  if (hasQueryStarted(query)) {
    return query;
  }

  for (const dependency of dependentQueries) {
    if (hasQueryStarted(dependency)) {
      return {
        ...query,
        ...pick(dependency, ['error', 'loading']),
      };
    }
  }

  // None of the dependent queries have started yet. This should probably
  // never happen.
  return {
    ...query,
    loading: true,
  };
}
