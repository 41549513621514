import styled from 'styled-components/macro';
import { rem, color } from 'theme/lib';
import media from 'theme/media';

export const ErrorLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: ${rem(40)};
  padding-right: ${rem(15)};
  padding-left: ${rem(15)};
  ${media.up('medium')} {
    padding-right: ${rem(45)};
    padding-left: ${rem(45)};
  }
`;

export const ErrorDescription = styled.p`
  margin-top: ${rem(0)};
  margin-top: ${rem(28)};
`;

export const ErrorBorderBottom = styled.div`
  height: ${rem(1)};
  background-color: ${color('grays.200')};
  width: 100%;
  position: absolute;
  bottom: ${rem(80)};
`;

export const SupportLink = styled.a`
  margin-left: ${rem(4)};
`;
