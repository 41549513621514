/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { rem } from 'theme/lib';
import media from 'theme/media';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';

export const QuestionnaireSidebar = styled(SplitLayout.Sidebar)`
  display: none;

  ${media.up('medium')} {
    display: block;
  }

  ${media.only('medium')} {
    min-width: ${rem(133)};
  }
`;
