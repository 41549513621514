import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { color, rem } from 'theme/lib';
import { TextLinkButton } from 'shared/components/styled/buttons';
import { ReactComponent as IconFile } from 'assets/svg/file.svg';

function UploadedResource({ className, isEditDisabled, onViewFile, onRemove }) {
  return (
    <StyledUploadedResource className={className}>
      <FileLink onClick={onViewFile}>
        <StyledIconFile />
        View uploaded file
      </FileLink>
      {!isEditDisabled && (
        <RemoveButton onClick={onRemove}>Remove</RemoveButton>
      )}
    </StyledUploadedResource>
  );
}

UploadedResource.propTypes = {
  isEditDisabled: PropTypes.bool,
  /** Event handler to view file. */
  onViewFile: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

UploadedResource.defaultProps = {
  isEditDisabled: false,
};

const StyledUploadedResource = styled.div`
  display: flex;
  align-items: center;
  font-size: ${rem(13)};
  line-height: ${26 / 13};
`;

const StyledIconFile = styled(IconFile)`
  vertical-align: middle;
  margin-right: ${rem(6)};
  width: ${rem(12)};
  height: ${rem(16)};
`;

const FileLink = styled.span`
  text-decoration: none;
  color: ${color('link')};
  cursor: pointer;

  &:hover {
    color: ${color('text')};
    text-decoration: none;
  }

  svg {
    margin-top: -0.125rem;
  }
`;

const RemoveButton = styled(TextLinkButton)`
  font-size: ${rem(13)};
  line-height: ${26 / 13};
  color: ${color('brand.error')};
  margin-left: auto;

  &:hover {
    color: ${color('text')};
    text-decoration: none;
  }
`;

export default UploadedResource;
