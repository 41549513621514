/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';
import trim from 'lodash/trim';

/**
 * Returns true if value is empty. Pass this function to Yup.array.compact()
 * @param {string} value to validate
 */
export function EmptyValueRejector(value) {
  return !value || !trim(value);
}

/**
 * Validates value's, which must be object, fields.
 * @param {string} errorMsg error message to show when validation fails.
 */
function atLeastOneOfValidator(errorMsg) {
  return this.test({
    name: 'atLeastOneOf',
    message: errorMsg,
    exclusive: true,
    test: value => Object.keys(value).some(f => !!value[f]),
  });
}

// == Add custom validation methods to Yup
Yup.addMethod(Yup.object, 'atLeastOneOf', atLeastOneOfValidator);
