import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useMediaQuery } from 'react-responsive';
import { color, rem } from 'theme/lib';
import Button from 'shared/components/Button/Button';

function Instructions({ className, icon, title, acceptedTypes }) {
  const isMediumScreen = useMediaQuery({ query: '(min-width: 640px)' });

  return (
    <StyledInstructions className={className}>
      {icon && icon}
      <Title>{title}</Title>
      {isMediumScreen ? (
        <Content>
          Drag and drop here or <StyledLink>select file</StyledLink>
        </Content>
      ) : (
        <MobileUploadButton shape="pill">Upload Photo</MobileUploadButton>
      )}
      <AcceptedTypes>{acceptedTypes}</AcceptedTypes>
    </StyledInstructions>
  );
}

Instructions.propTypes = {
  title: PropTypes.string.isRequired,
  acceptedTypes: PropTypes.string.isRequired,
  icon: PropTypes.element,
};

Instructions.defaultProps = {
  icon: null,
};

const StyledInstructions = styled.div`
  font-size: ${rem(14)};
  line-height: ${20 / 14};
  text-align: center;
  color: ${color('textSecondary')};
  padding-top: ${rem(32)};
  padding-bottom: ${rem(32)};
`;

const Title = styled.h4`
  color: ${color('blacks.100')};
  font-weight: 500;
  margin: 0;
  padding: 0;
`;

const Content = styled.div`
  margin-top: ${rem(4)};
  margin-bottom: ${rem(4)};
`;

const StyledLink = styled.span`
  color: #0091ff;
  text-decoration: underline;
`;

const AcceptedTypes = styled.div`
  color: ${color('form.inputPlaceholder')};
  font-size: ${rem(13)};
`;

const MobileUploadButton = styled(Button)`
  margin-top: ${rem(8)};
  margin-bottom: ${rem(16)};
  pointer-events: none; /* Pass through events to the label underneath, which triggers upload */
`;

export default Instructions;
