import { useCallback, useEffect, useState } from 'react';

/**
 * Returns the new values of getBoundingClientRect() for a component when the
 * window is resized.
 */
export default function useRectAfterWindowResize(ref) {
  const [rect, setRect] = useState(0);

  const handleResize = useCallback(() => {
    const newRect = ref.current.getBoundingClientRect();
    setRect(newRect);
  }, [ref]);

  window.addEventListener('resize', handleResize);

  useEffect(() => {
    const newRect = ref.current.getBoundingClientRect();
    setRect(newRect);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref, handleResize]);

  return rect;
}
