import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';
import { color } from 'theme/lib';
import { baseFontSize } from './config';

/**
 * Define global styles for elements that are not components.
 * These are used by the application and Storybook.
 */
const GlobalStyles = createGlobalStyle`
  ${normalize()}

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    font-size: ${baseFontSize};
  }

  body {
    margin: 0;
    padding: 0;
    font-family: "Favorit", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1rem;
    line-height: ${20 / 16};
    color: ${color('text')};
    /* Reduce the font weight slightly to match the design. */
    -webkit-font-smoothing: antialiased;
  }

  strong {
    font-weight: 500;
  }

  a {
    color: ${color('link')};
    text-decoration: underline;

    &:hover {
      color: ${color('text')};
    }
  }
`;

export default GlobalStyles;
