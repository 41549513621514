import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { color, rem } from 'theme/lib';
import media from 'theme/media';
import ProgressBar from 'shared/components/ProgressBar/ProgressBar';
import Logo from 'shared/components/Logo/Logo';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  max-width: ${rem(1440)};
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${rem(15)};

  ${media.up('medium')} {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: ${rem(40)};
    padding-right: ${rem(45)};
  }
`;

export const StyledLogo = styled(Logo)`
  margin-right: ${rem(25)};
  width: ${rem(100)};
  height: ${rem(32.5)};
  margin-bottom: ${rem(8)};

  ${media.up('medium')} {
    margin-bottom: 0;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  margin-bottom: 1em;
  flex: none;

  ${media.up('medium')} {
    flex: 1 1 auto;
    margin-bottom: 0;
  }
`;

export const SaveMessage = styled.div`
  position: absolute;
  flex: none;
  bottom: ${rem(-30)};
  left: 0;
  right: 0;
  text-align: right;
  font-size: ${rem(13)};
  line-height: ${20 / 13};
  padding: ${rem(5)} ${rem(15)};
  background-color: ${color('white')};

  ${media.up('medium')} {
    position: static;
    bottom: auto;
    right: auto;
    margin-bottom: 0;
    margin-left: ${rem(20)};
    font-size: ${rem(16)};
    line-height: ${20 / 16};
  }

  ${media.up('huge')} {
    flex: 0 1 ${rem(410)};
    text-align: left;
  }
`;

export const SaveLink = styled(Link)`
  /* Force line wrapping to fit long messages. */
  @media screen and (min-width: 640px) and (max-width: 800px) {
    display: block;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.2;
    `}
`;

export const StyledProgressBar = styled(ProgressBar)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
`;

export const StyledSignOut = styled.div`
  position: absolute;
  flex: none;
  bottom: ${rem(-52)};
  left: 0;
  right: 0;
  text-align: right;
  font-size: ${rem(13)};
  line-height: ${20 / 13};
  padding: ${rem(5)} ${rem(15)};
  background-color: ${color('white')};

  ${media.up('medium')} {
    position: static;
    bottom: auto;
    right: auto;
    margin-bottom: 0;
    margin-left: ${rem(20)};
    font-size: ${rem(16)};
    line-height: ${20 / 16};
  }

  ${media.up('huge')} {
    flex: 0 1 ${rem(510)};
    text-align: left;
  }
`;

export const SignOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ${media.up('large')} {
    flex-direction: row;
  }
`;

export const SupportWrapper = styled.div`
  display: flex;
`;

export const SignOutMessage = styled.div`
  margin-right: ${rem(4)};
`;

export const EmailLink = styled(Link)``;

export const SignOutLink = styled(Link)`
  margin-left: ${rem(12)};
  ${media.up('large')} {
    margin-left: ${rem(32)};
  }
`;
