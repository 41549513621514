import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
  Route,
  useLocation,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { useAnimation } from 'framer-motion';
import { joinRoutes } from 'shared/utils/string';
import withNavigation from './withNavigation';

export default function WizardNavigation({
  initialStep,
  parentStep,
  globalTotalSteps,
  entryRoute,
  exitRoute,
  baseRoute,
  screens,
  Layout,
}) {
  // Redirect to the first screen if the route doesn't match any steps in the wizard
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch(screens.map(s => joinRoutes(baseRoute, s.route)));
  useEffect(() => {
    if (!match) {
      history.replace(joinRoutes(baseRoute, screens[0].route));
    }
  }, [baseRoute, history, match, screens]);

  // Navigation Functions for the layout component. Useful for global footers
  const [navigationFunctions, setNavigationFunctions] = useState({
    goNext: () => {},
    goBack: () => {},
  });

  // Is the user navigating back through the history?
  const [isDirectionReversed, setDirectionReversed] = useState(false);

  // Framer Motion animation controls for the sidebar.
  const sidebarControls = useAnimation();

  // Figure out how many steps there are in the wizard. Including any sub wizards.
  // Also track the number of steps that occurred before each step (including sub steps).
  // These values play a factor in tracking the global wizard progress.
  const { localPreviousSteps, localTotalSteps } = useMemo(() => {
    return screens.reduce(
      (acc, curr) => {
        acc.localPreviousSteps.push(acc.localTotalSteps);
        acc.localTotalSteps += get(curr, 'Content.screens.length', 1);
        return acc;
      },
      { localPreviousSteps: [], localTotalSteps: 0 }
    );
  }, [screens]);

  // Recursively find the currently rendered steps. It is important that the parameters
  // are not listed as a dependency to memoizing the content; that would cause the content array to be
  // rebuilt on every path change.
  const { currentlyRenderedLocal, currentlyRenderedGlobal } = useMemo(() => {
    return findCurrentlyRendered(
      screens,
      baseRoute,
      initialStep,
      location.pathname
    );
  }, [baseRoute, initialStep, location.pathname, screens]);

  // Handle coming to the wizard from any screen, and returning to that same
  // screen when 'going back' from the first step.
  const entry = get(location, 'state.entry', entryRoute);

  // Fires when the content area animation begins so the sidebar animations
  // can be triggered.
  const handleContentAnimationStart = useCallback(
    async (exitVariant = '') => {
      const exitSequence = async () => {
        if (!exitVariant) {
          return null;
        }

        return sidebarControls.start(exitVariant);
      };

      await exitSequence();
      return sidebarControls.start('in');
    },
    [sidebarControls]
  );

  const content = useMemo(() => {
    return screens.map((screen, idx) => {
      // Use the exitRoute when 'going forward' from the last screen
      const nextRoute =
        idx === screens.length - 1
          ? exitRoute
          : joinRoutes(baseRoute, screens[idx + 1].route);

      // Use the entry when 'going back' from the first screen
      const previousRoute =
        idx === 0 ? entry : buildPreviousRoute(baseRoute, screens[idx - 1]);

      // Position of this screen in the wizard
      const localStep = idx + 1;

      const NavigationStep = withNavigation(screen.Content, {
        route: joinRoutes(baseRoute, screen.route),
        nextRoute,
        previousRoute,
        localStep,
        isDirectionReversed,
        setDirectionReversed,
        globalStep: initialStep + localPreviousSteps[idx],
        setNavigationFunctions,
      });

      return (
        <NavigationStep
          key={screen.route}
          globalTotalSteps={globalTotalSteps || localTotalSteps}
          localTotalSteps={localTotalSteps}
          parentStep={parentStep}
          onContentAnimationStart={handleContentAnimationStart}
        />
      );
    });
  }, [
    baseRoute,
    entry,
    exitRoute,
    initialStep,
    parentStep,
    screens,
    globalTotalSteps,
    localTotalSteps,
    isDirectionReversed,
    handleContentAnimationStart,
    localPreviousSteps,
  ]);

  const sideBars = useMemo(() => {
    return screens
      .filter(exists => exists)
      .map(screen => {
        const route = joinRoutes(baseRoute, screen.route);
        return (
          <Route key={route} path={route}>
            {screen.Sidebar ? <screen.Sidebar /> : null}
          </Route>
        );
      });
  }, [baseRoute, screens]);

  return (
    <Layout
      content={content}
      sidebar={sideBars}
      sidebarControls={sidebarControls}
      isDirectionReversed={isDirectionReversed}
      initialStep={initialStep}
      localStep={currentlyRenderedLocal}
      globalStep={currentlyRenderedGlobal}
      parentStep={parentStep}
      globalTotalSteps={globalTotalSteps || localTotalSteps}
      localTotalSteps={localTotalSteps}
      goBack={navigationFunctions.goBack}
      goNext={navigationFunctions.goNext}
    />
  );
}

WizardNavigation.propTypes = {
  /** Control the layout of the content and sidebar */
  Layout: PropTypes.elementType,

  /** The individual steps on the navigator */
  screens: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string.isRequired,
      Content: PropTypes.elementType.isRequired,
      Sidebar: PropTypes.elementType,
    })
  ).isRequired,

  /** Initial global step of the wizard. Useful for having nested wizards */
  initialStep: PropTypes.number,

  /** Local parent step of the wizard. Useful for having nested wizards */
  parentStep: PropTypes.number,

  /** Totals steps of the overall wizard. Useful for having nested wizards */
  globalTotalSteps: PropTypes.number,

  /** Where the navigator should exit to upon completion */
  exitRoute: PropTypes.string.isRequired,

  /** A base route to be applied to all navigation steps */
  baseRoute: PropTypes.string,

  /**
   * The default route to return to if the user 'goes back' from the first step.
   * Use a location state parameter if the wizard can be launched from multiple
   * different locations.
   */
  entryRoute: PropTypes.string,
};

WizardNavigation.defaultProps = {
  Layout: ({ content }) => <>{content}</>, // eslint-disable-line react/prop-types
  initialStep: 1,
  parentStep: 0,
  globalTotalSteps: 0,
  baseRoute: '/',
  entryRoute: '/',
};

/**
 * Recursively build the route that a screen should 'go back' to. This takes into
 * the previous screen being a navigation wizard and will use the last screen in that wizard.
 * @param {string} base - The base route
 * @param {object} prevScreen - The previous screen in the navigator
 * @returns {string} The route of the previous step
 */
function buildPreviousRoute(base, prevScreen) {
  const route = joinRoutes(base, prevScreen.route);
  if (prevScreen.Content && prevScreen.Content.screens) {
    // The previous screen is a wizard navigator and we need the tour of its last screen
    const previousWizardScreens = prevScreen.Content.screens;
    return buildPreviousRoute(
      route,
      previousWizardScreens[previousWizardScreens.length - 1]
    );
  }

  return route;
}

function findCurrentlyRendered(screens, baseRoute, initialStep, pathname) {
  return screens.reduce(
    (acc, curr) => {
      const localStep = initialStep + acc.localTotalSteps;
      if (joinRoutes(baseRoute, curr.route) === pathname) {
        acc.currentlyRenderedGlobal = localStep;
        acc.currentlyRenderedLocal = localStep;
      }

      if (curr.Content.screens && curr.Content.screens.length) {
        const childProps = findCurrentlyRendered(
          curr.Content.screens,
          joinRoutes(baseRoute, curr.route),
          initialStep + acc.localTotalSteps,
          pathname
        );

        if (childProps.currentlyRenderedGlobal) {
          acc.currentlyRenderedGlobal = childProps.currentlyRenderedGlobal;
          acc.currentlyRenderedLocal = localStep;
        }

        acc.localTotalSteps += childProps.localTotalSteps;
      } else {
        acc.localTotalSteps += 1;
      }

      return acc;
    },
    {
      localTotalSteps: 0,
      currentlyRenderedLocal: 0,
      currentlyRenderedGlobal: 0,
    }
  );
}
