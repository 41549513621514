import React from 'react';
import styled from 'styled-components';
import { rem } from 'theme/lib';
import media from 'theme/media';
// import devonPortraitImg from './devon-portrait.jpg';
import devonLookingUpImg from './devon-looking-up.jpg';

function DevonSidebarImage({ className }) {
  return (
    <ImageWrapper className={className}>
      <Overlay />
      <Image
        src={devonLookingUpImg}
        alt="Devon Brooks - CEO and Founder of Sphere"
      />
    </ImageWrapper>
  );
}

DevonSidebarImage.propTypes = {};

DevonSidebarImage.defaultProps = {};

const ImageWrapper = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  ${media.up('large')} {
    height: calc(100% - ${rem(40)});
    margin-top: ${rem(20)};
    margin-bottom: ${rem(20)};
    border-radius: ${rem(10)};
  }
`;

const Image = styled.img`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.66);
  z-index: 2;
`;

export default DevonSidebarImage;
