/**
 * Theme helper functions are inspired by Styled System to reduce boilerplate
 * for accessing properties of a provided theme.
 * @link https://styled-system.com/
 */

import get from 'lodash/get';
import { hsla, parseToHsl, rem as polishedRem } from 'polished';
import { baseFontSize } from './config';

/**
 * Return the value of any property defined in the theme.
 */
export function themeProp(key = '') {
  return ({ theme }) => {
    const value = get(theme, key);

    if (!value) {
      console.warn(`Theme: themeProp('${key}') is not defined.`);
    }

    return value;
  };
}

/**
 * Return the value of any color defined in the theme.
 */
export function color(key = 'black') {
  return ({ theme }) => {
    const value = get(theme.colors, key);

    if (!value) {
      console.warn(`Theme: color('${key}') is not defined.`);
    }

    return value || '#000';
  };
}

/**
 * Return the white space value from the theme scale for the index provided.
 */
export function space(index = 3) {
  return ({ theme }) => {
    if (index > theme.space.length - 1) {
      console.warn(`Theme: space(${index}) is not defined.`);
    }

    return theme.space[index];
  };
}

/**
 * Return the font size value from the theme scale for the index provided.
 */
export function fontSize(index = 3) {
  return ({ theme }) => {
    if (index > theme.fontSizes.length - 1) {
      console.warn(`Theme: fontSize(${index}) is not defined.`);
    }

    return theme.fontSizes[index];
  };
}

/**
 * Return the font size value from the theme scale for the index provided.
 * @param {string} key Property of zIndexes to return numeric value for.
 * @param {number} modify Optional value to make relative adjustments to the z-index.
 */
export function zIndex(key = 'base', modify = 0) {
  return ({ theme }) => {
    const value = get(theme.zIndexes, key);

    if (!value) {
      console.warn(`Theme: zIndex('${key}') is not defined.`);
    }

    return value + modify || theme.zIndexes.base;
  };
}

/**
 * Adds the alpha channel to an HSL color string to control opacity.
 * @param {number} alpha Amount of transparency applied, 0 - 1.
 * @return {string} Color in hsla() format.
 */
export function hslToHsla(hslColor, alpha = 1) {
  const hslParsed = parseToHsl(hslColor);
  return hsla({
    ...hslParsed,
    alpha,
  });
}

/**
 * A rem function that sets the default base font-size
 */
export function rem(pxval, base = baseFontSize) {
  return polishedRem(pxval, base);
}
