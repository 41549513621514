// Amount of horizontal translating applied to in/exit animations.
const xForward = 60;
const xBack = xForward * -1;

const transition = (custom = {}) => ({
  type: 'tween',
  ease: 'anticipate',
  duration: 0.7,
  ...custom,
});

export default {
  // Animate content into view.
  in: {
    opacity: 1,
    x: 0,
    transition: transition(),
  },

  // Navigating forward animates content from left to right.
  goForwardInitial: {
    opacity: 0,
    x: xForward * -1,
    transition: transition(),
  },
  goForwardExit: {
    opacity: 0,
    x: xForward,
    transition: transition(),
  },

  // Navigating back animates content from right to left.
  goBackInitial: {
    opacity: 0,
    x: xBack * -1,
    transition: transition(),
  },
  goBackExit: {
    opacity: 0,
    x: xBack,
    transition: transition(),
  },
};
