import styled from 'styled-components';
import { rem, color } from 'theme/lib';

export const PrimaryBox = styled.div`
  background-color: ${color('brand.primary')};
  border-radius: ${rem(10)};
  padding: ${rem(20)};
`;

export const PrimaryBoxTitle = styled.div`
  text-transform: uppercase;
  font-size: ${rem(13)};
  color: ${color('brand.warning')};
  margin-bottom: ${rem(6)};
`;

export const PrimaryBoxSubtitle = styled.div`
  color: ${color('white')};
  margin-bottom: ${rem(30)};
`;
