import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { rem } from 'theme/lib';
import media from 'theme/media';
import CloudSelectorField from 'shared/components/formik/CloudSelectorField';
import TextField from 'shared/components/formik/TextField/TextField';
import {
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionRequirement,
} from 'shared/components/WizardQuestionForm';
import Button from 'shared/components/Button/Button';
import ErrorMessage from 'shared/components/ErrorMessage/ErrorMessage';
import useReportSource from '../../hooks/useReportSource';

const MoreTextField = styled(TextField)`
  margin-bottom: ${rem(20)};
`;

const StyledFormContent = styled(FormContent)`
  ${media.up('medium')} {
    margin-right: ${rem(0)};
  }
`;

const fieldOptions = [
  'A Coach',
  'Friend/Family',
  'Client',
  'Sphere Team',
  'Sphere Customer',
  'Online Ad',
  'Search Engine',
  'Facebook',
  'Instagram',
  'LinkedIn',
  'Twitter',
].map(v => ({ id: v, label: v }));

const validationSchema = Yup.object().shape({
  sources: Yup.array().min(1, 'Please select at least 1 source'),
  other: Yup.string(),
});

const initialValues = {
  sources: [],
  other: '',
};

function FindOutForm({ onSuccess }) {
  const [reportSource, { loading, error }] = useReportSource();
  const handleSubmit = useCallback(
    ({ sources, other }) => {
      reportSource(sources, other, onSuccess);
      window.location.assign('https://sphere.guide/how-it-works');
    },
    [onSuccess, reportSource]
  );

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ dirty, isValid }) => {
        const isSubmitDisabled = !dirty || !isValid || loading;
        return (
          <Form>
            <FormContentHeader>
              <QuestionHeading as="h1">
                How did you find out about Sphere?
              </QuestionHeading>
              <QuestionRequirement>Select all that apply.</QuestionRequirement>
            </FormContentHeader>

            <StyledFormContent width={470}>
              <Field
                name="sources"
                component={CloudSelectorField}
                items={fieldOptions}
              />
              <Field
                name="other"
                component={MoreTextField}
                placeholder="Tell us more"
              />
              <Button shape="pill" type="submit" disabled={isSubmitDisabled}>
                Submit
              </Button>
            </StyledFormContent>
            <ErrorMessage error={error} />
          </Form>
        );
      }}
    </Formik>
  );
}

FindOutForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default FindOutForm;
