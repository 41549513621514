import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rem } from 'theme/lib';
import OutlinedCheckbox from 'shared/components/OutlinedCheckbox/OutlinedCheckbox';

const ConductRule = ({ step = 1, question, accepted, onClick }) => {
  return (
    <>
      <RuleContainer>
        <Number>{step}.</Number>
        <Rule>{question}</Rule>
      </RuleContainer>
      <OutlinedCheckbox
        checked={accepted}
        disabled={accepted}
        name="acknowledge"
        label={
          <span>
            I acknowledge that I have read and understood this code of conduct.
          </span>
        }
        labelCSS={css`
          font-size: ${rem(16)};
          font-weight: 500;
          margin: ${rem(30)} 0 0;
          cursor: pointer;
        `}
        onClick={onClick}
      />
    </>
  );
};

const RuleContainer = styled.div`
  display: flex;
`;

const Rule = styled.div`
  margin: 0;
`;

const Number = styled.span`
  padding: 0 ${rem(10)} 0 0;
`;

ConductRule.propTypes = {
  step: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  accepted: PropTypes.bool,
};

ConductRule.defaultProps = {
  accepted: false,
};

export default ConductRule;
