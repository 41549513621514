import React, { useContext, useState, useEffect, useCallback } from 'react';
import get from 'lodash/get';
import { formatDistanceToNow } from 'date-fns';
import { useHistory } from 'react-router-dom';
import routes from 'shared/routes';
import GuideProfileContext from 'guideProfile/GuideProfileContext';
import { useGuideProfile } from 'guideProfile/hooks/queries';
import { SaveMessage, SaveLink } from './styled';

const UPDATE_SAVED_TIME_INTERVAL = 10000;

function SaveAndExitLink() {
  const [, setCount] = useState(0); // Used to trigger a rerender on an interval
  const history = useHistory();

  const {
    isScreenValid,
    updateProfile,
    currentScreenValues,
    queryHandler: { loading: isSaving },
  } = useContext(GuideProfileContext);
  const handleSave = useCallback(() => {
    if (!isScreenValid) {
      // Screen is not in a valid form state. Just exit without saving.
      history.push(routes.summaryBase);
      return;
    }

    updateProfile(currentScreenValues, () => {
      history.push(routes.summaryBase);
    });
  }, [currentScreenValues, history, isScreenValid, updateProfile]);

  const lastSaved = get(useGuideProfile(), 'data.updated_at');
  let saveMessage = lastSaved
    ? `Saved ${formatDistanceToNow(new Date(lastSaved), {
        includeSeconds: true,
        addSuffix: true,
      })}.`
    : '';

  if (isSaving) {
    saveMessage = 'Saving...';
  }

  // Rerender on an interval so that the savedMessage gets updated
  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count => count + 1);
    }, UPDATE_SAVED_TIME_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <SaveMessage>
      {saveMessage}{' '}
      <SaveLink to="#" onClick={handleSave}>
        Save and Exit
      </SaveLink>
    </SaveMessage>
  );
}

SaveAndExitLink.propTypes = {};

SaveAndExitLink.defaultProps = {};

export default SaveAndExitLink;
