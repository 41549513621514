import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import * as Yup from 'yup';
import converter from 'number-to-words';
import { rem } from 'theme/lib';
import CloudSelectorField from 'shared/components/formik/CloudSelectorField';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import { useProfileFieldOptions } from 'guideProfile/hooks/queries';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
} from 'shared/components/WizardQuestionForm';
import TextField from 'shared/components/formik/TextField/TextField';

const REQUIRED_OPTIONS_COUNT = 2;

const validationSchema = Yup.object().shape({
  keyword: Yup.array()
    .min(
      REQUIRED_OPTIONS_COUNT,
      `Please select ${converter.toWords(REQUIRED_OPTIONS_COUNT)}`
    )
    .max(
      REQUIRED_OPTIONS_COUNT,
      `Please select ${converter.toWords(REQUIRED_OPTIONS_COUNT)}`
    ),
  other: Yup.object().shape({
    keywords: Yup.string(),
  }),
});

export default function Keyword(props) {
  const fieldOptions = useProfileFieldOptions('keyword').data;

  return (
    <WizardQuestionForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValues={{
        keyword: [],
        other: {
          keywords: '',
        },
      }}
      validationSchema={validationSchema}
    >
      {({ values: { keyword, other } }) => {
        // Show the 'other' field if two or more options are enabled, or if the user
        // has already started to type in the field. We don't want the field to go away
        // if the user unselects an option.
        const showOtherField = !!(
          keyword.length >= REQUIRED_OPTIONS_COUNT || other.keywords
        );
        return (
          <FormContainer>
            <FormContentHeader>
              <QuestionHeading as="h1">
                How would you define yourself?
              </QuestionHeading>

              <QuestionDescription>
                We need this to properly tag you in our data-base.
                <br />
                Select two.
              </QuestionDescription>
            </FormContentHeader>

            <FormContent width={620}>
              <Field
                name="keyword"
                component={CloudSelectorField}
                items={fieldOptions}
              />

              {showOtherField && (
                <TextFieldWrapper>
                  <Field
                    name="other.keywords"
                    component={TextField}
                    placeholder="Would you define yourself any different? (optional)"
                  />
                </TextFieldWrapper>
              )}
            </FormContent>
          </FormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

Keyword.propTypes = withNavigationPropTypes;

const TextFieldWrapper = styled.div`
  max-width: ${rem(470)};
`;
