import React, { useCallback, useState } from 'react';
import get from 'lodash/get';
import styled, { keyframes } from 'styled-components';
import { rem, color } from 'theme/lib';
import { LoadingCircle } from 'assets/svg';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import Checklist from 'shared/components/Checklist/Checklist';
import ErrorMessage from 'shared/components/ErrorMessage/ErrorMessage';
import WaitForExternalData from 'shared/components/WaitForExternalData/WaitForExternalData';
import { H4 } from 'shared/components/styled/typography';
import Button from 'shared/components/Button/Button';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import { useCurrentUser } from 'auth/hooks/queries';
import Footer from '../Footer/Footer';
import useProtectCodeOfConduct from '../../hooks/useProtectCodeOfConduct';
import useAgreeToCodeOfConduct from '../../hooks/useAgreeToCodeOfConduct';
import {
  CODE_OF_CONDUCT_QUESTIONS,
  CAL_INTEGRATION_LINK,
} from '../../constants';

import {
  ConductLayout,
  ContentContainer,
  ContentWrapper,
} from '../CodeOfConductWizard/styled';

export default function Calendar({ goBack, goNext }) {
  // Make sure the code of conduct has been fully completed & signature given
  useProtectCodeOfConduct(CODE_OF_CONDUCT_QUESTIONS.length, true);

  const [hasClickedAccept, setHasClickedAccept] = useState(false);
  const [agreeToTerms, { loading, error }] = useAgreeToCodeOfConduct();

  const currentUser = useCurrentUser();
  const hasUserAcceptedCodeOfConduct = get(
    currentUser,
    'data.code_of_conduct',
    false
  );

  const isChecked = Boolean(hasUserAcceptedCodeOfConduct || hasClickedAccept);
  const handleContinue = useCallback(() => {
    if (hasUserAcceptedCodeOfConduct) {
      // Already accepted the terms, so just move to the next screen.
      goNext();
      return;
    }

    // Agree to the terms and move to the next screen on success
    agreeToTerms(goNext);
  }, [hasUserAcceptedCodeOfConduct, goNext, agreeToTerms]);

  const handleAccept = useCallback(() => {
    setHasClickedAccept(true);

    // Try to automatically agree to the terms and move to the next screen
    if (!isChecked) {
      agreeToTerms(goNext);
    }
  }, [goNext, agreeToTerms, isChecked]);

  return (
    <SplitLayout showLogo={false}>
      <SplitLayout.Main>
        <SplitLayout.MainContent>
          <WaitForExternalData external={currentUser}>
            <ConductLayout>
              <ContentContainer>
                <ContentWrapper>
                  <WaitForExternalData external={currentUser}>
                    <Heading>
                      Stellar Guidance starts with an accurate calendar.
                    </Heading>
                    <Content>
                      <p>
                        Seekers will book you instantly. As such, keep your
                        integrated calendar & availability up-to-date.
                      </p>
                      <p>
                        Missing sessions disrupts Seekers. If you miss a session
                        because your calendar is inaccurate, you&apos;ll still
                        be charged a $100 flat fee, as outlined in the Sphere
                        Code of Conduct.
                      </p>
                      <p>
                        <a
                          href={CAL_INTEGRATION_LINK}
                          target="_blank"
                          title="Sphere Help Center"
                          rel="noopener noreferrer"
                        >
                          Here is more information about calendar integrations
                          with Sphere.
                        </a>
                      </p>
                    </Content>
                    <HorizontalRule />
                    <AcknowledgeButton>
                      <Checklist.Item
                        onClick={handleAccept}
                        checked={isChecked}
                      >
                        I agree to all the terms and conditions mentioned above
                        {loading && <StyledLoadingCircle />}
                      </Checklist.Item>
                    </AcknowledgeButton>
                    <ErrorMessage error={error} />
                  </WaitForExternalData>
                </ContentWrapper>
              </ContentContainer>
            </ConductLayout>
          </WaitForExternalData>
        </SplitLayout.MainContent>
      </SplitLayout.Main>
      <Footer goBack={goBack}>
        <Button
          shape="pill"
          onClick={handleContinue}
          disabled={!isChecked || loading}
        >
          Continue
        </Button>
      </Footer>
    </SplitLayout>
  );
}

const loadingKeyframe = keyframes`
  from {
    transform: rotate(0);
  }
 to {
    transform: rotate(360deg);
  }
}`;

const Heading = styled(H4).attrs({
  as: 'h1',
})``;

const AcknowledgeButton = styled.button`
  border: none;
  padding: 0;
  margin: ${rem(40)} 0 0;
  background: none;
  outline: none;
  cursor: pointer;
  text-align: left;
`;

const HorizontalRule = styled.hr`
  border-top: solid 1px ${color('grays.400')};
  margin: ${rem(45)} 0 0;
`;

const Content = styled.div`
  font-size: ${rem(13)};
`;

const StyledLoadingCircle = styled(LoadingCircle)`
  animation: ${loadingKeyframe} 1s linear infinite;
  height: 20px;
  width: 20px;
  margin: 0 0 0 ${rem(10)};
  display: inline-block;
`;

Calendar.propTypes = withNavigationPropTypes;
