import { useState, useEffect, useMemo } from 'react';
import firebase from 'firebaseConfig';

export default function useToken() {
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setLoading(true);
        user.getIdToken().then(jwtToken => {
          setToken(jwtToken);
          setLoading(false);
        });
      } else {
        setToken('');
      }
    });

    // unsubscribe onUnmount
    return unsubscribe;
  }, []);

  return { token, loading };
}

export function useAuthorizationHeader() {
  const { token, loading } = useToken();
  return useMemo(
    () => ({
      header:
        loading || !token
          ? null
          : {
              authorization: `Bearer ${token}`,
            },
      loading,
    }),
    [loading, token]
  );
}
