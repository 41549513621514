import styled from 'styled-components/macro';
import { rem } from 'theme/lib';
import media from 'theme/media';

export const ForgotPasswordLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  ${media.up('medium')} {
    margin-top: 11vh;
    padding-right: ${rem(47)};
  }

  ${media.up('large')} {
    margin-top: 14.5vh;
    flex-flow: row nowrap;
  }
`;

export const Container = styled.div`
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding-bottom: 0;

  ${media.up('large')} {
    padding-bottom: ${rem(80)};
    max-width: ${rem(475)};
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  margin-bottom: ${rem(48)};

  ${media.up('large')} {
    margin-bottom: 0;
  }
`;

export const ImageContainer = styled.div`
  flex: 1 1 55%;
  padding-top: ${rem(100)};
  padding-bottom: ${rem(33)};
  ${media.up('large')} {
    padding-bottom: 0;
    padding-top: 0;
  }
`;

export const StyledImg = styled.img`
  width: 100%;
  max-width: ${rem(500)};
  height: auto;

  ${media.up('medium')} {
    max-width: 100%;
  }

  ${media.up('large')} {
    margin-bottom: ${rem(80)};
  }
`;
