import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import routes from 'shared/routes';
import IntroScreen from 'screens/IntroScreen/IntroScreen';
import QuestionnaireWizard from 'screens/QuestionnaireWizard/QuestionnaireWizard';
import AboutWizard from 'screens/AboutWizard/AboutWizard';
import CertificationsWizard from 'screens/CertificationsWizard/CertificationsWizard';
import PhotosWizard from 'screens/PhotosWizard/PhotosWizard';
import SummaryScreen from 'screens/SummaryScreen/SummaryScreen';
import SignInScreen from 'screens/SignInScreen/SignInScreen';
import ForgotPasswordScreen from 'screens/ForgotPasswordScreen/ForgotPasswordScreen';
import UpdatePasswordScreen from 'screens/UpdatePasswordScreen/UpdatePasswordScreen';
import ApplicationApprovedWizard from 'screens/ApplicationApprovedWizard/ApplicationApprovedWizard';
import ErrorScreen from 'screens/ErrorScreen/ErrorScreen';
import ApplicationStatusScreen from 'screens/ApplicationStatusScreen/ApplicationStatusScreen';
import CancelApplicationScreen from 'screens/CancelApplicationScreen/CancelApplicationScreen';
import SignUpScreen from 'screens/SignUpScreen/SignUpScreen';
import VerifyScreen from 'screens/VerifyScreen/VerifyScreen';
import makeWizardNavigator from 'shared/wizardNavigation';
import ProtectedRoute from 'auth/components/ProtectedRoute/ProtectedRoute';

const WizardNavigator = makeWizardNavigator({
  entryRoute: '/',
  exitRoute: '/',
  screens: [
    {
      route: routes.questionnaireBase,
      Content: QuestionnaireWizard,
    },
    {
      route: routes.photosBase,
      Content: PhotosWizard,
    },
    {
      route: routes.aboutYouBase,
      Content: AboutWizard,
    },
    {
      route: routes.credentialsBase,
      Content: CertificationsWizard,
    },
  ],
});

function App() {
  return (
    <Router>
      <Switch>
        <Route path={routes.error} component={ErrorScreen} />
        <Route path={routes.login} component={SignInScreen} />
        <Route path={routes.signup} component={SignUpScreen} />
        <Route path={routes.forgotPassword} component={ForgotPasswordScreen} />
        <Route path={routes.updatePassword} component={UpdatePasswordScreen} />
        <ProtectedRoute path={routes.verify} component={VerifyScreen} />
        <ProtectedRoute path={routes.summaryBase} component={SummaryScreen} />
        <ProtectedRoute
          path={routes.questionnaireBase}
          component={WizardNavigator}
        />
        <ProtectedRoute
          path={routes.aboutYouBase}
          component={WizardNavigator}
        />
        <ProtectedRoute
          path={routes.credentialsBase}
          component={WizardNavigator}
        />
        <ProtectedRoute
          path={routes.applicationStatus}
          component={ApplicationStatusScreen}
        />
        <ProtectedRoute
          path={routes.cancelApplication}
          component={CancelApplicationScreen}
        />
        <ProtectedRoute
          path={routes.applicationApprovedBase}
          component={ApplicationApprovedWizard}
        />
        <ProtectedRoute path={routes.photosBase} component={WizardNavigator} />
        <Route path="/" component={IntroScreen} />
      </Switch>
    </Router>
  );
}

export default App;
