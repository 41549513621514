import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { rem, color } from 'theme/lib';
import useSubmitApplication from 'guideProfile/hooks/useSubmitApplication';
import OutlinedCheckboxField from 'shared/components/formik/CheckboxField/CheckboxField';
import Button from 'shared/components/Button/Button';
import ErrorMessage from 'shared/components/ErrorMessage/ErrorMessage';
import {
  PrimaryBox,
  PrimaryBoxTitle,
  PrimaryBoxSubtitle,
} from 'shared/components/styled/primaryBox';

const initialValues = {
  details: false,
  termsAndConditions: false,
};

const validationSchema = Yup.object().shape({
  details: Yup.boolean().oneOf([true], 'Required'),
  termsAndConditions: Yup.boolean().oneOf([true], 'Required'),
});

function FinalStep({ className }) {
  const [
    handleSubmit,
    { loading: isSubmitting, error },
  ] = useSubmitApplication();

  return (
    <PrimaryBox className={className}>
      <PrimaryBoxTitle>Profile Complete</PrimaryBoxTitle>
      <PrimaryBoxSubtitle>
        If you&apos;re happy with how your profile looks, submit now. You are
        unable to edit any further during this process.
      </PrimaryBoxSubtitle>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => (
          <StyledForm>
            <StyledField
              name="details"
              icon="bordered"
              component={OutlinedCheckboxField}
            >
              I verify that all the details I provided are accurate and true
            </StyledField>
            <StyledField
              name="termsAndConditions"
              icon="bordered"
              component={OutlinedCheckboxField}
            >
              I agree to the
              <a
                href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                terms & conditions
              </a>
            </StyledField>
            <ErrorMessage error={error} />
            <StyledButton
              shape="pill"
              size="small"
              color="default"
              inverted
              type="submit"
              disabled={!dirty || !isValid || isSubmitting}
            >
              Apply Now!
            </StyledButton>
          </StyledForm>
        )}
      </Formik>
    </PrimaryBox>
  );
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const StyledField = styled(Field)`
  margin-bottom: ${rem(10)};
  font-weight: 500;
  color: ${color('white')};
  a {
    color: ${color('white')};
    margin-left: ${rem(4)};
    text-decoration: underline;
    &:hover {
      color: ${color('grays.300')};
    }
  }
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
`;

export default FinalStep;
