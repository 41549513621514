import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'shared/components/Button/Button';
import { useCurrentUser } from 'auth/hooks/queries';
import useAddPaymentMethod from '../../hooks/useAddPaymentMethod';
import usePaymentMethodItem from '../../hooks/usePaymentMethodItem';
import { H5 } from '../../../shared/components/styled/typography';
import { PRIVACY_POLICY_LINK } from '../../constants';

const options = {
  hidePostalCode: true,
  style: {
    base: {
      fontSize: '18px',
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const CreditCard = ({ stripe }) => {
  const { data: currentUser } = useCurrentUser();
  const { paymentMethods } = currentUser;
  const [isDisabled, setDisabled] = useState(Boolean(paymentMethods));
  const [token, setToken] = useState('');
  const last4 = usePaymentMethodItem('last4');
  const brand = usePaymentMethodItem('brand');

  const { addPaymentMethod, error, loading } = useAddPaymentMethod(token);

  useEffect(() => {
    const elements = stripe.elements();
    const card = elements.create('card', options);

    const handleStripeInputs = ({ complete }) => {
      setToken('');
      if (!paymentMethods.length) setDisabled(!complete);
      if (complete) {
        stripe
          .createToken(card)
          .then(data => {
            const {
              token: { id },
            } = data;
            setToken(id);
          })
          .catch(() => {});
      }
    };

    card.mount('#card-element');
    card.on('change', handleStripeInputs);
  }, [stripe, paymentMethods]);

  useEffect(() => {
    if (paymentMethods && paymentMethods.length) {
      setDisabled(true);
    }
  }, [paymentMethods]);

  const handleAddPaymentMethod = useCallback(() => {
    addPaymentMethod();
  }, [addPaymentMethod]);

  const buttonText = useMemo(() => {
    return brand && paymentMethods && paymentMethods.length
      ? `${brand.charAt(0).toUpperCase() +
          brand.substr(1)} ending in ${last4} added`
      : 'Add Card';
  }, [paymentMethods, brand, last4]);

  return (
    <Container>
      <p>
        We want this to be a win-win for stellar Guides like you, so there is
        absolutely no fee to join to Sphere.
      </p>
      <p>
        As laid out in the Code of Conduct, and in your Guide interview, your
        credit card details will only be used in the event that you:
      </p>
      <ul>
        <li>
          cancel a session within 24 hours, reschedule the same session more
          than twice, or no-show a session [this results in a $100 flat fee]
        </li>
        <li>
          leave Sphere prior to 18-months from the time your profile is first
          activated & published to our ecosystem [this results in a $489 flat
          fee]
        </li>
        <li>
          breach the Code of Conduct in anyway, and/or are removed by Sphere at
          any time due to a breach of the Code of Conduct [this results in a
          $489 flat fee]
        </li>
      </ul>
      <p>
        To protect the integrity of the Sphere experience for all, should any of
        the above occur, the associated fee/fine will be charged immediately. If
        the the charge is not successful, Sphere maintains the right to withhold
        any outstanding payments until the situation is rectified. The intent
        here is to hold a relationship with you of mutual respect and
        accountability, and grow the global impact of coaching together. Read
        our{' '}
        <a href={PRIVACY_POLICY_LINK} target="_blank" rel="noopener noreferrer">
          privacy policy
        </a>
        .
      </p>

      <CardElement id="card-element" isVisible={Boolean(!last4)} />

      {error &&
        error.graphQLErrors.map(({ message }, index) => (
          // eslint-disable-next-line
          <Error key={index}>{message}</Error>
        ))}

      <Button
        disabled={isDisabled || loading}
        size="small"
        onClick={handleAddPaymentMethod}
        style={{ marginTop: 20 }}
      >
        {buttonText}
      </Button>
    </Container>
  );
};

CreditCard.propTypes = {
  // eslint-disable-next-line
  stripe: PropTypes.object.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
`;

const CardElement = styled.div`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  height: 40px;
  margin: 30px 0;
`;

const Error = styled(H5)`
  color: ${({ theme }) => theme.colors.brand.error};
  margin-top: -35px; // position over card element margin
`;

export default CreditCard;
