import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { color, rem } from 'theme/lib';
import ProgressBar from 'shared/components/ProgressBar/ProgressBar';
import { IconBigArrowDown } from 'assets/svg';

function SceneProgress({
  className,
  sceneIndex,
  percent,
  isLastScene,
  onGoBack,
  onGoForward,
}) {
  return (
    <div className={className}>
      <ProgressBarWrapper>
        <InstructionText isLastScene={isLastScene}>
          {/* Changing the message until vertical scroll is implemented */}
          Click the arrow to learn more
        </InstructionText>
        <ProgressBar
          trackColor={sceneIndex === 1 ? '#fff' : ''}
          percent={sceneIndex === 1 ? 0 : percent}
          size="small"
        />
      </ProgressBarWrapper>

      <ArrowButton
        as={motion.button}
        animate={{
          y: [0, 5, 0],
          transition: {
            ease: 'easeOut',
            duration: 0.5,
            delay: 3,
          },
        }}
        whileHover={{
          y: 5,
          transition: {
            ease: 'easeOut',
            duration: 1,
          },
        }}
        disabled={isLastScene}
        onClick={onGoForward}
      >
        <StyledIconBigArrowDown />
      </ArrowButton>

      <ArrowButton disabled={sceneIndex === 1} onClick={onGoBack}>
        <StyledIconBigArrowUp />
      </ArrowButton>
    </div>
  );
}

SceneProgress.propTypes = {
  percent: PropTypes.number,
  sceneIndex: PropTypes.number,
  isLastScene: PropTypes.bool,
  onGoBack: PropTypes.func,
  onGoForward: PropTypes.func,
};

SceneProgress.defaultProps = {
  percent: 0,
  sceneIndex: 1,
  isLastScene: false,
  onGoBack: () => {},
  onGoForward: () => {},
};

const InstructionText = styled.p`
  flex: none;
  font-weight: 500;
  margin: 0 ${rem(8)} 0 0;

  ${({ isLastScene }) =>
    isLastScene &&
    css`
      color: ${color('textDisabled')};
    `}
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIconBigArrowDown = styled(IconBigArrowDown)`
  display: block;
  margin-top: ${rem(10)};
  transition: transform 0.3s;
  color: ${color('brand.primary')};
`;

const StyledIconBigArrowUp = styled(StyledIconBigArrowDown)`
  transform: rotate(180deg);
`;

const ArrowButton = styled.button`
  border: 0;
  padding: 0;
  outline: 0;
  background: transparent;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s;

  &[disabled] {
    opacity: 0;
    pointer-events: none;
  }

  & + & {
    margin-left: ${rem(10)};
  }
`;

export default SceneProgress;
