/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import styled from 'styled-components/macro';
import { useMediaQuery } from 'react-responsive';
import { color, zIndex, rem } from 'theme/lib';
import media, { breakpoints } from 'theme/media';
import { useHistory } from 'react-router-dom';
import routes from 'shared/routes';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import WaitForExternalData from 'shared/components/WaitForExternalData/WaitForExternalData';
import SummaryHeader from 'summary/components/SummaryHeader/SummaryHeader';
import StepNumberedList from 'summary/components/StepNumberedList/StepNumberedList';
import StepSummary from 'summary/components/StepSummary/StepSummary';
import StepBreadcrumbs from 'summary/components/StepBreadcrumbs/StepBreadcrumbs';
import SummaryFooter from 'summary/components/SummaryFooter/SummaryFooter';
import ViewProfileAction from 'summary/components/ViewProfileAction/ViewProfileAction';
import MobileModal from 'shared/components/MobileModal/MobileModal';
import ProfilePreview from 'shared/components/ProfilePreview/ProfilePreview';
import * as SummarySidebars from 'summary/components/sidebars';
import FinalStep from 'summary/components/FinalStep/FinalStep';
import { TextLinkButton } from 'shared/components/styled/buttons';
import { STATUS_NOT_SUBMITTED, STATUS_REJECTED } from 'guideProfile/constants';
import { useGuideProfile } from 'guideProfile/hooks/queries';
import useProfilePreview from 'shared/components/ProfilePreview/useProfilePreview';
import { SummaryLayout, BreadcrumbsWrapper, StepDivider } from './styled';

const defaultMilestoneProgress = [
  {
    name: 'questionnaire',
    title: 'Questionnaire',
    subTitle: 'Answer questions about your experience as a coach',
    progress: 0,
  },
  {
    name: 'profilePhotos',
    title: 'Profile',
    subTitle: 'Add stunning images of yourself to your profile.',
    progress: 0,
  },
  {
    name: 'aboutYou',
    title: 'About You',
    subTitle: 'Give us a peek into who you are and your past experience.',
    progress: 0,
  },
  {
    name: 'credentials',
    title: 'Certifications & Testimonials',
    subTitle: 'Your education and training related to your coaching practice',
    progress: 0,
  },
];

function SummaryScreen({ className }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const guideProfile = useGuideProfile();
  const applicationStatus = get(guideProfile, 'data.application_status');
  const guideName = get(guideProfile, 'data.name');

  const isLargeScreen = useMediaQuery({
    query: `(min-width: ${breakpoints.large}px)`,
  });

  const history = useHistory();
  useEffect(() => {
    // Only allow applicants to view this page if they have not submitted their application
    // or if they have been rejected and require edits.
    if (
      applicationStatus &&
      applicationStatus !== STATUS_NOT_SUBMITTED &&
      applicationStatus !== STATUS_REJECTED
    ) {
      history.push(routes.applicationStatus);
    }
  }, [history, applicationStatus]);

  let milestoneProgress = defaultMilestoneProgress;
  if (guideProfile.data) {
    // Merge the defaults with the actual progress
    milestoneProgress = defaultMilestoneProgress.map((milestone, idx) => {
      const actualProgress = get(
        guideProfile,
        `data.applicationProgress[${milestone.name}]`,
        {}
      );
      const previousMilestone = defaultMilestoneProgress[idx - 1];

      return {
        ...milestone,
        ...actualProgress,
        isPreviousStepPending: !!(
          previousMilestone && previousMilestone.progress === 0
        ),
      };
    });
  }

  const currentStep = milestoneProgress.findIndex(m => m.progress < 100) + 1;
  const showFinalStep = currentStep === 0;
  const isStepOne = currentStep === 1;

  const {
    profileToShow,
    canShowOwnProfile,
    switchPreviewButtonLabel,
    handleSwitchPreviewButtonClick,
  } = useProfilePreview();

  const handleViewProfile = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <SplitLayout className={className}>
      <WaitForExternalData external={guideProfile}>
        <SplitLayout.Main>
          <SummaryLayout>
            <SummaryHeader step={currentStep} name={guideName} />

            {isStepOne ? (
              <>
                <StepNumberedList />
                <StepSummary
                  step={1}
                  currentStep={currentStep}
                  {...milestoneProgress[0]}
                />
                <StepBreadcrumbs progress={milestoneProgress} />
              </>
            ) : (
              <>
                {!showFinalStep && (
                  <BreadcrumbsWrapper>
                    <StepBreadcrumbs progress={milestoneProgress} />
                  </BreadcrumbsWrapper>
                )}

                <ViewProfileAction
                  onViewProfile={handleViewProfile}
                  canShowOwnProfile={canShowOwnProfile}
                />

                {isModalOpen && (
                  <MobileModal onClose={handleModalClose}>
                    <ProfilePreview profile={profileToShow}>
                      {switchPreviewButtonLabel && (
                        <StyledTextLinkButton
                          type="button"
                          onClick={handleSwitchPreviewButtonClick}
                        >
                          {switchPreviewButtonLabel}
                        </StyledTextLinkButton>
                      )}
                    </ProfilePreview>
                  </MobileModal>
                )}

                <StepSummary
                  step={1}
                  currentStep={currentStep}
                  {...milestoneProgress[0]}
                />
                <StepDivider />
                <StepSummary
                  step={2}
                  currentStep={currentStep}
                  {...milestoneProgress[1]}
                />
                <StepDivider />
                <StepSummary
                  step={3}
                  currentStep={currentStep}
                  {...milestoneProgress[2]}
                />
                <StepDivider />
                <StepSummary
                  step={4}
                  currentStep={currentStep}
                  {...milestoneProgress[3]}
                />
                {showFinalStep && (
                  <>
                    <StepDivider />
                    <FinalStep />
                  </>
                )}
              </>
            )}
            <SummaryFooter />
          </SummaryLayout>
        </SplitLayout.Main>

        {isStepOne ? (
          <ImageSidebar
            fixedContent={!isLargeScreen}
            className={className}
            width={[null, 368, 417, 572]}
          >
            <SummarySidebars.DevonImage />
          </ImageSidebar>
        ) : (
          <PreviewSidebar width={[null, null, 417, 515]} bg>
            <SummarySidebars.ProfilePreview />
          </PreviewSidebar>
        )}
      </WaitForExternalData>
    </SplitLayout>
  );
}

const ImageSidebar = styled(SplitLayout.Sidebar)`
  position: relative;
  z-index: ${zIndex('fixedHeader')};
  display: none;

  ${media.up('medium')} {
    display: block;
    min-height: 100vh;
  }
`;

const PreviewSidebar = styled(SplitLayout.Sidebar)`
  background: ${color('black')};
  z-index: ${zIndex('fixedHeader')};
  display: none;
  &::after {
    background: ${color('black')};
  }

  ${media.up('medium')} {
    min-height: 100vh;
  }

  ${media.up('large')} {
    display: block;
  }
`;

const StyledTextLinkButton = styled(TextLinkButton)`
  color: ${color('white')};
  padding-top: ${rem(20)};
  padding-left: ${rem(5)};
  margin-bottom: ${rem(15)};
`;

SummaryScreen.propTypes = {};
SummaryScreen.defaultProps = {};

export default SummaryScreen;
