import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rem } from 'theme/lib';
import { makeVariantStyles } from './variants';

function Button({ className, children, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledButton className={className} {...props}>
      {children && children}
    </StyledButton>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  variant: PropTypes.oneOf(['contained', 'outlined']),
  color: PropTypes.oneOf(['primary', 'default', 'secondary']),
  shape: PropTypes.oneOf(['rounded', 'pill']),
  /**  Applies smaller font-size and padding */
  size: PropTypes.oneOf(['default', 'small']),
  /**  Inverts background and color props (only applies to contained buttons ) */
  inverted: PropTypes.bool,
  /**  Allows button to occupy the full width of it's parent container */
  block: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  children: null,
  type: 'button',
  variant: 'contained',
  color: 'primary',
  shape: 'rounded',
  size: 'default',
  inverted: false,
  block: false,
  onClick: () => {},
};

const StyledButton = styled.button`
  border: 1px solid;
  border-radius: ${rem(10)};
  min-width: ${rem(190)};
  padding: ${rem(5)} ${rem(25)};
  font-size: ${rem(25)};
  outline: none;
  letter-spacing: -0.4px;
  cursor: pointer;

  /* Apply specific styles according to variant, color and inverted props  */
  ${makeVariantStyles}

  /* Apply shape prop for button variation */
  ${({ shape }) =>
    shape === 'pill' &&
    css`
      border-radius: ${rem(21)};
    `}

  /* Apply size prop for button variation */
  ${({ size }) =>
    size === 'small' &&
    css`
      padding: ${rem(5)} ${rem(12)};
      font-size: ${rem(18)};
    `}

  /* Apply block prop for rendering a full width block element button */
  ${({ block }) =>
    block &&
    css`
      display: block;
      width: 100%;
    `}

  &[disabled] {
    cursor: default;
    pointer-events: none;
  }
`;

export default Button;
