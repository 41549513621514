import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/react-hooks';
import GlobalStyles from 'theme/GlobalStyles';
import GlobalLayoutStyles from 'theme/GlobalLayoutStyles';
import defaultTheme from 'theme/default';
import client from './graph/clientState';
import App from './App';
import './assets/fonts/favorit.css';

//
if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={defaultTheme}>
      <>
        <GlobalLayoutStyles />
        <GlobalStyles />
        <App />
      </>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root')
);
