// Allow props spreading because this is a wrapper component
/* eslint-disable  react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { ErrorMessage } from 'formik';
import Slider from 'shared/components/Slider/Slider';
import { FieldGroup, FieldError } from './styled';
import { formikFieldPropTypes, formikFormPropTypes } from './propTypes';

function normalizeToNumber(v) {
  const stripped = v.replace(/\D/g, '');
  return stripped ? Number(stripped) : '';
}

function SliderField({
  className,
  field,
  form,
  max,
  min,
  value,
  OutOfBoundsComponent,
  ...props
}) {
  const { setFieldValue, initialValues } = form;
  const initialValue = get(initialValues, field.name);
  const [sliderValue, setSliderValue] = useState();
  const [inputValue, setInputValue] = useState('');

  const handleChangeSlider = newValues => {
    const newValue = newValues[0];
    if (newValues < max) {
      setSliderValue(newValue);
      setFieldValue(field.name, newValues[0]);
    } else {
      setSliderValue(max);
      setFieldValue(field.name, inputValue);
    }
  };

  const handleChangeInput = e => {
    const v = normalizeToNumber(e.target.value);
    setFieldValue(field.name, v);
    setInputValue(v);
  };

  // Load the initial values
  useEffect(() => {
    setSliderValue(initialValue);
    if (initialValue > max) {
      setInputValue(initialValue);
    }
  }, [initialValue, setSliderValue, setInputValue, max]);

  const showOutOfBounds = sliderValue >= max || sliderValue <= min;
  return (
    <FieldGroup className={className}>
      <Slider
        max={max}
        min={min}
        value={sliderValue}
        {...props}
        onChange={handleChangeSlider}
      />
      {showOutOfBounds && (
        <OutOfBoundsComponent
          field={field}
          form={form}
          value={inputValue || ''}
          normalize={normalizeToNumber}
          {...props}
          onChange={handleChangeInput}
          overMaximum={sliderValue >= max}
        />
      )}
      {!showOutOfBounds && (
        <ErrorMessage component={FieldError} name={field.name} />
      )}
    </FieldGroup>
  );
}

SliderField.propTypes = {
  form: formikFormPropTypes.isRequired,
  field: formikFieldPropTypes.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  value: PropTypes.number,
  OutOfBoundsComponent: PropTypes.elementType,
};

SliderField.defaultProps = {
  OutOfBoundsComponent: () => null,
  value: undefined,
};

export const outOfBoundsPropTypes = {
  form: formikFormPropTypes.isRequired,
  field: formikFieldPropTypes.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overMaximum: PropTypes.bool,
};

export default SliderField;
