import React from 'react';
import TextField from '../TextField/TextField';

function normalizePhoneNumber(v = '') {
  // Remove non-digits and force a max length of 10
  return v.replace(/\D/g, '').slice(0, 10);
}

const PHONE_NUMBER_MASK = '(___) ___-____';

function PhoneNumberField(props) {
  return (
    <TextField
      normalize={normalizePhoneNumber}
      mask={PHONE_NUMBER_MASK}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

export default PhoneNumberField;
