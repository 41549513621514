import { rem } from './lib';

const baseColors = {
  sphereBlue: 'hsl(227, 97%, 28%)', // #02208f
  azulBlue: 'hsl(227, 79%, 54%)', // #2f56e6
  sunflowerYellow: 'hsl(48, 100%, 50%)', // #ffcd00
  scarletRed: 'hsl(353, 98%, 41%)', // #d0021b
  kermitGreen: 'hsl(96, 100%, 37%)', // #4abc00
  pumpkinOrange: 'hsl(29, 100%, 50%)', // #ff7c00
};

const colors = {
  // Distinct colors
  base: baseColors,

  // White
  white: 'hsl(0, 0%, 100%)', // #ffffff
  offWhite: 'hsl(60, 5%, 96%)', // #f6f6f5

  // Black
  black: 'hsl(0, 0%, 0%)', // #000000
  blacks: {
    100: 'hsl(0, 0%, 10%)', // #1a1a1a
    200: 'hsl(0, 0%, 13%)', // #202020
  },

  // Neutral grays
  grays: {
    100: 'hsl(0, 0%, 95%)', // #f2f2f2
    200: 'hsl(0, 0%, 80%)', // #cccccc
    300: 'hsl(0, 0%, 67%)', // #aaaaaa
    400: 'hsl(0, 0%, 52%)', // #858585
  },
};

const space = [
  0, // 0
  '0.25rem', // 1
  '0.5rem', // 2
  '1rem', // 3
  '2rem', // 4
  '4rem', // 5
  '8rem', // 6
  '16rem', // 7
];

const fontSizes = [
  rem(10), // 0
  rem(12), // 1
  rem(14), // 2
  rem(16), // 3
  rem(20), // 4
  rem(24), // 5
  rem(36), // 6
  rem(48), // 7
];

const zIndexes = {
  base: 1,
  fixedScrollProgress: 10,
  sidebar: 10,
  fixedHeader: 100,
  introScreenSidebar: 110,
  modal: 990,
};

export default {
  // Accessible through the `color()` theme function.
  colors: {
    ...colors,
    brand: {
      primary: colors.base.sphereBlue,
      success: colors.base.kermitGreen,
      warning: colors.base.sunflowerYellow,
      error: colors.base.scarletRed,
    },
    link: colors.base.azulBlue,
    text: colors.blacks[200],
    textSecondary: colors.grays[400],
    textDisabled: colors.grays[200],
    form: {
      inputPlaceholder: colors.grays[300],
      inputBorder: colors.grays[300],
      inputBorderFocus: colors.blacks[200],
      outlinedInputBorder: colors.grays[300],
      outlinedInputBorderFocus: colors.base.azulBlue,
    },
  },
  // Accessible through the `space()` theme function.
  space,
  // Accessible through the `fontSize()` theme function.
  fontSizes,
  // Accessible through the `zIndex()` theme function.
  zIndexes,
};
