/* eslint-disable import/prefer-default-export */
import { getIn } from 'formik';

export const isFieldError = (field, form) => {
  const isError = getIn(form.errors, field.name);
  const isTouched = getIn(form.touched, field.name);

  // Check if this field is a field array item or a dot-notation object property.
  if (field.name.indexOf('.') > 0) {
    const [fieldBase] = field.name.split('.');

    // If the error message is a string then it is a single error representing the field array.
    // Prevent error styling on the field to avoid confusing the user as to
    // why the field has an error without a field-level error message.
    if (typeof form.errors[fieldBase] === 'string') {
      return false;
    }
  }

  return !!(isError && isTouched);
};
