import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import routes from 'shared/routes';
import HeaderLayout from 'shared/layouts/HeaderLayout/HeaderLayout';
import { H3 } from 'shared/components/styled/typography';
import Button from 'shared/components/Button/Button';
import {
  ErrorLayout,
  ErrorDescription,
  ErrorBorderBottom,
  SupportLink,
} from './styled';

function ErrorScreen({ className }) {
  const history = useHistory();
  const location = useLocation();

  const onTryAgain = () => {
    if (location.state.returnTo) {
      // Remove this error screen from the stack.
      history.replace(location.state.returnTo);
    } else {
      history.goBack();
    }
  };

  // Make sure that users can't directly navigate to this page.
  useEffect(() => {
    if (!location.state || !location.state.error) {
      history.replace(routes.summaryBase);
    }
  }, [history, location.state]);

  return (
    <HeaderLayout className={className}>
      <ErrorLayout>
        <H3>Yikes! Something went sideways.</H3>
        <ErrorDescription>
          Try again. And if you still aren&apos;t getting anywhere
          <SupportLink
            href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=Becoming a Sphere Coach`}
            target="_blank"
            rel="noopener noreferrer"
          >
            give us a shout.
          </SupportLink>
        </ErrorDescription>
        <Button shape="pill" onClick={onTryAgain}>
          Try Again
        </Button>
      </ErrorLayout>
      <ErrorBorderBottom />
    </HeaderLayout>
  );
}

ErrorScreen.propTypes = {};
ErrorScreen.defaultProps = {};

export default ErrorScreen;
