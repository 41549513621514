import React from 'react';
import styled from 'styled-components';
import { color, rem } from 'theme/lib';
import { ReactComponent as IconNegative } from 'assets/svg/negative-section.svg';
import { ReactComponent as IconPositive } from 'assets/svg/positive-section.svg';

const StyledCarouselLegend = styled.div`
  display: inline-flex;
  flex-direction: row;
  margin-bottom: ${rem(55)};
  margin-left: auto;
  margin-right: auto;

  > * + * {
    margin-left: ${rem(24)};
  }
`;

const LegendItem = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${rem(115)};
  height: ${rem(36)};
  padding-top: ${rem(6)};
  padding-bottom: ${rem(6)};
  font-size: ${rem(24)};
  letter-spacing: -0.5px;
  line-height: 1;
  border: 1px solid ${color('brand.primary')};
  background-color: transparent;
  text-align: center;

  > svg {
    vertical-align: middle;
  }
`;

const StyledDoLegend = styled(LegendItem)`
  background-color: ${color('brand.primary')};
  color: ${color('white')};
`;

export const StyledDoNotLegend = styled(LegendItem)``;

const CarouselLegendDo = () => (
  <StyledDoLegend>
    <IconPositive />
    &nbsp;Do
  </StyledDoLegend>
);

const CarouselLegendDoNot = () => (
  <StyledDoNotLegend>
    <IconNegative />
    &nbsp;Don&apos;t
  </StyledDoNotLegend>
);

function CarouselLegend({ className }) {
  return (
    <StyledCarouselLegend className={className}>
      <CarouselLegendDo />
      <CarouselLegendDoNot />
    </StyledCarouselLegend>
  );
}

export default CarouselLegend;
