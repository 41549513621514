import { motion } from 'framer-motion';
import styled from 'styled-components/macro';
import { rem } from 'theme/lib';
import media from 'theme/media';
import { H3 } from 'shared/components/styled/typography';

export { default as FormContainer } from './FormContainer';

export const StyledAnimationWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

export const FormLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
`;

export const FormContentHeader = styled.div`
  margin-bottom: ${rem(20)};
  font-size: ${rem(13)};
  line-height: ${20 / 13};
  max-width: ${({ width }) => (width ? rem(width) : 'auto')};

  ${media.up('medium')} {
    margin-right: ${rem(30)};
  }

  ${media.up('large')} {
    margin-right: ${rem(30)};
    min-height: ${rem(90)};
  }
`;

export const FormContent = styled.div`
  max-width: ${({ width }) => (width ? rem(width) : rem(475))};

  ${media.up('medium')} {
    margin-right: ${rem(30)};
  }
`;

export const QuestionHeading = styled(H3)`
  margin-bottom: ${rem(8)};
`;

export const QuestionDescription = styled.p`
  font-size: ${rem(13)};
  line-height: ${20 / 13};
  margin-top: 0;
  margin-bottom: 0;
  max-width: ${({ width }) => (width ? rem(width) : rem(475))};
`;

export const QuestionRequirement = styled.p`
  font-size: ${rem(16)};
  font-weight: 500;
`;

export const OutOfBoundsGroup = styled.div`
  margin-top: ${rem(35)};
  font-size: ${rem(13)};
  font-weight: 500;
`;
