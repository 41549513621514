import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { rem, zIndex, fontSize } from 'theme/lib';
import media from 'theme/media';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';

export const SignUpLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  ${media.up('medium')} {
    margin-top: 11vh;
    padding-right: ${rem(47)};
  }

  ${media.up('large')} {
    margin-top: 14.5vh;
    flex-flow: row nowrap;
    padding-right: 0;
  }
`;

export const FormContainer = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  ${media.up('large')} {
    max-width: ${rem(470)};
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  margin-bottom: ${rem(48)};

  ${media.up('large')} {
    margin-bottom: 0;
  }
`;

export const ImageContainer = styled.div`
  flex: 1 1 50%;
  padding-bottom: ${rem(33)};
  padding-top: ${rem(40)};

  ${media.up('large')} {
    padding-left: ${rem(30)};
    padding-bottom: 0;
    padding-top: 0;
  }
`;

export const StyledImg = styled.img`
  width: 100%;
  max-width: ${rem(400)};
  height: auto;

  ${media.up('medium')} {
    max-width: 100%;
  }

  ${media.up('large')} {
    margin-bottom: ${rem(80)};
  }
`;

export const LayoutSidebar = styled(SplitLayout.Sidebar).attrs({
  enableScroll: false,
})`
  z-index: ${zIndex('introScreenSidebar')};

  ${media.up('huge')} {
    margin-right: ${rem(20)};
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  padding-bottom: ${rem(33)};
  display: flex;
  flex-direction: column;

  ${media.up('large')} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const SignInMessage = styled.div`
  font-size: ${fontSize(2)};
  margin-top: ${rem(16)};

  ${media.up('large')} {
    margin-top: ${rem(0)};
  }
`;

export const SignInLink = styled(Link)`
  margin-left: ${rem(5)};
`;
