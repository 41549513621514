import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuthorizationHeader } from 'auth/hooks/useToken';

export function useAuthImgSrc(src, header) {
  const [imgSrc, setImgSrc] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (header && !loading && !imgSrc) {
      setLoading(true);

      // start fetching image asynchronously
      (async () => {
        const options = {
          headers: header,
        };

        fetch(src, options)
          .then(res => res.blob())
          .then(blob => {
            const srcToUrlObject = URL.createObjectURL(blob);
            setImgSrc(srcToUrlObject);
            setLoading(false);
          })
          .catch(() => {
            setImgSrc(src);
            setLoading(false);
          });
      })();
    }

    // revokeObjectURL when unloading component
    if (imgSrc) {
      return () => {
        URL.revokeObjectURL(imgSrc);
      };
    }
    return undefined;
  }, [header, imgSrc, loading, src]);

  return { imgSrc, loading };
}

/** Wraps regular <img />; enables showing images that require auth token in request header */
export default function AuthImg({ src, ...props }) {
  const { header, loading: authLoading } = useAuthorizationHeader();
  const { imgSrc, loading: imgLoading } = useAuthImgSrc(src, header);

  const loading = authLoading || imgLoading;

  if (loading) {
    return null;
  }
  // eslint-disable-next-line jsx-a11y/alt-text, react/jsx-props-no-spreading
  return <img src={imgSrc} {...props} />;
}

AuthImg.propTypes = {
  src: PropTypes.string.isRequired,
};
