import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { color, rem } from 'theme/lib';
import media from 'theme/media';

function TextFileControls({ onUpload, onDelete }) {
  return (
    <StyledTextFileControls>
      <TextControlButton type="button" onClick={onUpload}>
        Upload New
      </TextControlButton>
      {onDelete && (
        <TextControlButton type="button" onClick={onDelete}>
          Delete
        </TextControlButton>
      )}
    </StyledTextFileControls>
  );
}

TextFileControls.propTypes = {
  onUpload: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

TextFileControls.defaultProps = {
  onDelete: PropTypes.null,
};

const TextControlButton = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: ${rem(13)};
  font-weight: 500;
  color: ${color('textSecondary')};
`;

const StyledTextFileControls = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-top: ${rem(16)};

  ${media.up('medium')} {
    padding-top: ${rem(0)};
    padding-bottom: ${rem(0)};
  }

  > * + * {
    margin-left: ${rem(16)};
  }
`;

export default TextFileControls;
