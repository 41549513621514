import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { rem } from 'theme/lib';
import media from 'theme/media';
import { Field } from 'formik';
import TextField from 'shared/components/formik/TextField/TextField';
import CharCountAdornment from 'shared/components/formik/CharCountAdornment/CharCountAdornment';
import OutlinedTextareaField from 'shared/components/formik/OutlinedTextareaField/OutlinedTextareaField';
import { TESTIMONIAL_MAX_LENGTH } from 'guideProfile/constants';

const FlexTextField = styled(TextField)`
  margin-bottom: ${rem(20)};
`;

const StyledOutlinedTextareaField = styled(OutlinedTextareaField)`
  margin-bottom: ${rem(30)};
`;
function TestimonialsFields({ index }) {
  return (
    <div role="group">
      <Row>
        <Col name="firstName">
          <Field
            label="First Name"
            labelVariant="noMargin"
            name={`allTestimonials.${index}.first_name`}
            type="text"
            component={FlexTextField}
            errorSize="small"
          />
        </Col>
        <Col name="lastInitial">
          <Field
            label="Last Initial"
            labelVariant="noMargin"
            name={`allTestimonials.${index}.last_initial`}
            type="text"
            component={FlexTextField}
            maxLength={1}
            errorSize="small"
          />
        </Col>
      </Row>
      <div>
        <Field
          name={`allTestimonials.${index}.text`}
          component={StyledOutlinedTextareaField}
          placeholder="Start typing here..."
          maxLength={TESTIMONIAL_MAX_LENGTH + 1}
          maxRows={6}
          renderAdornmentEnd={({ value }) => (
            <CharCountAdornment value={value} limit={TESTIMONIAL_MAX_LENGTH} />
          )}
        />
      </div>
    </div>
  );
}

TestimonialsFields.propTypes = {
  /** FieldArray index. */
  index: PropTypes.number.isRequired,
};

TestimonialsFields.defaultProps = {};

export default TestimonialsFields;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Col = styled.div`
  padding-right: ${rem(5)};
  flex: 1 1 100%;
  max-width: 100%;

  ${({ name }) => {
    switch (name) {
      case 'firstName':
        return css`
          max-width: ${(3 / 5) * 100}%;

          ${media.up('medium')} {
            max-width: ${(2 / 5) * 100}%;
          }
        `;
      case 'lastInitial':
        return css`
          padding-left: ${rem(5)};
          max-width: ${(2 / 5) * 100}%;

          ${media.up('medium')} {
            max-width: ${(1 / 5) * 100}%;
          }
        `;
      default:
        return css``;
    }
  }}
`;
