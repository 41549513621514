import styled from 'styled-components/macro';
import { rem, color } from 'theme/lib';
import { H1, H3 } from 'shared/components/styled/typography';
import Checklist from 'shared/components/Checklist/Checklist';

export const RoundedBox = styled.li`
  padding: ${rem(20)};
  border-radius: ${rem(15)};
  margin: 0 0 ${rem(20)};
  background: ${color('blacks.200')};

  &:last-of-type {
    margin: 0;
  }

  ${({ transparentContainer }) =>
    transparentContainer &&
    `
    background: none;
    }
  `}
`;

export const Wrapper = styled.div`
  padding: ${rem(20)};
  color: ${color('white')};
`;

export const About = styled.div`
  margin-bottom: ${rem(50)};
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: ${rem(40)};
`;

export const NameAndFocus = styled.div`
  position: absolute;
  bottom: 5%;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  text-align: left;
  height: 220px;
`;

export const Name = styled(H1)`
  color: ${color('white')};
  margin: 0;
`;

export const FieldFocusItem = styled(H3).attrs({
  as: 'li',
})`
  color: #fffdb8;
  font-size: ${rem(20)};
  line-height: 1.5em;
  margin: 0;
`;

export const CheckListItem = styled(Checklist.Item)`
  margin-bottom: ${rem(20)};
`;

export const Quote = styled.q`
  background: #f9f9f9;
  quotes: '\201C';
  font-size: ${rem(100)};

  &:before {
    font-family: Favorit;
    content: '“';
    font-size: ${rem(100)};
    line-height: 0.1em;
    margin-bottom: -0.15em;
    vertical-align: text-bottom;
    display: inline-block;
  }

  &:after {
    display: none;
  }
`;

export const TestimonialAuthor = styled.div`
  font-size: ${rem(20)};
  font-weight: 500;
  margin-top: ${rem(20)};
`;
