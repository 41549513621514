import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { rem } from 'theme/lib';
import media from 'theme/media';
import Button from 'shared/components/Button/Button';
import ArrowNavButton from 'shared/components/ArrowNavButton/ArrowNavButton';

function NavigationFooter({
  className,
  goBack,
  showContinue,
  canContinue,
  isLastQuestion,
}) {
  return (
    <Container className={className}>
      <ArrowNavButton onClick={goBack}>Go back</ArrowNavButton>
      {showContinue && (
        <Button type="submit" shape="pill" disabled={!canContinue}>
          {isLastQuestion ? 'Finish' : 'Continue'}
        </Button>
      )}
    </Container>
  );
}

NavigationFooter.propTypes = {
  goBack: PropTypes.func.isRequired,
  showContinue: PropTypes.bool,
  canContinue: PropTypes.bool,
  /** Controls the rendered text on the button if current step is the last one in the local wizard  */
  isLastQuestion: PropTypes.bool,
};

NavigationFooter.defaultProps = {
  showContinue: false,
  canContinue: true,
  isLastQuestion: false,
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: auto;
  padding-top: ${rem(40)};
  padding-bottom: ${rem(33)};
  width: 100%;

  ${media.up('medium')} {
    padding-right: ${rem(32)};
  }
`;

export default NavigationFooter;
