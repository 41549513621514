import React from 'react';
import styled from 'styled-components/macro';
import { Field, ErrorMessage } from 'formik';
import converter from 'number-to-words';
import compact from 'lodash/compact';
import * as Yup from 'yup';
import { EmptyValueRejector } from 'shared/utils/yup';
import { FieldError } from 'shared/components/formik/styled';
import { rem } from 'theme/lib';
import TextField from 'shared/components/formik/TextField/TextField';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
  QuestionRequirement,
} from 'shared/components/WizardQuestionForm';
import { FOCUS_MAX_LENGTH } from 'guideProfile/constants';

const MIN_FIELDS_REQUIRED = 3;

const initialValues = {
  fields: ['', '', ''],
};

const validationSchema = Yup.object().shape({
  fields: Yup.array()
    .compact(EmptyValueRejector)
    .min(MIN_FIELDS_REQUIRED, 'Please provide ${min} areas of focus.'), // eslint-disable-line no-template-curly-in-string
});

const fieldPlaceholders = ['Business', 'Purpose', 'Leadership'];

const FieldArrayErrorMessage = styled(ErrorMessage)`
  margin-bottom: ${rem(40)};
`;

// eslint-disable-next-line react/prop-types
const FocusField = ({ index }) => {
  return (
    <Field
      name={`fields.${index}`}
      type="text"
      component={TextField}
      placeholder={fieldPlaceholders[index]}
      maxLength={FOCUS_MAX_LENGTH + 1} // Allow user to exceed to trigger validation feedback.
      hideFieldError
      ignoreErrorStyle
    />
  );
};

export default function Focus(props) {
  return (
    <WizardQuestionForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValues={initialValues}
      validationSchema={validationSchema}
      forceEnableSubmit
    >
      {({ values, touched }) => {
        // Prevent the field array error message from displaying until the
        // minimum number of required fields are touched.
        const isMinRequiredTouched =
          compact(touched.fields).length >= MIN_FIELDS_REQUIRED;

        return (
          <FormContainer>
            <FormContentHeader>
              <QuestionHeading as="h1">Your coaching focus</QuestionHeading>

              <QuestionDescription>
                These three words are shown on every profile, giving Seekers a
                quick view into your focus.
              </QuestionDescription>
            </FormContentHeader>

            <FormContent>
              <QuestionRequirement>
                Must write {converter.toWords(MIN_FIELDS_REQUIRED)}.
              </QuestionRequirement>
              {values.fields.map((_, index) => {
                // eslint-disable-next-line react/no-array-index-key
                return <FocusField key={index} index={index} />;
              })}

              {isMinRequiredTouched && (
                <FieldArrayErrorMessage component={FieldError} name="fields" />
              )}
            </FormContent>
          </FormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

Focus.propTypes = withNavigationPropTypes;
