// Allow props spreading because this is a wrapper for Link
/* eslint-disable  react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components/macro';
import { Link, useHistory } from 'react-router-dom';
import media from 'theme/media';
import { rem, color } from 'theme/lib';
import routes from 'shared/routes';
import useLogout from 'auth/hooks/useLogout';

function SummaryFooter({ className }) {
  const history = useHistory();
  const logout = useLogout();
  const handleCancelApplication = () => {
    history.push(routes.cancelApplication);
  };

  return (
    <Container className={className}>
      <CancelButton onClick={handleCancelApplication}>
        Cancel Application
      </CancelButton>
      <div>
        Want to take a break?
        <SignOutLink to="#" onClick={logout}>
          Sign Out
        </SignOutLink>
      </div>
    </Container>
  );
}
SummaryFooter.propTypes = {};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: auto;
  padding-top: ${rem(70)};
  padding-bottom: ${rem(33)};
  width: 100%;
  color: ${color('grays.400')};
  font-size: ${rem(13)};

  ${media.up('medium')} {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
`;

const CancelButton = styled.button`
  text-align: left;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  outline: none;
  cursor: pointer;
  color: ${color('grays.400')};
  margin-top: ${rem(16)};
  margin-right: ${rem(4)};
  order: 1;

  ${media.up('medium')} {
    order: 0;
  }
`;

const SignOutLink = styled(Link)`
  font-size: ${rem(13)};
  margin-left: ${rem(2)};
`;

export default SummaryFooter;
