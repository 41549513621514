import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import routes from 'shared/routes';
import { H3 } from 'shared/components/styled/typography';
import Button from 'shared/components/Button/Button';
import { animationControlsPropTypes } from 'shared/motion/propTypes';
import EmbeddedImage from '../EmbeddedImage';
import MotionContent from './MotionContent';
import imgJoinEmbedded1x from './join-embedded.jpg';
import imgJoinEmbedded2x from './join-embedded@2x.jpg';
import imgJoinEmbedded3x from './join-embedded@3x.jpg';
import { ListHeading } from './styled';

function JoinContent({ isDirectionReversed, animate }) {
  const history = useHistory();

  const handleJoinUsClick = () => history.push(routes.signup);

  return (
    <MotionContent isDirectionReversed={isDirectionReversed} animate={animate}>
      <H3 as="h1">
        Cultivate thriving relationships with new, diverse clients
      </H3>

      <EmbeddedImage
        src={imgJoinEmbedded1x}
        srcSet={`${imgJoinEmbedded3x} 3x, ${imgJoinEmbedded2x} 2x, ${imgJoinEmbedded1x} 1x`}
        alt="Guide profile"
      />

      <p>
        You’ll increase your exposure to a wide and diverse community of
        clients.
      </p>

      <p>
        We’re extending coaching beyond the C-Suite. It’s not just an executive
        tool, but a resource for everyone. You’ll develop and support
        individuals from all walks of life.
      </p>

      <ListHeading as="h2">Be a part of something bigger.</ListHeading>

      <Button shape="pill" onClick={handleJoinUsClick}>
        Join Us!
      </Button>
    </MotionContent>
  );
}

JoinContent.propTypes = {
  /** Is navigating back through history. */
  isDirectionReversed: PropTypes.bool,
  /** Framer Motion AnimationControls. */
  animate: animationControlsPropTypes.isRequired,
};

JoinContent.defaultProps = {
  isDirectionReversed: false,
};

export default JoinContent;
