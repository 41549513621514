// Allow props spreading because this is a wrapper component
/* eslint-disable  react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'theme/media';
import { Link } from 'react-router-dom';
import { rem, color } from 'theme/lib';
import { ReactComponent as CheckmarkFilled } from 'assets/svg/primary-checkmark-filled.svg';

function StepCompleted({ className, stepTitle, launchRoute }) {
  return (
    <StyledStepCompleted className={className}>
      <div>
        <StepTitle>{stepTitle}</StepTitle>
        <EditLink to={launchRoute}>Edit</EditLink>
      </div>
      <StyledCheckmarkFilled />
    </StyledStepCompleted>
  );
}

StepCompleted.propTypes = {
  stepTitle: PropTypes.string.isRequired,
  launchRoute: PropTypes.string.isRequired,
};

const StyledStepCompleted = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
`;

const StepTitle = styled.div`
  color: ${color('black')};
  margin-bottom: ${rem(10)};
  font-size: ${rem(18)};
`;

const EditLink = styled(Link)`
  letter-spacing: -0.4px;
`;

const StyledCheckmarkFilled = styled(CheckmarkFilled)`
  display: none;
  ${media.up('medium')} {
    display: block;
    align-self: center;
  }
`;

export default StepCompleted;
