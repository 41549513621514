import { useCallback, useState, useEffect } from 'react';
import get from 'lodash/get';
import firebase from 'firebaseConfig';
import { useCurrentUser } from 'auth/hooks/queries';

export default function useUploaderParams(type) {
  const currentUserId = get(useCurrentUser(), 'data.id');
  const [authToken, setAuthToken] = useState('');

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user.getIdToken().then(token => {
          setAuthToken(token);
        });
      } else {
        setAuthToken('');
      }
    });

    // unsubscribe onUn-mount
    return unsubscribe;
  }, []);

  return useCallback(
    ({ file }) => {
      const body = new FormData();
      body.append('user-file', file);
      body.append('type', type);
      body.append('userid', currentUserId);

      return {
        url: process.env.REACT_APP_FILE_UPLOAD_ENDPOINT,
        body,
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      };
    },
    [authToken, currentUserId, type]
  );
}
