import React from 'react';
import styled from 'styled-components/macro';
import { rem } from 'theme/lib';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'theme/media';
import Guidelines from 'shared/components/Guidelines/Guidelines';

function TestimonialsSidebarContent() {
  const canShowSidebar = useMediaQuery({
    query: `(min-width: ${breakpoints.large}px)`,
  });

  if (!canShowSidebar) {
    return null;
  }
  return (
    <StyledGuidelines>
      <Guidelines.Header>
        <Guidelines.Title>Testimonial Guidelines</Guidelines.Title>
        <Guidelines.Subtitle>
          Use the following as a guide for how to add your statements into your
          profile.
        </Guidelines.Subtitle>
      </Guidelines.Header>
      <Guidelines.Content>
        <Guidelines.Section>
          <Guidelines.SectionHeading />
          <Guidelines.List>
            <Guidelines.ListItem>
              Select a testimonial that describes the problem your client was
              facing before you came into the picture.
            </Guidelines.ListItem>
            <Guidelines.ListItem>
              Encourage specifics. Rather than “I saw a big improvement,” get
              your client to state exactly what the improvement was.
            </Guidelines.ListItem>
          </Guidelines.List>
        </Guidelines.Section>
        <Guidelines.Section>
          <Guidelines.SectionHeading variant="negative" />
          <Guidelines.List variant="negative">
            <Guidelines.ListItem>
              Over-edit. Use “real” language. Those small grammar and language
              quirks help the reader connect and demonstrate they are real.
            </Guidelines.ListItem>
            <Guidelines.ListItem>
              Never fake it. People have well-trained BS detectors they can
              smell a fake a mile away.
            </Guidelines.ListItem>
          </Guidelines.List>
        </Guidelines.Section>
      </Guidelines.Content>
    </StyledGuidelines>
  );
}

TestimonialsSidebarContent.propTypes = {};

TestimonialsSidebarContent.defaultProps = {};

const StyledGuidelines = styled(Guidelines)`
  margin-top: ${rem(60)};
  margin-left: ${rem(30)};
  margin-right: ${rem(30)};
  height: 100%;
`;

export default TestimonialsSidebarContent;
