export default {
  first_name: 'Noah',
  header_image: 'https://images-cdn.sphereishere.com/99999.jpg',
  bio:
    "As an Entrepreneur, I've founded, built and successfully sold 3 companies over the last 28 years. I have a reputation and track record for strategically building businesses, fiscally responsible companies, dynamic teams, and strong brands and cultures.\n\n" +
    "I've spent the last decade supporting hundreds of leaders, creatives, professionals, start-ups, and founders in articulating and realizing their goals as a leadership and business coach.\n\n" +
    'I work with leaders who are restless in their pursuit of learning and growth in order to have a better impact on people and results. And when my clients work with me they feel calm and focused on moving forward & making a lasting impact.',
  fields: ['Business', 'Leadership', 'Communication'],
  benefits: [
    '1-on-1 leadership development',
    'Insightful self-awareness',
    'Enhanced communication skills',
  ],
  experience: [
    'Facilitated over 250+ emotional intelligence retreats & workshops',
    'A blend of senior leadership and entrepreneurial experience',
    '20+ years working with leaders and organizations to increase impact',
  ],
  fullCerts: [
    {
      name: 'a Masters in Applied Behavioral Science',
      url: null,
    },
    {
      name:
        'CTI CertifiedFoundations of NeuroLeadership Certificate from the NeuroLeadership Institute in New York',
      url: null,
    },
    {
      name: 'MBTI',
      url: null,
    },
    {
      name: 'EQi 2.0',
      url: null,
    },
  ],
  communication_style: ['Challenging', 'Inspiring', 'Thought-provoking'],
  allTestimonials: [
    {
      first_name: 'Oliver',
      last_initial: 'K',
      author: 'Oliver K',
      text:
        "I've been working with Noah for over three years, and he is the secret to my success as a business owner. Noah has a unique ability to see both the strategic '40,000 foot' level of a company and the tactical requirements for execution. Noah's experience as a business owner who has 'been there' make him a phenomenal coach.",
    },
  ],
};
