import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useCurrentUser } from 'auth/hooks/queries';
import get from 'lodash/get';

const usePaymentMethodItem = item => {
  const paymentMethods = get(useCurrentUser(), 'data.paymentMethods', []);

  const getPaymentMethodItem = useCallback(
    i => {
      if (paymentMethods && paymentMethods.length) {
        return paymentMethods[0][i];
      }

      return null;
    },
    [paymentMethods]
  );

  return getPaymentMethodItem(item);
};

usePaymentMethodItem.propTypes = {
  item: PropTypes.string.isRequired,
};

export default usePaymentMethodItem;
