import React, { useState } from 'react';
import get from 'lodash/get';
import * as Yup from 'yup';
import styled from 'styled-components/macro';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import media, { breakpoints } from 'theme/media';
import { rem } from 'theme/lib';
import { useMediaQuery } from 'react-responsive';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
} from 'shared/components/WizardQuestionForm';
import useUploaderParams from 'guideProfile/hooks/useUploaderParams';
import { UPLOAD_TYPE_SECONDARY_PHOTO } from 'guideProfile/constants';
import { useCurrentUser } from 'auth/hooks/queries';
import Button from 'shared/components/Button/Button';
import MobileModal from 'shared/components/MobileModal/MobileModal';
import { StyledDoNotLegend } from 'shared/components/Carousel/CarouselLegend';
import SecondaryPhotoCarousel from '../SecondaryPhotoCarousel/SecondaryPhotoCarousel';
import DropzoneInput from '../Dropzone/Input';
import DropzoneLayout from '../Dropzone/Layout';
import DropzonePreview from '../Dropzone/Preview';
import { handleChangeStatus, getFilesFromEvent } from '../Dropzone/helpers';
import { ReactComponent as IconCircleNumber2 } from '../Dropzone/circle-2.svg';
import { useUpdateProfileImageField } from './hooks';

const validationSchema = Yup.object().shape({
  hero_image: Yup.string().required('Required'),
});

export default function SecondaryPhotoQuestion(props) {
  const canShowMobileModal = useMediaQuery({
    query: `(max-width: ${breakpoints.large}px)`,
  });

  const [isModalOpen, setModalOpen] = useState(false);

  const currentUser = useCurrentUser();
  const currentUserId = get(currentUser, 'data.id');
  const updateProfileImageField = useUpdateProfileImageField(currentUserId);

  const getUploadParams = useUploaderParams(UPLOAD_TYPE_SECONDARY_PHOTO);

  if (!currentUserId) {
    return null;
  }

  const handleViewGuidelines = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <WizardQuestionForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValues={{
        hero_image: '',
      }}
      validationSchema={validationSchema}
      continueInFooter
    >
      {({ values }) => {
        return (
          <StyledFormContainer padding="small">
            <FormContentHeader>
              <QuestionHeading as="h1">Your secondary photo</QuestionHeading>

              <QuestionDescription width={640}>
                Please upload another image that isn’t a headshot. A landscape
                photo of you in your natural environment. This could be you
                speaking, facilitating, in your studio, out in nature. Something
                showing the side of you that you feel most proud of.
              </QuestionDescription>
            </FormContentHeader>

            <StyledFormContent width={640}>
              <Dropzone
                getUploadParams={getUploadParams}
                getFilesFromEvent={getFilesFromEvent}
                LayoutComponent={layoutProps => (
                  <DropzoneLayout
                    {...layoutProps} // eslint-disable-line react/jsx-props-no-spreading
                    uploadedImage={values.hero_image}
                    orientation="landscape"
                  />
                )}
                InputComponent={inputProps => (
                  <DropzoneInput
                    {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
                    title="Upload Secondary Photo (landscape format)"
                    uploadedImage={values.hero_image}
                    icon={<IconCircleNumber2 />}
                  />
                )}
                PreviewComponent={DropzonePreview}
                onChangeStatus={handleChangeStatus({
                  setFieldValue: updateProfileImageField,
                  fieldName: 'hero_image',
                })}
                accept="image/png, image/jpeg"
                multiple={false}
              />
            </StyledFormContent>
            {canShowMobileModal && (
              <StyledButton
                variant="outlined"
                shape="pill"
                size="small"
                type="button"
                onClick={handleViewGuidelines}
              >
                View Guidelines
              </StyledButton>
            )}
            {isModalOpen && (
              <MobileModal onClose={handleModalClose} bg="transparent">
                <SliderWrapper>
                  <SecondaryPhotoCarousel />
                </SliderWrapper>
              </MobileModal>
            )}
          </StyledFormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

SecondaryPhotoQuestion.propTypes = withNavigationPropTypes;

const StyledFormContainer = styled(FormContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledFormContent = styled(FormContent)`
  ${media.up('medium')} {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  align-self: center;
  ${media.up('medium')} {
    margin-top: ${rem(15)};
  }
`;

const SliderWrapper = styled.div`
  text-align: center;
  ${StyledDoNotLegend} {
    background-color: white;
  }
`;
