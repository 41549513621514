import styled from 'styled-components/macro';
import { rem, color } from 'theme/lib';
import media from 'theme/media';
import { contentBlockerHeight } from 'shared/layouts/SplitLayout/constants';

export const SummaryLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  max-width: ${rem(540)};

  ${media.up('medium')} {
    padding-top: ${rem(contentBlockerHeight + 25)};
    margin-right: ${rem(124)};
  }
  ${media.up('large')} {
    padding-top: ${rem(contentBlockerHeight + 45)};
  }
`;

export const BreadcrumbsWrapper = styled.div`
  margin-top: ${rem(20)};
  margin-bottom: ${rem(24)};

  ${media.up('large')} {
    margin-bottom: ${rem(60)};
  }
`;

export const StepDivider = styled.div`
  height: ${rem(2)};
  background-color: ${color('black')};
  width: 100%;
  margin-bottom: ${rem(20)};
  margin-top: ${rem(20)};
`;
