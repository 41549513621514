/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components';
import { renderToStaticMarkup } from 'react-dom/server';

function makeHitSlopArea(size) {
  return css`
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: -${size};
      left: -${size};
      right: -${size};
      bottom: -${size};
    }
  `;
}

export const hitSlops = {
  sm: makeHitSlopArea('5px'),
  md: makeHitSlopArea('10px'),
  lg: makeHitSlopArea('15px'),
};

export function encodeSVG(reactElement) {
  return 'data:image/svg+xml,' + escape(renderToStaticMarkup(reactElement));
}
