/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import SliderField, {
  outOfBoundsPropTypes,
} from 'shared/components/formik/SliderField';
import TextField from 'shared/components/formik/TextField/TextField';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
  OutOfBoundsGroup,
} from 'shared/components/WizardQuestionForm';

const validationSchema = Yup.object().shape({
  impact: Yup.object().shape({
    years: Yup.number()
      .required('Required')
      .min(1, 'Required'),
  }),
});

const MAX_YEARS_EXPERIENCE = 20;
const MIN_YEARS_EXPERIENCE = 2;
const MAX_SLIDER_VALUE = MAX_YEARS_EXPERIENCE + 1;
const MIN_SLIDER_VALUE = MIN_YEARS_EXPERIENCE - 1;

function OutOfBounds({ overMaximum, ...props }) {
  return (
    <OutOfBoundsGroup>
      {overMaximum ? (
        <TextField
          {...props}
          label="Impressive! Tell us how many years exactly?"
          placeholder={`Bigger than ${MAX_YEARS_EXPERIENCE}`}
        />
      ) : (
        <>
          Typically we look for at least {MIN_YEARS_EXPERIENCE} years
          experience.
        </>
      )}
    </OutOfBoundsGroup>
  );
}

OutOfBounds.propTypes = outOfBoundsPropTypes;

export default function YearsCoaching(props) {
  return (
    <WizardQuestionForm
      {...props}
      initialValues={{
        impact: {
          years: MIN_YEARS_EXPERIENCE,
        },
      }}
      validationSchema={validationSchema}
    >
      {() => {
        return (
          <FormContainer>
            <FormContentHeader>
              <QuestionHeading as="h1">
                How many years have you been coaching?
              </QuestionHeading>
              <QuestionDescription>
                Professionally with your own clients.
              </QuestionDescription>
            </FormContentHeader>

            <FormContent>
              <Field
                name="impact.years"
                component={SliderField}
                min={MIN_SLIDER_VALUE}
                max={MAX_SLIDER_VALUE}
                step={1}
                OutOfBoundsComponent={OutOfBounds}
              />
            </FormContent>
          </FormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

YearsCoaching.propTypes = withNavigationPropTypes;
