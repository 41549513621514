import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import { ErrorMessage } from 'formik';
import cx from 'classnames';
import useFocusField from '../useFocusField';
import { isFieldError } from '../helpers';
import { formikFieldPropTypes, formikFormPropTypes } from '../propTypes';
import { FieldGroup, FieldLabel, FieldError, FieldHelperText } from '../styled';
import { InputWrapper, InputAdornmentEnd, StyledTextarea } from './styled';

function OutlinedTextareaField({
  className,
  field: { onBlur, ...field },
  form,
  label,
  helperText,
  adornmentEndAlign,
  renderAdornmentEnd,
  minRows,
  maxRows,
  onHeightChange,
  useCacheForDOMMeasurements,
  required,
  errorSize,
  ...props
}) {
  // Simulate input focus on the wrapper to include adornments.
  const { isFocused, handleFocus, handleBlur } = useFocusField({ onBlur });
  const hasError = isFieldError(field, form);

  return (
    <FieldGroup
      className={cx(className, { 'has-error': hasError })}
      hasError={hasError}
    >
      {label && (
        <FieldLabel>
          {label}
          {required && <> *</>}
        </FieldLabel>
      )}

      <InputWrapper isFocused={isFocused}>
        <StyledTextarea
          {...field} // eslint-disable-line react/jsx-props-no-spreading
          {...props} // eslint-disable-line react/jsx-props-no-spreading
          as={TextareaAutosize}
          onBlur={handleBlur}
          onFocus={handleFocus}
          minRows={minRows}
          maxRows={maxRows}
          onHeightChange={onHeightChange}
          useCacheForDOMMeasurements={useCacheForDOMMeasurements}
        />

        {renderAdornmentEnd && (
          <InputAdornmentEnd align={adornmentEndAlign}>
            {renderAdornmentEnd(field)}
          </InputAdornmentEnd>
        )}
      </InputWrapper>

      {helperText && <FieldHelperText>{helperText}</FieldHelperText>}

      <ErrorMessage component={FieldError} name={field.name} size={errorSize} />
    </FieldGroup>
  );
}

OutlinedTextareaField.propTypes = {
  /** Formik field object. */
  field: formikFieldPropTypes.isRequired,
  /** Formik form object. */
  form: formikFormPropTypes.isRequired,
  /** Field label. */
  label: PropTypes.string,
  /** Supporting text placed underneath the field. */
  helperText: PropTypes.string,
  /** Vertical flexbox alignment for the end adornment. */
  adornmentEndAlign: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
  /** Render function to display content at the end of the field. */
  renderAdornmentEnd: PropTypes.func,
  /** Add required indicator to field label. */
  required: PropTypes.bool,
  /** Variant to change the size of error messages. */
  errorSize: PropTypes.oneOf(['default', 'small']),
  /** For multiline: minimum number of rows to show for textarea. */
  minRows: PropTypes.number,
  /** For multiline: maximum number of rows upto which the textarea can grow. */
  maxRows: PropTypes.number,
  /** For multiline: use object cache when computing height of textarea. */
  useCacheForDOMMeasurements: PropTypes.bool,
  /** For multiline: function invoked on textarea height change, with height as first argument and React component instance (this) as second. */
  onHeightChange: PropTypes.func,
};

OutlinedTextareaField.defaultProps = {
  label: '',
  helperText: '',
  required: false,
  errorSize: 'default',
  adornmentEndAlign: 'flex-start',
  renderAdornmentEnd: null,
  minRows: 4,
  maxRows: 10,
  useCacheForDOMMeasurements: false,
  onHeightChange: () => {},
};

export default OutlinedTextareaField;
