import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { rem } from 'theme/lib';
import { breakpoints } from 'theme/media';
import FocusProfileCarousel from '../FocusProfileCarousel/FocusProfileCarousel';

function FocusSidebar() {
  const canShowSidebar = useMediaQuery({
    query: `(min-width: ${breakpoints.large}px)`,
  });

  if (!canShowSidebar) {
    return null;
  }

  return (
    <SliderWrapper>
      <FocusProfileCarousel />
    </SliderWrapper>
  );
}

FocusSidebar.propTypes = {};

FocusSidebar.defaultProps = {};

const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding-top: ${rem(50)};
  padding-bottom: ${rem(50)};
`;

export default FocusSidebar;
