import { useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import firebase from 'firebaseConfig';

export default function useLogout() {
  const apolloClient = useApolloClient();
  const logout = useCallback(async () => {
    try {
      // Clear the current cache in case a new user logs in
      await apolloClient.resetStore();

      // Logout is detected by an 'onAuthStateChanged' handler registered elsewhere.
      // It is up to that handler to redirect the user to the login screen.
      await firebase.auth().signOut();
    } catch (err) {
      // This should never actually happen. Only here as a safety.
      // TODO: Issue toast notification
      console.error('Logout failure: ', err);
    }
  }, [apolloClient]);

  return logout;
}
