/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';
import { rem, color } from 'theme/lib';
import media from 'theme/media';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import Sidebar from 'shared/layouts/SplitLayout/Sidebar';
import NumberedList from 'shared/components/NumberedList/NumberedList';
import Button from 'shared/components/Button/Button';
import Checklist from 'shared/components/Checklist/Checklist';
import WaitForExternalData from 'shared/components/WaitForExternalData/WaitForExternalData';
import { useCurrentUser } from 'auth/hooks/queries';
import { useGuideProfile } from 'guideProfile/hooks/queries';
import { COUNTRY_CODE_AMERICA } from 'shared/constants';
import CodeOfConductContext from '../../CodeOfConductContext';
import useProtectCodeOfConduct from '../../hooks/useProtectCodeOfConduct';
import AstronautImage from './astronaut-gearingup.jpg';
import Footer from '../Footer/Footer';
import {
  Heading,
  RateStatement,
  CurrencyBlock,
  Content,
  ImageWrapper,
  Image,
} from './styled';
import { PERFORMANCE_INDICATORS } from './constants';

const RULE_CHANGE_DELAY = 250;

const GearingUp = ({ goBack, goNext, localStep }) => {
  useProtectCodeOfConduct(localStep - 1);
  const { setLastAcceptedRule, lastAcceptedRule } = useContext(
    CodeOfConductContext
  );
  const hasAcceptedRule = lastAcceptedRule >= localStep;
  const currentUser = useCurrentUser();
  const country = get(currentUser, 'data.country', COUNTRY_CODE_AMERICA);
  const currency = country === COUNTRY_CODE_AMERICA ? 'USD' : 'CAD';

  const guideProfile = useGuideProfile();
  const rate = get(guideProfile, 'data.cost', 0) / 100;

  const handleAcceptRule = useCallback(() => {
    if (!hasAcceptedRule) {
      setLastAcceptedRule(localStep);
      setTimeout(() => {
        goNext();
      }, RULE_CHANGE_DELAY);
    }
  }, [localStep, goNext, setLastAcceptedRule, hasAcceptedRule]);

  return (
    <SplitLayout showLogo={false}>
      <WaitForExternalData external={[currentUser, guideProfile]}>
        <SplitLayout.Main>
          <StyledSplitLayoutMainContent>
            <Heading>Gearing Up</Heading>
            <RateStatement>
              Your current rate is ${rate} / Hour
              <CurrencyBlock>{currency}</CurrencyBlock>
            </RateStatement>
            <p>
              Your rate is influenced on key-performance-indicators, which
              include:
            </p>
            <NumberedList>
              {PERFORMANCE_INDICATORS.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <NumberedList.Item key={i}>{item}</NumberedList.Item>
              ))}
            </NumberedList>
            <Content>
              <p>
                Sphere Guides receive a payout on the last business day of the
                month through PayPal to the email address registered with their
                account.
              </p>
              <p>
                Once you're active in the app, our algorithm will not boost or
                promote your profile immediately. It may take some time for you
                to receive your first meets and sessions. This is to ensure
                quality of delivery for both Seekers and Guides alike. The more
                meets and sessions completed, and as your KPIs improve, the more
                you will gain exposure to business in Sphere. Additionally, the
                more availability you offer, the better you perform in our
                algorithm.
              </p>
              <p>
                Your rate of pay will be reviewed no sooner than 6-months from
                signing your agreement with Sphere. This is because Sphere needs
                to collect enough data over time to be able to confidently
                influence your rate of pay. Not having enough data or having low
                availability may impact Sphere's ability to increase your rate
                of pay.
              </p>
              <p>
                In order to create a democratic environment of the utmost
                quality, Sphere's payout system is dynamic. Meaning you must
                consistently maintain those KPIs in order to prevent your rate
                of pay from fluctuating.
              </p>
            </Content>

            <HorizontalRule />
            <AcknowledgeButton>
              <Checklist.Item
                onClick={handleAcceptRule}
                checked={hasAcceptedRule}
              >
                I agree to all the terms and conditions mentioned above
                {/* {loading && <StyledLoadingCircle />} */}
              </Checklist.Item>
            </AcknowledgeButton>
          </StyledSplitLayoutMainContent>
        </SplitLayout.Main>
        <StyledSidebar>
          <ImageWrapper>
            <Image src={AstronautImage} />
          </ImageWrapper>
        </StyledSidebar>
      </WaitForExternalData>
      <Footer goBack={goBack}>
        <Button shape="pill" onClick={goNext} disabled={!hasAcceptedRule}>
          Continue
        </Button>
      </Footer>
    </SplitLayout>
  );
};

const AcknowledgeButton = styled.button`
  border: none;
  padding: 0;
  margin: ${rem(40)} 0 0;
  background: none;
  outline: none;
  cursor: pointer;
  text-align: left;
`;

const HorizontalRule = styled.hr`
  border-top: solid 1px ${color('grays.400')};
  margin: ${rem(45)} 0 0;
`;

const StyledSidebar = styled(Sidebar)`
  ${media.up('huge')} {
    margin-right: ${rem(20)};
  }
`;

const StyledSplitLayoutMainContent = styled(SplitLayout.MainContent)`
  padding: 0 0 ${rem(120)};
  ${media.up('medium')} {
    margin-top: 11vh;
    padding-right: ${rem(30)};
  }

  ${media.up('large')} {
    margin-top: 6vh;
  }
`;

GearingUp.propTypes = {
  goBack: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
  localStep: PropTypes.number.isRequired,
};

export default GearingUp;
