import { useEffect, useMemo } from 'react';
import { useGuideInfo } from 'guideProfile/hooks/queries';
import { FOCUS_PREVIEW_IDS as SAMPLE_PROFILE_IDS } from 'sampleProfiles';

export default function useProfilesForFocusSidebar() {
  // This is same as if we would have:
  // profiles[0] = useGuideInfo(id0);
  // profiles[1] = useGuideInfo(id1);
  // ...
  let stillLoading = false;
  const profiles = useMemo(() => ({}), []);
  for (let i = 0; i < SAMPLE_PROFILE_IDS.length; i++) {
    const id = SAMPLE_PROFILE_IDS[i];
    // Since SAMPLE_PROFILE_IDS is not changing,
    // order and number of hooks is not changing,
    // therefore it's safe to call hooks inside of this loop
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { loading, data } = useGuideInfo(id);
    stillLoading = stillLoading || loading;
    profiles[id] = data;
  }

  const profilesWithImages = useMemo(() => {
    if (stillLoading) {
      return [];
    }

    // Return retrieved profiles in order specified in SAMPLE_PROFILE_IDS,
    // minus the ones that have no header_image set
    return SAMPLE_PROFILE_IDS.map(id => profiles[id]).filter(
      profile => profile && profile.header_image
    );
  }, [profiles, stillLoading]);

  useEffect(() => {
    if (!stillLoading) {
      profilesWithImages.forEach(async slide => {
        // Prefetch profile images
        new Image().src = slide.header_image;
      });
    }
  }, [profilesWithImages, stillLoading]);

  return profilesWithImages;
}
