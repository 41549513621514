/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import SliderField, {
  outOfBoundsPropTypes,
} from 'shared/components/formik/SliderField';
import TextField from 'shared/components/formik/TextField/TextField';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  OutOfBoundsGroup,
} from 'shared/components/WizardQuestionForm';

const validationSchema = Yup.object().shape({
  impact: Yup.object().shape({
    seekers: Yup.number()
      .required('Required')
      .min(1, 'Required'),
  }),
});

const MAX_CLIENTS_COACHED = 100;
const MIN_CLIENTS_COACHED = 5;
const MAX_SLIDER_VALUE = MAX_CLIENTS_COACHED + 1;
const MIN_SLIDER_VALUE = MIN_CLIENTS_COACHED - 1;

function OutOfBounds({ overMaximum, ...props }) {
  return (
    <OutOfBoundsGroup>
      {overMaximum ? (
        <TextField
          {...props}
          label="Impressive! Tell us how many."
          placeholder={`Bigger than ${MAX_CLIENTS_COACHED}`}
        />
      ) : (
        <>Typically, we look for coaches that have a larger client roster.</>
      )}
    </OutOfBoundsGroup>
  );
}

OutOfBounds.propTypes = outOfBoundsPropTypes;

export default function ClientsCoached(props) {
  return (
    <WizardQuestionForm
      {...props}
      initialValues={{
        impact: {
          seekers: MIN_CLIENTS_COACHED,
        },
      }}
      validationSchema={validationSchema}
    >
      {() => {
        return (
          <FormContainer>
            <FormContentHeader width={615}>
              <QuestionHeading as="h1">
                How many clients have you coached 1-on-1 for more than 3
                sessions?
              </QuestionHeading>
            </FormContentHeader>

            <FormContent>
              <Field
                name="impact.seekers"
                component={SliderField}
                min={MIN_SLIDER_VALUE}
                max={MAX_SLIDER_VALUE}
                step={1}
                OutOfBoundsComponent={OutOfBounds}
              />
            </FormContent>
          </FormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

ClientsCoached.propTypes = withNavigationPropTypes;
