import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { color, rem } from 'theme/lib';
import media from 'theme/media';
import { makeSrcSetFromObject } from 'shared/utils/object';

function CarouselSlide({ className, children, src, srcSet, alt, aspectRatio }) {
  return (
    <StyledCarouselSlide className={className}>
      <SlideInner aspectRatio={aspectRatio}>
        <StyledImg
          src={src}
          srcSet={makeSrcSetFromObject(srcSet)}
          aspectRatio={aspectRatio}
          alt={alt}
        />
        {children && children}
      </SlideInner>
    </StyledCarouselSlide>
  );
}

CarouselSlide.propTypes = {
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.shape({
    '1x': PropTypes.string,
    '2x': PropTypes.string,
    '3x': PropTypes.string,
  }),
  alt: PropTypes.string,
  aspectRatio: PropTypes.number,
  children: PropTypes.node,
};

CarouselSlide.defaultProps = {
  alt: '',
  srcSet: null,
  aspectRatio: 1.6,
  children: null,
};

const StyledCarouselSlide = styled.div`
  text-align: center;
  outline: none;
  padding-left: ${rem(40)};
  padding-right: ${rem(40)};
`;

const SlideInner = styled.div`
  position: relative;
  overflow: hidden;
  height: 0;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 100%;
  max-width: ${rem(410)};

  ${({ aspectRatio }) => css`
    padding-bottom: ${aspectRatio * 100}%;
  `}
  ${media.up('large')} {
    border-radius: ${rem(47)};
  }
`;

const StyledImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: ${rem(28)};

  ${media.up('large')} {
    border-radius: initial;
  }
`;
// Overflow: hidden with border-radius is causing some incosistencies. Using border-radius on both parent and child to achieve
// the desired rounded corners
CarouselSlide.Footer = styled.footer`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${color('white')};
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${rem(60)};
  text-align: center;
  /* Slightly reduce the border-radius on the child to prevent extra pixels 'shining' through  */
  border-bottom-left-radius: ${rem(24)};
  border-bottom-right-radius: ${rem(24)};

  ${media.up('large')} {
    border-radius: initial;
  }

  background-color: ${({ positive, theme }) =>
    positive
      ? color('brand.primary')({ theme })
      : color('brand.error')({ theme })};
`;

export default CarouselSlide;
