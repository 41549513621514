import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { color, hslToHsla } from 'theme/lib';
import mouse from 'assets/svg/mouse.svg';

const ScrollPrompt = ({ children }) => (
  <>
    <img src={mouse} alt="mouse" />
    <Instructions>{children}</Instructions>
  </>
);

export const Container = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 150%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25%;
  color: ${color('white')};
  text-align: center;
  transition: opacity ${props => props.fadeDuration / 1000}s ease-out;

  ${({ theme }) => {
    const blackColor = color('blacks.100')({ theme });
    return css`
      background: ${hslToHsla(blackColor, 0.9)};
      background: linear-gradient(
        180deg,
        ${hslToHsla(blackColor, 0.9)} 70%,
        rgba(0, 0, 0, 0) 98%
      );
    `;
  }}
`;

const Instructions = styled.p`
  max-width: 300px;
`;

ScrollPrompt.defaultProps = {
  children: '',
};

ScrollPrompt.propTypes = {
  children: PropTypes.string,
};

export default ScrollPrompt;
