import { useState, useCallback } from 'react';
import get from 'lodash/get';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useCurrentUser } from 'auth/hooks/queries';

export const AGREE_TO_CODE_OF_CONDUCT = gql`
  mutation AgreeToCodeOfConduct($id: ID!) {
    storeCodeOfConduct(userId: $id, value: true)
  }
`;

export default function useAgreeToCodeOfConduct() {
  const currentUser = useCurrentUser();
  const refetchCurrentUser = currentUser.refetch;
  const currentUserId = get(currentUser, 'data.id');
  const [mutate, queryHandler] = useMutation(AGREE_TO_CODE_OF_CONDUCT);
  const [error, setError] = useState(null);

  const agreeToCodeOfConduct = useCallback(
    async onSuccess => {
      if (!currentUserId) {
        // Only here as a safety. Should never actually happen.
        console.error(
          'Trying to accept the code of conduct without a user id.'
        );

        setError(
          new Error('Can not agree to code of conduct as an anonymous user')
        );
        return;
      }

      try {
        setError(null);
        await mutate({
          variables: {
            id: currentUserId,
          },
        });
      } catch (err) {
        // Do nothing
        return;
      }

      // Refetch the current user without waiting for the query to finish. Placed here
      // instead of as a re-fetch query because re-fetch query errors throw unhandled promise exceptions.
      refetchCurrentUser().catch(() => {
        // Refetch user has failed. Do nothing.
      });

      if (onSuccess) {
        onSuccess();
      }
    },
    [currentUserId, mutate, refetchCurrentUser]
  );

  return [
    agreeToCodeOfConduct,
    Object.assign(queryHandler, {
      error: queryHandler.error || error,
    }),
  ];
}
