import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Logo from 'shared/components/Logo/Logo';
import { color, rem, zIndex } from 'theme/lib';
import media from 'theme/media';
import Sidebar from './Sidebar';
import { contentBlockerHeight } from './constants';

function SplitLayout({ className, showLogo, contentBlocker, children }) {
  return (
    <Layout className={className}>
      {showLogo && (
        <>
          <StyledLogo />
          {contentBlocker && <ContentBlocker />}
        </>
      )}
      {children}
    </Layout>
  );
}

SplitLayout.propTypes = {
  showLogo: PropTypes.bool,
  contentBlocker: PropTypes.bool,
  children: PropTypes.node,
};

SplitLayout.defaultProps = {
  showLogo: true,
  contentBlocker: true,
  children: null,
};

const StyledLogo = styled(Logo)`
  position: fixed;
  margin-top: ${rem(10)};
  z-index: ${zIndex('fixedHeader', 1)};

  ${media.up('medium')} {
    margin-top: ${rem(40)};
  }

  ${media.up('huge')} {
    margin-left: ${rem(-125)};
  }
`;

// Create a background behind logo for content to scroll underneath to prevent it
// from overlapping the logo.
const ContentBlocker = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${rem(59)};
  background-color: ${color('white')};
  z-index: ${zIndex('fixedHeader')};

  ${media.up('medium')} {
    height: ${rem(contentBlockerHeight)};
  }
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: ${rem(600)};
  min-width: ${rem(320)};
  max-width: ${rem(1440)};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: ${rem(15)};
  padding-left: ${rem(15)};

  ${media.up('medium')} {
    flex-direction: row;
    padding-right: 0;
    padding-left: ${rem(47)};
  }

  ${media.up('large')} {
    padding-right: ${rem(47)};
    padding-left: ${rem(47)};
  }

  ${media.up('huge')} {
    padding-right: 0;
    padding-left: ${rem(165)};
  }
`;

SplitLayout.Main = styled.main`
  position: relative;
  padding-top: ${rem(87)};
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  width: 100%;

  ${media.up('medium')} {
    padding-top: 0;
  }
`;

SplitLayout.MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  ${media.up('medium')} {
    margin-right: ${rem(30)};
  }

  ${media.between('large', 'huge')} {
    margin-right: ${rem(18)};
  }
`;

SplitLayout.Sidebar = Sidebar;

export default SplitLayout;
