import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { rem, fontSize } from 'theme/lib';
import { H3 } from 'shared/components/styled/typography';
import ForgotPasswordForm from '../ForgotPasswordForm/ForgotPasswordForm';
import { useRequestPasswordReset } from '../../hooks/queries';

export const ForgotPasswordMessage = styled.div`
  font-size: ${fontSize(2)};
`;

export const ForgotPasswordTitle = styled(H3)`
  margin-bottom: ${rem(16)};
`;

function ForgotPasswordContent({ initialEmailAddress }) {
  const [emailSentTo, setEmailSentTo] = useState('');
  const [requestPasswordReset, { loading, error }] = useRequestPasswordReset();
  const handleSubmit = useCallback(
    ({ email }) => {
      requestPasswordReset(email, () => {
        setEmailSentTo(email);
      });
    },
    [requestPasswordReset, setEmailSentTo]
  );

  if (!emailSentTo) {
    return (
      <>
        <ForgotPasswordTitle>Reset your password</ForgotPasswordTitle>
        <ForgotPasswordMessage>
          No worries. We&apos;ll email you instructions to reset your password.
        </ForgotPasswordMessage>
        <ForgotPasswordForm
          emailAddress={initialEmailAddress}
          onSubmit={handleSubmit}
          loading={loading}
          error={error}
        />
      </>
    );
  }

  return (
    <>
      <ForgotPasswordTitle>Instructions Sent!</ForgotPasswordTitle>
      <ForgotPasswordMessage>
        <p>
          Instructions for resetting your password have been sent to{' '}
          {emailSentTo}
        </p>
        <p>
          You’ll receive this email within 5 minutes. Be sure to check your spam
          folder too.
        </p>
      </ForgotPasswordMessage>
    </>
  );
}

ForgotPasswordContent.propTypes = {
  initialEmailAddress: PropTypes.string,
};
ForgotPasswordContent.defaultProps = {
  initialEmailAddress: '',
};

export default React.memo(ForgotPasswordContent);
