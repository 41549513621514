/* eslint-disable camelcase */
import React, { useState } from 'react';
import get from 'lodash/get';
import * as Yup from 'yup';
import styled from 'styled-components/macro';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import media, { breakpoints } from 'theme/media';
import { rem } from 'theme/lib';
import { useMediaQuery } from 'react-responsive';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
} from 'shared/components/WizardQuestionForm';
import useUploaderParams from 'guideProfile/hooks/useUploaderParams';
import { UPLOAD_TYPE_PROFILE_PHOTO } from 'guideProfile/constants';
import { useCurrentUser } from 'auth/hooks/queries';
import Button from 'shared/components/Button/Button';
import MobileModal from 'shared/components/MobileModal/MobileModal';
import { StyledDoNotLegend } from 'shared/components/Carousel/CarouselLegend';
import FeaturePhotoCarousel from '../FeaturePhotoCarousel/FeaturePhotoCarousel';
import DropzoneInput from '../Dropzone/Input';
import DropzoneLayout from '../Dropzone/Layout';
import DropzonePreview from '../Dropzone/Preview';
import { handleChangeStatus, getFilesFromEvent } from '../Dropzone/helpers';
import { useUpdateProfileImageField } from './hooks';

const validationSchema = Yup.object().shape({
  header_image: Yup.string().required('Required'),
});

export default function FeaturePhotoQuestion(props) {
  const canShowMobileModal = useMediaQuery({
    query: `(max-width: ${breakpoints.large}px)`,
  });

  const [isModalOpen, setModalOpen] = useState(false);

  const currentUser = useCurrentUser();
  const currentUserId = get(currentUser, 'data.id');
  const updateProfileImageField = useUpdateProfileImageField(currentUserId);

  const getUploadParams = useUploaderParams(UPLOAD_TYPE_PROFILE_PHOTO);

  if (!currentUserId) {
    return null;
  }

  const handleViewGuidelines = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <WizardQuestionForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValues={{
        header_image: '',
      }}
      validationSchema={validationSchema}
      continueInFooter
    >
      {({ values }) => {
        return (
          <StyledFormContainer padding="small">
            <FormContentHeader>
              <QuestionHeading as="h1">
                Your feature photo, why is it so important?
              </QuestionHeading>

              <QuestionDescription width={540}>
                A high-quality, professional and candid photo results in 3x as
                many bookings. Please upload a headshot where you&apos;re the
                only one in the photo and your face is fully visible.
              </QuestionDescription>
            </FormContentHeader>

            <StyledFormContent width={540}>
              <Dropzone
                getUploadParams={getUploadParams}
                getFilesFromEvent={getFilesFromEvent}
                LayoutComponent={layoutProps => (
                  <DropzoneLayout
                    {...layoutProps} // eslint-disable-line react/jsx-props-no-spreading
                    uploadedImage={values.header_image}
                  />
                )}
                InputComponent={inputProps => (
                  <DropzoneInput
                    {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
                    title="Your Feature Photo"
                    uploadedImage={values.header_image}
                  />
                )}
                PreviewComponent={DropzonePreview}
                onChangeStatus={handleChangeStatus({
                  setFieldValue: updateProfileImageField,
                  fieldName: 'header_image',
                })}
                accept="image/png, image/jpeg"
                multiple={false}
              />
            </StyledFormContent>
            {canShowMobileModal && (
              <StyledButton
                variant="outlined"
                shape="pill"
                size="small"
                type="button"
                onClick={handleViewGuidelines}
              >
                View Guidelines
              </StyledButton>
            )}
            {isModalOpen && (
              <MobileModal onClose={handleModalClose} bg="transparent">
                <SliderWrapper>
                  <FeaturePhotoCarousel />
                </SliderWrapper>
              </MobileModal>
            )}
          </StyledFormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

FeaturePhotoQuestion.propTypes = withNavigationPropTypes;

const StyledFormContainer = styled(FormContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledFormContent = styled(FormContent)`
  ${media.up('medium')} {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  align-self: center;
  ${media.up('medium')} {
    margin-top: ${rem(15)};
  }
`;

const SliderWrapper = styled.div`
  text-align: center;
  ${StyledDoNotLegend} {
    background-color: white;
  }
`;
