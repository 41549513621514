import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ErrorMessage } from 'formik';
import OutlinedCheckbox from 'shared/components/OutlinedCheckbox/OutlinedCheckbox';
import { FieldGroup, FieldError } from '../styled';
import { isFieldError } from '../helpers';
import { formikFieldPropTypes, formikFormPropTypes } from '../propTypes';

function RadioField({
  className,
  field,
  id,
  form: { setFieldValue, ...form },
  children,
  labelCSS,
  icon,
}) {
  const hasError = isFieldError(field, form);

  const handleChange = e => {
    setFieldValue(e.target.name, e.target.id);
  };

  return (
    <FieldGroup
      className={cx(className, { 'has-error': hasError })}
      hasError={hasError}
    >
      <OutlinedCheckbox
        {...field} // eslint-disable-line react/jsx-props-no-spreading
        id={id}
        type="radio"
        checked={field.value === id}
        onChange={handleChange}
        label={children}
        labelCSS={labelCSS}
        icon={icon}
      />

      <ErrorMessage component={FieldError} name={field.name} />
    </FieldGroup>
  );
}

RadioField.propTypes = {
  /** ID of the radio input. */
  id: PropTypes.string.isRequired,
  /** Formik field object. */
  field: formikFieldPropTypes.isRequired,
  /** Formik form object. */
  form: formikFormPropTypes.isRequired,
  /** Customize the label styles using styled-components css`` */
  labelCSS: PropTypes.arrayOf(PropTypes.string),
  /** Children is applied as the label contents. */
  children: PropTypes.node,
  /** Determines which checkboxes icons are rendered */
  icon: PropTypes.oneOf(['default', 'bordered']),
};

RadioField.defaultProps = {
  labelCSS: [],
  children: null,
  icon: 'default',
};

export default RadioField;
