import React, { useContext, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useAnimation } from 'framer-motion';
import pick from 'lodash/pick';
import merge from 'lodash/merge';
import isEqual from 'lodash/isEqual';
import { Formik, Form } from 'formik';
import Button from 'shared/components/Button/Button';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import GuideProfileContext from 'guideProfile/GuideProfileContext';
import wizardNavigationContentVariants from 'shared/motion/wizardNavigationContentVariants';
import {
  useGuideProfile,
  useProfileFieldOptions,
} from 'guideProfile/hooks/queries';
import { removeNullElements } from 'shared/utils/object';
import WaitForExternalData from 'shared/components/WaitForExternalData/WaitForExternalData';
import ErrorMessage from 'shared/components/ErrorMessage/ErrorMessage';
import NavigationFooter from './NavigationFooter';
import { StyledAnimationWrapper, FormLayout } from './styled';

/**
 * Build the initial values by merging existing data and defaults.
 * Only uses the keys that were specified in the initial values.
 */
function useInitialValues(defaults, data) {
  const merged = merge(
    {},
    defaults,
    pick(removeNullElements(data, true), Object.keys(defaults))
  );

  // Deep equal memoize so that the initial values reference does not change needlessly.
  // Formik has a weird side effect of flashing validation errors if the initial values
  // change.
  const prevMerge = useRef(merged);
  if (!isEqual(prevMerge.current, merged)) {
    prevMerge.current = merged;
    return merged;
  }

  return prevMerge.current;
}

/**
 * This component is here to allow using hooks in the render function of the
 * Formik component without violating the rules of hooks. Could instead disable
 * the eslint rule as I'm confident the order of execution will not be effected,
 * but I would rather not go down the road of over-riding the rules of hooks.
 */
function QuestionWrapper({ formikBag: { values, isValid }, children }) {
  const { setCurrentScreenValues } = useContext(GuideProfileContext);

  // Update the guide profile context with the current values of the screen.
  // This is so we know what to save if the user hits 'Save and Exit'.
  useEffect(() => {
    setCurrentScreenValues(values, isValid);
  }, [values, isValid, setCurrentScreenValues]);

  return children;
}

/**
 * This component is here to detect when the children are mounting so that we can
 * initiate the transition animation.
 */
/* eslint-disable react/prop-types */
function AnimationWrapper({
  contentControls,
  isDirectionReversed,
  onContentAnimationStart,
  children,
}) {
  // Animate content into view.
  useEffect(() => {
    window.scrollTo(0, 0);
    if (onContentAnimationStart) {
      onContentAnimationStart();
    }
    contentControls.start('in');
  }, [contentControls, onContentAnimationStart]);

  return (
    <StyledAnimationWrapper
      initial={isDirectionReversed ? 'goBackInitial' : 'goForwardInitial'}
      animate={contentControls}
      variants={wizardNavigationContentVariants}
    >
      {children}
    </StyledAnimationWrapper>
  );
}
/* eslint-enable react/prop-types */

export default function Question({
  goBack,
  goNext,
  initialValues,
  validationSchema,
  validate,
  continueInFooter,
  isDirectionReversed,
  children,
  localTotalSteps,
  localStep,
  onContentAnimationStart,
  forceEnableSubmit,
}) {
  const contentControls = useAnimation();
  const guideProfile = useGuideProfile();
  const mergedInitialValues = useInitialValues(
    initialValues,
    guideProfile.data
  );
  const {
    updateProfile,
    queryHandler: { loading: isUpdating, error: updateError },
  } = useContext(GuideProfileContext);

  const handleAnimatedGoBack = useCallback(async () => {
    if (onContentAnimationStart) {
      onContentAnimationStart('goBackExit');
    }
    await contentControls.start('goBackExit');
    goBack();
  }, [goBack, contentControls, onContentAnimationStart]);
  const isLastQuestion = localStep === localTotalSteps;

  const externalDataRequirements = [
    guideProfile,
    useProfileFieldOptions('family_status'),
    useProfileFieldOptions('keyword'),
    useProfileFieldOptions('lifestyle'),
    // useProfileFieldOptions('diversity'), // testing to see if removing this solves the double network call issue for this stage
    useProfileFieldOptions('communication_style'),
    useProfileFieldOptions('competencies'),
  ];

  const handleGoNext = useCallback(() => {
    if (onContentAnimationStart) {
      onContentAnimationStart('goForwardExit');
    }
    contentControls.start('goForwardExit').then(goNext);
  }, [contentControls, goNext, onContentAnimationStart]);

  const handleSubmit = useCallback(
    values => {
      updateProfile(values, handleGoNext);
    },
    [handleGoNext, updateProfile]
  );

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={mergedInitialValues}
      validationSchema={validationSchema}
      validate={validate}
      enableReinitialize
      validateOnMount
    >
      {formikBag => {
        const isSubmitDisabled =
          !forceEnableSubmit && (!formikBag.isValid || isUpdating);

        return (
          <QuestionWrapper formikBag={formikBag}>
            <FormLayout as={Form}>
              <WaitForExternalData external={externalDataRequirements}>
                <AnimationWrapper
                  contentControls={contentControls}
                  isDirectionReversed={isDirectionReversed}
                  onContentAnimationStart={onContentAnimationStart}
                >
                  {children(formikBag)}

                  {!continueInFooter && (
                    <Button
                      shape="pill"
                      type="submit"
                      disabled={isSubmitDisabled}
                    >
                      Continue
                    </Button>
                  )}
                  <ErrorMessage error={updateError} />
                </AnimationWrapper>
              </WaitForExternalData>
              <NavigationFooter
                goBack={handleAnimatedGoBack}
                showContinue={continueInFooter}
                canContinue={!isSubmitDisabled}
                isLastQuestion={isLastQuestion}
              />
            </FormLayout>
          </QuestionWrapper>
        );
      }}
    </Formik>
  );
}

Question.propTypes = {
  ...withNavigationPropTypes,
  children: PropTypes.elementType.isRequired,

  // The follow proptypes could be many different shapes
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object,
  /** Custom validation function. */
  validate: PropTypes.func,
  /** Force the submit button to always be enabled. */
  forceEnableSubmit: PropTypes.bool,
  continueInFooter: PropTypes.bool,
  /** Fired when content area animations begin. */
  onContentAnimationStart: PropTypes.func,
};

Question.defaultProps = {
  forceEnableSubmit: false,
  continueInFooter: false,
  onContentAnimationStart: undefined,
  validationSchema: undefined,
  validate: undefined,
};
