import React from 'react';
import PropTypes from 'prop-types';

const Checkmark = ({ checked, bg }) => {
  return checked ? (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      xmlSpace="preserve"
    >
      <circle cx="11.5" cy="11.5" r="11.5" fill={bg} />
      <path
        className="st1"
        d="M17,8.4l-7,7.2c-0.1,0.1-0.3,0.1-0.5,0c0,0,0,0,0,0L6,11.9c-0.1-0.1-0.1-0.4,0-0.5L6.5,11
	c0.1-0.1,0.3-0.1,0.5,0c0,0,0,0,0,0l2.9,3l6.3-6.5c0.1-0.1,0.3-0.1,0.5,0L17,7.9C17.1,8,17.1,8.2,17,8.4z"
        fill="#ffffff"
      />
    </svg>
  ) : (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      style={{ enableBackground: 'new 0 0 23 23' }}
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M11.5,1C17.3,1,22,5.7,22,11.5S17.3,22,11.5,22S1,17.3,1,11.5S5.7,1,11.5,1 M11.5,0C5.1,0,0,5.1,0,11.5S5.1,23,11.5,23
		S23,17.9,23,11.5S17.9,0,11.5,0L11.5,0z"
          fill={bg}
        />
      </g>
    </svg>
  );
};

Checkmark.defaultProps = {
  checked: true,
  bg: null,
};

Checkmark.propTypes = {
  checked: PropTypes.bool,
  bg: PropTypes.string,
};

export default Checkmark;
