import { useAnimation } from 'framer-motion';

export function useAstronautScene() {
  const contentAnimation = useAnimation();
  const imageAnimation = useAnimation();

  const runScene = (variant = 'goForwardExit') => {
    return Promise.all([
      contentAnimation.start(variant),
      imageAnimation.start(variant),
    ]);
  };

  return {
    contentAnimation,
    imageAnimation,
    runScene,
  };
}

export function useRequirementsScene() {
  const contentAnimation = useAnimation();
  const imageAnimation = useAnimation();

  const runScene = (variant = 'goForwardExit') => {
    return Promise.all([
      contentAnimation.start(variant),
      imageAnimation.start(variant),
    ]);
  };

  return {
    contentAnimation,
    imageAnimation,
    runScene,
  };
}

export function useJoinScene() {
  const contentAnimation = useAnimation();
  const imageAnimation = useAnimation();

  const runScene = (variant = 'goForwardExit') => {
    return Promise.all([
      contentAnimation.start(variant),
      imageAnimation.start(variant),
    ]);
  };

  return {
    contentAnimation,
    imageAnimation,
    runScene,
  };
}
