import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { ButtonBase } from 'shared/components/styled/buttons';
import { ReactComponent as PlayIcon } from './play-button.svg';

function VideoPlayer({ src, type }) {
  const videoRef = useRef(null);
  const [hasClickedPlay, setClickedPlay] = useState(false);
  const [isVideoEnded, setVideoEnded] = useState(false);
  const isInitialLoad = !hasClickedPlay;

  useEffect(() => {
    const videoEl = videoRef.current;

    const handleEnded = () => {
      setVideoEnded(true);
    };

    const handlePlay = () => {
      setVideoEnded(false);
    };

    videoEl.addEventListener('ended', handleEnded);
    videoEl.addEventListener('play', handlePlay);

    return () => {
      videoEl.removeEventListener('ended', handleEnded);
      videoEl.removeEventListener('play', handlePlay);
    };
  }, [videoRef, setVideoEnded]);

  const handlePlayClick = () => {
    setClickedPlay(true);
    videoRef.current.load(); // Restart the video.
  };

  return (
    <>
      <VideoOverlay hasClickedPlay={hasClickedPlay} isVideoEnded={isVideoEnded}>
        <PlayButton onClick={handlePlayClick}>
          <PlayIcon />
        </PlayButton>
      </VideoOverlay>

      <StyledVideo
        ref={videoRef}
        autoPlay
        controls={false}
        loop={isInitialLoad}
        muted={isInitialLoad}
      >
        <source src={src} type={type} />
        Your browser does not support the video tag.
      </StyledVideo>
    </>
  );
}

VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  type: PropTypes.string,
};

VideoPlayer.defaultProps = {
  type: 'video/mp4',
};

const PlayButton = styled(ButtonBase)`
  outline: none;
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: opacity 0.5s linear;

  /* Display overlay by default. */
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.66);

  /* Remove overlay when user clicks play. */
  ${({ hasClickedPlay }) =>
    hasClickedPlay &&
    css`
      opacity: 0;
      pointer-events: none;

      ${PlayButton} {
        display: none;
      }
    `}

  /* Display overlay again when video ends. */
  ${({ isVideoEnded }) =>
    isVideoEnded &&
    css`
      opacity: 1;
      pointer-events: auto;

      ${PlayButton} {
        display: block;
      }
    `}
`;

const StyledVideo = styled.video`
  position: relative;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export default VideoPlayer;
