import React from 'react';
import get from 'lodash/get';
import trim from 'lodash/trim';
import styled from 'styled-components';
import { Field } from 'formik';
import * as Yup from 'yup';
import { rem } from 'theme/lib';
import CloudSelectorField from 'shared/components/formik/CloudSelectorField';
import TextField from 'shared/components/formik/TextField/TextField';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import { useProfileFieldOptions } from 'guideProfile/hooks/queries';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
} from 'shared/components/WizardQuestionForm';

// eslint-disable-next-line consistent-return
const validationSchema = Yup.object().test('', '', value => {
  if (value.diversity.includes('NA') && value.diversity.length > 1) {
    return new Yup.ValidationError(
      `Please check your selection, "NA" cannot be selected with other fields`,
      null,
      'other.diversity'
    );
  }

  if (
    (!value.diversity || !value.diversity.length) &&
    !trim(get(value, 'other.diversity'))
  ) {
    return new Yup.ValidationError(
      'Please specify at least one option',
      null,
      'other.diversity'
    );
  }
});

export default function Diversity(props) {
  const fieldOptions = useProfileFieldOptions('diversity').data;
  return (
    <WizardQuestionForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValues={{
        diversity: [],
        other: {
          diversity: '',
        },
      }}
      validationSchema={validationSchema}
    >
      {() => {
        return (
          <FormContainer>
            <FormContentHeader>
              <QuestionHeading as="h1">
                Are any of the following considerations meaningful to you?
              </QuestionHeading>

              <QuestionDescription width={410}>
                If any of the following are meaningful to how you identify, we
                invite you to share it with us. With this insight, we can do a
                better job personalizing you to Seekers who might find these
                considerations of critical value to their coaching experience.
              </QuestionDescription>
            </FormContentHeader>

            <FormContent width={620}>
              <Field
                name="diversity"
                component={CloudSelectorField}
                items={fieldOptions}
              />

              <TextFieldWrapper>
                <Field
                  name="other.diversity"
                  component={TextField}
                  placeholder="Other (write-in)"
                />
              </TextFieldWrapper>
            </FormContent>
          </FormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

Diversity.propTypes = withNavigationPropTypes;

const TextFieldWrapper = styled.div`
  max-width: ${rem(470)};
`;
