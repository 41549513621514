import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { H1 } from 'shared/components/styled/typography';
import { rem } from 'theme/lib';
import media from 'theme/media';

function SummaryHeader({ className, step, name }) {
  const headerContent = [
    // 0 is *the* final step; aka no more steps are left
    {
      title: 'Done! Submit to apply now',
      subtitle: 'Revisit any section to fine-tune and edit.',
    },
    {
      title: `Hi, ${name}! Let's start building your application`,
    },
    {
      title: "You're on your way!",
      subtitle:
        'Add a photo of yourself to start building your own coach profile.',
    },
    {
      title: 'Great photo, keep going',
      subtitle:
        'You’re over halfway through the application. Now start building out what people are going to read about you in your coach profile.',
    },
    {
      title: "It's starting to come together nicely",
    },
  ];
  const { subtitle } = headerContent[step];
  return (
    <StyledSummaryHeader className={className} allStepsComplete={!step}>
      <Title>{headerContent[step].title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </StyledSummaryHeader>
  );
}

SummaryHeader.propTypes = {
  step: PropTypes.number.isRequired,
  name: PropTypes.string,
};

SummaryHeader.defaultProps = {
  name: '',
};

const StyledSummaryHeader = styled.header`
  /* Appply margin if all steps are done to add some spacing when there're no breadcrumbs */
  ${({ allStepsComplete }) =>
    allStepsComplete &&
    css`
      margin-bottom: ${rem(40)};
    `}
`;

const Title = styled(H1)`
  margin-bottom: ${rem(24)};
  ${media.up('medium')} {
    margin-bottom: ${rem(20)};
  }
`;

const Subtitle = styled.div`
  /* Negative margin to offset BreadcrumbsWrapper top margin */
  margin-bottom: ${rem(-4)};

  ${media.up('medium')} {
    margin-bottom: ${rem(15)};
  }
`;

export default SummaryHeader;
