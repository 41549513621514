// Allow props spreading because this a wrapper component for react-compound-slider
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem, color } from 'theme/lib';

function SliderHandle({ className, domain, handle, getHandleProps, ...props }) {
  const [min, max] = domain;
  const { id, value, percent } = handle;
  const renderValue = updatedValue => {
    if (updatedValue === min) return '<' + (min + 1);
    if (updatedValue === max) return '>' + (max - 1);
    return updatedValue;
  };

  return (
    <StyledHandle
      role="slider"
      className={className}
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      percent={percent}
      {...props}
      {...getHandleProps(id)}
    >
      <StyledValue>{renderValue(value)}</StyledValue>
    </StyledHandle>
  );
}

SliderHandle.propTypes = {
  domain: PropTypes.arrayOf(PropTypes.number).isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string,
    percent: PropTypes.number,
    value: PropTypes.number,
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
};

const StyledHandle = styled.div`
  cursor: grab;
  position: absolute;
  left: ${p => p.percent}%;
  margin-left: ${rem(-18)};
  margin-top: ${rem(-17)};
  z-index: 2;
  width: ${rem(36)};
  height: ${rem(36)};
  border: 2px solid ${color('brand.primary')};
  border-radius: 50%;
  background-color: ${color('white')};
  outline: none;
  text-align: center;
`;

const StyledValue = styled.div`
  margin-top: ${rem(40)};
  color: ${color('black')};
  font-weight: 500;
`;

export default SliderHandle;
