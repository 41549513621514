import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import routes from 'shared/routes';
import ErrorMessage from 'shared/components/ErrorMessage/ErrorMessage';
import { useVerifyUser } from 'auth/hooks/queries';
import { StyledTextField, StyledButton, StyledForm } from './styled';

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required('Required')
    .min(5, 'Code should be 5 characters.'),
});

export default function VerifyForm() {
  const location = useLocation();
  const redirectPath = get(location, 'state.returnTo', routes.summaryBase);
  const [verifyUser, { loading, error }] = useVerifyUser(redirectPath);

  const verify = useCallback(
    ({ code }) => {
      verifyUser(code);
    },
    [verifyUser]
  );

  return (
    <Formik
      onSubmit={verify}
      initialValues={{ code: '' }}
      validationSchema={validationSchema}
    >
      {({ isValid, values: { code } }) => (
        <StyledForm>
          <Field name="code">
            {({ field, form, ...props }) => (
              <StyledTextField
                autoFocus
                maxLength={5}
                placeholder="Your 5-digit Code"
                form={form}
                field={{
                  ...field,
                  value: field.value.toUpperCase().trim(),
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          </Field>

          <StyledButton
            block
            type="submit"
            disabled={!isValid || loading || !code.length}
          >
            Verify
          </StyledButton>
          <ErrorMessage error={error} />
        </StyledForm>
      )}
    </Formik>
  );
}
