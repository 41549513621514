import React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { rem, color } from 'theme/lib';
import { encodeSVG } from 'theme/helpers';
import Checkmark from './CheckMarkSVG';

function getBgColor(theme, bg) {
  return bg
    ? color(`base.${bg}`)({ theme })
    : color('base.sphereBlue')({ theme });
}

function Checklist({ className, children }) {
  return <StyledChecklist className={className}>{children}</StyledChecklist>;
}

Checklist.propTypes = {
  children: PropTypes.node,
};

Checklist.defaultProps = {
  children: null,
};

const StyledChecklist = styled.ul`
  font-weight: 500;
  list-style-type: none;
  padding: 0;
`;

Checklist.Item = styled.li`
  display: flex;
  align-items: flex-start;
  line-height: ${23 / 16};
  margin-bottom: ${rem(37)};
  -webkit-font-smoothing: subpixel-antialiased;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    display: block;
    content: '';
    min-width: 23px;
    width: 23px; /* pixels are used here to prevent clipping in narrow containers*/
    height: 23px;
    margin-right: ${rem(10)};

    ${({ theme, bg, checked }) =>
      css`
        background-image: url(${encodeSVG(
          <Checkmark checked={checked === true} bg={getBgColor(theme, bg)} />
        )});
      `}
  }
`;

export default Checklist;
