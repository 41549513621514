import React from 'react';
import styled from 'styled-components';
import { color, rem } from 'theme/lib';
import { ReactComponent as IconArrowLeft } from 'assets/svg/arrow-left.svg';

const CarouselNavButton = styled.button`
  && {
    display: flex !important; /* HACK: Override inline style from Slick */
    flex-direction: column;
    justify-content: center;
    width: ${rem(27)};
    height: ${rem(54)};
    overflow: hidden;
    z-index: 100;
    cursor: pointer;

    &,
    &:hover {
      color: ${color('white')};
    }

    &::before {
      display: none;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: '';
      width: ${rem(54)};
      height: ${rem(54)};
      border-radius: ${rem(54)};
      background-color: ${color('link')};
      z-index: -1;
    }

    &:hover::after {
      background-color: ${color('brand.primary')};
    }
  }

  &.slick-prev {
    left: 0;
    align-items: flex-start;
    padding-left: ${rem(3)};

    &::after {
      transform: translateX(-50%);
    }
  }

  &.slick-next {
    right: 0;
    align-items: flex-end;
    padding-right: ${rem(3)};

    &::after {
      transform: translateX(0);
    }
  }

  &.slick-prev,
  &.slick-next {
    &:focus,
    &:hover {
      color: ${color('white')};
    }
  }

  &.slick-disabled {
    opacity: 0.08;
    pointer-events: none;
  }

  svg {
    display: block;
    color: inherit;
  }
`;

const IconArrowRight = styled(IconArrowLeft)`
  transform: rotate(180deg);
`;

export const CarouselPrevButton = props => (
  <CarouselNavButton
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  >
    <IconArrowLeft />
  </CarouselNavButton>
);

export const CarouselNextButton = props => (
  <CarouselNavButton
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  >
    <IconArrowRight />
  </CarouselNavButton>
);
