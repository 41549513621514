import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useHistory, Link } from 'react-router-dom';
import { rem, fontSize } from 'theme/lib';
import routes from 'shared/routes';
import { H3 } from 'shared/components/styled/typography';
import UpdatePasswordForm from './UpdatePasswordForm';
import { useLogin, useUpdatePassword } from '../../hooks/queries';

export const UpdatePasswordMessage = styled.div`
  font-size: ${fontSize(2)};
`;

export const UpdatePasswordTitle = styled(H3)`
  margin-bottom: ${rem(16)};
`;

const LOGIN_AND_REDIRECT_DELAY = 1000;

function UpdatePasswordContent({ emailAddress, token, tokenExpired }) {
  const history = useHistory();
  const [login, { error: loginError }] = useLogin(routes.summaryBase);
  const [passwordUpdateSuccessful, setPasswordUpdateSuccessful] = useState(
    false
  );
  const [updatePassword, { loading, error }] = useUpdatePassword();
  const handleSubmit = useCallback(
    ({ newPassword }) => {
      updatePassword(emailAddress, newPassword, token, () => {
        setPasswordUpdateSuccessful(true);

        // Automatically login to the app
        setTimeout(() => {
          login(emailAddress, newPassword);
        }, LOGIN_AND_REDIRECT_DELAY);
      });
    },
    [emailAddress, token, updatePassword, login]
  );

  const handleCancel = useCallback(() => {
    // Let send them to the summary screen. If they are not logged in,
    // it will redirect them to the login page.
    history.push(routes.summaryBase);
  }, [history]);

  // If login fails, lets send them to the login screen so they can manually try again
  useEffect(() => {
    if (loginError) {
      history.replace(routes.login);
    }
  }, [history, loginError]);

  if (!emailAddress || tokenExpired) {
    return (
      <>
        <UpdatePasswordTitle>Invalid Token</UpdatePasswordTitle>
        <UpdatePasswordMessage>
          I am sorry, but this link is no longer valid and can not be used to
          reset your password.{' '}
          <Link
            to={{
              pathname: routes.forgotPassword,
              state: {
                email: emailAddress,
              },
            }}
          >
            Click here to receive a new one.
          </Link>
        </UpdatePasswordMessage>
      </>
    );
  }

  if (!passwordUpdateSuccessful) {
    return (
      <>
        <H3>Create new password</H3>
        <UpdatePasswordForm
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          loading={loading}
          error={error}
        />
      </>
    );
  }

  return (
    <>
      <UpdatePasswordTitle>Password updated!</UpdatePasswordTitle>
      <UpdatePasswordMessage>
        Your password has been successfully updated
      </UpdatePasswordMessage>
    </>
  );
}

UpdatePasswordContent.propTypes = {
  emailAddress: PropTypes.string,
  token: PropTypes.string,
  tokenExpired: PropTypes.bool,
};

UpdatePasswordContent.defaultProps = {
  emailAddress: '',
  token: '',
  tokenExpired: false,
};

export default React.memo(UpdatePasswordContent);
