import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import {
  StyledLabel,
  IconWrapper,
  StyledCheckboxEmpty,
  StyledCheckboxFilled,
  StyledBorderedCheckboxEmpty,
  StyledBorderedCheckboxFilled,
  HiddenCheckbox,
  CheckboxContainer,
} from './styled';

const iconsConfig = {
  default: {
    symbolEmpty: StyledCheckboxEmpty,
    symbolFilled: StyledCheckboxFilled,
    size: 23,
  },
  bordered: {
    symbolEmpty: StyledBorderedCheckboxEmpty,
    symbolFilled: StyledBorderedCheckboxFilled,
    size: 25,
  },
};

function OutlinedCheckbox({
  label,
  labelCSS,
  type,
  value,
  name,
  checked,
  onChange,
  id = uuid(),
  icon,
  ...props
}) {
  const idRef = useRef(id);

  const selectedIcon = iconsConfig[icon];
  const CheckboxEmpty = selectedIcon.symbolEmpty;
  const CheckboxFilled = selectedIcon.symbolFilled;

  return (
    <StyledLabel labelCSS={labelCSS} htmlFor={idRef.current}>
      <CheckboxContainer size={selectedIcon.size} icon={icon}>
        <HiddenCheckbox
          {...props} // eslint-disable-line react/jsx-props-no-spreading
          id={idRef.current}
          type={type}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <IconWrapper>
          <CheckboxEmpty className="checkbox-empty" />
          <CheckboxFilled className="checkbox-filled" />
        </IconWrapper>
      </CheckboxContainer>
      {label}
    </StyledLabel>
  );
}

OutlinedCheckbox.propTypes = {
  /** ID of DOM element to associate label with input. */
  id: PropTypes.string,
  /** Input type */
  type: PropTypes.oneOf(['checkbox', 'radio']),
  /** Input value */
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types, react/require-default-props
  /** Label beside checkbox. */
  label: PropTypes.node,
  /** Customize the label styles using styled-components css`` */
  labelCSS: PropTypes.arrayOf(PropTypes.string),
  /** Form field name. */
  name: PropTypes.string.isRequired,
  /** Is the checkbox checked. */
  checked: PropTypes.bool.isRequired,
  /** Triggered when checked state changes. */
  onChange: PropTypes.func,
  /** Determines which checkboxes icons are rendered */
  icon: PropTypes.oneOf(['default', 'bordered']),
};

OutlinedCheckbox.defaultProps = {
  id: undefined,
  type: 'checkbox',
  label: null,
  labelCSS: [],
  onChange: () => {},
  icon: 'default',
};

export default OutlinedCheckbox;
