/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ProgressBar from 'shared/components/ProgressBar/ProgressBar';
import { color, rem } from 'theme/lib';

function DropzonePreview({ meta, setIsPreviewShowing }) {
  const { percent } = meta;
  const progressPercentage = `${parseInt(percent, 10)}%`;

  useEffect(() => {
    setIsPreviewShowing(true);

    return () => {
      setIsPreviewShowing(false);
    };
  }, [setIsPreviewShowing]);

  return (
    <StyledPreview>
      <StyledProgressBar
        percent={percent}
        fillColor="primary"
        fillDuration={0.2}
        size="small"
      />
      <ProgressNumber>{progressPercentage}</ProgressNumber>
    </StyledPreview>
  );
}

DropzonePreview.propTypes = {
  meta: PropTypes.shape({
    id: PropTypes.string.isRequired,
    lastModifiedDate: PropTypes.string,
    name: PropTypes.string,
    percent: PropTypes.number,
    size: PropTypes.number,
    status: PropTypes.oneOf([
      'preparing',
      'error_file_size',
      'error_validation',
      'ready',
      'getting_upload_params',
      'error_upload_params',
      'uploading',
      'exception_upload',
      'aborted',
      'error_upload',
      'headers_received',
      'done',
      'removed',
    ]).isRequired,
    type: PropTypes.string.isRequired,
    uploadedDate: PropTypes.string,
  }).isRequired,
  fileWithMeta: PropTypes.shape({
    file: PropTypes.any.isRequired,
    meta: PropTypes.object.isRequired,
    cancel: PropTypes.func.isRequired,
    restart: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    xhr: PropTypes.any,
  }).isRequired,
  setIsPreviewShowing: PropTypes.func.isRequired,
};

const StyledProgressBar = styled(ProgressBar)``;

const ProgressNumber = styled.div`
  white-space: nowrap;
  min-width: 5ch;
  text-align: right;
`;

const StyledPreview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${rem(14)};
  line-height: ${26 / 14};
  /* color: ${color('grays.400')}; */
  text-align: left;
  /* max-width: 80%; */
`;

export default DropzonePreview;
