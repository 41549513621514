import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PasswordStrength from 'password-strength-calculator';
import { color } from 'theme/lib';
import { MIN_PASSWORD_LENGTH, MIN_PASSWORD_STRENGTH } from 'auth/constants';

const passwordStrengthTest = new PasswordStrength({
  minWidth: MIN_PASSWORD_LENGTH,
});

const COLORS = {
  red: color('base.scarletRed'),
  green: color('base.kermitGreen'),
  gray: color('grays.200'),
};

const bgColor = (idx, strength, isValid) => {
  const unfilledColor = idx === 1 ? COLORS.red : COLORS.gray;
  if (!isValid) {
    return unfilledColor;
  }

  if (strength >= MIN_PASSWORD_STRENGTH * (idx / 4)) {
    return COLORS.green;
  }

  return unfilledColor;
};

const PasswordStrengthMeter = ({ password }) => {
  const isValid =
    password.length >= MIN_PASSWORD_LENGTH && !/\s/.test(password);
  const strength = passwordStrengthTest.passwordStrength(password);

  return (
    <Wrapper>
      <Segment
        bgColor={password.length ? bgColor(1, strength, isValid) : COLORS.gray}
      />
      <Segment bgColor={bgColor(2, strength, isValid)} />
      <Segment bgColor={bgColor(2, strength, isValid)} />
      <Segment bgColor={bgColor(2, strength, isValid)} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Segment = styled.div`
  height: 6px;
  width: 24%;
  background: ${props => props.bgColor || COLORS.gray};
`;

PasswordStrengthMeter.defaultProps = {
  password: '',
};

PasswordStrengthMeter.propTypes = {
  password: PropTypes.string,
};

export default PasswordStrengthMeter;
