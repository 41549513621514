import React from 'react';
import { useMediaQuery } from 'react-responsive';
import get from 'lodash/get';
import styled from 'styled-components/macro';
import { rem } from 'theme/lib';
import { breakpoints } from 'theme/media';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import getDevonVideoAssets from '../VideoPlayer/devon-video-assets';

function VideoSidebarContent() {
  const isLargeScreen = useMediaQuery({
    query: `(min-width: ${breakpoints.large}px)`,
  });

  // Prevent video asset from loading on smaller screens.
  if (!isLargeScreen) {
    return null;
  }

  const videoAssets = getDevonVideoAssets();
  const videoUrl = get(videoAssets, 'large.url');
  const videoType = `video/${get(videoAssets, 'large.ext')}`;

  return (
    <VideoPlayerWrapper>
      {videoUrl && <VideoPlayer src={videoUrl} type={videoType} />}
    </VideoPlayerWrapper>
  );
}

VideoSidebarContent.propTypes = {};

VideoSidebarContent.defaultProps = {};

const VideoPlayerWrapper = styled.div`
  position: absolute;
  top: ${rem(20)};
  right: ${rem(20)};
  bottom: ${rem(20)};
  left: 0;
  overflow: hidden;
  border-radius: ${rem(10)};
`;

export default VideoSidebarContent;
