import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'shared/routes';
import { joinRoutes } from 'shared/utils/string';
import { useCurrentUser } from 'auth/hooks/queries';
import CodeOfConductContext from '../CodeOfConductContext';

// Make sure that the user can not skip any steps.
export default function useProtectCodeOfConduct(
  requiredStep,
  requireSignature = false
) {
  const history = useHistory();
  const currentUser = useCurrentUser();
  const { lastAcceptedRule, hasSigned } = useContext(CodeOfConductContext);

  useEffect(() => {
    if (!currentUser.data) {
      // Wait till the current user has been loaded before checking if they should be redirected
      return;
    }

    if (lastAcceptedRule < requiredStep) {
      // The required rule has not been accepted yet. Redirect to where the user
      // should pickup.
      history.replace(
        joinRoutes(
          routes.applicationApproved.codeOfConduct(true),
          String(lastAcceptedRule + 1)
        )
      );
    } else if (requireSignature && !hasSigned) {
      // Code of conduct has been accepted, but they haven't given an electronic signature yet.
      history.replace(routes.applicationApproved.signature(true));
    }
  }, [
    history,
    lastAcceptedRule,
    requiredStep,
    requireSignature,
    hasSigned,
    currentUser.data,
  ]);
}
