import styled from 'styled-components';
import { rem, color } from 'theme/lib';
import media from 'theme/media';
import { H3 } from 'shared/components/styled/typography';

export const Heading = styled(H3).attrs({ as: 'h1' })``;

export const MainInner = styled.div`
  margin-right: ${rem(30)};
`;

export const RateStatement = styled.p`
  margin-top: 0;
`;

export const CurrencyBlock = styled.span`
  background: ${color('base.sphereBlue')};
  color: ${color('white')};
  padding: ${rem(2)} ${rem(10)};
  display: inline-block;
  margin-left: ${rem(10)};
`;

export const Content = styled.div`
  font-size: ${rem(14)};
  max-width: ${rem(525)};
`;

export const Disclaimer = styled.div`
  font-size: ${rem(14)};
  color: ${color('base.azulBlue')};
  border: solid 1px ${color('base.azulBlue')};
  padding: ${rem(10)};
  margin: ${rem(35)} 0 0;
  max-width: ${rem(585)};
`;

export const DisclaimerContent = styled.p`
  margin: 0;
`;

export const ImageWrapper = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  ${media.up('large')} {
    height: calc(100% - ${rem(130)});
    margin-top: ${rem(20)};
    margin-bottom: ${rem(20)};
    border-radius: ${rem(10)};
  }
`;

export const Image = styled.img`
  position: relative;
  display: block;
  width: 105%;
  height: 102%;
  object-fit: cover;
  object-position: center center;
`;
