/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import get from 'lodash/get';
import routes from 'shared/routes';
import ArrowNavButton from 'shared/components/ArrowNavButton/ArrowNavButton';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import { useResendVerification } from 'auth/hooks/queries';
import { H3 } from 'shared/components/styled/typography';
import loginImg1x from '../SignInScreen/login.jpg';
import loginImg2x from '../SignInScreen/login@2x.jpg';
import loginImg3x from '../SignInScreen/login@3x.jpg';
import VerifyForm from './VerifyForm';
import { StyledLink } from './styled';
import {
  SignInLayout,
  FormContainer,
  FormWrapper,
  SignUpMessage,
  ImageContainer,
  StyledImg,
} from '../SignInScreen/styled';

function VerifyScreen({ className }) {
  const location = useLocation();
  const history = useHistory();
  const email = get(location, 'state.email');
  const [resendVerification] = useResendVerification();

  const resendCode = useCallback(() => {
    resendVerification();
  }, [resendVerification]);

  const handleGoBack = useCallback(() => {
    history.replace(routes.login);
  }, [history]);

  return (
    <SplitLayout className={className}>
      <SplitLayout.Main>
        <SplitLayout.MainContent>
          <SignInLayout>
            <FormContainer>
              <FormWrapper>
                <H3>Verify your account</H3>
                <SignUpMessage>
                  {email &&
                    `We sent a verification code to ${email}, please enter it below.`}
                  &nbsp;
                  <StyledLink onClick={resendCode}>Resend Code</StyledLink>
                </SignUpMessage>
                <VerifyForm />
              </FormWrapper>
              <ArrowNavButton onClick={handleGoBack}>Go back</ArrowNavButton>
            </FormContainer>

            <ImageContainer>
              <StyledImg
                className={className}
                src={loginImg1x}
                srcSet={`${loginImg1x} 1x, ${loginImg2x} 2x, ${loginImg3x} 3x`}
                alt="Login"
              />
            </ImageContainer>
          </SignInLayout>
        </SplitLayout.MainContent>
      </SplitLayout.Main>
    </SplitLayout>
  );
}

export default VerifyScreen;
