import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { rem } from 'theme/lib';
import { breakpoints } from 'theme/media';
import FeaturePhotoCarousel from '../../FeaturePhotoCarousel/FeaturePhotoCarousel';

function FeaturePhotoSidebar() {
  const canShowSidebar = useMediaQuery({
    query: `(min-width: ${breakpoints.large}px)`,
  });

  if (!canShowSidebar) {
    return null;
  }

  return (
    <SliderWrapper>
      <FeaturePhotoCarousel />
    </SliderWrapper>
  );
}

FeaturePhotoSidebar.propTypes = {};

FeaturePhotoSidebar.defaultProps = {};

const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding-top: ${rem(50)};
  padding-bottom: ${rem(50)};
`;

export default FeaturePhotoSidebar;
