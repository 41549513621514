import React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import converter from 'number-to-words';
import CloudSelectorField from 'shared/components/formik/CloudSelectorField';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import { useProfileFieldOptions } from 'guideProfile/hooks/queries';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
} from 'shared/components/WizardQuestionForm';

const REQUIRED_OPTIONS_COUNT = 1;

const validationSchema = Yup.object().shape({
  lifestyle: Yup.array().min(
    REQUIRED_OPTIONS_COUNT,
    `Please select ${converter.toWords(REQUIRED_OPTIONS_COUNT)}`
  ),
});

export default function Lifestyle(props) {
  const fieldOptions = useProfileFieldOptions('lifestyle').data;

  return (
    <WizardQuestionForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValues={{
        lifestyle: [],
      }}
      validationSchema={validationSchema}
    >
      {() => {
        return (
          <FormContainer>
            <FormContentHeader>
              <QuestionHeading as="h1">
                How would you describe the clients you work with the best?
              </QuestionHeading>

              <QuestionDescription>
                This helps our matching algorithm work it&apos;s magic.
              </QuestionDescription>
            </FormContentHeader>

            <FormContent width={620}>
              <Field
                name="lifestyle"
                component={CloudSelectorField}
                items={fieldOptions}
              />
            </FormContent>
          </FormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

Lifestyle.propTypes = withNavigationPropTypes;
