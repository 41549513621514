export const STATUS_NOT_SUBMITTED = 'not submitted';
export const STATUS_SUBMITTED = 'submitted';
export const STATUS_UNDER_REVIEW = 'pending';
export const STATUS_APPROVED = 'approved';
export const STATUS_REJECTED = 'rejected';
export const STATUS_CANCELLED = 'cancelled';

export const UPLOAD_TYPE_PROFILE_PHOTO = 'profile';
export const UPLOAD_TYPE_SECONDARY_PHOTO = 'hero';
export const UPLOAD_TYPE_CERTIFICATION = 'cert';

export const BIO_MAX_LENGTH = 960;
export const CERT_NAME_MAX_LENGTH = 70;
export const APPROACH_MAX_LENGTH = 40;
export const EXPERIENCE_MAX_LENGTH = 120;
export const FOCUS_MAX_LENGTH = 60;
export const BENEFIT_MAX_LENGTH = 60;
export const TESTIMONIAL_MAX_LENGTH = 500;
