import styled, { css } from 'styled-components';
import { color, rem } from 'theme/lib';

export const InputWrapper = styled.div`
  display: flex;
  background-color: ${color('white')};
  border: 1px solid ${color('form.outlinedInputBorder')};
  border-radius: ${rem(10)};
  padding: ${rem(20)};

  ${props =>
    props.isFocused &&
    css`
      border-color: ${color('form.outlinedInputBorderFocus')};
      caret-color: ${color('form.outlinedInputBorderFocus')};
    `}

  .has-error & {
    border-color: ${color('brand.error')};
  }
`;

export const InputAdornmentEnd = styled.div`
  display: flex;
  align-items: ${({ align }) => align};

  .char-count-adornment {
    font-size: ${rem(13)};
    line-height: ${20 / 13};
  }
`;

export const StyledTextarea = styled.textarea`
  flex: 1;
  display: block;
  border: 0;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25;
  background-color: transparent;
  resize: none;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    font-weight: normal;
    color: ${color('form.inputPlaceholder')};
  }
`;
