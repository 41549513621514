// Allow props spreading because this is a wrapper for Link
/* eslint-disable  react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import media from 'theme/media';
// import { Link } from 'react-router-dom';
import { rem } from 'theme/lib';
import { H3 } from 'shared/components/styled/typography';
import NumberedList from 'shared/components/NumberedList/NumberedList';

function StepNumberedList() {
  return (
    <StyledStepNumberedList>
      <NumberedListTitle>Things to know first</NumberedListTitle>
      <NumberedList>
        <NumberedList.Item>
          There are no upfront fees to join Sphere.
        </NumberedList.Item>
        <NumberedList.Item>
          <div>
            We pay you behind the scenes. Clients won&apos;t know or be
            influenced by price.
            {/* Commented out until payment screen is implemented */}
            {/* <br /> */}
            {/* <LearnLink {...linkProps}>Learn more about payment.</LearnLink> */}
          </div>
        </NumberedList.Item>
        <NumberedList.Item>
          You&apos;ll gain access to a diverse community of individuals, beyond
          your bubble.
        </NumberedList.Item>
        <NumberedList.Item>
          You spend less time on biz dev and more time doing what you love.
        </NumberedList.Item>
        <NumberedList.Item>
          Together we&apos;ll be part of something bigger: democratizing
          coaching & scaling the impact of Guidance.
        </NumberedList.Item>
      </NumberedList>
    </StyledStepNumberedList>
  );
}

StepNumberedList.propTypes = {};

const NumberedListTitle = styled(H3)`
  margin-top: ${rem(4)};
  margin-bottom: ${rem(16)};
  ${media.up('medium')} {
    margin-top: ${rem(24)};
  }
`;

const StyledStepNumberedList = styled.div`
  margin-bottom: ${rem(18)};
`;

// const LearnLink = styled(Link)``;

export default StepNumberedList;
