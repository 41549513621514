import React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import OutlinedTextareaField from 'shared/components/formik/OutlinedTextareaField/OutlinedTextareaField';
import CharCountAdornment from 'shared/components/formik/CharCountAdornment/CharCountAdornment';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
} from 'shared/components/WizardQuestionForm';
import { BIO_MAX_LENGTH } from 'guideProfile/constants';

const validationSchema = Yup.object().shape({
  bio: Yup.string()
    .required('Required')
    .trim(),
});

export default function AboutQuestion(props) {
  return (
    <WizardQuestionForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValues={{
        bio: '',
      }}
      validationSchema={validationSchema}
    >
      {() => {
        return (
          <FormContainer>
            <FormContentHeader>
              <QuestionHeading as="h1">Your bio</QuestionHeading>

              <QuestionDescription width={475}>
                Give Seekers a taste of who you are and what they can expect
                when they work with you.
              </QuestionDescription>
            </FormContentHeader>

            <FormContent width={540}>
              <Field
                name="bio"
                component={OutlinedTextareaField}
                placeholder="Start typing here..."
                maxLength={BIO_MAX_LENGTH}
                maxRows={20}
                renderAdornmentEnd={({ value }) => (
                  <CharCountAdornment value={value} limit={BIO_MAX_LENGTH} />
                )}
              />
            </FormContent>
          </FormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

AboutQuestion.propTypes = withNavigationPropTypes;
