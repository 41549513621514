import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rem, color } from 'theme/lib';

function StepPending({
  className,
  stepTitle,
  stepSubtitle,
  isPreviousStepPending,
}) {
  return (
    <StyledStepPending
      className={className}
      isPreviousStepPending={isPreviousStepPending}
    >
      <StepTitle>{stepTitle}</StepTitle>
      <StepSubtitle>{stepSubtitle}</StepSubtitle>
    </StyledStepPending>
  );
}

StepPending.propTypes = {
  stepTitle: PropTypes.string.isRequired,
  stepSubtitle: PropTypes.string.isRequired,
  /** Drops the opacity if the the previous step is not complete */
  isPreviousStepPending: PropTypes.bool,
};

StepPending.defaultProps = {
  isPreviousStepPending: false,
};

const StyledStepPending = styled.div`
  font-weight: 500;
  color: ${color('black')};
  opacity: 0.4;
  margin-top: ${rem(19)};

  ${({ isPreviousStepPending }) =>
    isPreviousStepPending &&
    css`
      opacity: 0.2;
    `}
`;

const StepTitle = styled.div`
  margin-bottom: ${rem(10)};
  font-size: ${rem(18)};
`;

const StepSubtitle = styled.div``;

export default StepPending;
