import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const REPORT_SOURCE = gql`
  mutation ReportSource($source: String!, $other: String) {
    reportSource(source: $source, other: $other)
  }
`;

export default function useReportSource() {
  const [mutate, queryHandler] = useMutation(REPORT_SOURCE);

  const reportSource = useCallback(
    async (sources, other, onSuccess) => {
      try {
        await mutate({
          variables: {
            source: JSON.stringify(sources), // JSON.stringify the array
            other,
          },
        });
      } catch (err) {
        // Do nothing but do not trigger success handler
        return;
      }

      if (onSuccess) {
        onSuccess();
      }
    },
    [mutate]
  );

  return [reportSource, queryHandler];
}
