import styled from 'styled-components/macro';
import { rem } from 'theme/lib';
import media from 'theme/media';

export const ConductLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  ${media.up('medium')} {
    margin-top: 11vh;
    padding-right: ${rem(47)};
  }

  ${media.up('large')} {
    margin-top: 6vh;
    flex-flow: row nowrap;
    padding-right: 0;
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  flex: 2 1 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  ${media.up('large')} {
    max-width: ${rem(580)};
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  margin-bottom: 0;
`;

export const ImageContainer = styled.div`
  flex: 1 2 25%;
  padding-bottom: ${rem(80)};

  ${media.up('large')} {
    padding-left: ${rem(30)};
    padding-bottom: 0;
    padding-top: 0;
    align-self: flex-start;
  }
`;

export const StyledImg = styled.img`
  width: 100%;
  max-width: ${rem(400)};
  height: auto;

  ${media.up('medium')} {
    max-width: 100%;
  }

  ${media.up('large')} {
    margin-bottom: ${rem(80)};
  }
`;

export const ProgressWrapper = styled.div`
  margin: ${rem(70)} 0;

  ${media.up('medium')} {
    max-width: ${rem(350)};
    margin: ${rem(70)} 0;
  }
`;

export const ProgressSummary = styled.div`
  margin: ${rem(10)} 0;
  font-weight: 500;
`;

export const AcknowledgeButton = styled.button`
  border: none;
  padding: 0;
  margin: ${rem(40)} 0 0;
  background: none;
  outline: none;
`;
