import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';
import IconFileControls from './IconFileControls';
import TextFileControls from './TextFileControls';
import {
  LandscapeLayoutWrapper,
  LandscapeLayout,
  PortraitLayoutWrapper,
  PortraitLayout,
  PreviewContainer,
} from './Layout.styled';

function DropzoneLayout({
  input,
  previews,
  dropzoneProps,
  files,
  uploadedImage,
  orientation,
}) {
  const hasFiles = files.length > 0;
  const canShowControls = !!uploadedImage || hasFiles;

  let LayoutWrapper = PortraitLayoutWrapper;
  let Layout = PortraitLayout;
  if (orientation === 'landscape') {
    LayoutWrapper = LandscapeLayoutWrapper;
    Layout = LandscapeLayout;
  }

  const isMediumScreen = useMediaQuery({ query: '(min-width: 640px)' });
  const handleUpload = () => {
    document.getElementById('dropzone-input').click();
  };

  return (
    <LayoutWrapper>
      <Layout
        {...dropzoneProps} // eslint-disable-line react/jsx-props-no-spreading
        uploadedImage={uploadedImage}
        orientation={orientation}
        className={cx(dropzoneProps.className, {
          'has-image-upload': !!uploadedImage,
        })}
      >
        {input}
        {canShowControls && (
          <PreviewContainer isUploadFinished={!!uploadedImage}>
            {hasFiles && previews}

            {!hasFiles &&
              (isMediumScreen ? (
                <IconFileControls onUpload={handleUpload} />
              ) : (
                <TextFileControls onUpload={handleUpload} />
              ))}
          </PreviewContainer>
        )}
      </Layout>
    </LayoutWrapper>
  );
}

DropzoneLayout.propTypes = {
  input: PropTypes.node,
  previews: PropTypes.arrayOf(PropTypes.node),
  dropzoneProps: PropTypes.shape({
    ref: PropTypes.any.isRequired, // eslint-disable-line
    className: PropTypes.string.isRequired,
    style: PropTypes.object, // eslint-disable-line
    onDragEnter: PropTypes.func.isRequired,
    onDragOver: PropTypes.func.isRequired,
    onDragLeave: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
  }).isRequired,
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
  uploadedImage: PropTypes.string,
  orientation: PropTypes.oneOf(['portrait', 'landscape']),
};

DropzoneLayout.defaultProps = {
  input: null,
  previews: [],
  uploadedImage: '',
  orientation: 'portrait',
};

export default DropzoneLayout;
