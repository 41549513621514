import React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import CloudSelectorField from 'shared/components/formik/CloudSelectorField';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import { useProfileFieldOptions } from 'guideProfile/hooks/queries';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
} from 'shared/components/WizardQuestionForm';

const validationSchema = Yup.object().shape({
  communication_style: Yup.array()
    .min(1, 'Please select at least one')
    .max(3, 'Select a maximum of three'),
});

export default function CommunicationStyle(props) {
  const fieldOptions = useProfileFieldOptions('communication_style').data;
  return (
    <WizardQuestionForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValues={{
        communication_style: [],
      }}
      validationSchema={validationSchema}
    >
      {() => {
        return (
          <FormContainer>
            <FormContentHeader>
              <QuestionHeading as="h1">
                How would you describe your communication style?
              </QuestionHeading>

              <QuestionDescription>
                This plays a small factor in our matching algorithm. Select up
                to three.
              </QuestionDescription>
            </FormContentHeader>

            <FormContent width={600}>
              <Field
                name="communication_style"
                component={CloudSelectorField}
                items={fieldOptions}
              />
            </FormContent>
          </FormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

CommunicationStyle.propTypes = withNavigationPropTypes;
