import styled from 'styled-components';
import { color } from 'theme/lib';

export const ButtonBase = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
`;

export const TextLinkButton = styled(ButtonBase)`
  font-size: inherit;
  font-weight: inherit;
  color: ${color('link')};
  outline: none;
  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;
