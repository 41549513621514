// Allow props spreading because this a wrapper component for react-compound-slider
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem, color } from 'theme/lib';

function SliderTrack({ className, source, target, getTrackProps, ...props }) {
  return (
    <Track
      className={className}
      source={source}
      target={target}
      {...getTrackProps()}
      {...props}
    />
  );
}

const trackDataPropType = PropTypes.shape({
  id: PropTypes.string,
  value: PropTypes.number,
  percent: PropTypes.number,
});

SliderTrack.propTypes = {
  source: trackDataPropType.isRequired,
  target: trackDataPropType.isRequired,
  getTrackProps: PropTypes.func.isRequired,
};

const Track = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: ${p => p.source.percent}%;
  z-index: 1;
  width: ${p => p.target.percent - p.source.percent}%;
  height: ${rem(3)};
  border: 0;
  background-color: ${color('brand.primary')};
`;

export default SliderTrack;
