/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import firebase from 'firebaseConfig';
import routes from 'shared/routes';

export default function ProtectedRoute(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const history = useHistory();
  const location = useLocation();

  // Subscribe only once.
  // BTW, 'history' doesn't change, so it's ok to have it in the dependency list.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        history.push(routes.login, {
          // Return to this path after successfully logging in.
          returnTo: location.pathname,
        });
      }
    });

    // unsubscribe when we navigate to a different page.
    return () => {
      unsubscribe();
    };
  }, [history, location.pathname]);

  // Delay rendering the content until we are sure that the user is authenticated.
  // This avoids flashing the sensitive content and then redirecting them away
  return isAuthenticated ? <Route {...props} /> : null;
}
