import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import cx from 'classnames';
import { color, rem } from 'theme/lib';

function CharCountAdornment({ className, value, limit }) {
  const charCount = value ? value.length : 0;
  return (
    <StyledAdornment
      className={cx(className, 'char-count-adornment')}
      charCount={charCount}
      limit={limit}
    >
      {charCount} / {limit}
    </StyledAdornment>
  );
}

CharCountAdornment.propTypes = {
  /** String to count characters from. */
  value: PropTypes.string,
  /** Upper bound number of characters. */
  limit: PropTypes.number,
};

CharCountAdornment.defaultProps = {
  value: '',
  limit: 0,
};

const StyledAdornment = styled.div`
  font-size: ${rem(13)};
  line-height: ${40 / 13};
  margin-left: ${rem(10)};
  color: ${color('grays.400')};
  text-align: right;

  ${({ charCount }) =>
    charCount > 0 &&
    css`
      color: inherit;
    `}

  ${({ charCount, limit }) => {
    // Define width using `ch` units matching number of characters to prevent
    // the width of the adornment from changing when numbers change during typing.
    const charCountLength = charCount.toString().length;
    const limitLength = limit.toString().length;
    const separatorLength = 1; // Slash character, excluding spaces.
    const totalCharLength = charCountLength + separatorLength + limitLength;
    return css`
      min-width: ${totalCharLength}ch;
    `;
  }}

  .has-error & {
    color: ${color('brand.error')};
  }
`;

export default CharCountAdornment;
