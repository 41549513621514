import { useCallback, useState } from 'react';

export default function useNavigateScenes({
  runAstronautScene,
  runRequirementsScene,
  runJoinScene,
}) {
  const [sceneIndex, setSceneIndex] = useState(1);
  const [isDirectionReversed, setDirectionReversed] = useState(false);

  const handleGoBack = useCallback(async () => {
    const goToPrevScene = () => {
      setDirectionReversed(true);
      setSceneIndex(current => current - 1);
      window.scrollTo(0, 0);
    };

    switch (sceneIndex) {
      case 3:
        await runJoinScene('goBackExit');
        goToPrevScene();
        runRequirementsScene('in');
        break;
      case 2:
        await runRequirementsScene('goBackExit');
        goToPrevScene();
        runAstronautScene('in');
        break;
      default:
      // Do nothing
    }
  }, [sceneIndex, runAstronautScene, runRequirementsScene, runJoinScene]);

  const handleGoForward = useCallback(async () => {
    const goToNextScene = () => {
      setDirectionReversed(false);
      setSceneIndex(current => current + 1);
      window.scrollTo(0, 0);
    };

    switch (sceneIndex) {
      case 1:
        await runAstronautScene('goForwardExit');
        goToNextScene();
        runRequirementsScene('in');
        break;
      case 2:
        await runRequirementsScene('goForwardExit');
        goToNextScene();
        runJoinScene('in');
        break;
      default:
      // Do nothing
    }
  }, [sceneIndex, runAstronautScene, runRequirementsScene, runJoinScene]);

  return {
    sceneIndex,
    /** Is the user navigating forward or backward. */
    isDirectionReversed,
    onGoBack: handleGoBack,
    onGoForward: handleGoForward,
  };
}
