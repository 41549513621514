import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CloudSelectorItem from './CloudSelectorItem';
import { cloudSelectorItemPropType } from './propTypes';

function CloudSelector({ className, items, value, onChange }) {
  return (
    <StyledCloudSelector className={className}>
      {items.map(item => (
        <CloudSelectorItem
          key={item.id}
          id={item.id}
          label={item.label}
          checked={value.includes(item.id.toString())}
          onChange={onChange}
        />
      ))}
    </StyledCloudSelector>
  );
}

CloudSelector.propTypes = {
  items: PropTypes.arrayOf(cloudSelectorItemPropType),
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

CloudSelector.defaultProps = {
  items: [],
  value: [],
  onChange: () => {},
};

const StyledCloudSelector = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export default CloudSelector;
