import styled, { css } from 'styled-components/macro';
import { color, rem, hslToHsla } from 'theme/lib';
import { ReactComponent as BorderedCheckboxEmpty } from 'assets/svg/bordered-unchecked.svg';
import { ReactComponent as BorderedCheckboxFilled } from 'assets/svg/bordered-checked.svg';
import { ReactComponent as CheckboxEmpty } from './checkbox-empty.svg';
import { ReactComponent as CheckboxFilled } from './checkbox-filled.svg';

export const HiddenCheckbox = styled.input`
  /**
   * Hide checkbox visually but remain accessible to screen readers.
   * Source: https://polished.js.org/docs/#hidevisually
   */
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledLabel = styled.label`
  display: inline-block;
  font-size: ${rem(13)};
  line-height: ${20 / 13};
  padding-left: ${rem(31)};
  cursor: pointer;
  user-select: none;
  ${props => props.labelCSS && props.labelCSS}
`;

export const IconWrapper = styled.div`
  display: inherit;
  width: inherit;
  height: inherit;
`;

export const StyledCheckboxEmpty = styled(CheckboxEmpty)`
  display: block;
`;

export const StyledCheckboxFilled = styled(CheckboxFilled)`
  display: none;
`;

export const StyledBorderedCheckboxEmpty = styled(BorderedCheckboxEmpty)`
  display: block;
`;

export const StyledBorderedCheckboxFilled = styled(BorderedCheckboxFilled)`
  display: none;
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  margin-top: -0.25em;
  margin-right: ${rem(8)};
  margin-left: ${rem(-31)};
  vertical-align: middle;
  color: ${color('brand.primary')};
  cursor: pointer;

  .has-error & {
    color: ${color('brand.error')};
  }

  ${({ size }) =>
    size &&
    css`
      width: ${rem(size)};
      height: ${rem(size)};
    `};

  ${HiddenCheckbox} {
    &:checked + ${IconWrapper} {
      .checkbox-empty {
        display: none;
      }

      .checkbox-filled {
        display: block;
      }
    }

    &:focus + ${IconWrapper} {
      border-radius: 50%;

      ${({ theme, icon }) => {
        let currentColor = color('brand.primary')({ theme });
        if (icon === 'bordered') currentColor = color('white')({ theme });
        const currentFocusColor = hslToHsla(currentColor, 0.15);
        const error = color('brand.error')({ theme });
        const errorFocusColor = hslToHsla(error, 0.15);

        return css`
          box-shadow: 0 0 0 3px ${currentFocusColor};

          .has-error & {
            box-shadow: 0 0 0 3px ${errorFocusColor};
          }
        `;
      }}
    }
  }
`;
