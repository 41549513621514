import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { animationControlsPropTypes } from 'shared/motion/propTypes';
import { contentVariants } from '../motionVariants';
import { Content } from './styled';

function MotionContent({ isDirectionReversed, animate, children }) {
  return (
    <Content
      as={motion.div}
      initial={isDirectionReversed ? 'goBackInitial' : 'goForwardInitial'}
      animate={animate}
      variants={contentVariants}
    >
      {children}
    </Content>
  );
}

MotionContent.propTypes = {
  /** Is navigating back through history. */
  isDirectionReversed: PropTypes.bool,
  /** Framer Motion AnimationControls. */
  animate: animationControlsPropTypes.isRequired,
  children: PropTypes.node.isRequired,
};

MotionContent.defaultProps = {
  isDirectionReversed: false,
};

export default MotionContent;
