import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem, color } from 'theme/lib';
import { STATUS_APPROVED, STATUS_NOT_SUBMITTED } from 'guideProfile/constants';
import Checklist from 'shared/components/Checklist/Checklist';
import { H2 } from 'shared/components/styled/typography';
import { ReactComponent as CheckmarkFilled } from 'assets/svg/primary-checkmark-filled.svg';

function ApplicationStatusChecklist({
  applicationStatus,
  interviewBooked,
  showDisclaimer,
}) {
  const hasSubmittedApplication = applicationStatus !== STATUS_NOT_SUBMITTED;

  return (
    <>
      <StatusHeader>
        {showDisclaimer ? (
          <>
            <StatusTitle>
              <StyledCheckmarkFilled />
              <H2>Applied!</H2>
            </StatusTitle>
            <StatusSubtitle>
              We&apos;ve sent you an email with confirmation we have received
              your application.
            </StatusSubtitle>
          </>
        ) : (
          <StatusTitle>
            <H2>In Review</H2>
          </StatusTitle>
        )}
      </StatusHeader>

      <Checklist>
        <Checklist.Item checked>Created Account</Checklist.Item>
        <Checklist.Item checked={hasSubmittedApplication}>
          Application Filled Out
        </Checklist.Item>
        <Checklist.Item checked={hasSubmittedApplication}>
          Application Submitted for Review (30-day review process)
        </Checklist.Item>
        <Checklist.Item checked={interviewBooked}>
          Interview with Sphere to determine next steps
        </Checklist.Item>
        <Checklist.Item checked={applicationStatus === STATUS_APPROVED}>
          Approved to join Sphere
        </Checklist.Item>
      </Checklist>
    </>
  );
}

export default ApplicationStatusChecklist;

ApplicationStatusChecklist.propTypes = {
  applicationStatus: PropTypes.string.isRequired,
  interviewBooked: PropTypes.bool,
  showDisclaimer: PropTypes.bool,
};

ApplicationStatusChecklist.defaultProps = {
  interviewBooked: false,
  showDisclaimer: false,
};

const StatusHeader = styled.header`
  display: flex;
  flex-direction: column;
`;

const StyledCheckmarkFilled = styled(CheckmarkFilled)`
  margin-right: ${rem(10)};
`;

const StatusTitle = styled.div`
  display: flex;
  align-items: baseline;
`;

const StatusSubtitle = styled.div`
  font-weight: 500;
  margin-top: ${rem(4)};
  margin-bottom: ${rem(40)};
  color: ${color('black')};
`;
