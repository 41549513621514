import React from 'react';
import { Field } from 'formik';
import { parse, isValid } from 'date-fns';
import TextField from 'shared/components/formik/TextField/TextField';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
} from 'shared/components/WizardQuestionForm';

const validationFunction = ({ birthdate }) => {
  const errors = {};
  if (!birthdate) {
    errors.birthdate = 'Required';
  } else if (!isValid(parse(birthdate, 'yyyy-MM-dd', new Date()))) {
    errors.birthdate = 'Please use a format of yyyy-mm-dd';
  } else if (new Date(birthdate) > new Date()) {
    errors.birthdate = 'Please pick a date in the past.';
  }

  return errors;
};

function normalizeDate(v = '') {
  return v.replace(/[^\d-]/g, '').slice(0, 10);
}

export default function AgeQuestion(props) {
  return (
    <WizardQuestionForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValues={{
        birthdate: '',
      }}
      validate={validationFunction}
    >
      {() => {
        return (
          <FormContainer>
            <FormContentHeader>
              <QuestionHeading as="h1">
                Aging is an illuminating process
              </QuestionHeading>

              <QuestionDescription>
                Age won&apos;t inhibit or expedite your application, though it
                is a consideration in our algorithm. Your age will not be
                publicly displayed anywhere.
              </QuestionDescription>
            </FormContentHeader>

            <FormContent>
              <Field
                name="birthdate"
                type="date"
                component={TextField}
                placeholder="yyyy-mm-dd"
                normalize={normalizeDate}
              />
            </FormContent>
          </FormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

AgeQuestion.propTypes = withNavigationPropTypes;
