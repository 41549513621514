import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rem, color } from 'theme/lib';

function ProgressBar({
  className,
  percent,
  preload,
  size,
  fillColor,
  fillDuration,
  trackColor,
}) {
  const [animated, setAnimated] = useState(!preload);
  const [managedPercent, setManagedPercent] = useState(preload || percent);

  useEffect(() => {
    let timeout;

    if (preload) {
      setAnimated(true);
      timeout = setTimeout(() => {
        setManagedPercent(percent);
      }, fillDuration);
    } else {
      setManagedPercent(percent);
    }

    return () => {
      if (preload) {
        clearTimeout(timeout);
      }
    };
  }, [managedPercent, percent, preload, fillDuration]);

  return (
    <ProgressBarContainer
      className={className}
      size={size}
      trackColor={trackColor}
    >
      <ProgressBarFill
        percent={managedPercent}
        size={size}
        fillColor={fillColor}
        fillDuration={fillDuration}
        animated={animated}
      />
    </ProgressBarContainer>
  );
}

ProgressBar.propTypes = {
  percent: PropTypes.number,
  /** allows animation to begin from a predetermined percentage */
  preload: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  /** Controls the bar height */
  size: PropTypes.oneOf(['default', 'small']),
  fillColor: PropTypes.oneOf(['primary', 'warning', 'success', 'link']),
  /** Duration of fill transition, in seconds. */
  fillDuration: PropTypes.number,
  trackColor: PropTypes.string,
};

ProgressBar.defaultProps = {
  percent: 0,
  preload: false,
  size: 'default',
  fillColor: 'primary',
  fillDuration: 1,
  trackColor: '',
};

const applyColorStyles = ({ theme, fillColor }) => {
  const brandThemes = {
    primary: theme.colors.brand.primary,
    warning: theme.colors.brand.warning,
    success: theme.colors.brand.success,
    link: theme.colors.link,
  };
  const currentColor = brandThemes[fillColor] || brandThemes.primary;
  return css`
    background-color: ${currentColor};
  `;
};

const ProgressBarContainer = styled.div`
  background-color: ${({ trackColor }) => trackColor || color('grays.200')};
  width: 100%;
  height: ${rem(8)};
  transition: background-color 0.2s;

  ${({ size }) =>
    size === 'small' &&
    css`
      height: ${rem(6)};
    `}
`;

const ProgressBarFill = styled.div`
  overflow: hidden;
  position: relative;
  height: ${rem(8)};

  ${({ size }) =>
    size === 'small' &&
    css`
      height: ${rem(6)};
    `}

  &::after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: '';
    display: block;
    ${({ percent }) => {
      if (percent === 100) {
        return css`
          transform: translateX(0);
        `;
      }
      return css`
        transform: translateX(${percent - 100.5}%);
      `;
    }}

    transition: ${({ animated, fillDuration }) =>
      animated ? `transform ${fillDuration}s` : 'none'};
    /* Apply color styles according to fillColor props */
    ${applyColorStyles}
  }
`;

export default ProgressBar;
