/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'shared/routes';
import RegisterSuccessModal from 'shared/components/RegisterSuccessModal/RegisterSuccessModal';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import ArrowNavButton from 'shared/components/ArrowNavButton/ArrowNavButton';
import { H1 } from 'shared/components/styled/typography';
import SignupForm from 'auth/components/SignupForm/SignupForm';
import AstronautVisorImage from './astronaut-visor.jpg';
import SidebarImage from './SidebarImage';
import {
  SignUpLayout,
  FormContainer,
  FormWrapper,
  ButtonWrapper,
  SignInMessage,
  SignInLink,
  LayoutSidebar,
} from './styled';

function SignUpScreen({ className }) {
  const history = useHistory();
  const [showSuccess, setShowSuccess] = useState(false);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <SplitLayout className={className}>
      <SplitLayout.Main>
        <SplitLayout.MainContent>
          <SignUpLayout>
            <FormContainer>
              <FormWrapper>
                <p>We’re so excited you’re here!</p>
                <H1>Let’s start by getting to know you.</H1>
                <SignupForm onSuccess={() => setShowSuccess(true)} />
              </FormWrapper>
              <ButtonWrapper>
                <ArrowNavButton onClick={handleGoBack}>Go back</ArrowNavButton>
                <SignInMessage>
                  Already started an application?
                  <SignInLink to={routes.login}>Sign In</SignInLink>
                </SignInMessage>
              </ButtonWrapper>
            </FormContainer>
          </SignUpLayout>
        </SplitLayout.MainContent>
      </SplitLayout.Main>
      <LayoutSidebar fixedContent>
        <SidebarImage src={AstronautVisorImage} />
      </LayoutSidebar>
      <RegisterSuccessModal
        isOpen={showSuccess}
        text="Account Created!"
        onAutoClose={() => setShowSuccess(false)}
      />
    </SplitLayout>
  );
}

SignUpScreen.propTypes = {};
SignUpScreen.defaultProps = {};

export default SignUpScreen;
