import React from 'react';
import styled from 'styled-components/macro';
import CarouselSlider from 'shared/components/Carousel/CarouselSlider';
import CarouselSlide from 'shared/components/Carousel/CarouselSlide';
import CarouselLegend from 'shared/components/Carousel/CarouselLegend';
import { makeSlideData } from 'shared/components/Carousel/helpers';
import imgGoodCleanBackground from './good-clean-background/do-1.jpg';
import imgGoodCleanBackground2x from './good-clean-background/do-1@2x.jpg';
import imgGoodCleanBackground3x from './good-clean-background/do-1@3x.jpg';
import imgGoodNaturalPose from './good-natural-pose/do-1.jpg';
import imgGoodNaturalPose2x from './good-natural-pose/do-1@2x.jpg';
import imgGoodNaturalPose3x from './good-natural-pose/do-1@3x.jpg';
import imgGoodHighQuality from './good-high-quality/do-1.jpg';
import imgGoodHighQuality2x from './good-high-quality/do-1@2x.jpg';
import imgGoodHighQuality3x from './good-high-quality/do-1@3x.jpg';
import imgBadTakenWithPhone from './bad-taken-with-phone/do-1.jpg';
import imgBadTakenWithPhone2x from './bad-taken-with-phone/do-1@2x.jpg';
import imgBadTakenWithPhone3x from './bad-taken-with-phone/do-1@3x.jpg';
import imgBadBusyBackgrounds from './bad-busy-backgrounds/do-1.jpg';
import imgBadBusyBackgrounds2x from './bad-busy-backgrounds/do-1@2x.jpg';
import imgBadBusyBackgrounds3x from './bad-busy-backgrounds/do-1@3x.jpg';
import imgBadWhiteBackgrounds from './bad-white-backgrounds/do-1.jpg';
import imgBadWhiteBackgrounds2x from './bad-white-backgrounds/do-1@2x.jpg';
import imgBadWhiteBackgrounds3x from './bad-white-backgrounds/do-1@3x.jpg';
import imgBadStockPhotos from './bad-stock-photos/do-1.jpg';
import imgBadStockPhotos2x from './bad-stock-photos/do-1@2x.jpg';
import imgBadStockPhotos3x from './bad-stock-photos/do-1@3x.jpg';

const slides = [
  makeSlideData({
    positive: true,
    caption: 'Clean background',
    src: imgGoodCleanBackground,
    srcSet: {
      '1x': imgGoodCleanBackground,
      '2x': imgGoodCleanBackground2x,
      '3x': imgGoodCleanBackground3x,
    },
  }),
  makeSlideData({
    positive: true,
    caption: 'A natural, and authentic pose',
    src: imgGoodNaturalPose,
    srcSet: {
      '1x': imgGoodNaturalPose,
      '2x': imgGoodNaturalPose2x,
      '3x': imgGoodNaturalPose3x,
    },
  }),
  makeSlideData({
    positive: true,
    caption: 'High Quality Image',
    src: imgGoodHighQuality,
    srcSet: {
      '1x': imgGoodHighQuality,
      '2x': imgGoodHighQuality2x,
      '3x': imgGoodHighQuality3x,
    },
  }),
  makeSlideData({
    positive: false,
    caption: 'Photos taken with your phone, selfies included',
    src: imgBadTakenWithPhone,
    srcSet: {
      '1x': imgBadTakenWithPhone,
      '2x': imgBadTakenWithPhone2x,
      '3x': imgBadTakenWithPhone3x,
    },
  }),
  makeSlideData({
    positive: false,
    caption: 'Busy backgrounds',
    src: imgBadBusyBackgrounds,
    srcSet: {
      '1x': imgBadBusyBackgrounds,
      '2x': imgBadBusyBackgrounds2x,
      '3x': imgBadBusyBackgrounds3x,
    },
  }),
  makeSlideData({
    positive: false,
    caption: 'White backgrounds',
    src: imgBadWhiteBackgrounds,
    srcSet: {
      '1x': imgBadWhiteBackgrounds,
      '2x': imgBadWhiteBackgrounds2x,
      '3x': imgBadWhiteBackgrounds3x,
    },
  }),
  makeSlideData({
    positive: false,
    caption: 'Corporate stock style photos',
    src: imgBadStockPhotos,
    srcSet: {
      '1x': imgBadStockPhotos,
      '2x': imgBadStockPhotos2x,
      '3x': imgBadStockPhotos3x,
    },
  }),
];

const makeImgAlt = (positive = true) =>
  positive ? 'Example of a good photo' : 'Example of a bad photo';

function FeaturePhotoCarousel() {
  return (
    <>
      <StyledCarouselLegend />

      <StyledCarouselSlider>
        {slides.map(slide => (
          <CarouselSlide
            key={slide.id}
            src={slide.src}
            srcSet={slide.srcSet}
            alt={makeImgAlt(slide.positive)}
          >
            <CarouselSlide.Footer positive={slide.positive}>
              {slide.caption}
            </CarouselSlide.Footer>
          </CarouselSlide>
        ))}
      </StyledCarouselSlider>
    </>
  );
}

FeaturePhotoCarousel.propTypes = {};

FeaturePhotoCarousel.defaultProps = {};

const StyledCarouselLegend = styled(CarouselLegend)`
  align-self: flex-start;
`;

const StyledCarouselSlider = styled(CarouselSlider)`
  margin-top: auto;
  margin-bottom: auto;
`;

export default FeaturePhotoCarousel;
