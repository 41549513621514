import React, { useState } from 'react';
import { ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { color, rem } from 'theme/lib';
import media from 'theme/media';
import { FieldError } from 'shared/components/formik/styled';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
} from 'shared/components/WizardQuestionForm';
import ReferenceFields from '../ReferenceFields/ReferenceFields';
import { REFERENCES_COUNT_MAX_VALUE } from '../constants';

const referenceInitialValue = {
  first_name: '',
  last_initial: '',
  num_sessions: '',
  phone: '',
  email: '',
};

const initialValues = {
  references: [
    referenceInitialValue,
    referenceInitialValue,
    referenceInitialValue,
  ],
};

const referenceSchema = Yup.object().shape({
  first_name: Yup.string()
    .trim()
    .required('Required'),
  last_initial: Yup.string()
    .trim()
    .required('Required'),
  num_sessions: Yup.number()
    .min(0, 'Value must be greater than 0')
    .max(
      REFERENCES_COUNT_MAX_VALUE,
      `No more than ${REFERENCES_COUNT_MAX_VALUE}`
    )
    .required('Required'),
  phone: Yup.string()
    .required('Required')
    .min(10, 'Invalid phone number'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
});

const validationSchema = Yup.object().shape({
  references: Yup.array().of(referenceSchema),
});

export default function References(props) {
  const [focusedIndex, setFocusedIndex] = useState(0);

  return (
    <WizardQuestionForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValues={initialValues}
      validationSchema={validationSchema}
      continueInFooter
    >
      {({ errors }) => {
        return (
          <FormContainer>
            <FormContentHeader>
              <QuestionHeading as="h1">
                Distinct and powerful references.
              </QuestionHeading>

              <QuestionDescription width={640}>
                We need to feel confident about how others have benefited from
                your coaching. Provide three client references with their first
                name and the first letter of their last name, email and phone
                number.
              </QuestionDescription>
            </FormContentHeader>

            <FormContent width={600}>
              <ReferenceFields
                index={0}
                name="references"
                focusedIndex={focusedIndex}
                onFocus={setFocusedIndex}
              />
              <ReferenceFields
                index={1}
                name="references"
                focusedIndex={focusedIndex}
                onFocus={setFocusedIndex}
              />
              <ReferenceFields
                index={2}
                name="references"
                focusedIndex={focusedIndex}
                onFocus={setFocusedIndex}
              />
              <ContentFooter>
                {typeof errors.references === 'string' && (
                  <ErrorMessage component={FieldError} name="references" />
                )}

                <Instructions>
                  We encourage you to let them know we&apos;ll be giving them a
                  shout. We keep this information 100% private & confidential.
                </Instructions>
              </ContentFooter>
            </FormContent>
          </FormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

References.propTypes = withNavigationPropTypes;

const ContentFooter = styled.div`
  ${media.up('medium')} {
    margin-left: ${rem(40)};
    max-width: ${rem(500)};
  }
`;

const Instructions = styled.p`
  font-weight: 500;
  color: ${color('brand.primary')};
`;
