import { useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useCurrentUser } from 'auth/hooks/queries';

const ADD_PAYMENT = gql`
  mutation addPaymentMethod($userId: ID!, $token: String!) {
    addPaymentMethod(userId: $userId, token: $token) {
      id
      last4
      exp_month
      exp_year
      brand
    }
  }
`;

const useAddPaymentMethod = token => {
  const currentUser = useCurrentUser();
  const userId = get(currentUser, 'data.id');
  const refetchCurrentUser = currentUser.refetch;

  const [_addPaymentMethod, { data, loading, error }] = useMutation(
    ADD_PAYMENT,
    {
      awaitRefetchQueries: true,
      onCompleted: refetchCurrentUser,
    }
  );

  const addPaymentMethod = useCallback(async () => {
    try {
      await _addPaymentMethod({
        variables: {
          userId,
          token,
        },
      });
    } catch (err) {
      //
    }
  }, [_addPaymentMethod, userId, token]);

  return {
    addPaymentMethod,
    data,
    loading,
    error,
  };
};

useAddPaymentMethod.propTypes = {
  token: PropTypes.string.isRequired,
};

export default useAddPaymentMethod;
