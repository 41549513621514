import React from 'react';
import styled from 'styled-components/macro';
import { rem } from 'theme/lib';
import Guidelines from 'shared/components/Guidelines/Guidelines';

function CertificationsGuidelines() {
  return (
    <StyledGuidelines>
      <Guidelines.Header>
        <Guidelines.Title>Certification Guidelines</Guidelines.Title>
        <Guidelines.Subtitle>
          Use the following as a guide for how to add your statements into your
          profile.
        </Guidelines.Subtitle>
      </Guidelines.Header>
      <StyledGuidelinesContent>
        <Guidelines.Section>
          <Guidelines.SectionHeading />
          <Guidelines.List>
            <Guidelines.ListItem>
              Upload a photo or copy of your certification, diploma or degree.
            </Guidelines.ListItem>
            <Guidelines.ListItem>
              Upload a copy of your transcripts.
            </Guidelines.ListItem>
          </Guidelines.List>
        </Guidelines.Section>
      </StyledGuidelinesContent>
    </StyledGuidelines>
  );
}

const StyledGuidelines = styled(Guidelines)`
  margin-left: ${rem(10)};
  margin-right: ${rem(10)};
  background-color: transparent;
  height: 100%;
  max-width: 100%;
`;

const StyledGuidelinesContent = styled(Guidelines.Content)`
  min-height: ${rem(300)};
`;

export default CertificationsGuidelines;
