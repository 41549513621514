import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { animationControlsPropTypes } from 'shared/motion/propTypes';
import { H3 } from 'shared/components/styled/typography';
import {
  astronautContentVariants,
  astronautImageVariants,
} from '../motionVariants';
import {
  MainContentLayout,
  ContentContainer,
  ImageContainer,
  IntroText,
  AstronautImageSmall,
  AstronautImageLarge,
} from './styled';
import imgMobileAstronaut1x from './astronaut-mobile.jpg';
import imgMobileAstronaut2x from './astronaut-mobile@2x.jpg';
import imgMobileAstronaut3x from './astronaut-mobile@3x.jpg';
import imgAstronaut1x from './astronaut.jpg';
import imgAstronaut2x from './astronaut@2x.jpg';
import imgAstronaut3x from './astronaut@3x.jpg';

function AstronautScene({ contentAnimation, imageAnimation, motionInitial }) {
  const isSmallScreen = useMediaQuery({ query: '(max-device-width: 639px)' });

  return (
    <MainContentLayout>
      <ContentContainer
        as={motion.div}
        initial={motionInitial}
        animate={contentAnimation}
        variants={astronautContentVariants}
      >
        <H3 as="h1">Greetings stellar human</H3>

        <p>
          <IntroText>The work you do is powerful stuff.</IntroText> We&apos;re
          buzzing that you&apos;re interested in becoming a coach to spend more
          time causing transformational experiences and less time on business
          development.
        </p>
      </ContentContainer>

      <ImageContainer
        as={motion.div}
        initial={motionInitial}
        animate={imageAnimation}
        variants={astronautImageVariants}
      >
        {isSmallScreen ? (
          <AstronautImageSmall
            src={imgMobileAstronaut1x}
            srcSet={`${imgMobileAstronaut3x} 3x, ${imgMobileAstronaut2x} 2x, ${imgMobileAstronaut1x} 1x`}
            alt="Astronaut"
          />
        ) : (
          <AstronautImageLarge
            src={imgAstronaut1x}
            srcSet={`${imgAstronaut3x} 3x, ${imgAstronaut2x} 2x, ${imgAstronaut1x} 1x`}
            alt="Astronaut"
          />
        )}
      </ImageContainer>
    </MainContentLayout>
  );
}

AstronautScene.propTypes = {
  /** The initial framer motion variant changes based on when this section is loaded. */
  motionInitial: PropTypes.string.isRequired,
  /** Framer Motion AnimationControls. */
  contentAnimation: animationControlsPropTypes.isRequired,
  /** Framer Motion AnimationControls. */
  imageAnimation: animationControlsPropTypes.isRequired,
};

AstronautScene.defaultProps = {};

export default AstronautScene;
