import React from 'react';
import styled from 'styled-components/macro';
import { rem } from 'theme/lib';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'theme/media';
import Guidelines from 'shared/components/Guidelines/Guidelines';

function CertificationsSidebarContent() {
  const canShowSidebar = useMediaQuery({
    query: `(min-width: ${breakpoints.large}px)`,
  });

  if (!canShowSidebar) {
    return null;
  }

  return (
    <StyledGuidelines>
      <Guidelines.Header>
        <Guidelines.Title>Certification Guidelines</Guidelines.Title>
        <Guidelines.Subtitle>
          Use the following as a guide for how to add your statements into your
          profile.
        </Guidelines.Subtitle>
      </Guidelines.Header>
      <Guidelines.Content>
        <Guidelines.Section>
          <Guidelines.SectionHeading />
          <Guidelines.List>
            <Guidelines.ListItem>
              Upload a photo or copy of your certification, diploma or degree.
            </Guidelines.ListItem>
            <Guidelines.ListItem>
              Upload a copy of your transcripts.
            </Guidelines.ListItem>
          </Guidelines.List>
        </Guidelines.Section>
      </Guidelines.Content>
    </StyledGuidelines>
  );
}

CertificationsSidebarContent.propTypes = {};

CertificationsSidebarContent.defaultProps = {};

const StyledGuidelines = styled(Guidelines)`
  margin-top: ${rem(60)};
  margin-left: ${rem(30)};
  margin-right: ${rem(30)};
  height: 100%;
`;

export default CertificationsSidebarContent;
