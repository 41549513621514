import { useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import firebase from 'firebaseConfig';
import gql from 'graphql-tag';
import get from 'lodash/get';
import routes from 'shared/routes';

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      jwt
      verified
      type
    }
  }
`;

export const SIGNUP = gql`
  mutation SignUpLite(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
  ) {
    signupLite(
      first_name: $firstName
      last_name: $lastName
      email: $email
      password: $password
      type: "Guides"
    ) {
      email
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      name
      code_of_conduct
      country
      paymentMethods {
        id
        last4
        brand
      }
    }
  }
`;

export const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($email: String!) {
    requestResetPassword(email: $email)
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword(
    $email: String!
    $password: String!
    $token: String!
  ) {
    updateForgottenPassword(email: $email, password: $password, token: $token)
  }
`;

export const VERIFY_USER = gql`
  mutation verifyUser($code: String!) {
    verifyUser(code: $code)
  }
`;

export const RESEND_VERIFICATION = gql`
  mutation resendVerificationCode {
    resendVerificationCode
  }
`;

export function useLogin(onSuccessRouteTo) {
  const history = useHistory();
  const [firebaseError, setFireBaseError] = useState(null);
  // const [seekerError, setSeekerError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mutate, { error: mutationError }] = useMutation(LOGIN, {
    onCompleted: ({ login }) => {
      if (login.type === 'Seekers') {
        const error = new ApolloError(`Guide Account Error`);
        error.extraInfo = { seekerError: true };
        throw error;
      }
    },
  });
  const { refetch: refetchCurrentUser } = useQuery(GET_CURRENT_USER, {
    // Do not initiate the query on mount. Only here for re-fetching
    skip: true,
    // ignore error on new signups
    errorPolicy: onSuccessRouteTo === routes.verify ? 'ignore' : 'none', // "none" is the default policy
  });

  const login = useCallback(
    async (email, password) => {
      // Track loading state separate from the mutation loading state to encapsulate
      // the firebase loading as well.
      setLoading(true);

      let res;
      try {
        res = await mutate({
          variables: {
            email,
            password,
          },
        });
      } catch (err) {
        // The error is contained in the mutationResult.
        // Do not continue with firebase login.
        setLoading(false);
        return;
      }
      try {
        await firebase
          .auth()
          .signInWithCustomToken(get(res, 'data.login.jwt'))
          .then(async () => {
            const verified = get(res, 'data.login.verified');

            if (verified) {
              // Refetch the current user now there has been a new login.
              // This is to update any components that are observing the current user query.
              // Must happen after firebase login (and not as a refetch query to the mutation),
              // so that the new auth header is sent in the request.
              await refetchCurrentUser().then(() => {
                // this was previously handled synchronously, but now that login
                // needs to be aware of the value of verified this makes more sense...
                history.replace(onSuccessRouteTo);
              });
            } else {
              history.replace(routes.verify, { email });
            }
          });
        setFireBaseError();
      } catch (err) {
        // Any error occurred logging in to firebase. Firebase is down?
        console.error('Failed to login with firebase: ', err);
        setFireBaseError(err);
      }

      setLoading(false);
    },
    [history, mutate, onSuccessRouteTo, refetchCurrentUser]
  );

  // Combine the errors
  const result = useMemo(
    () => ({
      loading,
      error: mutationError || firebaseError,
    }),
    [firebaseError, mutationError, loading]
  );

  return [login, result];
}

export function useSignUp() {
  const [_signup, signupResult] = useMutation(SIGNUP);
  const [_login, loginResult] = useLogin(routes.verify);

  const signup = useCallback(
    async (firstName, lastName, email, password) => {
      try {
        await _signup({
          variables: {
            firstName,
            lastName,
            email,
            password,
          },
        }).then(({ data: { signupLite } }) => {
          if (signupLite) {
            _login(email, password);
          } else {
            throw new Error('Something went wrong, please try again.');
          }
        });
      } catch (err) {
        // do nothing
      }
    },
    [_signup, _login]
  );

  const result = useMemo(
    () => ({
      called: signupResult.called || loginResult.called,
      loading: signupResult.loading || loginResult.loading,
      error: signupResult.error || loginResult.error,
    }),
    [signupResult, loginResult]
  );

  return [signup, result];
}

export function useCurrentUser() {
  const query = useQuery(GET_CURRENT_USER);
  return {
    ...query,
    data: get(query, 'data.currentUser'),
  };
}

export function useRequestPasswordReset() {
  const [mutate, result] = useMutation(REQUEST_PASSWORD_RESET);
  const requestResetPassword = useCallback(
    async (email, onSuccess) => {
      try {
        await mutate({
          variables: {
            email,
          },
        });

        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        // Do nothing
      }
    },
    [mutate]
  );

  return [requestResetPassword, result];
}

export function useUpdatePassword() {
  const [mutate, result] = useMutation(UPDATE_PASSWORD);
  const updatePassword = useCallback(
    async (email, password, token, onSuccess) => {
      try {
        await mutate({
          variables: {
            email,
            password,
            token,
          },
        });

        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        // Do nothing
      }
    },
    [mutate]
  );

  return [updatePassword, result];
}

export function useVerifyUser(redirectPath) {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [mutate, _result] = useMutation(VERIFY_USER, {
    refetchQueries: ['GetCurrentUser'],
    awaitRefetchQueries: true,
    onCompleted: ({ verifyUser }) => {
      if (verifyUser) {
        history.replace(redirectPath);
      } else {
        setError(new Error('Something went wrong, please try again.'));
      }
    },
  });

  const verifyUser = useCallback(
    async code => {
      if (error) setError(null);

      try {
        await mutate({
          variables: {
            code,
          },
        });
      } catch (err) {
        // Do nothing
      }
    },
    [mutate, error, setError]
  );

  const result = useMemo(
    () => ({
      loading: _result.loading,
      error: _result.error || error,
    }),
    [_result, error]
  );

  return [verifyUser, result];
}

export function useResendVerification() {
  const [resendVerification, result] = useMutation(RESEND_VERIFICATION);

  return [resendVerification, result];
}
