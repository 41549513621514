import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'theme/media';
import { H3 } from 'shared/components/styled/typography';
import { color, rem } from 'theme/lib';
import Button from 'shared/components/Button/Button';

const ImportantRead = ({ link }) => (
  <SecondaryContent>
    <H3>Important Read</H3>
    <HowTo>HOW TO BE A GUIDE</HowTo>
    <p>
      Visit the Sphere Guide Support Centre. All you need to know about being a
      Sphere Guide.
    </p>
    <Button onClick={() => window.open(link)} shape="pill">
      Visit Page
    </Button>
  </SecondaryContent>
);

export const SecondaryContent = styled.div`
  background: ${color('offWhite')};
  padding: ${rem(24)};
  border-radius: ${rem(10)};
  ${media.up('large')} {
    border-radius: none;
    padding: ${rem(24)} ${rem(24)} ${rem(75)} ${rem(24)};
    border-top-left-radius: ${rem(10)};
    border-bottom-left-radius: ${rem(10)};
  }
`;

export const HowTo = styled.span`
  color: ${color('base.sphereBlue')};
  font-size: ${rem(13)};
`;

ImportantRead.propTypes = {
  link: PropTypes.string.isRequired,
};

export default ImportantRead;
