import React, { useState, useEffect, useCallback } from 'react';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import routes from 'shared/routes';
import HeaderLayout from 'shared/layouts/HeaderLayout/HeaderLayout';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import WaitForExternalData from 'shared/components/WaitForExternalData/WaitForExternalData';
import { useCurrentUser } from 'auth/hooks/queries';
import { useGuideProfile } from 'guideProfile/hooks/queries';
import {
  STATUS_NOT_SUBMITTED,
  STATUS_UNDER_REVIEW,
} from 'guideProfile/constants';
import ApplicationDisclaimer from 'app-status/components/ApplicationDisclaimer/ApplicationDisclaimer';
import ApplicationStatusChecklist from 'app-status/components/ApplicationStatusChecklist/ApplicationStatusChecklist';
import SignOut from 'shared/components/ProgressHeader/SignOut';
import FindOutForm from '../../app-status/components/FindOutForm/FindOutForm';
import {
  ApplicationStatusLayout,
  ChecklistContainer,
  CloudSelectorContainer,
} from './styled';

function disclaimerKey(userId) {
  // Prefix with a user id to handle multiple users on the same device
  return `${userId}-hasClickedUnderstand`;
}

function reportSourceKey(userId) {
  // Prefix with a user id to handle multiple users on the same device
  return `${userId}-hasReportedSource`;
}

function ApplicationStatusScreen() {
  const history = useHistory();
  const guideProfile = useGuideProfile();
  const currentUserId = get(useCurrentUser(), 'data.id');
  const applicationStatus = get(
    guideProfile,
    'data.application_status',
    STATUS_UNDER_REVIEW
  );
  const interviewBooked = get(guideProfile, 'data.interview_booked', false);
  const isApplicationApproved = get(guideProfile, 'data.approved', false);

  // Store whether or not the disclaimer has been clicked and source reported
  // in local storage so that they survive page reloads.
  const initialShowDisclaimer =
    localStorage.getItem(disclaimerKey(currentUserId)) !== 'false';
  const initialShowReportSource =
    localStorage.getItem(reportSourceKey(currentUserId)) !== 'true';
  const [showDisclaimer, setShowDisclaimer] = useState(initialShowDisclaimer);
  const [showReportSource, setShowReportSource] = useState(
    initialShowReportSource
  );

  // Update the state if the local storage state changes (userId changes)
  useEffect(() => {
    setShowDisclaimer(initialShowDisclaimer);
    setShowReportSource(initialShowReportSource);
  }, [initialShowDisclaimer, initialShowReportSource]);

  const handleClickUnderstand = useCallback(() => {
    // Update local storage.
    localStorage.setItem(disclaimerKey(currentUserId), false);

    // Update the state so that the changes take effect
    setShowDisclaimer(false);
  }, [currentUserId]);

  const handleSourceReported = useCallback(() => {
    // Update local storage.
    localStorage.setItem(reportSourceKey(currentUserId), false);

    // Update the state so that the changes take effect
    setShowReportSource(false);
  }, [currentUserId]);

  // If the application has not been submitted yet, redirect the user to the summary page
  if (applicationStatus === STATUS_NOT_SUBMITTED) {
    history.replace(routes.summaryBase);
  }

  // If the applicant is already approved, send them to the codeOfConduct page
  if (isApplicationApproved) {
    history.replace(routes.applicationApproved.codeOfConduct(true));
  }

  return (
    <HeaderLayout title="Application Status" renderActions={() => <SignOut />}>
      <SplitLayout showLogo={false}>
        <WaitForExternalData external={guideProfile}>
          <SplitLayout.Main>
            <SplitLayout.MainContent>
              <ApplicationStatusLayout>
                <ChecklistContainer>
                  <ApplicationStatusChecklist
                    applicationStatus={applicationStatus}
                    interviewBooked={interviewBooked}
                    showDisclaimer={showDisclaimer}
                  />
                  {showDisclaimer && (
                    <ApplicationDisclaimer
                      onClickDisclaimer={handleClickUnderstand}
                    />
                  )}
                </ChecklistContainer>
                <CloudSelectorContainer showDisclaimer={showDisclaimer}>
                  {showReportSource && (
                    <FindOutForm onSuccess={handleSourceReported} />
                  )}
                </CloudSelectorContainer>
              </ApplicationStatusLayout>
            </SplitLayout.MainContent>
          </SplitLayout.Main>
        </WaitForExternalData>
      </SplitLayout>
    </HeaderLayout>
  );
}

export default ApplicationStatusScreen;
