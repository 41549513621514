import React, { useReducer, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { useUpdateProfile } from './hooks/queries';

const GuideProfileContext = React.createContext({
  currentScreenValues: {},
  isScreenValid: false,
  setCurrentScreenValues: () => {},
  updateProfile: () => {},
  queryHandler: { loading: false },
});

export default GuideProfileContext;

const DEBOUNCE_WAIT_TIME = 500;

const UPDATE_STATE_ACTION_TYPE = 'update';
function currentScreenReducer(state, action) {
  switch (action.type) {
    case UPDATE_STATE_ACTION_TYPE:
      return {
        values: action.values,
        isScreenValid: action.isValid,
      };
    default:
      return state;
  }
}

export function GuideProfileContextProvider({ children }) {
  const [state, dispatch] = useReducer(currentScreenReducer, {
    values: {},
    isScreenValid: false,
  });
  const setCurrentScreenValues = (values, isValid) => {
    dispatch({
      type: UPDATE_STATE_ACTION_TYPE,
      values,
      isValid,
    });
  };

  const debouncedSetCurrentScreenValues = useRef(
    debounce(setCurrentScreenValues, DEBOUNCE_WAIT_TIME)
  ).current;

  // kill debouncer on component destroy
  useEffect(() => {
    return () => {
      if (debouncedSetCurrentScreenValues) {
        debouncedSetCurrentScreenValues.cancel();
      }
    };
  }, [debouncedSetCurrentScreenValues]);

  const [updateProfile, queryHandler] = useUpdateProfile();

  return (
    <GuideProfileContext.Provider
      value={{
        setCurrentScreenValues: debouncedSetCurrentScreenValues,
        currentScreenValues: state.values,
        isScreenValid: state.isScreenValid,
        updateProfile,
        queryHandler,
      }}
    >
      {children}
    </GuideProfileContext.Provider>
  );
}

GuideProfileContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
