import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { PROFILE_PHOTO_SCREENS } from 'navigationConfig';
import routes from 'shared/routes';
import HeaderLayout from 'shared/layouts/HeaderLayout/HeaderLayout';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import SaveAndExitLink from 'shared/components/ProgressHeader/SaveAndExitLink';
import wizardNavigationSidebarVariants from 'shared/motion/wizardNavigationSidebarVariants';
import makeWizardNavigator, { layoutPropTypes } from 'shared/wizardNavigation';
import makeStepTitle from 'shared/wizardNavigation/helpers';
import { GuideProfileContextProvider } from 'guideProfile/GuideProfileContext';

function LayoutComponent({
  content,
  sidebar,
  sidebarControls,
  isDirectionReversed,
  globalStep,
  globalTotalSteps,
  parentStep,
}) {
  return (
    <GuideProfileContextProvider>
      <HeaderLayout
        title={makeStepTitle(parentStep, 'Profile Photos')}
        progressPercent={((globalStep - 1) / globalTotalSteps) * 100}
        enableProgressBar
        renderActions={() => <SaveAndExitLink />}
      >
        <SplitLayout showLogo={false}>
          <SplitLayout.Main>
            <SplitLayout.MainContent>{content}</SplitLayout.MainContent>
          </SplitLayout.Main>

          <StyledSidebar width={[null, 133, 417, 515]} bg>
            <SidebarContent
              as={motion.div}
              key={`step-${globalStep}`}
              initial={
                isDirectionReversed ? 'goBackInitial' : 'goForwardInitial'
              }
              animate={sidebarControls}
              exit={isDirectionReversed ? 'goBackExit' : 'goForwardExit'}
              variants={wizardNavigationSidebarVariants}
            >
              {sidebar}
            </SidebarContent>
          </StyledSidebar>
        </SplitLayout>
      </HeaderLayout>
    </GuideProfileContextProvider>
  );
}

LayoutComponent.propTypes = layoutPropTypes;

const StyledSidebar = styled(SplitLayout.Sidebar)`
  overflow: hidden;
`;

const SidebarContent = styled.div`
  height: 100%;
`;

export default makeWizardNavigator({
  entryRoute: routes.summaryBase,
  exitRoute: routes.summaryBase,
  screens: PROFILE_PHOTO_SCREENS,
  Layout: LayoutComponent,
});
