/**
 * Wistia videos JSON is loaded globally in public/index.html
 */
import get from 'lodash/get';

// TODO: This video is temporary until the correct Devon video ID is given to us.
// TODO: Add this snippet to public/index.html to import Wistia assets
// <script src="https://fast.wistia.com/embed/medias/ekxfuhjt6a.jsonp"></script>

const WISTIA_VIDEO_ID = 'ekxfuhjt6a';

export default function devonVideoAssets() {
  const wistiaAssets = get(
    window[`wistiajsonp-/embed/medias/${WISTIA_VIDEO_ID}.jsonp`],
    'media.assets',
    []
  );

  return {
    default: wistiaAssets.find(
      a => a.type === 'iphone_video' && a.slug === 'mp4_h264_368k'
    ),
    large: wistiaAssets.find(
      a => a.type === 'hd_mp4_video' && a.slug === 'mp4_h264_896k'
    ),
  };
}
