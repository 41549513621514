import React, { useState, useCallback, useEffect } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useCurrentUser } from 'auth/hooks/queries';

function lastAcceptedKey(userId) {
  // Prefix with a user id to handle multiple users on the same device
  return `${userId}-lastAcceptedRule`;
}

function hasSignedKey(userId) {
  // Prefix with a user id to handle multiple users on the same device
  return `${userId}-hasSignedCodeOfConduct`;
}

const CodeOfConductContext = React.createContext({
  lastAcceptedRule: 0,
  setLastAcceptedRule: () => {},
  hasSigned: false,
  signAgreement: () => {},
  canContinue: false,
  setCanContinue: () => {},
});

export default CodeOfConductContext;

export function CodeOfConductContextProvider({ children }) {
  const currentUser = useCurrentUser();
  const currentUserId = get(currentUser, 'data.id');

  // If the user has accepted the terms already, everything is in a read only state.
  const hasAcceptedCodeOfConduct = get(
    currentUser,
    'data.code_of_conduct',
    false
  );

  const storedLastAcceptedRule = Number(
    localStorage.getItem(lastAcceptedKey(currentUserId))
  );
  const [lastAcceptedRule, setLastAcceptedRule] = useState(
    storedLastAcceptedRule
  );

  const storedHasSigned =
    localStorage.getItem(hasSignedKey(currentUserId)) === 'true';
  const [hasSigned, setHasSigned] = useState(storedHasSigned);
  const [canContinue, setCanContinue] = useState(false);

  // If the userId changes, the localStorage key changes and we need to update the state
  useEffect(() => {
    setHasSigned(storedHasSigned);
    setLastAcceptedRule(storedLastAcceptedRule);
  }, [storedLastAcceptedRule, storedHasSigned]);

  const onSetLastAccepted = useCallback(
    lastAccepted => {
      // Update the local storage to survive page reloads
      localStorage.setItem(lastAcceptedKey(currentUserId), lastAccepted);

      setLastAcceptedRule(lastAccepted);
    },
    [currentUserId]
  );

  const onSignAgreement = useCallback(() => {
    // Update the local storage to survive page reloads
    localStorage.setItem(hasSignedKey(currentUserId), true);

    setHasSigned(true);
  }, [currentUserId]);

  return (
    <CodeOfConductContext.Provider
      value={{
        lastAcceptedRule: hasAcceptedCodeOfConduct
          ? Infinity
          : lastAcceptedRule,
        setLastAcceptedRule: onSetLastAccepted,
        hasSigned: hasAcceptedCodeOfConduct || hasSigned,
        signAgreement: onSignAgreement,
        canContinue,
        setCanContinue,
      }}
    >
      {children}
    </CodeOfConductContext.Provider>
  );
}

CodeOfConductContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
