import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { rem } from 'theme/lib';
import { useCurrentUser } from 'auth/hooks/queries';
import media from 'theme/media';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import Button from 'shared/components/Button/Button';
import WaitForExternalData from 'shared/components/WaitForExternalData/WaitForExternalData';
import TextField from 'shared/components/formik/TextField/TextField';
import Footer from '../Footer/Footer';
import CodeOfConductContext from '../../CodeOfConductContext';

const ElectronicSignature = ({ goBack, goNext }) => {
  const { signAgreement, canContinue, hasSigned, setCanContinue } = useContext(
    CodeOfConductContext
  );
  const currentUser = useCurrentUser();
  const name = get(currentUser, 'data.name');

  const initialValues = useMemo(() => {
    // Only show the name after they have accepted the terms previously
    return {
      name: hasSigned ? name : '',
    };
  }, [name, hasSigned]);

  const handleSignAgreement = useCallback(() => {
    signAgreement();
    goNext();
  }, [signAgreement, goNext]);

  const validate = useCallback(
    v => {
      if (v.name.toUpperCase() !== name.toUpperCase()) {
        return { name: 'That name does not match the one we have on record' };
      }

      return {};
    },
    [name]
  );

  return (
    <SplitLayout showLogo={false}>
      <SplitLayout.Main>
        <SplitLayout.MainContent>
          <WaitForExternalData external={currentUser}>
            <Formik
              initialValues={initialValues}
              validate={validate}
              onSubmit={signAgreement} // Needed for the 'enter' key to submit the form
              validateOnMount
            >
              {({ isValid }) => {
                setCanContinue(isValid);
                return (
                  <Form>
                    <Content style={{ marginTop: 50 }}>
                      We take Guidance seriously. In order to take care of our
                      community, all Sphere Guides are required to stay in
                      compliance with our Code of Conduct.
                      <br />
                      <br />
                      As we aim to democratize coaching and curate an ecosystem
                      of exceptional Guides, any breaches of the Code of Conduct
                      will result in immediate review and the risk of removal.
                      <br />
                      <br />
                      By adding my name below I am whole-heartedly acknowledging
                      that I have read the above in full and agree to all
                      details outlined. I&apos;m ready to join Sphere&apos;s
                      orbit.
                    </Content>
                    <FieldWrapper>
                      <Field
                        name="name"
                        placeholder="Electronic Signature"
                        component={TextField}
                        autoFocus
                        disabled={hasSigned}
                      />
                    </FieldWrapper>
                  </Form>
                );
              }}
            </Formik>

            <Footer goBack={goBack}>
              <Button
                shape="pill"
                onClick={handleSignAgreement}
                disabled={!canContinue}
              >
                Continue
              </Button>
            </Footer>
          </WaitForExternalData>
        </SplitLayout.MainContent>
      </SplitLayout.Main>
    </SplitLayout>
  );
};

const Content = styled.p`
  font-size: ${rem(13)};
  margin-bottom: ${rem(25)};

  ${media.up('large')} {
    margin: ${rem(100)} 0 ${rem(30)} 0;
  }
`;

const FieldWrapper = styled.div`
  max-width: ${rem(470)};
`;

ElectronicSignature.propTypes = {
  goBack: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
};

ElectronicSignature.defaultProps = {};

export default ElectronicSignature;
