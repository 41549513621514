/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import CancelApplicationForm from './CancelApplicationForm';
import imgSidebar1x from './search.jpg';
import imgSidebar2x from './search@2x.jpg';
import imgSidebar3x from './search@3x.jpg';
import {
  Layout,
  FormContainer,
  ImageContainer,
  StyledImg,
} from './CancelApplicationScreen.styled';

function CancelApplicationScreen({ className }) {
  return (
    <SplitLayout className={className}>
      <SplitLayout.Main>
        <Layout>
          <FormContainer>
            <CancelApplicationForm />
          </FormContainer>
          <ImageContainer>
            <StyledImg
              className={className}
              src={imgSidebar1x}
              srcSet={`${imgSidebar1x} 1x, ${imgSidebar2x} 2x, ${imgSidebar3x} 3x`}
              alt="Man looking off in the distance"
            />
          </ImageContainer>
        </Layout>
      </SplitLayout.Main>
    </SplitLayout>
  );
}

CancelApplicationScreen.propTypes = {};
CancelApplicationScreen.defaultProps = {};

export default CancelApplicationScreen;
