import React from 'react';
import styled from 'styled-components';
import { rem } from 'theme/lib';
import logo1x from './logo.png';
import logo2x from './logo@2x.png';
import logo3x from './logo@3x.png';

function Logo({ className }) {
  return (
    <img
      className={className}
      src={logo1x}
      srcSet={`${logo1x} 1x, ${logo2x} 2x, ${logo3x} 3x`}
      alt="Sphere"
    />
  );
}

const StyledLogo = styled(Logo)`
  display: block;
  width: ${rem(124)};
  height: ${rem(39)};
`;

export default StyledLogo;
