/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

export const formikFormPropTypes = PropTypes.shape({
  dirty: PropTypes.bool,
  errors: PropTypes.object,
  getFieldMeta: PropTypes.func,
  getFieldProps: PropTypes.func,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleReset: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialErrors: PropTypes.object,
  initialStatus: PropTypes.object,
  initialTouched: PropTypes.object,
  initialValues: PropTypes.object,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  isValidating: PropTypes.bool,
  registerField: PropTypes.func,
  resetForm: PropTypes.func,
  setErrors: PropTypes.func,
  setFieldError: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFormikState: PropTypes.func,
  setStatus: PropTypes.func,
  setSubmitting: PropTypes.func,
  setTouched: PropTypes.func,
  setValues: PropTypes.func,
  status: PropTypes.object,
  submitCount: PropTypes.number,
  submitForm: PropTypes.func,
  touched: PropTypes.object,
  unregisterField: PropTypes.func,
  validateField: PropTypes.func,
  validateForm: PropTypes.func,
  validateOnBlur: PropTypes.bool,
  validateOnChange: PropTypes.bool,
  validateOnMount: PropTypes.bool,
  values: PropTypes.object,
});

export const formikFieldPropTypes = PropTypes.shape({
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.any,
});
