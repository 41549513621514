import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { color, rem, zIndex } from 'theme/lib';
import media from 'theme/media';
import ProgressHeader from 'shared/components/ProgressHeader/ProgressHeader';

function HeaderLayout({
  title,
  progressPercent,
  progressPreload,
  enableProgressBar,
  renderActions,
  children,
}) {
  return (
    <Layout className="header-layout">
      <StyledProgressHeader
        title={title}
        progressPercent={progressPercent}
        progressPreload={progressPreload}
        enableProgressBar={enableProgressBar}
        renderActions={renderActions}
      />
      {children}
    </Layout>
  );
}

HeaderLayout.propTypes = {
  children: PropTypes.node,
  progressPercent: PropTypes.number,
  progressPreload: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  title: PropTypes.string,
  /** Renders HeaderActions components */
  renderActions: PropTypes.func,
  /** Renders ProgressBar */
  enableProgressBar: PropTypes.bool,
};

HeaderLayout.defaultProps = {
  children: null,
  progressPercent: 0,
  title: '',
  progressPreload: false,
  enableProgressBar: false,
  renderActions: () => {},
};

const headerHeightDefault = 110;
const headerHeightMedium = 100;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${rem(headerHeightDefault)};
  width: 100%;
  min-height: 100vh;

  ${media.up('medium')} {
    padding-top: ${rem(headerHeightMedium)};
  }
`;

const StyledProgressHeader = styled(ProgressHeader)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${rem(headerHeightDefault)};
  width: 100%;
  background: ${color('white')};
  z-index: ${zIndex('fixedHeader')};
  border-bottom: 2px solid ${color('grays.200')};

  ${({ enableProgressBar }) =>
    enableProgressBar &&
    css`
      border-bottom: none;
    `}

  ${media.up('medium')} {
    height: ${rem(headerHeightMedium)};
  }
`;

export default HeaderLayout;
