import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem, color } from 'theme/lib';
import PhoneBezel from 'assets/svg/phone-bezel.svg';

const PHONE_DIMENSIONS = {
  bezel: {
    width: 525,
    height: 1142,
  },
  content: {
    width: 492,
    height: 1015,
    top: 60,
    bottom: 60,
    left: 12,
  },
};

function PhoneContainer({ className, children }) {
  return (
    <StyledPhoneContainer className={className}>
      {children}
    </StyledPhoneContainer>
  );
}

PhoneContainer.propTypes = {
  children: PropTypes.node,
};

PhoneContainer.defaultProps = {
  children: null,
};

const StyledPhoneContainer = styled.div`
  max-width: ${rem(520)};
  background-color: ${color('white')};
  border-radius: ${rem(10)} ${rem(10)} 0 0;
  overflow: hidden;
  background: green;
`;

PhoneContainer.Wrapper = styled.div`
  margin-left: ${rem(30)};
  margin-right: ${rem(30)};
  height: 100%;
  position: relative;
`;

PhoneContainer.Bezel = styled.div`
  background-image: url(${PhoneBezel});
  position: absolute;
  padding-bottom: ${(PHONE_DIMENSIONS.bezel.height /
    PHONE_DIMENSIONS.bezel.width) *
    100}%;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
`;

PhoneContainer.Screen = styled.div`
  position: absolute;
  background: ${color('blacks.100')};
  overflow: hidden;
  background-size: 100%;
  width: ${(PHONE_DIMENSIONS.content.width / PHONE_DIMENSIONS.bezel.width) *
    100}%;
  height: ${(PHONE_DIMENSIONS.content.height / PHONE_DIMENSIONS.bezel.height) *
    100}%;
  top: ${(PHONE_DIMENSIONS.content.top / PHONE_DIMENSIONS.bezel.height) * 100}%;
  left: ${(PHONE_DIMENSIONS.content.left / PHONE_DIMENSIONS.bezel.width) *
    100}%;
  border-radius: ${rem(37.5)};
`;

PhoneContainer.Content = styled.div`
  padding: 0;
  position: relative;
  height: ${props => (props.height ? props.height + 'px' : '100%')};
  width: 100%;
  overflow-y: scroll;
`;

export default PhoneContainer;
