import React from 'react';
import { Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components/macro';
import { rem } from 'theme/lib';
import media from 'theme/media';
import { COUNTRY_CODE_CANADA } from 'shared/constants';
import TextField from 'shared/components/formik/TextField/TextField';
import PhoneNumberField from 'shared/components/formik/PhoneNumberField/PhoneNumberField';
import SelectField from 'shared/components/formik/SelectField';
import { FieldError, FieldLabel } from 'shared/components/formik/styled';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import { ReactComponent as FacebookIcon } from 'assets/svg/facebook.svg';
import { ReactComponent as LinkedInIcon } from 'assets/svg/linkedin.svg';
import { ReactComponent as InstagramIcon } from 'assets/svg/instagram.svg';
import { ReactComponent as WebsiteIcon } from 'assets/svg/website.svg';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
} from 'shared/components/WizardQuestionForm';
import { countries, states, provinces } from '../contactInfoContent';

const REGEX = {
  FACEBOOK: /facebook\.com\/./,
  LINKEDIN: new RegExp(/linkedin\.com\/./),
  INSTAGRAM: new RegExp(/instagram\.com\/./),
  WEBSITE: /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:.[a-zA-Z]{2,})+/,
};

const ColumnGrid = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-right: ${rem(-30)};

  ${media.up('medium')} {
    flex-flow: row wrap;
  }
`;

const ColumnInputField = styled(TextField)`
  flex: 1 1 50%;
  padding-right: ${rem(30)};
  margin-bottom: ${rem(25)};
`;
const ColumnSelectField = styled(SelectField)`
  flex: 1 1 50%;
  padding-right: ${rem(30)};
  margin-bottom: ${rem(25)};
`;

const MailingAddressField = styled(TextField)`
  margin-bottom: ${rem(25)};
`;

const validationSchema = Yup.object().shape({
  phone: Yup.object().shape({
    mobile: Yup.string()
      .length(10, 'Invalid phone number')
      .required('Required'),
    work: Yup.string().length(10, 'Invalid phone number'),
  }),
  mailing_address: Yup.object().shape({
    address_1: Yup.string()
      .required('Required')
      .trim(),
    address_2: Yup.string(),
    postal_code: Yup.string()
      .required('Required')
      .trim()
      .max(10, 'Maximum 10 characters'),
    city: Yup.string()
      .trim()
      .required('Required'),
    stprv: Yup.string().required('Required'),
  }),
  country: Yup.string().required('Required'),
  socials: Yup.object()
    .shape({
      facebook: Yup.string()
        .trim()
        .matches(REGEX.FACEBOOK, {
          excludeEmptyString: true,
          message: 'Invalid Facebook url.',
        }),
      linkedin: Yup.string()
        .trim()
        .matches(REGEX.LINKEDIN, {
          excludeEmptyString: true,
          message: 'Invalid LinkedIn url.',
        }),
      instagram: Yup.string()
        .trim()
        .matches(REGEX.INSTAGRAM, {
          excludeEmptyString: true,
          message: 'Invalid Instagram url.',
        }),
      website: Yup.string()
        .trim()
        .matches(REGEX.WEBSITE, {
          excludeEmptyString: true,
          message: 'Invalid website url.',
        }),
    })
    .atLeastOneOf('One social account is required.'), // Yup expanded in shared/utils/yup.js
});

const initialValues = {
  phone: {
    mobile: '',
    work: '',
  },
  mailing_address: {
    address_1: '',
    address_2: '',
    postal_code: '',
    city: '',
    stprv: '',
  },
  country: '',
  socials: {
    facebook: '',
    linkedin: '',
    instagram: '',
    website: '',
  },
};

export default function ContactInformation(props) {
  return (
    <WizardQuestionForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValues={initialValues}
      validationSchema={validationSchema}
      continueInFooter
    >
      {({ values, setFieldValue, errors }) => {
        const isCanadianAddress =
          values.country === COUNTRY_CODE_CANADA || values.country === '';
        const handleCountryChange = e => {
          setFieldValue('country', e.target.value);
          // Resets provinces/states value when country changes
          setFieldValue('mailing_address.stprv', '');
        };
        return (
          <FormContainer>
            <FormContentHeader>
              <QuestionHeading as="h1">Where can we find you?</QuestionHeading>
              <QuestionDescription>
                Connecting with you is an important part of our process.
              </QuestionDescription>
            </FormContentHeader>

            <FormContent>
              <Field
                name="phone.mobile"
                type="text"
                component={PhoneNumberField}
                placeholder="Mobile phone number"
                label="Mobile phone number"
                labelVariant="noMargin"
              />
              <Field
                name="phone.work"
                type="text"
                component={PhoneNumberField}
                placeholder="Work phone number (optional)"
                label="Work phone number"
                labelVariant="noMargin"
              />
              <Field
                name="mailing_address.address_1"
                type="text"
                component={MailingAddressField}
                placeholder="Street Address"
                label="Mailing Address. Because sometimes old school is best."
                labelVariant="noMargin"
              />
              <Field
                name="mailing_address.address_2"
                type="text"
                component={MailingAddressField}
                placeholder="Apartment/Suite/Other"
              />
              <ColumnGrid>
                <Field
                  name="country"
                  component={ColumnSelectField}
                  onChange={handleCountryChange}
                  placeholderLabel="Country"
                  options={countries}
                />
                <Field
                  name="mailing_address.stprv"
                  component={ColumnSelectField}
                  placeholderLabel={isCanadianAddress ? 'Province' : 'State'}
                  options={isCanadianAddress ? provinces : states}
                />
              </ColumnGrid>
              <ColumnGrid>
                <Field
                  name="mailing_address.city"
                  type="text"
                  component={ColumnInputField}
                  placeholder="City"
                />
                <Field
                  name="mailing_address.postal_code"
                  type="text"
                  component={ColumnInputField}
                  placeholder={isCanadianAddress ? 'Postal Code' : 'Zip Code'}
                />
              </ColumnGrid>
              <FieldLabel>
                Add your social accounts (Only 1 is required). The number of
                &apos;followers&apos; you have has absolutely no impact on our
                decision-making process.
              </FieldLabel>
              <ColumnGrid>
                <Field
                  name="socials.facebook"
                  type="text"
                  component={ColumnInputField}
                  placeholder="Facebook Business"
                  borderSize={1}
                  paddingSize="large"
                  adornmentEndAlign="center"
                  renderAdornmentEnd={() => <FacebookIcon />}
                />
                <Field
                  name="socials.linkedin"
                  type="text"
                  component={ColumnInputField}
                  placeholder="LinkedIn"
                  borderSize={1}
                  paddingSize="large"
                  adornmentEndAlign="center"
                  renderAdornmentEnd={() => <LinkedInIcon />}
                />
                <Field
                  name="socials.instagram"
                  type="text"
                  component={ColumnInputField}
                  placeholder="Instagram"
                  borderSize={1}
                  paddingSize="large"
                  adornmentEndAlign="center"
                  renderAdornmentEnd={() => <InstagramIcon />}
                />
                <Field
                  name="socials.website"
                  type="text"
                  component={ColumnInputField}
                  placeholder="Website"
                  borderSize={1}
                  paddingSize="large"
                  adornmentEndAlign="center"
                  renderAdornmentEnd={() => <WebsiteIcon />}
                />
              </ColumnGrid>
              {errors && typeof errors.socials === 'string' && (
                <ErrorMessage component={FieldError} name="socials" />
              )}
            </FormContent>
          </FormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

ContactInformation.propTypes = withNavigationPropTypes;
