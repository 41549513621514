// Allow props spreading because this may be wrapped in a NavLink
/* eslint-disable  react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem, fontSize, color } from 'theme/lib';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left.svg';
import { hitSlops } from 'theme/helpers';

function ArrowNavButton({ className, type, children, onClick, ...props }) {
  return (
    <StyledArrowNavButton
      type={type}
      className={className}
      onClick={onClick}
      {...props}
    >
      <StyledArrowLeft />
      {children}
    </StyledArrowNavButton>
  );
}

ArrowNavButton.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

ArrowNavButton.defaultProps = {
  type: 'button',
  children: null,
  onClick: () => {},
};

const StyledArrowNavButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  color: ${color('black')};
  background-color: transparent;
  font-size: ${fontSize(2)};
  font-weight: 500;
  padding: 0;

  &[disabled] {
    cursor: default;
    pointer-events: none;
    color: ${color('grays.200')};
  }

  ${hitSlops.lg}
`;

const StyledArrowLeft = styled(ArrowLeft)`
  width: ${rem(13)};
  height: ${rem(14)};
  margin-right: ${rem(10)};
`;

export default ArrowNavButton;
