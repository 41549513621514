// Allow props spreading because this is a wrapper component
/* eslint-disable  react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import StepStarted from './StepStarted';
import StepCompleted from './StepCompleted';
import StepPending from './StepPending';

function StepSummary({
  step,
  currentStep,
  progress,
  title,
  subTitle,
  launchRoute,
  isPreviousStepPending,
}) {
  const isCompleted = progress >= 100;
  const isPending = progress <= 0 && step !== currentStep;
  const hasStarted = !isCompleted && !isPending;
  return (
    <>
      {hasStarted && (
        <StepStarted
          step={step}
          stepTitle={title}
          stepSubtitle={subTitle}
          launchRoute={launchRoute}
        />
      )}
      {isCompleted && (
        <StepCompleted stepTitle={title} launchRoute={launchRoute} />
      )}
      {isPending && (
        <StepPending
          stepTitle={title}
          stepSubtitle={subTitle}
          isPreviousStepPending={isPreviousStepPending}
        />
      )}
    </>
  );
}

StepSummary.propTypes = {
  step: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  launchRoute: PropTypes.string,
  /** Drops the opacity on StepPending if the previous step is not complete */
  isPreviousStepPending: PropTypes.bool,
};

StepSummary.defaultProps = {
  launchRoute: '',
  isPreviousStepPending: false,
};

export default StepSummary;
