import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { CarouselPrevButton, CarouselNextButton } from './CarouselNav';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function CarouselSlider({ children, ...props }) {
  return (
    <Slider
      dots={false}
      infinite={false}
      speed={500}
      slidesToShow={1}
      slidesToScroll={1}
      prevArrow={<CarouselPrevButton />}
      nextArrow={<CarouselNextButton />}
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
      {children}
    </Slider>
  );
}

CarouselSlider.propTypes = {
  children: PropTypes.node.isRequired,
};

CarouselSlider.defaultProps = {};

export default CarouselSlider;
