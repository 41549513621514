import { css } from 'styled-components';
import { darken, rgba } from 'polished';
import { containedThemes, outlinedThemes, getButtonColorValue } from './themes';

export const makeVariantStyles = ({ theme, variant, color, inverted }) => {
  const colorValue = getButtonColorValue(color, theme);
  switch (variant) {
    case 'contained':
    default:
      return containedButtonVariant(
        containedThemes(color, colorValue, theme, inverted)
      );
    case 'outlined':
      return outlinedButtonVariant(outlinedThemes(color, colorValue, theme));
  }
};

export function containedButtonVariant({
  color,
  backgroundColor,
  borderColor,
  hover,
  disabled,
  focus,
}) {
  return css`
    color: ${color};
    background-color: ${backgroundColor};
    border-color: ${borderColor};

    &:hover {
      background-color: ${hover.backgroundColor};
      border-color: ${hover.borderColor};
      color: ${hover.color};
    }

    &:disabled {
      background-color: ${disabled.backgroundColor};
      border-color: ${disabled.borderColor};
      color: ${disabled.color};
      opacity: ${disabled.opacity};
    }

    &:focus {
      box-shadow: 0 0 0 3px ${focus.boxShadowColor};
    }
  `;
}

export function outlinedButtonVariant({
  color,
  backgroundColor,
  borderColor,
  disabled,
  focus,
}) {
  return css`
    color: ${color};
    background-color: ${backgroundColor};
    border-color: ${borderColor};

    &:hover {
      background-color: ${rgba(borderColor, 0.1)};
      border-color: ${darken(0.1, borderColor)};
    }

    &:disabled {
      border-color: ${disabled.borderColor};
      color: ${disabled.color};
    }

    &:focus {
      box-shadow: 0 0 0 3px ${focus.boxShadowColor};
    }
  `;
}
