/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import get from 'lodash/get';
import { useUpdateProfile } from 'guideProfile/hooks/queries';

const IMAGE_NAME_PREFIXES = {
  header_image: '',
  hero_image: 'h',
};

/**
 * Hook that returns s callback for updating specified field
 * with uploaded images's url.
 *
 * @param {*} currentUserId
 * @param {*} imageNamePrefix
 */
export function useUpdateProfileImageField(currentUserId) {
  const [updateProfile] = useUpdateProfile(currentUserId);

  // Note: value is always going to be empty/null unless API in backend
  // is changed to return a new file name for uploaded profile image.
  return useCallback(
    async (fieldName, value) => {
      const values = {};

      if (value) {
        values[fieldName] = value;
      } else {
        // if value is not provided, build profile image url in here
        values[fieldName] =
          process.env.REACT_APP_IMG_ROOT +
          get(IMAGE_NAME_PREFIXES, fieldName, '') +
          currentUserId +
          `.jpg?${new Date().getTime()}`;
      }
      // Re-download image after 1.5sec uploading finished to prevent flicker.
      new Image().src = values[fieldName];

      updateProfile(values);
    },
    [currentUserId, updateProfile]
  );
}
