import styled from 'styled-components/macro';
import { rem } from 'theme/lib';
import media from 'theme/media';

export const Content = styled.div`
  overflow: auto;
  ${media.up('medium')} {
    font-size: ${rem(13)};
    line-height: ${22 / 14};
  }
`;

export const Instruction = styled.p`
  font-weight: 500;
  margin: ${rem(40)} 0 ${rem(40)};

  ${media.up('medium')} {
    margin: ${rem(55)} 0 ${rem(60)};
  }
`;
