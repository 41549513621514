import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import PanZoomImage from 'shared/components/PanZoomImage/PanZoomImage';
import { animationControlsPropTypes } from 'shared/motion/propTypes';
import { color, rem } from 'theme/lib';
import media from 'theme/media';

export const transition = {
  type: 'tween',
  duration: 0.5,
};

export const variants = {
  goForwardInitial: {
    // Animate wipe to reveal the image from top to bottom.
    clipPath: 'inset(0% 0% 100% 0%)',
    opacity: 0,
    transition,
  },
  goBackInitial: {
    // Animate wipe to reveal the image from bottom to top.
    clipPath: 'inset(100% 0% 0% 0%)',
    opacity: 0,
    transition,
  },
  in: {
    // Reveal the image.
    clipPath: 'inset(0% 0% 0% 0%)',
    // Opacity transition is used as a fallback for browsers that don't
    // support clip-path animations (mainly IE, Safari). https://caniuse.com/#feat=css-clip-path
    // Browsers that do support clip-path will use both transitions.
    opacity: 1,
    transition,
  },
  goForwardExit: {
    // Animate wipe to hide the image from top to bottom.
    clipPath: 'inset(100% 0% 0% 0%)',
    opacity: 0,
    transition,
  },
  goBackExit: {
    // Animate wipe to hide the image from bottom to top.
    clipPath: 'inset(0% 0% 100% 0%)',
    opacity: 0,
    transition,
  },
};

const slideDuration = 6000;
const fadeDuration = 2;

function SidebarImage({
  className,
  isDirectionReversed,
  src,
  alt,
  animate,
  profiles,
}) {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const t = setTimeout(() => {
      setCount(count + 1);
    }, slideDuration);
    return () => clearTimeout(t);
  }, [count]);

  const renderedProfiles = profiles.map((p, idx) => {
    if (count % profiles.length === idx) {
      return (
        <ImageWrapper
          as={motion.div}
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: fadeDuration }}
        >
          <PanZoomImage src={p.src} alt={p.alt || alt} />
          <ProfileDetails>
            <ProfileName>{p.name}</ProfileName>
            {p.career}
          </ProfileDetails>
        </ImageWrapper>
      );
    }
    return null;
  });

  return (
    <ImageWrapper
      as={motion.div}
      initial={isDirectionReversed ? 'goBackInitial' : 'goForwardInitial'}
      animate={animate}
      variants={variants}
      className={className}
    >
      {src && <PanZoomImage src={src} alt={alt} />}

      {profiles && profiles.length > 0 && (
        <AnimatePresence>{renderedProfiles}</AnimatePresence>
      )}
    </ImageWrapper>
  );
}

const ProfileShape = PropTypes.shape({
  name: PropTypes.string,
  career: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
});

SidebarImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  isDirectionReversed: PropTypes.bool,
  profiles: PropTypes.arrayOf(ProfileShape),
  /** Framer Motion AnimationControls. */
  animate: animationControlsPropTypes.isRequired,
};

SidebarImage.defaultProps = {
  alt: '',
  src: '',
  isDirectionReversed: false,
  profiles: [],
};

const ImageWrapper = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  ${media.up('large')} {
    height: calc(100% - ${rem(40)});
    margin-top: ${rem(20)};
    margin-bottom: ${rem(20)};
    border-radius: ${rem(10)};
  }
`;

const ProfileDetails = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    to bottom,
    hsla(0, 0%, 10%, 0),
    ${color('blacks.100')}
  );
  font-size: ${rem(24)};
  line-height: ${34 / 24};
  color: #fffdb8;
  padding: ${rem(20)} ${rem(20)} ${rem(75)};
`;

const ProfileName = styled.h2`
  font-size: ${rem(60)};
  font-weight: 500;
  line-height: ${60 / 54};
  color: ${color('white')};
  margin-top: 0;
  margin-bottom: 0;
`;

export default SidebarImage;
