import React from 'react';
import { Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { EmptyValueRejector } from 'shared/utils/yup';
import styled from 'styled-components';
import { color, rem } from 'theme/lib';
import TextField from 'shared/components/formik/TextField/TextField';
import { FieldError } from 'shared/components/formik/styled';
import Button from 'shared/components/Button/Button';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
} from 'shared/components/WizardQuestionForm';

const MAX_INPUTS = 6;

const validationSchema = Yup.object().shape({
  practice: Yup.array()
    .of(Yup.string())
    .compact(EmptyValueRejector)
    .min(1, 'Please provide at least one example.'),
});

const fieldPlaceholders = [
  'Full-time personal coach',
  'HR Leader',
  'Part-time personal practice',
  'Therapist',
  'Wellness Professional',
  'Counselor',
];

export default function DescribePractice(props) {
  return (
    <WizardQuestionForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValues={{
        practice: ['', '', '', ''],
      }}
      validationSchema={validationSchema}
      continueInFooter
    >
      {({ values }) => {
        return (
          <FormContainer>
            <FormContentHeader>
              <QuestionHeading as="h1">
                Concisely describe your work and practice.
              </QuestionHeading>

              <QuestionDescription>
                What accurately describes and represents your coaching
                career-to-date?
              </QuestionDescription>
            </FormContentHeader>

            <FormContent>
              <FieldArray
                name="practice"
                render={arrayHelpers => (
                  <>
                    {values.practice.map((item, index) => (
                      <Field
                        key={index} // eslint-disable-line react/no-array-index-key
                        name={`practice.${index}`}
                        type="text"
                        component={TextField}
                        placeholder={fieldPlaceholders[index]}
                        hideFieldError
                      />
                    ))}
                    <AddAnotherContent>
                      {values.practice.length < MAX_INPUTS ? (
                        <>
                          <AddAnotherButton
                            onClick={() => arrayHelpers.push('')}
                          >
                            Add Another
                          </AddAnotherButton>
                          Maximum {MAX_INPUTS}
                        </>
                      ) : (
                        <>Max Number of Statements</>
                      )}
                    </AddAnotherContent>
                  </>
                )}
              />
              <ErrorMessage component={FieldError} name="practice" />
            </FormContent>
          </FormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

DescribePractice.propTypes = withNavigationPropTypes;

const AddAnotherButton = styled(props => (
  <Button
    shape="pill"
    color="default"
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  />
))`
  min-width: auto;
  font-size: ${rem(20)};
  padding-left: ${rem(13)};
  padding-right: ${rem(13)};
`;

const AddAnotherContent = styled.div`
  display: flex;
  align-items: center;
  font-size: ${rem(13)};
  line-height: ${20 / 13};
  color: ${color('textSecondary')};

  ${AddAnotherButton} {
    margin-right: ${rem(13)};
  }
`;
