import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { rem } from 'theme/lib';
import media from 'theme/media';
import Button from 'shared/components/Button/Button';
import {
  PrimaryBox,
  PrimaryBoxTitle,
  PrimaryBoxSubtitle,
} from 'shared/components/styled/primaryBox';

function ApplicationDisclaimer({ onClickDisclaimer }) {
  return (
    <StyledPrimaryBox>
      <PrimaryBoxTitle>Next Steps</PrimaryBoxTitle>
      <BoxWrapper>
        <PrimaryBoxSubtitle>
          Your profile is officially in review. Please check your email inbox as
          you will receive an email regarding the status of your application
          within 30 days.
        </PrimaryBoxSubtitle>
        <StyledButton
          shape="pill"
          size="small"
          color="default"
          inverted
          type="submit"
          onClick={onClickDisclaimer}
        >
          I understand
        </StyledButton>
      </BoxWrapper>
    </StyledPrimaryBox>
  );
}

export default ApplicationDisclaimer;

ApplicationDisclaimer.propTypes = {
  onClickDisclaimer: PropTypes.func.isRequired,
};

const StyledPrimaryBox = styled(PrimaryBox)`
  margin: ${rem(60)} 0px ${rem(60)};
  max-width: ${rem(540)};
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.up('medium')} {
    flex-direction: row;
  }
  ${PrimaryBoxSubtitle} {
    margin-bottom: ${rem(14)};
  }
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
  margin-left: ${rem(43)};
  /* Visually aligns Button text with PrimaryBoxSubtitle */
  margin-bottom: ${rem(8)};
`;
