import React from 'react';
import styled from 'styled-components/macro';
import CarouselSlider from 'shared/components/Carousel/CarouselSlider';
import CarouselSlide from 'shared/components/Carousel/CarouselSlide';
import CarouselLegend from 'shared/components/Carousel/CarouselLegend';
import { makeSlideData } from 'shared/components/Carousel/helpers';
import imgGoodFacilitating from './good-facilitating/do-1.jpg';
import imgGoodFacilitating2x from './good-facilitating/do-1@2x.jpg';
import imgGoodFacilitating3x from './good-facilitating/do-1@3x.jpg';
import imgGoodSpeaking from './good-speaking/do-1.jpg';
import imgGoodSpeaking2x from './good-speaking/do-1@2x.jpg';
import imgGoodSpeaking3x from './good-speaking/do-1@3x.jpg';
import imgGoodStudio from './good-studio/do-1.jpg';
import imgGoodStudio2x from './good-studio/do-1@2x.jpg';
import imgGoodStudio3x from './good-studio/do-1@3x.jpg';
import imgGoodElement from './good-element/do-1.jpg';
import imgGoodElement2x from './good-element/do-1@2x.jpg';
import imgGoodElement3x from './good-element/do-1@3x.jpg';
import imgBadHeadshot from './bad-headshot/do-1.jpg';
import imgBadHeadshot2x from './bad-headshot/do-1@2x.jpg';
import imgBadHeadshot3x from './bad-headshot/do-1@3x.jpg';
import imgBadIphone from './bad-iphone/do-1.jpg';
import imgBadIphone2x from './bad-iphone/do-1@2x.jpg';
import imgBadIphone3x from './bad-iphone/do-1@3x.jpg';
import imgBadPersonalInformation from './bad-personal-information/do-1.jpg';
import imgBadPersonalInformation2x from './bad-personal-information/do-1@2x.jpg';
import imgBadPersonalInformation3x from './bad-personal-information/do-1@3x.jpg';
import imgBadNotFocalPoint from './bad-not-focal-point/do-1.jpg';
import imgBadNotFocalPoint2x from './bad-not-focal-point/do-1@2x.jpg';
import imgBadNotFocalPoint3x from './bad-not-focal-point/do-1@3x.jpg';
import imgBadBlurry from './bad-blurry/do-1.jpg';
import imgBadBlurry2x from './bad-blurry/do-1@2x.jpg';
import imgBadBlurry3x from './bad-blurry/do-1@3x.jpg';

const slides = [
  makeSlideData({
    positive: true,
    caption: 'Facilitating',
    src: imgGoodFacilitating,
    srcSet: {
      '1x': imgGoodFacilitating,
      '2x': imgGoodFacilitating2x,
      '3x': imgGoodFacilitating3x,
    },
  }),
  makeSlideData({
    positive: true,
    caption: 'Speaking',
    src: imgGoodSpeaking,
    srcSet: {
      '1x': imgGoodSpeaking,
      '2x': imgGoodSpeaking2x,
      '3x': imgGoodSpeaking3x,
    },
  }),
  makeSlideData({
    positive: true,
    caption: 'In your studio',
    src: imgGoodStudio,
    srcSet: {
      '1x': imgGoodStudio,
      '2x': imgGoodStudio2x,
      '3x': imgGoodStudio3x,
    },
  }),
  makeSlideData({
    positive: true,
    caption: 'In your element',
    src: imgGoodElement,
    srcSet: {
      '1x': imgGoodElement,
      '2x': imgGoodElement2x,
      '3x': imgGoodElement3x,
    },
  }),
  makeSlideData({
    positive: false,
    caption: 'Another headshot',
    src: imgBadHeadshot,
    srcSet: {
      '1x': imgBadHeadshot,
      '2x': imgBadHeadshot2x,
      '3x': imgBadHeadshot3x,
    },
  }),
  makeSlideData({
    positive: false,
    caption: 'An iPhone photo',
    src: imgBadIphone,
    srcSet: {
      '1x': imgBadIphone,
      '2x': imgBadIphone2x,
      '3x': imgBadIphone3x,
    },
  }),
  makeSlideData({
    positive: false,
    caption: 'Show any personal information',
    src: imgBadPersonalInformation,
    srcSet: {
      '1x': imgBadPersonalInformation,
      '2x': imgBadPersonalInformation2x,
      '3x': imgBadPersonalInformation3x,
    },
  }),
  makeSlideData({
    positive: false,
    caption: "You're not the focal point",
    src: imgBadNotFocalPoint,
    srcSet: {
      '1x': imgBadNotFocalPoint,
      '2x': imgBadNotFocalPoint2x,
      '3x': imgBadNotFocalPoint3x,
    },
  }),
  makeSlideData({
    positive: false,
    caption: 'Blurry',
    src: imgBadBlurry,
    srcSet: {
      '1x': imgBadBlurry,
      '2x': imgBadBlurry2x,
      '3x': imgBadBlurry3x,
    },
  }),
];

const makeImgAlt = (positive = true) =>
  positive ? 'Example of a good photo' : 'Example of a bad photo';

function SecondaryPhotoCarousel() {
  return (
    <>
      <StyledCarouselLegend />

      <StyledCarouselSlider>
        {slides.map(slide => (
          <CarouselSlide
            key={slide.id}
            src={slide.src}
            srcSet={slide.srcSet}
            alt={makeImgAlt(slide.positive)}
            aspectRatio={0.72}
          >
            <CarouselSlide.Footer positive={slide.positive}>
              {slide.caption}
            </CarouselSlide.Footer>
          </CarouselSlide>
        ))}
      </StyledCarouselSlider>
    </>
  );
}

SecondaryPhotoCarousel.propTypes = {};

SecondaryPhotoCarousel.defaultProps = {};

const StyledCarouselLegend = styled(CarouselLegend)`
  align-self: flex-start;
`;

const StyledCarouselSlider = styled(CarouselSlider)`
  margin-top: auto;
  margin-bottom: auto;
`;

export default SecondaryPhotoCarousel;
