import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import { color as themeColor, rem } from 'theme/lib';
import backgroundBullet from 'assets/svg/bullet.svg';

function NumberBullet({ className, variant, color, children }) {
  return (
    <StyledNumberBullet className={className} variant={variant} color={color}>
      {children}
    </StyledNumberBullet>
  );
}

NumberBullet.propTypes = {
  variant: PropTypes.oneOf(['outlined', 'contained']),
  color: PropTypes.string,
  children: PropTypes.node,
};

NumberBullet.defaultProps = {
  variant: 'outlined',
  color: 'default',
  children: null,
};

const variantCSS = ({ variant, color, theme }) => {
  switch (variant) {
    case 'contained': {
      let backgroundColor;
      const colorFromTheme = get(theme.colors, color);

      if (color === 'default') {
        backgroundColor = themeColor('textColor')({ theme });
      } else if (colorFromTheme) {
        backgroundColor = themeColor(color)({ theme });
      } else {
        // Infer that the color is an accepted CSS color value.
        backgroundColor = color;
      }

      return css`
        border-radius: 1em;
        background-color: ${backgroundColor};
        color: ${themeColor('white')};
      `;
    }
    case 'outlined':
    default:
      return css`
        background-image: url('${backgroundBullet}');
      `;
  }
};

const StyledNumberBullet = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${rem(19)};
  height: ${rem(19)};
  font-size: ${rem(10)};
  line-height: ${19 / 10};

  ${variantCSS}
`;

export default NumberBullet;
