/* eslint-disable import/prefer-default-export */

/**
 * Join routes together without worrying about trailing or leading slashes
 * @param  {...string} routes
 * @returns {string} All the routes joined together
 */
export function joinRoutes(...routes) {
  return (
    '/' +
    routes
      .map(r => {
        // Remove any trailing or leading slashes
        return r.replace(/(^\/)|(\/$)/g, '');
      })
      .filter(exists => exists) // Filter out empty strings
      .join('/')
  );
}
