import { darken, setLightness } from 'polished';
import { hslToHsla } from 'theme/lib';

export const getButtonColorValue = (color, theme) => {
  const colorThemes = {
    default: theme.colors.black,
    primary: theme.colors.brand.primary,
    secondary: theme.colors.grays[400],
  };
  return colorThemes[color] || colorThemes.default;
};

export const containedThemes = (color, colorValue, theme, inverted) => {
  let containedStyles = {};
  if (inverted) {
    containedStyles = {
      color: colorValue,
      backgroundColor: theme.colors.white,
      borderColor: theme.colors.white,
      hover: {
        backgroundColor: darken(0.3, theme.colors.white),
        borderColor: darken(0.3, theme.colors.white),
        color: theme.colors.white,
      },
      disabled: {
        opacity: 0.3,
      },
      focus: {
        boxShadowColor: hslToHsla(colorValue, 0.15),
      },
    };
  } else {
    containedStyles = {
      color: theme.colors.white,
      backgroundColor: colorValue,
      borderColor: colorValue,
      hover: {
        backgroundColor: setLightness(0.3, colorValue),
        borderColor: setLightness(0.3, colorValue),
        color: theme.colors.white,
      },
      disabled: {
        backgroundColor: theme.colors.grays[200],
        borderColor: theme.colors.grays[200],
        color: theme.colors.white,
      },
      focus: {
        boxShadowColor: hslToHsla(colorValue, 0.15),
      },
    };
  }
  switch (color) {
    case 'primary': {
      return {
        ...containedStyles,
        hover: {
          backgroundColor: darken(0.1, colorValue),
          borderColor: darken(0.1, colorValue),
          color: theme.colors.white,
        },
      };
    }
    case 'default':
    default: {
      if (inverted) {
        return {
          ...containedStyles,
          hover: {
            backgroundColor: colorValue,
            borderColor: colorValue,
            color: theme.colors.white,
          },
        };
      }
      return containedStyles;
    }
    case 'secondary':
      return containedStyles;
  }
};

export const outlinedThemes = (color, colorValue, theme) => {
  const outlinedStyles = {
    color: colorValue,
    backgroundColor: 'transparent',
    borderColor: colorValue,
    disabled: {
      color: theme.colors.grays[200],
      borderColor: theme.colors.grays[200],
    },
    focus: {
      boxShadowColor: hslToHsla(colorValue, 0.15),
    },
  };
  switch (color) {
    case 'primary':
      return outlinedStyles;
    case 'default':
    default:
      return outlinedStyles;
    case 'secondary':
      return outlinedStyles;
  }
};
