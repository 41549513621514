import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { rem, color, zIndex } from 'theme/lib';
import media from 'theme/media';
import ArrowNavButton from 'shared/components/ArrowNavButton/ArrowNavButton';

const Footer = ({ goBack, children }) => (
  <FixedFooter>
    <FixedFooterContent>
      <BackArrowWrapper>
        <ArrowNavButton onClick={goBack}>Go back</ArrowNavButton>
      </BackArrowWrapper>
      {children}
    </FixedFooterContent>
  </FixedFooter>
);

Footer.defaultProps = {
  children: '',
};

Footer.propTypes = {
  goBack: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const FixedFooter = styled.div`
  width: 100%;
  padding: ${rem(20)} ${rem(15)};
  background: ${color('white')};
  position: fixed;
  border-top: solid 1px ${color('grays.300')};
  width: 100%;
  left: 0;
  bottom: 0;
  text-align: right;
  z-index: ${zIndex('sidebar')};
  display: flex;
  justify-content: center;

  ${media.up('large')} {
    padding: ${rem(20)} ${rem(20)};
  }
`;

const FixedFooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${rem(1400)};
  width: 100%;

  ${media.up('large')} {
    justify-content: flex-end;
    padding: 0 ${rem(20)} 0;
  }

  ${media.up('huge')} {
    padding: 0;
  }
`;

const BackArrowWrapper = styled.div`
  display: inline-block;
  padding: 0 ${rem(40)} 0 0;
`;

export default Footer;
