import React from 'react';
import routes from 'shared/routes';
import makeWizardNavigator, { layoutPropTypes } from 'shared/wizardNavigation';
import HeaderLayout from 'shared/layouts/HeaderLayout/HeaderLayout';
import { CodeOfConductContextProvider } from 'Approved/CodeOfConductContext';
import CodeOfConductWizard from 'Approved/components/CodeOfConductWizard/CodeOfConductWizard';
import Calendar from 'Approved/components/Questions/Calendar';
import GearingUp from 'Approved/components/GearingUp/GearingUp';
import ElectronicSignature from 'Approved/components/Questions/ElectronicSignature';
import Download from 'Approved/components/Download/Download';

const screens = [
  {
    route: routes.applicationApproved.codeOfConduct(),
    Content: CodeOfConductWizard,
  },
  {
    route: routes.applicationApproved.calendar(),
    Content: Calendar,
  },
  {
    route: routes.applicationApproved.gearingup(),
    Content: GearingUp,
  },
  {
    route: routes.applicationApproved.signature(),
    Content: ElectronicSignature,
  },
  {
    route: routes.applicationApproved.download(),
    Content: Download,
  },
];

function LayoutComponent({ content, globalStep, globalTotalSteps }) {
  return (
    <HeaderLayout
      title="You're In! Almost"
      progressPercent={(globalStep / globalTotalSteps) * 100}
      enableProgressBar
    >
      <CodeOfConductContextProvider>{content}</CodeOfConductContextProvider>
    </HeaderLayout>
  );
}

LayoutComponent.propTypes = layoutPropTypes;

export default makeWizardNavigator({
  baseRoute: routes.applicationApprovedBase,
  entryRoute: routes.applicationStatus,
  exitRoute: routes.applicationStatus,
  screens,
  Layout: LayoutComponent,
});
