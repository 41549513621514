import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import PanZoomImage from 'shared/components/PanZoomImage/PanZoomImage';
import { rem } from 'theme/lib';
import media from 'theme/media';

function SidebarImage({ className, src, alt }) {
  if (!src) {
    return null;
  }

  return (
    <ImageWrapper as={motion.div} className={className}>
      <PanZoomImage src={src} alt={alt} />
    </ImageWrapper>
  );
}

SidebarImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

SidebarImage.defaultProps = {
  alt: '',
};

const ImageWrapper = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  ${media.up('large')} {
    height: calc(100% - ${rem(40)});
    margin-top: ${rem(20)};
    margin-bottom: ${rem(20)};
    border-radius: ${rem(10)};
  }
`;

export default SidebarImage;
