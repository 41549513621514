/* eslint-disable camelcase */
import React from 'react';
import { Field } from 'formik';
import CloudSelectorField from 'shared/components/formik/CloudSelectorField';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
} from 'shared/components/WizardQuestionForm';
import { useProfileFieldOptions } from 'guideProfile/hooks/queries';
import {
  FAMILY_STATUS_SINGLE,
  FAMILY_STATUS_COMMITTED,
  FAMILY_STATUS_OPTIONS_LABELS,
} from 'shared/constants';

function validate({ family_status }) {
  if (!family_status || !family_status.length) {
    return {
      family_status: 'Please select at least one option',
    };
  }

  if (
    family_status.includes(FAMILY_STATUS_SINGLE) &&
    family_status.includes(FAMILY_STATUS_COMMITTED)
  ) {
    return {
      family_status: 'Can not be single and in a relationship at the same time',
    };
  }

  return {};
}

export default function FamilyStatus(props) {
  const fieldOptions = useProfileFieldOptions(
    'family_status',
    FAMILY_STATUS_OPTIONS_LABELS
  ).data;

  return (
    <WizardQuestionForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValues={{
        family_status: [],
      }}
      validate={validate}
    >
      {() => {
        return (
          <FormContainer>
            <FormContentHeader>
              <QuestionHeading as="h1">
                Give us a peek into your world.
              </QuestionHeading>

              <QuestionDescription width={410}>
                We ask this to know about your life experiences, this plays a
                small factor in our matching algorithm. Select all that apply.
              </QuestionDescription>
            </FormContentHeader>

            <FormContent width={620}>
              <Field
                name="family_status"
                component={CloudSelectorField}
                items={fieldOptions}
              />
            </FormContent>
          </FormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

FamilyStatus.propTypes = withNavigationPropTypes;
