import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Field, ErrorMessage } from 'formik';
import Dropzone from 'react-dropzone-uploader';
import uuid from 'uuid/v4';
import { rem } from 'theme/lib';
import Button from 'shared/components/Button/Button';
import TextField from 'shared/components/formik/TextField/TextField';
import CharCountAdornment from 'shared/components/formik/CharCountAdornment/CharCountAdornment';
import { FieldError } from 'shared/components/formik/styled';
import useUploaderParams from 'guideProfile/hooks/useUploaderParams';
import {
  UPLOAD_TYPE_CERTIFICATION,
  CERT_NAME_MAX_LENGTH,
} from 'guideProfile/constants';
import {
  handleChangeStatus,
  getFilesFromEvent,
} from 'guideProfile/components/Dropzone/helpers';
import DropzoneInput from '../../SingleFileDropzone/Input';
import DropzoneLayout from '../../SingleFileDropzone/Layout';
import DropzonePreview from '../../SingleFileDropzone/Preview';
import UploadedResource from '../../SingleFileDropzone/UploadedResource';
import ImageModal from '../../ImageModal/ImageModal';

const inputPlaceholders = [
  'CTI Certified',
  'Erickson Certified',
  'Myers-Briggs Type Indicator Level I & II Certified Facilitator',
  'Certification #4',
  'Certification #5',
  'Certification #6',
];

const StyledTextField = styled(TextField)`
  margin-bottom: ${rem(8)};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
function CertificationFields({
  index,
  name,
  url,
  setFieldValue,
  setFieldError,
  setFieldTouched,
}) {
  const inputId = useRef(uuid());
  const [isModalOpen, setModalOpen] = useState(false);
  const getUploadParams = useUploaderParams(UPLOAD_TYPE_CERTIFICATION);
  const urlFieldName = `fullCerts.${index}.url`;

  const handleRemove = () => {
    setFieldValue(urlFieldName, '');
  };

  const handleViewFile = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const [isPreviewShowing, setIsPreviewShowing] = useState(false);
  const handleUploadClick = () => {
    // Hack to work around the dropzone input un-mounting and mounting on field blurs.
    // This prevents needing to click twice on the upload button
    document.getElementById(inputId.current).click();
  };

  // Name must not be empty but we can't check against the Formik errors object
  // because it is empty in its initial state.
  const isUploadDisabled = !name;

  return (
    <FieldGroup role="group">
      <Field
        name={`fullCerts.${index}.name`}
        type="text"
        component={StyledTextField}
        placeholder={inputPlaceholders[index]}
        errorSize="small"
        renderAdornmentEnd={({ value }) => (
          <CharCountAdornment value={value} limit={CERT_NAME_MAX_LENGTH} />
        )}
        maxLength={CERT_NAME_MAX_LENGTH + 1} // Allow user to exceed to trigger validation feedback.
      />

      {url ? (
        <UploadedResource
          url={url}
          onViewFile={handleViewFile}
          onRemove={handleRemove}
        />
      ) : (
        <>
          {!isPreviewShowing && (
            <ButtonWrapper>
              <TinyButton
                shape="pill"
                size="small"
                type="button"
                disabled={isUploadDisabled}
                onClick={handleUploadClick}
              >
                Upload File...
              </TinyButton>
            </ButtonWrapper>
          )}
          <Dropzone
            getUploadParams={getUploadParams}
            getFilesFromEvent={getFilesFromEvent}
            LayoutComponent={layoutProps => (
              <DropzoneLayout
                {...layoutProps} // eslint-disable-line react/jsx-props-no-spreading
              />
            )}
            InputComponent={inputProps => (
              <DropzoneInput
                {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
                isUploadDisabled={isUploadDisabled}
                id={inputId.current}
              />
            )}
            PreviewComponent={previewProps => (
              <DropzonePreview
                {...previewProps} // eslint-disable-line react/jsx-props-no-spreading
                setIsPreviewShowing={setIsPreviewShowing}
              />
            )}
            onChangeStatus={handleChangeStatus({
              setFieldValue,
              fieldName: urlFieldName,
              setFieldError,
              setFieldTouched,
            })}
            maxFiles={1}
            multiple={false}
          />
          <ErrorMessage component={FieldError} name={urlFieldName} />
        </>
      )}

      {isModalOpen && <ImageModal imageUrl={url} onClose={handleModalClose} />}
    </FieldGroup>
  );
}

CertificationFields.propTypes = {
  /** FieldArray index. */
  index: PropTypes.number.isRequired,
  /** Description of uploaded file. */
  name: PropTypes.string,
  /** URL of uploaded file. */
  url: PropTypes.string,
  /** Formik function. */
  setFieldValue: PropTypes.func.isRequired,
  /** Formik function. */
  setFieldError: PropTypes.func.isRequired,
  /** Formik function. */
  setFieldTouched: PropTypes.func.isRequired,
};

CertificationFields.defaultProps = {
  name: '',
  url: '',
};

const FieldGroup = styled.div`
  margin-bottom: ${rem(30)};
`;

const TinyButton = styled(Button)`
  font-size: ${rem(13)};
  min-width: auto;
  user-select: none;
`;

export default CertificationFields;
