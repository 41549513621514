import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem, fontSize } from 'theme/lib';
import cx from 'classnames';
import positiveIcon from 'assets/svg/positive.svg';
import negativeIcon from 'assets/svg/negative.svg';

function GuidelinesList({ className, variant, children }) {
  const customClassNames = cx(className, `guidelines-list--${variant}`);
  return <StyledList className={customClassNames}>{children}</StyledList>;
}

GuidelinesList.propTypes = {
  /** Apply icon variation */
  variant: PropTypes.oneOf(['positive', 'negative']),
  children: PropTypes.node,
};

GuidelinesList.defaultProps = {
  variant: 'positive',
  children: null,
};

const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: ${fontSize(2)};
  letter-spacing: -0.4px;
`;

export const StyledListItem = styled.li`
  display: flex;
  align-items: text-bottom;
  line-height: ${23 / 16};
  margin-bottom: ${rem(15)};
  margin-left: ${rem(25)};
  margin-right: ${rem(25)};
  -webkit-font-smoothing: subpixel-antialiased;

  &::before {
    display: block;
    content: "";
    margin-right: ${rem(12)};

    .guidelines-list--positive & {
      background-image: url('${positiveIcon}');
      flex: 0 0 ${rem(16)};
      width: ${rem(16)};
      height: ${rem(16)};
    }

    .guidelines-list--negative & {
      background-image: url('${negativeIcon}');
      flex: 0 0 ${rem(12)};
      width: ${rem(12)};
      height: ${rem(12)};
      margin-top: ${rem(2)}; /* Vertically align with text */
      margin-left: 1px;
      margin-right: ${rem(15)}; /* Horizontally align with "Do" text */
    }
  }
`;

export default GuidelinesList;
