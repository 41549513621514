import styled, { css } from 'styled-components';
import { rem } from 'theme/lib';
import media from 'theme/media';
import TextField from 'shared/components/formik/TextField/TextField';
import NumberBullet from 'shared/components/NumberBullet/NumberBullet';
import PhoneNumberField from 'shared/components/formik/PhoneNumberField/PhoneNumberField';

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 ${rem(-5)};

  ${({ name }) => {
    switch (name) {
      case 'contact':
        return css`
          ${media.up('medium')} {
            padding-right: ${rem(35)};
          }
        `;
      default:
        return css``;
    }
  }}
`;

export const Col = styled.div`
  padding-left: ${rem(5)};
  padding-right: ${rem(5)};
  flex: 1 1 100%;
  max-width: 100%;

  ${({ name }) => {
    switch (name) {
      case 'firstName':
        return css`
          max-width: ${(3 / 5) * 100}%;

          ${media.up('medium')} {
            max-width: ${(2 / 5) * 100}%;
          }
        `;
      case 'lastInitial':
        return css`
          max-width: ${(2 / 5) * 100}%;

          ${media.up('medium')} {
            max-width: ${(1 / 5) * 100}%;
          }
        `;
      case 'numSessions':
        return css`
          ${media.up('medium')} {
            max-width: ${(2 / 5) * 100}%;
          }
        `;
      case 'phone':
        return css`
          ${media.up('medium')} {
            max-width: ${(1 / 2) * 100}%;
          }
        `;
      case 'email':
        return css`
          ${media.up('medium')} {
            max-width: ${(1 / 2) * 100}%;
          }
        `;
      default:
        return css``;
    }
  }}
`;

export const FlexTextField = styled(TextField)`
  margin-bottom: ${rem(20)};
`;

export const FlexPhoneField = styled(PhoneNumberField)`
  margin-bottom: ${rem(20)};
`;

export const CountWrapper = styled.div`
  flex: none;
  width: ${rem(40)};
`;

export const Count = styled(NumberBullet)`
  margin-top: ${rem(-2)};
`;

export const StyledReferenceFields = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border: 0;
  padding: 0;
  margin: 0 0 ${rem(50)};
`;
