import { Form } from 'formik';
import styled from 'styled-components/macro';
import { rem } from 'theme/lib';
import media from 'theme/media';
import { FormContentHeader } from 'shared/components/WizardQuestionForm/styled';
import OutlinedTextareaField from 'shared/components/formik/OutlinedTextareaField/OutlinedTextareaField';
import RadioField from 'shared/components/formik/RadioField/RadioField';

export const StyledFormContentHeader = styled(FormContentHeader)`
  margin-bottom: ${rem(30)};
  flex: none;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  max-width: ${rem(540)};
`;

export const FieldGroup = styled.div`
  margin-bottom: ${rem(36)};
`;

export const StyledRadioField = styled(RadioField)`
  margin-bottom: ${rem(10)};
`;

export const StyledOutlinedTextareaField = styled(OutlinedTextareaField)`
  margin-bottom: 0;
`;

export const FormActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: ${rem(30)};

  ${media.up('medium')} {
    flex-direction: row;
    justify-content: space-between;
  }

  .back-button {
    order: 2;
    margin-top: ${rem(30)};

    ${media.up('medium')} {
      order: 1;
      margin-top: 0;
      margin-right: ${rem(20)};
    }
  }

  .submit-button {
    order: 1;

    ${media.up('medium')} {
      order: 2;
    }
  }
`;
