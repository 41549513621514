import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem, fontSize } from 'theme/lib';
import cx from 'classnames';
import positiveSectionIcon from 'assets/svg/positive-section.svg';
import negativeSectionIcon from 'assets/svg/negative-section.svg';

function GuidelinesSectionHeading({ className, variant }) {
  const customClassNames = cx(className, `section-icon--${variant}`);
  const sectionTitle = variant === 'positive' ? 'Do' : "Don't";
  return (
    <StyledSectionHeading className={customClassNames}>
      {sectionTitle}
    </StyledSectionHeading>
  );
}

GuidelinesSectionHeading.propTypes = {
  /** Apply icon variation and respective section title */
  variant: PropTypes.oneOf(['positive', 'negative']),
};

GuidelinesSectionHeading.defaultProps = {
  variant: 'positive',
};

const StyledSectionHeading = styled.header`
  padding: ${rem(20)};
  font-size: ${fontSize(3)};
  font-weight: 500;
  display: flex;
  align-items: center;
  -webkit-font-smoothing: subpixel-antialiased;
  &::before {
    display: block;
    content: "";
    width: ${rem(23)};
    height: ${rem(23)};
    margin-right: ${rem(10)};
  }
  &.section-icon--positive {
    &::before {
      background-image: url('${positiveSectionIcon}');
    }
  }
  &.section-icon--negative {
    &::before {
    background-image: url('${negativeSectionIcon}');
    }
  }
`;

export default GuidelinesSectionHeading;
