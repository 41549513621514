import styled, { css } from 'styled-components';
import { color, rem, fontSize } from 'theme/lib';

/* eslint-disable consistent-return */
export const FieldLabel = styled.label`
  display: block;
  font-size: ${rem(13)};
  line-height: ${18 / 13};
  font-weight: 500;
  margin-bottom: ${rem(20)};

  ${({ variant }) => {
    if (variant === 'noMargin') {
      return css`
        margin-bottom: 0;
      `;
    }
  }}
`;
/* eslint-enable consistent-return */

export const FieldGroup = styled.div`
  margin-bottom: ${rem(50)};

  ${({ hasError }) =>
    hasError &&
    css`
      input,
      select,
      textarea {
        border-color: ${color('brand.error')};
      }

      ${FieldLabel} {
        color: ${color('brand.error')};
      }
    `}
`;

export const FieldHelperText = styled.p`
  margin-top: ${rem(4)};
  margin-bottom: 0;
  font-size: ${fontSize(1)};
`;

export const FieldError = styled.p`
  margin-top: ${rem(14)};
  margin-bottom: 0;
  font-size: 1rem;
  color: ${color('brand.error')};

  ${({ size }) => {
    if (size === 'small') {
      return css`
        font-size: ${fontSize(1)};
        margin-top: ${rem(4)};
      `;
    }

    return null;
  }}
`;
