import React, { useCallback, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import { ErrorMessage } from 'formik';
import cx from 'classnames';
import useFocusField from '../useFocusField';
import { isFieldError } from '../helpers';
import { formikFieldPropTypes, formikFormPropTypes } from '../propTypes';
import { FieldGroup, FieldLabel, FieldError, FieldHelperText } from '../styled';
import {
  InputWrapper,
  InputAdornmentEnd,
  StyledInput,
  StyledTextarea,
} from './styled';

const MASK_REPLACEMENT_REGEX = /[_x]/;

function applyMask(v, mask) {
  if (!v) {
    // Allow placeholder to show and not the empty mask
    return v;
  }

  let masked = mask;
  for (const char of v) {
    masked = masked.replace(MASK_REPLACEMENT_REGEX, char);
  }

  return masked;
}

function TextField({
  className,
  field: { onBlur, ...field },
  onFocus,
  form,
  label,
  labelVariant,
  helperText,
  adornmentEndAlign,
  renderAdornmentEnd,
  multiline,
  minRows,
  maxRows,
  onHeightChange,
  useCacheForDOMMeasurements,
  required,
  errorSize,
  paddingSize,
  borderSize,
  hideFieldError,
  normalize,
  mask,
  ignoreErrorStyle,
  onChange,
  ...props
}) {
  // Get the reference for the input field so that we can set the cursor position
  // on masked inputs.
  const inputRef = useRef(null);

  // Track the differences in values to help determine the cursor position on masked
  // input fields
  const [previousValue, setPreviousValue] = useState('');

  // Simulate input focus on the wrapper to include adornments.
  const { isFocused, handleFocus, handleBlur } = useFocusField({
    onBlur,
    onFocus,
  });
  const hasError = isFieldError(field, form);

  const inputWrapperProps = {
    borderSize,
    isFocused,
  };

  // Store the normalized form value instead of the actual value
  const { setFieldValue } = form;
  const handleChange = useCallback(
    e => {
      if (onChange) {
        onChange(e);
      }
      setFieldValue(
        e.target.name,
        normalize ? normalize(e.target.value) : e.target.value
      );
    },
    [setFieldValue, normalize, onChange]
  );

  // This is the value that will be displayed, not the one that will be submitted
  const value = mask ? applyMask(field.value, mask) : field.value;

  // If there is a mask applied to the field, we need to manually track the appropriate
  // cursor position, so that it doesn't stay at the end.
  useEffect(() => {
    if (
      inputRef.current &&
      mask &&
      previousValue !== field.value &&
      field.value
    ) {
      // Find the first character that differs from the previous value
      let cursor = 0;
      for (cursor = 0; cursor < field.value.length; cursor++) {
        if (field.value.charAt(cursor) !== previousValue.charAt(cursor)) {
          break;
        }
      }

      // If a character has been added, move the cursor past that character
      if (field.value.length > previousValue.length) {
        cursor++;
      }

      // Find the position of the first character difference after the mask has
      // been applied. This ensures that the cursor is always directly after a normalized
      // field value and not in a position where the user can not press backspace.
      const cursorPosition =
        applyMask(field.value.slice(0, cursor), mask).lastIndexOf(
          field.value.charAt(cursor - 1)
        ) + 1;
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);

      // Update the previous value state
      setPreviousValue(field.value);
    }
  }, [field.value, previousValue, mask]);

  const inputControlProps = {
    ...field,
    ...props,
    value,
    paddingSize,
    onBlur: handleBlur,
    onFocus: handleFocus,
    onChange: handleChange,
  };

  let InputControl = StyledInput;

  // Render multiline inputs as textarea.
  if (multiline) {
    const textareaProps = {
      as: TextareaAutosize,
      minRows,
      maxRows,
      onHeightChange,
      useCacheForDOMMeasurements,
    };

    Object.assign(inputControlProps, textareaProps);
    InputControl = StyledTextarea;
  }

  return (
    <FieldGroup
      className={cx(className, { 'has-error': hasError && !ignoreErrorStyle })}
      hasError={hasError}
    >
      {label && (
        <FieldLabel variant={labelVariant}>
          {label}
          {required && <> *</>}
        </FieldLabel>
      )}

      <InputWrapper
        {...inputWrapperProps} // eslint-disable-line react/jsx-props-no-spreading
      >
        <InputControl
          ref={inputRef}
          {...inputControlProps} // eslint-disable-line react/jsx-props-no-spreading
        />
        {renderAdornmentEnd && (
          <InputAdornmentEnd align={adornmentEndAlign}>
            {renderAdornmentEnd(field)}
          </InputAdornmentEnd>
        )}
      </InputWrapper>

      {helperText && <FieldHelperText>{helperText}</FieldHelperText>}

      {!hideFieldError && (
        <ErrorMessage
          component={FieldError}
          name={field.name}
          size={errorSize}
        />
      )}
    </FieldGroup>
  );
}

TextField.propTypes = {
  /** Formik field object. */
  field: formikFieldPropTypes.isRequired,
  /** Formik form object. */
  form: formikFormPropTypes.isRequired,
  /** Field label. */
  label: PropTypes.string,
  /** Apply alternate styling to the field label. */
  labelVariant: PropTypes.oneOf(['default', 'noMargin']),
  /** Supporting text placed underneath the field. */
  helperText: PropTypes.string,
  /** Vertical flexbox alignment for the end adornment. */
  adornmentEndAlign: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
  /** Render function to display content at the end of the field. */
  renderAdornmentEnd: PropTypes.func,
  /** Add required indicator to field label. */
  required: PropTypes.bool,
  /** Variant to change the amount of padding inside the field. */
  paddingSize: PropTypes.oneOf(['default', 'large']),
  /** Variant to change the input border width. */
  borderSize: PropTypes.number,
  /** Variant to change the size of error messages. */
  errorSize: PropTypes.oneOf(['default', 'small']),
  /** Prevent errors messages from rendering under field.  Useful for hiding errors on individual fields in a FieldArray. */
  hideFieldError: PropTypes.bool,
  /** Field focus event. */
  onFocus: PropTypes.func,
  /** Allow multiple lines using react-textarea-autosize component. */
  multiline: PropTypes.bool,
  /** For multiline: minimum number of rows to show for textarea. */
  minRows: PropTypes.number,
  /** For multiline: maximum number of rows upto which the textarea can grow. */
  maxRows: PropTypes.number,
  /** For multiline: use object cache when computing height of textarea. */
  useCacheForDOMMeasurements: PropTypes.bool,
  /** For multiline: function invoked on textarea height change, with height as first argument and React component instance (this) as second. */
  onHeightChange: PropTypes.func,
  /** Normalize function */
  normalize: PropTypes.func,
  /** Mask string */
  mask: PropTypes.string,
  /** Prevents error className from being applied to field when in error state */
  ignoreErrorStyle: PropTypes.bool,
  onChange: PropTypes.func,
};

TextField.defaultProps = {
  label: '',
  labelVariant: 'default',
  helperText: '',
  required: false,
  paddingSize: 'default',
  borderSize: 2,
  errorSize: 'default',
  hideFieldError: false,
  multiline: false,
  onFocus: () => {},
  adornmentEndAlign: 'flex-end',
  renderAdornmentEnd: null,
  minRows: 1,
  maxRows: 20,
  useCacheForDOMMeasurements: false,
  onHeightChange: () => {},
  normalize: undefined,
  mask: '',
  ignoreErrorStyle: false,
  onChange: () => {},
};

export default TextField;
