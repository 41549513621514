import mediaHelper from 'styled-media-helper';

export const breakpoints = {
  small: 480,
  medium: 640,
  large: 940,
  huge: 1240,
};

export default mediaHelper(breakpoints);
