import React from 'react';
import styled from 'styled-components';
import { rem } from 'theme/lib';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'theme/media';
import Guidelines from 'shared/components/Guidelines/Guidelines';

function AboutSidebarContent() {
  const canShowSidebar = useMediaQuery({
    query: `(min-width: ${breakpoints.large}px)`,
  });

  if (!canShowSidebar) {
    return null;
  }

  return (
    <StyledGuidelines>
      <Guidelines.Header>
        <Guidelines.Title>Bio Guidelines</Guidelines.Title>
        <Guidelines.Subtitle>
          Use the following as a guide for how to fill out your bio.
        </Guidelines.Subtitle>
      </Guidelines.Header>
      <Guidelines.Content>
        <Guidelines.Section>
          <Guidelines.SectionHeading />
          <Guidelines.List>
            <Guidelines.ListItem>
              Get specific about the types of people and companies you work
              with.
            </Guidelines.ListItem>
            <Guidelines.ListItem>
              Present yourself, but also transition this to speak directly to
              the seeker.
            </Guidelines.ListItem>
            <Guidelines.ListItem>
              If the company is international, impressive and recognizable,
              include it. Name drop!
            </Guidelines.ListItem>
            <Guidelines.ListItem>
              Since we don&apos;t base our algorithm on gender, we typically
              like to stay gender-neutral.
            </Guidelines.ListItem>
          </Guidelines.List>
        </Guidelines.Section>
        <Guidelines.Section>
          <Guidelines.SectionHeading variant="negative" />
          <Guidelines.List variant="negative">
            <Guidelines.ListItem>
              Don&apos;t try to reinvent the wheel. Keep it simple: borrow
              strong copy from your website, client testimonials, and LinkedIn.
            </Guidelines.ListItem>
            <Guidelines.ListItem>
              Keep in mind we are building a global platform. Please avoid
              including specific places when it isn&apos;t absolutely required.
            </Guidelines.ListItem>
          </Guidelines.List>
        </Guidelines.Section>
      </Guidelines.Content>
    </StyledGuidelines>
  );
}

AboutSidebarContent.propTypes = {};

AboutSidebarContent.defaultProps = {};

const StyledGuidelines = styled(Guidelines)`
  margin-top: ${rem(60)};
  margin-left: ${rem(30)};
  margin-right: ${rem(30)};
  height: 100%;
`;

export default AboutSidebarContent;
