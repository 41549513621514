import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useGuideProfile } from './queries';

export const SUBMIT_APPLICATION = gql`
  mutation SubmitApplication {
    submitApplication
  }
`;

export default function useSubmitApplication() {
  const profile = useGuideProfile();
  const refetchGuideProfile = profile.refetch;
  const [mutate, queryHandler] = useMutation(SUBMIT_APPLICATION);

  const submitApplication = useCallback(async () => {
    try {
      await mutate();

      // Refetch the guide profile because the application status should be updated now.
      // Placed here instead of a refetch query because of the user id dependence in fetching the guide profile,
      // and I thought this was more readable than managing the user id here.
      await refetchGuideProfile().catch(() => {
        // Ignore
      });
    } catch (err) {
      // Do nothing.
      // Error is contained in query handler.
    }
  }, [mutate, refetchGuideProfile]);

  return [submitApplication, queryHandler];
}
