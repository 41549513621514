import React from 'react';
import styled from 'styled-components/macro';
import CarouselSlider from 'shared/components/Carousel/CarouselSlider';
import CarouselSlide from 'shared/components/Carousel/CarouselSlide';
import { color, rem } from 'theme/lib';
import {
  NameAndFocus,
  Name,
  List,
  FieldFocusItem,
} from 'shared/components/ProfilePreview/styled';
import useProfilesForFocusSidebar from '../../hooks/useProfilesForFocusSidebar';

function FocusProfileCarousel() {
  const profiles = useProfilesForFocusSidebar();

  if (!profiles.length) {
    return null;
  }

  return (
    <StyledCarouselSliderWrapper>
      <CarouselTitle>Examples</CarouselTitle>
      <CarouselSlider>
        {profiles.map(profile => (
          <CarouselSlide key={profile.guide_id} src={profile.header_image}>
            <Overlay>
              <StyledNameAndFocus>
                <Name>{profile.first_name}</Name>
                {profile.fields && (
                  <List>
                    {profile.fields.map((focus, fieldIndex) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <FieldFocusItem key={fieldIndex}>{focus}.</FieldFocusItem>
                    ))}
                  </List>
                )}
              </StyledNameAndFocus>
            </Overlay>

            <CarouselSlide.Footer positive>
              {profile.fields && profile.fields.join(', ')}
            </CarouselSlide.Footer>
          </CarouselSlide>
        ))}
      </CarouselSlider>
    </StyledCarouselSliderWrapper>
  );
}

FocusProfileCarousel.propTypes = {};

FocusProfileCarousel.defaultProps = {};

const StyledCarouselSliderWrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

const CarouselTitle = styled.p`
  margin-bottom: ${rem(24)};
  font-size: ${rem(16)};
  font-weight: 500;
`;

const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    ${color('blacks.100')} 100%
  );
`;

const StyledNameAndFocus = styled(NameAndFocus)`
  padding: 0 ${rem(20)};
`;

export default FocusProfileCarousel;
