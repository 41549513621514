import styled, { css } from 'styled-components/macro';
import { color, rem } from 'theme/lib';
import media from 'theme/media';
import { percentage } from 'shared/utils/number';

// The layout wrapper is used to constrain the dimensions of the layout so
// it can maintain the appropriate aspect ratio.
const BaseLayoutWrapper = styled.div`
  margin: auto;
  width: 100%;
  /* Leave room for TextFileControls on mobile screens. */
  padding-bottom: ${rem(40)};

  ${media.up('medium')} {
    padding-bottom: 0;
  }
`;

export const PortraitLayoutWrapper = styled(BaseLayoutWrapper)`
  max-width: ${rem(410)};
  max-height: ${rem(658)};
  height: 100%;
`;

export const LandscapeLayoutWrapper = styled(BaseLayoutWrapper)``;

// The layout is the dropzone area.  After the image finishes uploading, it becomes
// the background image of this dropzone.
const BaseLayout = styled.div`
  /* Override .dzu-dropzone styles */
  && {
    position: relative;
    text-align: center;
    border: 0;
    border-radius: ${({ orientation }) =>
      orientation === 'landscape' ? rem(15) : rem(47)};
    overflow: visible; /* Allow PreviewContainer to be rendered outside these bounds. */

    ${media.up('medium')} {
      overflow: hidden;
    }

    /* Display the uploaded image. */
    ${({ uploadedImage }) =>
      uploadedImage &&
      css`
        background: url('${uploadedImage}') center center no-repeat;
        background-size: cover;
        min-height: auto;
      `}

    /**
     * HACK: Apply the dropzone border using a pseudo-element because it allows
     * us to change the border width without affecting the dimensions of the dropzone.
     */
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: ${({ orientation }) =>
        orientation === 'landscape' ? rem(15) : rem(47)};
      border: 2px dashed ${color('link')};
      z-index: 11;
      pointer-events: none;

      ${({ uploadedImage }) =>
        uploadedImage &&
        css`
          border-width: 0;
        `}

      &.dzu-dropzoneActive {
        border-color: ${color('brand.primary')};
      }
    }

    /* Overlay displayed when a file is dragged into dropzone. */
    &::after {
      ${media.up('medium')} {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        background-color: ${color('link')};
        transition: opacity 0.2s;
        z-index: 10;
        pointer-events: none;

        ${({ uploadedImage }) =>
          uploadedImage &&
          css`
            background-color: ${color(
              'blacks.100'
            )} !important; /* Override .dzu-dropzoneActive */
            opacity: 0.6;
          `}
      }
    }

    &.dzu-dropzoneActive {
      background-color: transparent;

      &::after {
        opacity: ${({ uploadedImage }) => (uploadedImage ? '0.7' : '0.1')};
      }
    }
  }
`;

// The components for `input` and <PreviewContainer> use absolute positioning
// so these layout orientations use the padding-bottom hack for maintaining
// dropzone aspect ratio.

const instructionsDefaultPaddingBottom = percentage(160, 290); // Smaller view for dropzone with the instructions.
const portraitDefaultPaddingBottom = percentage(350, 290); // Smaller view of the portrait dropzone after image is uploaded.
const portraitMediumPaddingBottom = percentage(658, 410); // Larger view of portrait dropzone.
const landscapeDefaultPaddingBottom = percentage(410, 658); // Smaller view of landscape dropzpone after image is uploaded.
const landscapeMediumPaddingBottom = landscapeDefaultPaddingBottom; // larger view of landscape dropzone.

export const PortraitLayout = styled(BaseLayout)`
  height: 0;
  width: 100%;
  padding-bottom: ${instructionsDefaultPaddingBottom};

  ${media.up('medium')} {
    padding-bottom: ${portraitMediumPaddingBottom};
  }

  &.has-image-upload {
    padding-bottom: ${portraitDefaultPaddingBottom};

    ${media.up('medium')} {
      padding-bottom: ${portraitMediumPaddingBottom};
    }
  }
`;

export const LandscapeLayout = styled(BaseLayout)`
  height: 0;
  width: 100%;
  padding-bottom: ${instructionsDefaultPaddingBottom};

  ${media.up('medium')} {
    padding-bottom: ${landscapeMediumPaddingBottom};
  }

  &.has-image-upload {
    padding-bottom: ${landscapeDefaultPaddingBottom};

    ${media.up('medium')} {
      padding-bottom: ${landscapeMediumPaddingBottom};
    }
  }
`;

/**
 * This container positions the preview content relative to the layout, depending
 * on device size and the upload progress state.
 */
export const PreviewContainer = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  z-index: 20;

  /* Mobile screens display the file controls below the layout photo background. */
  ${media.down('small')} {
    ${({ isUploadFinished }) =>
      isUploadFinished &&
      css`
        top: auto;
        bottom: 0;
        transform: translateY(100%);
      `}
  }
`;
