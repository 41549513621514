/* eslint-disable import/prefer-default-export */
export const PERFORMANCE_INDICATORS = [
  'Post-booking ratings by Seekers.',
  'Meet to Session conversion percentage.',
  // 'Average weekly bookable availability tba',
  '100% session attendance and timeliness.',
  'Prompt and proactive in-app engagement (pre & post-booking follow-up using messaging).',
  'Communication with Sphere (high-levels of responsiveness in all communication with SphereHQ).',
  'Community endorsement (referring Guides, linking and engaging with Sphere on social feeds).',
];
