// Currently supported country codes
export const COUNTRY_CODE_CANADA = 'CA';
export const COUNTRY_CODE_AMERICA = 'US';

export const FAMILY_STATUS_SINGLE = 'single';
export const FAMILY_STATUS_HAS_CHILDREN = 'has-children';
export const FAMILY_STATUS_CARETAKER = 'caretaker';
export const FAMILY_STATUS_COMMITTED = 'partnership';
export const FAMILY_STATUS_OTHER = 'other';

export const FAMILY_STATUS_OPTIONS_LABELS = {
  [FAMILY_STATUS_SINGLE]: 'Single',
  [FAMILY_STATUS_HAS_CHILDREN]: 'Have Children',
  [FAMILY_STATUS_CARETAKER]: 'Caretaker',
  [FAMILY_STATUS_COMMITTED]: 'In a committed partnership',
  [FAMILY_STATUS_OTHER]: 'Other',
};
