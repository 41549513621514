import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components/macro';
import { rem } from 'theme/lib';
import media from 'theme/media';
import Button from 'shared/components/Button/Button';
import ErrorMessage from 'shared/components/ErrorMessage/ErrorMessage';
import TextField from 'shared/components/formik/TextField/TextField';
import passwordSchema from 'shared/validators/passwordSchema';
import PasswordStrengthMeter from '../PasswordStrengthMeter/PasswordStrengthMeter';

const validationSchema = Yup.object().shape({
  newPassword: passwordSchema.required('Required'),
  confirmPassword: Yup.string()
    // eslint-disable-next-line func-names
    .test('match', 'Passwords do not match', function(confirmPassword) {
      return confirmPassword === this.parent.newPassword;
    })
    .required('Required'),
});

const StyledForm = styled(Form)`
  margin-top: ${rem(36)};
  flex-direction: column;
  display: flex;
  justify-content: center;
`;

const PasswordField = styled(TextField)`
  margin-bottom: ${rem(12)};
`;

const ConfirmField = styled(TextField)`
  margin-top: ${rem(40)};
`;

const ButtonToolbar = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;

  ${media.up('medium')} {
    flex-direction: row;
  }
`;

const CancelButton = styled(Button)`
  margin-top: ${rem(20)};
  order: 1;
  ${media.up('medium')} {
    order: 0;
    margin-top: ${rem(0)};
    margin-right: ${rem(30)};
  }
`;

const initialValues = {
  newPassword: '',
  confirmPassword: '',
};

export default function UpdatePasswordForm({
  onSubmit,
  onCancel,
  loading,
  error,
}) {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ isValid, values: { newPassword } }) => {
        const isSubmitDisabled = !isValid || loading;
        return (
          <StyledForm>
            <Field
              name="newPassword"
              placeholder="New Password"
              type="password"
              component={PasswordField}
              autoFocus
            />
            <PasswordStrengthMeter password={newPassword} />

            <Field
              name="confirmPassword"
              placeholder="Confirm new password"
              type="password"
              component={ConfirmField}
            />

            <ButtonToolbar>
              <CancelButton
                shape="pill"
                type="button"
                variant="outlined"
                onClick={onCancel}
              >
                Cancel
              </CancelButton>
              <Button shape="pill" type="submit" disabled={isSubmitDisabled}>
                Save
              </Button>
            </ButtonToolbar>
            <ErrorMessage error={error} />
          </StyledForm>
        );
      }}
    </Formik>
  );
}

UpdatePasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.instanceOf(Error),
};

UpdatePasswordForm.defaultProps = {
  loading: false,
  error: undefined,
};
