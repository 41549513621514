import { joinRoutes } from './utils/string';

const introBase = '/intro';
const questionnaireBase = '/questionnaire';
const summaryBase = '/summary';
const photosBase = '/photos';
const aboutYouBase = '/about';
const credentialsBase = '/credentials';
const applicationApprovedBase = '/approved';

function makePath(base, child) {
  return (fullPath = false) => {
    return fullPath ? joinRoutes(base, child) : child;
  };
}

export default {
  login: '/login',
  verify: '/verify',
  signup: '/signup',
  forgotPassword: '/forgot-password',
  updatePassword: '/update-password',
  error: '/error',
  applicationStatus: '/status',
  cancelApplication: '/cancel-application',
  applicationApprovedBase,
  introBase,
  questionnaireBase,
  summaryBase,
  photosBase,
  aboutYouBase,
  credentialsBase,
  questionnaire: {
    age: makePath(questionnaireBase, '/age'),
    yearsCoaching: makePath(questionnaireBase, '/yearsCoaching'),
    clientsCoached: makePath(questionnaireBase, '/clients'),
    describePractice: makePath(questionnaireBase, '/practice'),
    describeClientele: makePath(questionnaireBase, '/clientele'),
    familyStatus: makePath(questionnaireBase, '/family'),
    keyword: makePath(questionnaireBase, '/category'),
    lifestyle: makePath(questionnaireBase, '/lifestyle'),
    communicationStyle: makePath(questionnaireBase, '/style'),
    diversity: makePath(questionnaireBase, '/diversity'),
    references: makePath(questionnaireBase, '/references'),
    contactInformation: makePath(questionnaireBase, '/contact'),
  },
  photos: {
    feature: makePath(photosBase, '/feature'),
    secondary: makePath(photosBase, '/secondary'),
  },
  aboutYou: {
    bio: makePath(aboutYouBase, '/bio'),
    focus: makePath(aboutYouBase, '/focus'),
    outcomes: makePath(aboutYouBase, '/outcomes'),
    experience: makePath(aboutYouBase, '/exp'),
    approach: makePath(aboutYouBase, '/approach'),
  },
  credentials: {
    certifications: makePath(credentialsBase, '/certifications'),
    testimonials: makePath(credentialsBase, '/testimonials'),
  },
  applicationApproved: {
    codeOfConduct: makePath(applicationApprovedBase, '/conduct'),
    calendar: makePath(applicationApprovedBase, '/calendar'),
    creditCard: makePath(applicationApprovedBase, '/card'),
    signature: makePath(applicationApprovedBase, '/signature'),
    gearingup: makePath(applicationApprovedBase, '/gearingup'),
    download: makePath(applicationApprovedBase, '/download'),
  },
};
