import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { percentage } from 'shared/utils/number';
import useRectAfterWindowResize from 'shared/hooks/useRectAfterWindowResize';
import { color, rem, zIndex } from 'theme/lib';
import media from 'theme/media';

const breakpoints = ['small', 'medium', 'large', 'huge'];

// Default sidebar widths for each layout breakpoint, as they are defined in the design.
const defaultBreakpointWidth = [null, 368, 370, 572];

// These denomonators are the sidebar width of the breakpoint layout in the design,
// minus horizontal padding. This allows us to use widths as they are defined in the
// design to create sidebars that scale proportionally as fluid-width.
const breakpointWidthDenomonators = [null, 720, 930, 1275];

// Calculate the width of the sidebar as a percentage of usable layout for each breakpoint.
function getBreakpointWidthPercentages(
  customWidths,
  defaultWidths = defaultBreakpointWidth
) {
  let breakpointWidths = defaultWidths;

  if (Array.isArray(customWidths)) {
    if (customWidths.length === defaultWidths.length) {
      breakpointWidths = customWidths;
    } else {
      console.warn(
        `SplitLayout.Sidebar "width" prop does not meet array length requirements (${defaultWidths.length}).`
      );
    }
  }

  return breakpointWidths.map((w, idx) =>
    w ? percentage(w, breakpointWidthDenomonators[idx]) : null
  );
}

/**
 * This layout requires JavaScript to achieve fluid-width fixed position by
 * recalculating the width of the sidebar when the window is resized and applying
 * it explicitly to the fixed position child.
 */
function Sidebar({
  className,
  bg,
  enableScroll,
  width,
  fixedContent,
  children,
}) {
  const sidebarRef = useRef(null);
  const sidebarRect = useRectAfterWindowResize(sidebarRef);

  return (
    <StyledSidebar
      className={className}
      ref={sidebarRef}
      bg={bg}
      widthPercentages={getBreakpointWidthPercentages(width)}
    >
      {fixedContent ? (
        <FixedPosition width={sidebarRect.width} enableScroll={enableScroll}>
          {children}
        </FixedPosition>
      ) : (
        children
      )}
    </StyledSidebar>
  );
}

Sidebar.propTypes = {
  /** Enable the background color that extends to the right edge of viewport */
  bg: PropTypes.bool,
  /** Enable vertical scrolling */
  enableScroll: PropTypes.bool,
  /**
   * Array of sidebar widths for each responsive breakpoint.
   * Refer to code comments for notes about defining widths.
   */
  width: PropTypes.arrayOf(PropTypes.number),
  fixedContent: PropTypes.bool,
  children: PropTypes.node,
};

Sidebar.defaultProps = {
  bg: false,
  enableScroll: true,
  width: defaultBreakpointWidth,
  fixedContent: false,
  children: null,
};

const StyledSidebar = styled.div`
  position: relative;
  display: none;
  z-index: ${zIndex('sidebar')};

  ${media.up('medium')} {
    display: block;
  }

  ${({ widthPercentages }) => {
    // Generate media queries to define the width of the sidebar as a percentage
    // of the usable layout (which excludes horizontal padding).
    return widthPercentages.map((width, idx) => {
      if (width) {
        return css`
          ${media.up(breakpoints[idx])} {
            max-width: ${width};
            width: 100%;
          }
        `;
      }
      return null;
    });
  }}

  ${({ bg }) =>
    bg &&
    css`
      background-color: ${color('offWhite')};

      ${media.up('large')} {
        &::after {
          position: fixed;
          top: 0;
          width: 100vw;
          height: 100%;
          content: '';
          display: block;
          background-color: ${color('offWhite')};
          z-index: -1;
        }
      }
    `}
`;

const FixedPosition = styled.div`
  position: fixed;
  width: ${({ width }) => rem(width)};
  height: 100%;
  overflow-y: hidden;

  ${({ enableScroll }) =>
    enableScroll &&
    css`
      overflow-y: auto;
    `}

  /**
   * HACK: The height of this sidebar needs to offset the height of the header
   * when it is nested inside HeaderLayout to calculate the correct scroll height.
   */
  .header-layout & {
    height: calc(100% - ${rem(100)});
  }
`;

export default Sidebar;
