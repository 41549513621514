import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ApolloError } from 'apollo-client';
import { color, fontSize } from 'theme/lib';

// const SEEKER_ERROR = 'Seeker_Error';
const CUSTOM_ERROR_MESSAGES = {
  'Invalid Credentials.': 'Incorrect email address or password',
  'Seeker Error': `This email already exists`,

  // This is the error return when resetting the password on an email that doesn't exist
  'An error occured when requesting to reset password':
    "We don't seem to have any record of that email address in our system",
};

function ErrorMessage({ error }) {
  if (!error) {
    return null;
  }

  let { message } = error;
  if (error instanceof ApolloError) {
    if (error.networkError) {
      // This will happen anytime an ApolloLink returns an error. Most likely means
      // that the network is unavailable, or the backend is down. Also happens if the server
      // returns a non 200 status code.
      message = 'A network error has ocurred.';
    } else if (error.extraInfo?.seekerError) {
      message = get(
        CUSTOM_ERROR_MESSAGES,
        'Seeker Error',
        'Account Type Error'
      );
    } else {
      // Any sort of GraphQL Error.
      message = get(
        error,
        'graphQLErrors[0].message',
        'An unknown error has occurred'
      );
    }
  }
  // Apply custom error message overrides
  message = CUSTOM_ERROR_MESSAGES[message] || message;
  return <ErrorText>{message}</ErrorText>;
}

ErrorMessage.propTypes = {
  error: PropTypes.instanceOf(Error),
};

ErrorMessage.defaultProps = {
  error: null,
};

const ErrorText = styled.h3`
  color: ${color('brand.error')};
  font-size: ${fontSize(3)};
  font-weight: 600;
`;

export default React.memo(ErrorMessage);
