import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem, color } from 'theme/lib';
import { NameAndFocus } from './styled';

const PROFILE_PHOTO_SIZE = [497, 986];

const ProfilePreviewPhoto = ({ image, children }) => (
  <Photo image={image}>
    <NameAndFocus>{children}</NameAndFocus>
  </Photo>
);

const Photo = styled.div`
  position: relative;
  padding: 0 ${rem(20)}
    ${(PROFILE_PHOTO_SIZE[1] / PROFILE_PHOTO_SIZE[0]) * 100}% ${rem(20)};
  background-image: linear-gradient(
      0deg,
      ${color('blacks.100')} 1%,
      rgba(255, 255, 255, 0) 30%
    ),
    url(${props => props.image});
  background-position: 0, center;
  background-size: cover, cover;
`;

ProfilePreviewPhoto.propTypes = {
  image: PropTypes.string,
  children: PropTypes.node,
};

ProfilePreviewPhoto.defaultProps = {
  image: '',
  children: '',
};

export default ProfilePreviewPhoto;
