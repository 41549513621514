/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useCallback } from 'react';
import styled from 'styled-components';
import routes from 'shared/routes';
import { rem } from 'theme/lib';
import get from 'lodash/get';
import media from 'theme/media';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import Button from 'shared/components/Button/Button';
import ProgressBar from 'shared/components/ProgressBar/ProgressBar';
import WaitForExternalData from 'shared/components/WaitForExternalData/WaitForExternalData';
import makeWizardNavigator, { layoutPropTypes } from 'shared/wizardNavigation';
import { useCurrentUser } from 'auth/hooks/queries';
import { CODE_OF_CONDUCT_QUESTIONS } from '../../constants';
import CodeOfConductContext from '../../CodeOfConductContext';
import ConductRuleWrapper from '../Questions/ConductRuleWrapper';
import Footer from '../Footer/Footer';
import sidebarImage1x from './sidebar.jpg';
import sidebarImage2x from './sidebar@2x.jpg';
import sidebarImage3x from './sidebar@3x.jpg';
import {
  ConductLayout,
  ContentContainer,
  ContentWrapper,
  ImageContainer,
  StyledImg,
  ProgressWrapper,
  ProgressSummary,
} from './styled';

const screens = CODE_OF_CONDUCT_QUESTIONS.map((q, idx) => {
  return {
    route: String(idx + 1),
    Content: props => <ConductRuleWrapper question={q} {...props} />,
  };
}).concat([
  {
    route: routes.applicationApproved.creditCard(),
    Content: props => (
      <ConductRuleWrapper showInstruction={false} creditCard {...props} />
    ),
  },
]);

function LayoutComponent({ content, localStep, goBack, goNext }) {
  const currentUser = useCurrentUser();
  const { lastAcceptedRule, canContinue, signAgreement } = useContext(
    CodeOfConductContext
  );
  const hasAcceptedRule = lastAcceptedRule >= localStep;
  const totalConductRules = CODE_OF_CONDUCT_QUESTIONS.length;
  const progressPercent = (localStep / totalConductRules) * 100;
  const showConductProgress = localStep <= totalConductRules;

  const hasAddedCard = currentUser.data
    ? Boolean(get(currentUser, 'data.paymentMethods').length)
    : false;
  const creditCardStep = content.find(item => item.key === '/card');
  const isCreditCardStep = content.indexOf(creditCardStep) === localStep - 1;
  const disabled = !hasAddedCard;

  const handleContinue = useCallback(() => {
    if (localStep > totalConductRules) {
      signAgreement();
    }

    goNext();
  }, [goNext, localStep, signAgreement, totalConductRules]);

  return (
    <SplitLayout showLogo={false}>
      <StyledSplitMain>
        <SplitLayout.MainContent>
          <WaitForExternalData external={currentUser}>
            <ConductLayout>
              <ContentContainer>
                <ContentWrapper>
                  {content}

                  {/* Have the progress defined here instead of the wrapping component so that the css transitions work. */}
                  {showConductProgress && (
                    <ProgressWrapper>
                      <ProgressBar percent={progressPercent} size="small" />
                      <ProgressSummary>
                        {localStep}/{totalConductRules}
                      </ProgressSummary>
                    </ProgressWrapper>
                  )}
                </ContentWrapper>
              </ContentContainer>
              <StyledImageContainer>
                <StyledImg
                  src={sidebarImage1x}
                  srcSet={`${sidebarImage1x} 1x, ${sidebarImage2x} 2x, ${sidebarImage3x} 3x`}
                  alt="CodeOfConduct"
                />
              </StyledImageContainer>
            </ConductLayout>
          </WaitForExternalData>
        </SplitLayout.MainContent>
      </StyledSplitMain>
      <Footer goBack={goBack}>
        <Button
          shape="pill"
          onClick={handleContinue}
          disabled={
            isCreditCardStep ? disabled : !hasAcceptedRule && !canContinue
          }
        >
          Continue
        </Button>
      </Footer>
    </SplitLayout>
  );
}

const StyledSplitMain = styled(SplitLayout.Main)`
  padding-top: ${rem(40)};
`;

const StyledImageContainer = styled(ImageContainer)`
  max-width: 400px;

  ${media.up('large')} {
    max-width: none;
  }
`;

LayoutComponent.propTypes = layoutPropTypes;

export default makeWizardNavigator({
  entryRoute: routes.applicationStatus,
  exitRoute: routes.applicationApproved.calendar(true),
  screens,
  Layout: LayoutComponent,
});
