import { ApolloLink, Observable } from 'apollo-link';
import firebase from '../firebaseConfig';

/**
 * Register an observer to find out when the auth state has been initialized.
 * No requests should be allowed to go through, until then. This is required we
 * do not want the user to have requests be unauthorized while firebase is still
 * in the progress of initalizing the auth state of the app. In that case, some
 * resources would fail to load whenever the user refreshes the page. Storing
 * the token in local storage would solve most cases, but fail when user returns
 * to the site with an expired token.
 *
 * We delay the resolution of API requests over delaying an initial render, to
 * have minimal impact on the user.
 */
export default class AuthorizationLink extends ApolloLink {
  constructor() {
    super();

    // Track whether or not the authorization state has been initialized
    this.initialized = false;

    // Track the requests that are waiting for initialization to complete
    this.pendingRequests = [];

    const unsubscribe = firebase.auth().onAuthStateChanged(() => {
      // Unsubscribe from the auth state observer.
      unsubscribe();

      // Allow future requests to pass through without waiting
      this.initialized = true;

      // Resolve any pending requests
      this.pendingRequests.forEach(cb => cb());
    });
  }

  async getIdToken() {
    if (!this.initialized) {
      // Wait for the auth state to be initialized before continuing
      await new Promise(resolve => {
        this.pendingRequests.push(resolve);
      });
    }

    const user = firebase.auth().currentUser;
    let token = '';
    if (user) {
      try {
        // This will automatically handle refreshing access tokens.
        token = await user.getIdToken();
      } catch (err) {
        console.error('Error fetching the id token from firebase: ', err);
      }
    }

    return token;
  }

  request(operation, forward) {
    return new Observable(observer => {
      let handle;
      this.getIdToken().then(token => {
        const { headers } = operation.getContext();
        operation.setContext({
          headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : '',
          },
        });

        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      });

      return () => {
        if (handle) {
          handle.unsubscribe();
        }
      };
    });
  }
}
