import styled from 'styled-components/macro';
import { rem, zIndex } from 'theme/lib';
import media from 'theme/media';

const contentBlockerConfig = {
  default: {
    height: 59,
  },
  medium: {
    height: 90,
  },
};

export const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: ${rem(40)};

  ${media.up('medium')} {
    padding-top: calc(${rem(contentBlockerConfig.medium.height)} + 4vh);
    margin-right: ${rem(47)};
  }

  ${media.up('large')} {
    flex-flow: row nowrap;
    padding-top: calc(${rem(contentBlockerConfig.medium.height)} + 9.5vh);
    margin-right: ${rem(70)};
  }
`;

export const FormContainer = styled.div`
  position: relative;
  flex: 1 1 50%;
  z-index: ${zIndex('base')};
  display: flex;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  position: relative;
  flex: 1 1 50%;
  padding-top: ${rem(60)};
  max-width: ${rem(540)};
  text-align: center;
  z-index: ${zIndex('fixedHeader', 10)};

  ${media.up('large')} {
    max-width: none;
    padding-top: 0;
  }
`;

export const StyledImg = styled.img`
  max-width: ${rem(400)};
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;

  ${media.up('large')} {
    max-width: none;
    margin-bottom: ${rem(60)};
  }
`;
