/* eslint-disable no-param-reassign */
import get from 'lodash/get';
import {
  QUESTIONNAIRE_SCREENS,
  PROFILE_PHOTO_SCREENS,
  CERTIFICATION_SCREENS,
  ABOUT_YOU_SCREENS,
} from 'navigationConfig';
import routes from 'shared/routes';
import { joinRoutes } from 'shared/utils/string';

const fieldGroupings = {
  questionnaire: {
    baseRoute: routes.questionnaireBase,
    screens: QUESTIONNAIRE_SCREENS,
  },
  profilePhotos: {
    baseRoute: routes.photosBase,
    screens: PROFILE_PHOTO_SCREENS,
  },
  aboutYou: {
    baseRoute: routes.aboutYouBase,
    screens: ABOUT_YOU_SCREENS,
  },
  credentials: {
    baseRoute: routes.credentialsBase,
    screens: CERTIFICATION_SCREENS,
  },
};

const defaultMilestoneProgress = {
  __typename: 'MilestoneProgress',
  progress: 0,
  launchRoute: null,
};

// Define what values are considered 'missing/empty';
function isMissing(v) {
  if (v === null || v === undefined || v === '') {
    return true;
  }

  // Empty array
  if (Array.isArray(v) && !v.length) {
    return true;
  }

  // Empty object
  if (typeof v === 'object' && !Object.keys(v).length) {
    return true;
  }

  return false;
}

export default {
  // Calculated Properties
  Guide: {
    applicationProgress: guideProfile => {
      if (!guideProfile) {
        return null;
      }

      return Object.keys(fieldGroupings).reduce(
        (acc, milestone) => {
          const { baseRoute } = fieldGroupings[milestone];
          const screensWithData = fieldGroupings[milestone].screens.filter(
            step => !!step.fields
          );
          const totalScreens = screensWithData.length;

          // No data requirement for this milestone, return the default progress
          if (totalScreens === 0) {
            return acc;
          }

          // Go through each screen in the milestone to calculate the progress
          const { incomplete, launchRoute } = screensWithData.reduce(
            (accumulated, step) => {
              // Handle one or many fields per screen
              const dataFields = !Array.isArray(step.fields)
                ? [step.fields]
                : step.fields;

              for (const fieldName of dataFields) {
                if (
                  isMissing(get(guideProfile, fieldName)) && // Check the field
                  isMissing(get(guideProfile, `other.${fieldName}`)) // Maybe the 'other' option is filled out
                ) {
                  // This screen has missing required data
                  accumulated.incomplete++;

                  // Find the first screen with missing data. This is where the user
                  // can continue from.
                  if (!accumulated.launchRoute) {
                    accumulated.launchRoute = joinRoutes(baseRoute, step.route);
                  }
                  break;
                }
              }

              return accumulated;
            },
            {
              incomplete: 0,
            }
          );

          acc[milestone] = {
            __typename: 'MilestoneProgress',
            progress: Math.round(
              ((totalScreens - incomplete) / totalScreens) * 100
            ),

            // Default the launch route to the first step
            launchRoute:
              launchRoute || joinRoutes(baseRoute, screensWithData[0].route),
          };
          return acc;
        },
        {
          __typename: 'ApplicationProgress',
          questionnaire: defaultMilestoneProgress,
          profilePhotos: defaultMilestoneProgress,
          aboutYou: defaultMilestoneProgress,
          credentials: defaultMilestoneProgress,
        }
      );
    },
  },
  GuideProfile: {
    // Convert certs from guideInfo to be similar to fullCerts from guide
    fullCerts: ({ certs }) => {
      if (!certs) {
        return null;
      }

      return certs.map(c => ({
        __typename: 'Cert',
        id: '',
        url: '',
        name: c,
      }));
    },

    // Convert testimonials from guideInfo to be similar to allTestimonials from guide
    allTestimonials: ({ testimonials }) => {
      if (!testimonials) {
        return null;
      }

      return [testimonials];
    },
  },
};
