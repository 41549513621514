import { createGlobalStyle } from 'styled-components';

/**
 * Define styles for elements outside the scope of components to achieve the
 * full screen layouts.
 */
const GlobalLayoutStyles = createGlobalStyle`
  html {
    min-height: 100%;
  }

  body {
    min-height: 100vh;
  }

  #root {
    min-height: 100vh;
  }
`;

export default GlobalLayoutStyles;
