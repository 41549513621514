import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { rem } from 'theme/lib';
import styled from 'styled-components';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import { H3 } from 'shared/components/styled/typography';
import useStripe from '../../hooks/useStripe';
import CodeOfConductContext from '../../CodeOfConductContext';
import useProtectCodeOfConduct from '../../hooks/useProtectCodeOfConduct';
import ConductRule from './ConductRule';
import CreditCard from './CreditCard';
import { Content, Instruction } from './styled';

const RULE_CHANGE_DELAY = 250;
const animationVariants = {
  enter: () => ({
    opacity: 0,
    position: 'absolute',
  }),
  center: {
    zIndex: 1,
    opacity: 1,
    position: 'relative',
  },
  exit: () => ({
    zIndex: 0,
    opacity: 0,
    position: 'absolute',
  }),
};

function CodeOfConductWrapper({
  goNext,
  route,
  localStep,
  question,
  creditCard,
  showInstruction,
  title,
}) {
  // Make sure the previous rule has been accepted, otherwise redirect to the appropriate place
  useProtectCodeOfConduct(localStep - 1);
  const { setLastAcceptedRule, lastAcceptedRule } = useContext(
    CodeOfConductContext
  );
  const stripe = useStripe();
  const hasAcceptedRule = lastAcceptedRule >= localStep;

  const handleAcceptRule = useCallback(() => {
    setLastAcceptedRule(localStep);
    setTimeout(() => {
      goNext();
    }, RULE_CHANGE_DELAY);
  }, [localStep, goNext, setLastAcceptedRule]);

  return (
    <>
      <Heading>{title}</Heading>
      {showInstruction && (
        <>
          <Content>
            <p>
              We take Guidance seriously. In order to take care of our
              community, all Sphere Guides are required to stay in compliance
              with our Code of Conduct. As we curate an ecosystem of exceptional
              Guides, any breach of the following will result in an immediate
              review and risk of removal.
            </p>
          </Content>
          <Instruction>
            This is how we roll. Read this in full before signing:
          </Instruction>
        </>
      )}
      <AnimatePresence>
        <motion.div
          key={route}
          variants={animationVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ ease: 'easeOut', duration: 0.75 }}
        >
          {question && (
            <ConductRule
              step={localStep}
              question={question}
              accepted={hasAcceptedRule}
              onClick={handleAcceptRule}
            />
          )}

          {stripe && creditCard && <CreditCard stripe={stripe} />}
        </motion.div>
      </AnimatePresence>
    </>
  );
}

const Heading = styled(H3).attrs({
  as: 'h1',
})`
  margin-bottom: ${rem(5)};
`;

CodeOfConductWrapper.propTypes = {
  ...withNavigationPropTypes,
  question: PropTypes.string,
  creditCard: PropTypes.bool.isRequired,
  showInstruction: PropTypes.bool,
  title: PropTypes.string,
};

CodeOfConductWrapper.defaultProps = {
  question: '',
  showInstruction: true,
  title: 'Code of Conduct',
};

export default CodeOfConductWrapper;
