/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { H3 } from 'shared/components/styled/typography';
import Checklist from 'shared/components/Checklist/Checklist';
import { RoundedBox, List, CheckListItem } from './styled';

export default function ProfilePreviewList({
  title,
  items,
  transparentContainer,
  checklistItemColor,
}) {
  return (
    <div>
      <H3>{title}</H3>
      <List>
        <RoundedBox transparentContainer={transparentContainer}>
          {items && (
            <Checklist>
              {items.map((item, index) => (
                <CheckListItem key={index} checked bg={checklistItemColor}>
                  {item}
                </CheckListItem>
              ))}
            </Checklist>
          )}
        </RoundedBox>
      </List>
    </div>
  );
}

ProfilePreviewList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  transparentContainer: PropTypes.bool,
  checklistItemColor: PropTypes.string,
};

ProfilePreviewList.defaultProps = {
  title: '',
  items: [],
  transparentContainer: false,
  checklistItemColor: '',
};
