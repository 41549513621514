/* eslint-disable import/prefer-default-export */
import styled from 'styled-components/macro';

export const StyledLayout = styled.div`
  /* Override .dzu-dropzone styles */
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    min-height: auto;
    overflow: auto;

    /* Dropzone is not used for this component so disable drop indicators. */
    &.dzu-dropzoneActive {
      background-color: transparent;
    }
  }
`;
