import React from 'react';
import { Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { EmptyValueRejector } from 'shared/utils/yup';
import compact from 'lodash/compact';
import TextField from 'shared/components/formik/TextField/TextField';
import { FieldError } from 'shared/components/formik/styled';
import CharCountAdornment from 'shared/components/formik/CharCountAdornment/CharCountAdornment';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
  QuestionRequirement,
} from 'shared/components/WizardQuestionForm';
import { EXPERIENCE_MAX_LENGTH } from 'guideProfile/constants';

const MIN_FIELDS_REQUIRED = 3;

const validationSchema = Yup.object().shape({
  experience: Yup.array()
    .of(
      // Pass `true` as error string to trigger error but not render a message.
      Yup.string().max(EXPERIENCE_MAX_LENGTH, true)
    )
    .compact(EmptyValueRejector)
    .min(MIN_FIELDS_REQUIRED, 'Write at least ${min} experience statements'), // eslint-disable-line no-template-curly-in-string
});

const fieldPlaceholders = [
  'Facilitated over 250+ emotional intelligence workshops',
  'Work with organizations to increase impact for 20+ years',
  'Marathon runner & yoga instructor',
  'Board Member & over 1000+ hours of volunteer work',
];

// eslint-disable-next-line react/prop-types
const ExperienceField = ({ index }) => {
  return (
    <Field
      name={`experience.${index}`}
      type="text"
      component={TextField}
      placeholder={fieldPlaceholders[index]}
      maxLength={EXPERIENCE_MAX_LENGTH + 1}
      hideFieldError
      renderAdornmentEnd={({ value }) => (
        <CharCountAdornment value={value} limit={EXPERIENCE_MAX_LENGTH} />
      )}
    />
  );
};

export default function Experience(props) {
  return (
    <WizardQuestionForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValues={{
        experience: ['', '', '', ''],
      }}
      validationSchema={validationSchema}
      forceEnableSubmit
      continueInFooter
    >
      {({ touched }) => {
        // Prevent the field array error message from displaying until the
        // minimum number of required fields are touched.
        const isMinRequiredTouched =
          compact(touched.experience).length >= MIN_FIELDS_REQUIRED;

        return (
          <FormContainer>
            <FormContentHeader>
              <QuestionHeading as="h1">Your experience</QuestionHeading>

              <QuestionDescription>
                Consider some big achievements you&apos;ve made.
              </QuestionDescription>
            </FormContentHeader>

            <FormContent>
              <QuestionRequirement>
                Write at least {MIN_FIELDS_REQUIRED} experience statements
              </QuestionRequirement>
              <ExperienceField index={0} />
              <ExperienceField index={1} />
              <ExperienceField index={2} />
              <ExperienceField index={3} />

              {isMinRequiredTouched && (
                <ErrorMessage component={FieldError} name="experience" />
              )}
            </FormContent>
          </FormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

Experience.propTypes = withNavigationPropTypes;
