import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rem, color } from 'theme/lib';
import media from 'theme/media';
import ProgressBar from 'shared/components/ProgressBar/ProgressBar';

function StepBreadcrumbs({ className, progress }) {
  return (
    <StyledStepBreadcrumbs className={className}>
      {progress.map(item => (
        <StepContainer key={item.title}>
          <StyledProgressBar size="small" percent={item.progress} />
          <StyledText percent={item.progress}>{item.title}</StyledText>
        </StepContainer>
      ))}
    </StyledStepBreadcrumbs>
  );
}

StepBreadcrumbs.propTypes = {
  progress: PropTypes.arrayOf(
    PropTypes.shape({
      /** Percentage indication of each step completion  */
      progress: PropTypes.number.isRequired,

      /** Step Title  */
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

StepBreadcrumbs.defaultProps = {};

const StyledStepBreadcrumbs = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const StepContainer = styled.div`
  padding-right: ${rem(24)};

  &:nth-child(3) {
    flex-basis: ${rem(110)};
  }

  &:last-child {
    padding-right: 0;
  }

  ${media.up('small')} {
    &:nth-child(3) {
      flex-basis: ${rem(89)};
    }
  }
  ${media.up('medium')} {
    padding-right: ${rem(10)};
  }
`;

const StyledProgressBar = styled(ProgressBar)`
  min-width: ${rem(74)};

  ${media.up('small')} {
    min-width: ${rem(89)};
  }
`;

const StyledText = styled.p`
  white-space: nowrap;
  font-size: ${rem(12)};
  margin-top: ${rem(4)};
  color: ${color('grays.400')};

  ${media.up('medium')} {
    font-size: ${rem(13)};
  }

  ${({ percent }) =>
    percent === 100 &&
    css`
      color: ${color('brand.primary')};
    `}
`;

export default StepBreadcrumbs;
