import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem, color } from 'theme/lib';
import checkmark from 'assets/svg/primary-checkmark-filled.svg';

const CheckmarkList = ({ className, children }) => (
  <StyledCheckmarkList className={className}>{children}</StyledCheckmarkList>
);

CheckmarkList.propTypes = {
  children: PropTypes.node,
};

CheckmarkList.defaultProps = {
  children: null,
};

const StyledCheckmarkList = styled.ul`
  font-weight: 500;
  list-style-type: none;
  padding: 0;
`;

CheckmarkList.Item = styled.li`
  display: flex;
  align-items: flex-start;
  color: ${color('black')};
  font-weight: 500;
  margin-bottom: ${rem(40)};
  line-height: ${20 / 16};
  -webkit-font-smoothing: subpixel-antialiased;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    display: block;
    content: "";
    background-image: url('${checkmark}');
    background-repeat: no-repeat;
    min-width: 25px;
    height: ${rem(23)};
    margin-right: ${rem(8)};
  }
`;

export default CheckmarkList;
