import styled from 'styled-components';
import { rem } from 'theme/lib';
import { Form } from 'formik';
import Button from 'shared/components/Button/Button';
import TextField from 'shared/components/formik/TextField/TextField';

export const StyledTextField = styled(TextField)`
  margin-bottom: ${rem(0)};
  height: ${rem(60)};
`;

export const StyledButton = styled(Button)`
  margin-top: ${rem(36)};
`;

export const StyledForm = styled(Form)`
  margin-top: ${rem(35)};
`;

export const StyledLink = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.link};
  cursor: pointer;
  display: inline;
  padding: 0;
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;
