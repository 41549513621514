import PropTypes from 'prop-types';

const CertPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
});

const TestimonialPropType = PropTypes.shape({
  id: PropTypes.string,
  author: PropTypes.string,
  first_name: PropTypes.string,
  last_initial: PropTypes.string,
  text: PropTypes.string,
});

// eslint-disable-next-line import/prefer-default-export
export const ProfilePreviewPropType = {
  /** Without the name, we won't show user's profile preview */
  first_name: PropTypes.string.isRequired,
  /** Without the image, we won't show user's profile preview */
  header_image: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.string),
  bio: PropTypes.string,

  benefits: PropTypes.arrayOf(PropTypes.string),
  experience: PropTypes.arrayOf(PropTypes.string),
  fullCerts: PropTypes.arrayOf(CertPropType),

  communication_style: PropTypes.arrayOf(PropTypes.string),

  allTestimonials: PropTypes.arrayOf(TestimonialPropType),
};
