// Allow props spreading because this a wrapper component for react-compound-slider
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { rem, color } from 'theme/lib';
import {
  Slider as SliderCompound,
  Rail,
  Handles,
  Tracks,
} from 'react-compound-slider';
import SliderHandle from './SliderHandle';
import SliderTrack from './SliderTrack';

function Slider({
  className,
  min,
  max,
  step,
  onChange,
  onUpdate,
  value,
  ...props
}) {
  // Force the value into the proper domain
  const sliderValue = Math.min(Math.max(value, min), max);

  return (
    <SliderWrapper>
      <StyledSlider
        step={step}
        domain={[min, max]}
        onUpdate={onUpdate}
        onChange={onChange}
        values={[sliderValue]}
        {...props}
      >
        <Rail>{({ getRailProps }) => <StyledRail {...getRailProps()} />}</Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <>
              <HandlesWrapper>
                {handles.map(handle => (
                  <SliderHandle
                    key={handle.id}
                    handle={handle}
                    domain={[min, max]}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </HandlesWrapper>
            </>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <>
              {tracks.map(({ id, source, target }) => (
                <SliderTrack
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </>
          )}
        </Tracks>
      </StyledSlider>
    </SliderWrapper>
  );
}

Slider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  /** Initial value to render */
  value: PropTypes.number,
  /** Step value for the slider */
  step: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  /** Function called with the values at each update (eg: high-volume updates when dragging) */
  onUpdate: PropTypes.func,
};

Slider.defaultProps = {
  onUpdate: () => {},
  value: 0,
};

// This wrapper applies horizontal margins half the width of the handle so the
// handle always appears inset with the rail and tracks.
const HandlesWrapper = styled.div`
  position: relative;
  margin-left: ${rem(18)};
  margin-right: ${rem(18)};
`;

const StyledRail = styled.div`
  position: absolute;
  width: 100%;
  height: ${rem(3)};
  cursor: pointer;
  background-color: ${color('grays.300')};
`;

const StyledSlider = styled(SliderCompound)`
  position: relative;
  width: 100%;
`;

// This wrapper ensures that all of the elements inside this component are contained
// within this box it does not overlap adjacent components.
const SliderWrapper = styled.div`
  min-height: ${rem(58)};
  padding-top: ${rem(18)};
`;

export default Slider;
