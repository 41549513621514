import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'theme/media';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import { H1 } from 'shared/components/styled/typography';
import CheckmarkList from 'shared/components/CheckmarkList/CheckmarkList';
import useLogout from 'auth/hooks/useLogout';
import Footer from '../Footer/Footer';
import ImportantRead from './ImportantRead';
import downloadImage from './download.jpg';
import downloadImage2x from './download@2x.jpg';
import downloadImage3x from './download@3x.jpg';
import AppleDownloadImage from './apple-download.svg';
import GoogleDownloadImage from './google-download.svg';
import {
  FAQ_LINK,
  LEARN_LINK,
  APP_STORE_LINK,
  GOOGLE_PLAY_LINK,
} from '../../constants';
import {
  StyledSidebar,
  Apps,
  HorizontalRule,
  Subheading,
  RestrictedContainer,
  ContentFooter,
  StyledSplitLayoutMain,
  StyledSplitLayoutMainContent,
  SecondaryContentContainer,
  ImageContainer,
  Image,
  Link,
  LinkImages,
} from './styled';

const TASK_LIST = [
  'Set your availability',
  'Check your timezone',
  'Integrate your calendar',
  'Ensure you’ve enabled notifications',
];

const Download = ({ goBack }) => {
  const logout = useLogout();
  const isMediumBreakpoint = useMediaQuery({
    query: `(min-width: ${breakpoints.medium}px)`,
  });
  return (
    <SplitLayout showLogo={false}>
      <StyledSplitLayoutMain>
        <StyledSplitLayoutMainContent>
          <RestrictedContainer>
            <H1>Final Steps:</H1>
            <Subheading>
              Download the App, Sync Your Calendar & Set Your Availability
            </Subheading>
            <Apps>
              <Link href={APP_STORE_LINK}>
                <LinkImages
                  src={AppleDownloadImage}
                  alt="Download on the App Store"
                />
              </Link>
              <Link href={GOOGLE_PLAY_LINK}>
                <LinkImages
                  src={GoogleDownloadImage}
                  alt="Get it on Google Play"
                />
              </Link>
            </Apps>
            <CheckmarkList>
              {TASK_LIST.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <CheckmarkList.Item key={i}>{item}</CheckmarkList.Item>
              ))}
            </CheckmarkList>
          </RestrictedContainer>
          <Subheading style={{ marginTop: 20 }}>
            Once you complete the steps above, we’ll book an in app walk-through
            with you within 7 days.
          </Subheading>
          {!isMediumBreakpoint && (
            <SecondaryContentContainer.Default>
              <ImportantRead link={FAQ_LINK} />
            </SecondaryContentContainer.Default>
          )}
          <HorizontalRule />
          <ContentFooter>
            <p>
              Have more questions?{' '}
              <a href={LEARN_LINK} target="_blank" rel="noopener noreferrer">
                Learn more about how Sphere operates
              </a>
            </p>
          </ContentFooter>
        </StyledSplitLayoutMainContent>
      </StyledSplitLayoutMain>
      {isMediumBreakpoint && (
        <StyledSidebar>
          <SecondaryContentContainer.Large>
            <ImportantRead link={FAQ_LINK} />
          </SecondaryContentContainer.Large>
          <ImageContainer>
            <Image
              src={downloadImage}
              srcSet={`${downloadImage3x} 3x, ${downloadImage2x} 2x, ${downloadImage} 1x`}
              alt="Astronaut"
            />
          </ImageContainer>
        </StyledSidebar>
      )}
      <Footer goBack={goBack}>
        <Link href="/login" onClick={logout}>
          Logout
        </Link>
      </Footer>
    </SplitLayout>
  );
};

Download.propTypes = {
  goBack: PropTypes.func.isRequired,
};

export default Download;
