// Allow props spreading because this is a wrapper compponent
/* eslint-disable  react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import CloudSelector from 'shared/components/CloudSelector/CloudSelector';
import { cloudSelectorItemPropType } from 'shared/components/CloudSelector/propTypes';
import { FieldGroup, FieldError } from './styled';
import { formikFieldPropTypes, formikFormPropTypes } from './propTypes';

function CloudSelectorField({
  className,
  items,
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}) {
  const { name, value: formikValue } = field;
  const handleChange = e => {
    const checkedValue = e.target.value;
    const values = [...formikValue];
    const index = values.indexOf(checkedValue);
    if (index === -1) {
      values.push(checkedValue);
    } else {
      values.splice(index, 1);
    }
    setFieldTouched(name);
    setFieldValue(name, values);
  };

  return (
    <FieldGroup className={className}>
      <CloudSelector
        items={items}
        {...field}
        {...props}
        onChange={handleChange}
      />
      <ErrorMessage component={FieldError} name={field.name} />
    </FieldGroup>
  );
}

CloudSelectorField.propTypes = {
  items: PropTypes.arrayOf(cloudSelectorItemPropType).isRequired,
  form: formikFormPropTypes.isRequired,
  field: formikFieldPropTypes.isRequired,
};

export default CloudSelectorField;
