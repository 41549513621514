import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import converter from 'number-to-words';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { rem, color } from 'theme/lib';
import { H2 } from 'shared/components/styled/typography';
import media from 'theme/media';
import Button from 'shared/components/Button/Button';

function StepStarted({
  className,
  step,
  stepTitle,
  stepSubtitle,
  launchRoute,
}) {
  const history = useHistory();
  const handleClick = useCallback(() => {
    history.push(launchRoute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launchRoute]);

  return (
    <StyledStepStarted className={className}>
      <StepName>Step {converter.toWords(step)}</StepName>
      <StyledH2>{stepTitle}</StyledH2>
      <StepSubtitle>{stepSubtitle}</StepSubtitle>
      <Button shape="pill" onClick={handleClick}>
        Continue
      </Button>
    </StyledStepStarted>
  );
}

StepStarted.propTypes = {
  step: PropTypes.number.isRequired,
  stepTitle: PropTypes.string.isRequired,
  stepSubtitle: PropTypes.string.isRequired,
  launchRoute: PropTypes.string,
};

StepStarted.defaultProps = {
  launchRoute: '',
};

const StyledStepStarted = styled.div`
  color: ${color('black')};
  font-weight: 500;
  margin-bottom: ${rem(24)};
  margin-top: ${rem(20)};
`;

const StepName = styled.div`
  color: ${color('brand.primary')};
  text-transform: uppercase;
  margin-bottom: ${rem(10)};
`;

const StyledH2 = styled(H2)`
  margin-bottom: ${rem(10)};
`;

const StepSubtitle = styled.div`
  margin-bottom: ${rem(25)};

  ${media.up('medium')} {
    margin-bottom: ${rem(30)};
  }
  ${media.up('huge')} {
    margin-bottom: ${rem(35)};
  }
`;

export default StepStarted;
