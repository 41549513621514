/**
 * Framer Motion variants for the intro screen.
 */

export const transition = (custom = {}) => ({
  type: 'tween',
  ease: 'anticipate',
  duration: 0.7,
  ...custom,
});

const yForward = 60;
const yBack = yForward * -1;

const staggerDelay = 0.1;

// Astronaut screen content variants.
export const astronautContentVariants = {
  goForwardInitial: {
    opacity: 0,
    y: yForward * -1,
    transition: transition({
      delay: staggerDelay,
    }),
  },
  in: {
    opacity: 1,
    y: 0,
    transition: transition({
      delay: staggerDelay,
    }),
  },
  goForwardExit: {
    opacity: 0,
    y: yForward,
    transition: transition({
      delay: staggerDelay,
    }),
  },
};

astronautContentVariants.goBackInitial = {
  ...astronautContentVariants.goForwardInitial,
  y: yBack * -1,
};

astronautContentVariants.goBackExit = {
  ...astronautContentVariants.goForwardExit,
  y: yBack,
};

// Astronaut screen image variants.
export const astronautImageVariants = {
  goForwardInitial: {
    opacity: 0,
    y: yForward * -1,
    transition: transition(),
  },
  in: {
    opacity: 1,
    y: 0,
    transition: transition(),
  },
  goForwardExit: {
    opacity: 0,
    y: yForward,
    transition: transition(),
  },
};

astronautImageVariants.goBackInitial = {
  ...astronautImageVariants.goForwardInitial,
  y: yBack * -1,
};

astronautImageVariants.goBackExit = {
  ...astronautImageVariants.goForwardExit,
  y: yBack,
};

// SplitLayout content variants.
export const contentVariants = {
  goForwardInitial: {
    display: 'none',
    opacity: 0,
    y: yForward * -1,
    transition: transition({
      delay: staggerDelay,
    }),
  },
  in: {
    display: 'block',
    opacity: 1,
    y: 0,
    transition: transition({
      delay: staggerDelay,
    }),
  },
  goForwardExit: {
    opacity: 0,
    y: yForward,
    transition: transition({
      delay: staggerDelay,
    }),
    transitionEnd: {
      display: 'none',
    },
  },
};

contentVariants.goBackInitial = {
  ...contentVariants.goForwardInitial,
  y: yBack * -1,
};

contentVariants.goBackExit = {
  ...contentVariants.goForwardExit,
  y: yBack,
};
