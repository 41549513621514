import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { rem } from 'theme/lib';
import { LoadingCircle, LoadingText } from 'assets/svg';

/**
 * NOTE: Normally the text underneath the spinner would be a text element. In this case, its a svg
 * because it uses the same font as the logo. There should be no need to import a different style
 * that only applies to this use case.
 */

function LoadingSpinner({ color, showText }) {
  return (
    <StyledLoadingSpinner color={color}>
      <StyledLoadingCircle />
      {showText && <LoadingText />}
    </StyledLoadingSpinner>
  );
}

LoadingSpinner.propTypes = {
  color: PropTypes.string,
  showText: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  color: '#02208F',
  showText: true,
};

export default LoadingSpinner;

const StyledLoadingSpinner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

const loadingKeyframe = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledLoadingCircle = styled(LoadingCircle)`
  animation: ${loadingKeyframe} 1s linear infinite;
  position: relative;
  margin-bottom: ${rem(12)};
`;
