import * as Yup from 'yup';
import PasswordStrength from 'password-strength-calculator';
import { MIN_PASSWORD_LENGTH, MIN_PASSWORD_STRENGTH } from 'auth/constants';

const passwordStrengthTest = new PasswordStrength({
  minWidth: MIN_PASSWORD_LENGTH,
});

export default Yup.string()
  .required('Required')
  .min(
    MIN_PASSWORD_LENGTH,
    `Must be at least ${MIN_PASSWORD_LENGTH} characters long`
  )
  .test('no-spaces', "Can't contain any spaces", value => {
    return !/\s/.test(value);
  })
  .test('strength', 'Password is too weak', value => {
    if (!value) {
      return false;
    }
    return (
      passwordStrengthTest.passwordStrength(value) >= MIN_PASSWORD_STRENGTH
    );
  });
