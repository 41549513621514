import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

// Creates a Ken Burns style effect of panning and zooming an image.
const variants = {
  initial: {
    x: '-4%',
    y: 0,
    scale: 1,
    originX: 0,
    originY: '100%',
  },
  in: {
    x: 0,
    y: '-2%',
    scale: 1.15,
  },
};

function PanZoomImage({ className, src, alt, width, height, duration }) {
  return (
    <ImageMask className={className} width={width} height={height}>
      <Image
        as={motion.img}
        initial="initial"
        animate="in"
        variants={variants}
        transition={{
          ease: 'easeOut',
          duration,
        }}
        src={src}
        alt={alt}
      />
    </ImageMask>
  );
}

PanZoomImage.propTypes = {
  /** Image source. */
  src: PropTypes.string.isRequired,
  /** Image alt tag text. */
  alt: PropTypes.string,
  /** Define a width for the image. Default is 100% for bounding container. */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Define a height for the image. Default is 100% for bounding container. */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Duration of animation. */
  duration: PropTypes.number,
};

PanZoomImage.defaultProps = {
  alt: '',
  duration: 30,
  width: '100%',
  height: '100%',
};

const ImageMask = styled.div`
  overflow: hidden;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const Image = styled.img`
  position: relative;
  display: block;
  width: 105%;
  height: 102%;
  object-fit: cover;
  object-position: center center;
`;

export default PanZoomImage;
