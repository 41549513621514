/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

export default function useStripe() {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    const _loadStripe = async () => {
      const _stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
      setStripe(_stripe);
    };
    _loadStripe();
  }, []);

  return stripe;
}
