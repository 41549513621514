/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import regModalAnim1x from './reg-modal-anim.gif';
import regModalAnim2x from './reg-modal-anim@2x.gif';

import { Overlay, Modal, StyledCheckboxFilled, StyledText } from './styled';

function RegisterSuccessModal({
  className,
  isOpen,
  modalTimeout,
  text,
  onAutoClose,
}) {
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onAutoClose();
      }, modalTimeout);
      // clear timer if component gets unmounted
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isOpen, modalTimeout, onAutoClose]);

  return (
    <Overlay className={className} isOpen={isOpen}>
      <Modal isOpen={isOpen}>
        <img
          className={className}
          src={regModalAnim1x}
          srcSet={`${regModalAnim1x} 1x, ${regModalAnim2x} 2x`}
          alt="Registration Success Modal"
        />
        <StyledCheckboxFilled />
        <StyledText>{text}</StyledText>
      </Modal>
    </Overlay>
  );
}

RegisterSuccessModal.propTypes = {
  /** Determines if the modal is shown */
  isOpen: PropTypes.bool,
  /** Determines how soon the modal closes */
  modalTimeout: PropTypes.number,
  /** The text that is shown at the bottom of the modal */
  text: PropTypes.string,
  /** Called when modal is closed because of timeout */
  onAutoClose: PropTypes.func,
};

RegisterSuccessModal.defaultProps = {
  isOpen: false,
  modalTimeout: 5000,
  text: '',
  onAutoClose: () => {},
};

export default RegisterSuccessModal;
