import routes from 'shared/routes';
import * as QuestionnaireQuestions from 'questionnaire/components/Questions';
import * as ProfileQuestions from 'guideProfile/components/Questions';
import * as ProfileSidebars from 'guideProfile/components/sidebars';
import * as AboutQuestions from 'step-about/components/Questions';
import * as AboutSidebars from 'step-about/components/sidebars';
import * as CertificationsSidebars from 'step-certifications/components/sidebars';
import * as CertificationsQuestions from 'step-certifications/components/Questions';

export const QUESTIONNAIRE_SCREENS = [
  {
    route: routes.questionnaire.age(),
    fields: 'birthdate',
    Content: QuestionnaireQuestions.Age,
  },
  {
    route: routes.questionnaire.yearsCoaching(),
    fields: 'impact.years',
    Content: QuestionnaireQuestions.YearsCoaching,
  },
  {
    route: routes.questionnaire.clientsCoached(),
    fields: 'impact.seekers',
    Content: QuestionnaireQuestions.ClientsCoached,
  },
  {
    route: routes.questionnaire.describePractice(),
    fields: 'practice',
    Content: QuestionnaireQuestions.DescribePractice,
  },
  {
    route: routes.questionnaire.describeClientele(),
    fields: 'competencies',
    Content: QuestionnaireQuestions.Clientele,
  },
  {
    route: routes.questionnaire.familyStatus(),
    fields: 'family_status',
    Content: QuestionnaireQuestions.FamilyStatus,
  },
  {
    route: routes.questionnaire.keyword(),
    fields: 'keyword',
    Content: QuestionnaireQuestions.Keyword,
  },
  {
    route: routes.questionnaire.lifestyle(),
    fields: 'lifestyle',
    Content: QuestionnaireQuestions.Lifestyle,
  },
  {
    route: routes.questionnaire.communicationStyle(),
    fields: 'communication_style',
    Content: QuestionnaireQuestions.CommunicationStyle,
  },
  {
    route: routes.questionnaire.diversity(),
    fields: 'diversity',
    Content: QuestionnaireQuestions.Diversity,
  },
  {
    route: routes.questionnaire.references(),
    fields: 'references',
    Content: QuestionnaireQuestions.References,
  },
  {
    route: routes.questionnaire.contactInformation(),
    fields: ['phone.mobile', 'socials', 'mailing_address'],
    Content: QuestionnaireQuestions.ContactInformation,
  },
];

export const PROFILE_PHOTO_SCREENS = [
  {
    route: routes.photos.feature(),
    fields: 'header_image',
    Content: ProfileQuestions.FeaturePhoto,
    Sidebar: ProfileSidebars.FeaturePhotoSidebar,
  },
  {
    route: routes.photos.secondary(),
    fields: 'hero_image',
    Content: ProfileQuestions.SecondaryPhoto,
    Sidebar: ProfileSidebars.SecondaryPhotoSidebar,
  },
];

export const ABOUT_YOU_SCREENS = [
  {
    route: routes.aboutYou.bio(),
    fields: 'bio',
    Content: AboutQuestions.About,
    Sidebar: AboutSidebars.About,
  },
  {
    route: routes.aboutYou.focus(),
    Content: AboutQuestions.Focus,
    fields: 'fields',
    Sidebar: AboutSidebars.FocusSidebar,
  },
  {
    route: routes.aboutYou.outcomes(),
    fields: 'benefits',
    Content: AboutQuestions.Outcomes,
  },
  {
    route: routes.aboutYou.experience(),
    fields: 'experience',
    Content: AboutQuestions.Experience,
  },
  {
    route: routes.aboutYou.approach(),
    fields: 'approach',
    Content: AboutQuestions.Approach,
  },
];

export const CERTIFICATION_SCREENS = [
  {
    route: routes.credentials.certifications(),
    fields: 'fullCerts',
    Content: CertificationsQuestions.Certifications,
    Sidebar: CertificationsSidebars.Certifications,
  },
  {
    route: routes.credentials.testimonials(),
    fields: 'allTestimonials',
    Content: CertificationsQuestions.Testimonials,
    Sidebar: CertificationsSidebars.Testimonials,
  },
];
