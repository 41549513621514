/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import * as Yup from 'yup';
import styled from 'styled-components/macro';
import { ErrorMessage } from 'formik';
import 'react-dropzone-uploader/dist/styles.css';
import isEqual from 'lodash/isEqual';
import { color, rem } from 'theme/lib';
import media, { breakpoints } from 'theme/media';
import { useMediaQuery } from 'react-responsive';
import { FieldError } from 'shared/components/formik/styled';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import Button from 'shared/components/Button/Button';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
} from 'shared/components/WizardQuestionForm';
import { CERT_NAME_MAX_LENGTH } from 'guideProfile/constants';
import { TextLinkButton } from 'shared/components/styled/buttons';
import MobileModal from 'shared/components/MobileModal/MobileModal';
import CertificationsGuidelines from '../mobile/CertificationsGuidelines';
import CertificationFields from './CertificationFields/CertificationFields';

const MAX_INPUTS = 6;

const certificationInitialValue = {
  __typename: 'Cert',
  id: '',
  name: '',
  url: '',
};

const certValidationSchema = Yup.object().shape(
  {
    id: Yup.string().notRequired(),
    name: Yup.string()
      .trim()
      .when('url', (url, schema) => {
        return url
          ? schema
              .required('Describe your uploaded file')
              .max(CERT_NAME_MAX_LENGTH, true)
          : schema.notRequired().max(CERT_NAME_MAX_LENGTH, true);
      }),
    url: Yup.string().when('name', (name, schema) => {
      return name
        ? schema.required('Please upload a file')
        : schema.notRequired();
    }),
  },
  [['name', 'url']]
);

function validationFunction(values) {
  const filledOutCertifications = values.fullCerts.filter(
    cert => !isEqual({ ...cert, id: '' }, certificationInitialValue)
  );

  if (!filledOutCertifications.length) {
    return {
      fullCerts: 'Please provide at least one certification',
    };
  }

  // eslint-disable-next-line consistent-return
  const certificationErrors = values.fullCerts.map(cert => {
    try {
      const schema = certValidationSchema;
      schema.validateSync(cert, { abortEarly: false });
    } catch (err) {
      return err.inner.reduce((acc, curr) => {
        acc[curr.path] = curr.message;
        return acc;
      }, {});
    }
  });

  if (certificationErrors.filter(c => c).length) {
    return {
      fullCerts: certificationErrors,
    };
  }

  return {};
}

export default function CertificationsQuestion(props) {
  const canShowMobileModal = useMediaQuery({
    query: `(max-width: ${breakpoints.large}px)`,
  });

  const [isModalOpen, setModalOpen] = useState(false);

  const handleViewGuidelines = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <WizardQuestionForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValues={{
        fullCerts: [
          certificationInitialValue,
          certificationInitialValue,
          certificationInitialValue,
        ],
      }}
      validate={validationFunction}
      continueInFooter
    >
      {({ values, setFieldValue, setFieldError, setFieldTouched, errors }) => {
        const handleAddField = () => {
          setFieldValue(
            'fullCerts',
            values.fullCerts.concat([certificationInitialValue])
          );
        };

        return (
          <StyledFormContainer>
            <FormContentHeader>
              <QuestionHeading as="h1">
                Certifications &amp; Training
              </QuestionHeading>

              <QuestionDescription width={540}>
                Share your certifications confirming your practice-related
                courses. Include any coaching tools or assessments you are
                trained to provide. Secondly, write-in the name of the
                correlating uploaded certification.
              </QuestionDescription>
            </FormContentHeader>

            <StyledFormContent width={540}>
              <InstructionsWrapper>
                <Instructions>
                  Writing out your certifications and other recognizable degrees
                  will show up on your profile for seekers to view.
                </Instructions>

                {canShowMobileModal && (
                  <TextLinkButton type="button" onClick={handleViewGuidelines}>
                    View Guidelines
                  </TextLinkButton>
                )}
              </InstructionsWrapper>

              {isModalOpen && (
                <MobileModal
                  onClose={handleModalClose}
                  flexAlignment="flex-start"
                >
                  <CertificationsGuidelines />
                </MobileModal>
              )}

              {values.fullCerts.map((item, index) => (
                <CertificationFields
                  key={`certification-${index}`} // eslint-disable-line react/no-array-index-key
                  index={index}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                  setFieldTouched={setFieldTouched}
                  name={item.name}
                  url={item.url}
                />
              ))}
              <AddAnotherContent>
                {values.fullCerts.length < MAX_INPUTS ? (
                  <>
                    <AddAnotherButton onClick={handleAddField}>
                      Add Another
                    </AddAnotherButton>
                    Up to {MAX_INPUTS} certifications (optional)
                  </>
                ) : (
                  <>Max Number of Certifications</>
                )}
              </AddAnotherContent>
              {typeof errors.fullCerts === 'string' && (
                <ErrorMessage component={FieldError} name="fullCerts" />
              )}
            </StyledFormContent>
          </StyledFormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

CertificationsQuestion.propTypes = withNavigationPropTypes;

const StyledFormContainer = styled(FormContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledFormContent = styled(FormContent)`
  ${media.up('medium')} {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

const InstructionsWrapper = styled.div`
  font-weight: 500;
  margin-top: ${rem(30)};
  margin-bottom: ${rem(30)};
  padding-right: ${rem(10)};
`;

const Instructions = styled.span`
  padding-right: ${rem(8)};
`;

const AddAnotherButton = styled(props => (
  <Button
    shape="pill"
    color="default"
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  />
))`
  min-width: auto;
  font-size: ${rem(20)};
  padding-left: ${rem(13)};
  padding-right: ${rem(13)};
`;

const AddAnotherContent = styled.div`
  display: flex;
  align-items: center;
  font-size: ${rem(13)};
  line-height: ${20 / 13};
  color: ${color('textSecondary')};
  margin-top: ${rem(40)};

  ${AddAnotherButton} {
    margin-right: ${rem(13)};
  }
`;
