import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'theme/lib';
import media from 'theme/media';

function EmbeddedImage({ className, src, srcSet, alt, ...props }) {
  if (!src) {
    return null;
  }

  return (
    <ImageWrapper className={className}>
      <StyledImage
        src={src}
        srcSet={srcSet}
        alt={alt}
        {...props} // eslint-disable-line react/jsx-props-no-spreading
      />
    </ImageWrapper>
  );
}

EmbeddedImage.propTypes = {
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
  alt: PropTypes.string,
};

EmbeddedImage.defaultProps = {
  srcSet: null,
  alt: '',
};

const ImageWrapper = styled.div`
  display: block;
  position: relative;
  padding-bottom: ${(400 / 305) * 100}%;
  width: calc(100% + ${rem(15)}); /* Flush with right viewport. */
  border-top-left-radius: ${rem(10)};
  border-bottom-left-radius: ${rem(10)};
  overflow: hidden;

  ${media.up('medium')} {
    display: none;
  }
`;

const StyledImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default EmbeddedImage;
