import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from 'shared/components/Button/Button';
import { rem, color } from 'theme/lib';
import media from 'theme/media';

function ViewProfileAction({ className, onViewProfile, canShowOwnProfile }) {
  const actionSubtitle = canShowOwnProfile
    ? 'Use this link to view what your coach profile will look like as you build out your application.'
    : 'Use this link to view an example coach profile';
  const actionButtonText = canShowOwnProfile
    ? 'View Profile'
    : 'View Example Profile';

  return (
    <StyledViewProfileAction className={className}>
      <Title>Profile Builder</Title>
      <Subtitle>{actionSubtitle}</Subtitle>
      <Button shape="pill" onClick={onViewProfile} size="small">
        {actionButtonText}
      </Button>
    </StyledViewProfileAction>
  );
}

ViewProfileAction.propTypes = {
  /** Event handler to view profile. */
  onViewProfile: PropTypes.func.isRequired,
  /** Controls which subtitle and button text are rendered */
  canShowOwnProfile: PropTypes.bool,
};

ViewProfileAction.defaultProps = {
  canShowOwnProfile: false,
};

const StyledViewProfileAction = styled.div`
  margin-bottom: ${rem(24)};
  ${media.up('large')} {
    display: none;
  }
`;

const Title = styled.div`
  text-transform: uppercase;
  font-size: ${rem(13)};
  color: ${color('brand.primary')};
  margin-bottom: ${rem(6)};
`;

const Subtitle = styled.div`
  color: ${color('black')};
  margin-bottom: ${rem(16)};
`;

export default ViewProfileAction;
