import { useState, useCallback, useEffect } from 'react';

export default function useScroll(element) {
  const [status, setStatus] = useState(false);

  const scrollHandler = useCallback(() => {
    setStatus(true);
    element.current.removeEventListener('scroll', scrollHandler);
  }, [element]);

  useEffect(() => {
    element.current.addEventListener('scroll', scrollHandler);
  }, [element, scrollHandler]);

  return [status];
}
