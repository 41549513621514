import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components/macro';
import isEmpty from 'lodash/isEmpty';
import { rem } from 'theme/lib';
import Button from 'shared/components/Button/Button';
import ErrorMessage from 'shared/components/ErrorMessage/ErrorMessage';
import TextField from 'shared/components/formik/TextField/TextField';
import passwordSchema from 'shared/validators/passwordSchema';
import PasswordStrengthMeter from '../PasswordStrengthMeter/PasswordStrengthMeter';
import { useSignUp } from '../../hooks/queries';
import { MIN_NAME_LENGTH } from '../../constants';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required')
    .trim()
    .min(
      MIN_NAME_LENGTH,
      `Must be at least ${MIN_NAME_LENGTH} characters long`
    ),
  lastName: Yup.string()
    .required('Required')
    .trim()
    .min(
      MIN_NAME_LENGTH,
      `Must be at least ${MIN_NAME_LENGTH} characters long`
    ),
  email: Yup.string()
    .required('Required')
    .email('Invalid email address'),
  password: passwordSchema.required('Required'),
});

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
};

export default function SignupForm({ onSuccess }) {
  const [signup, { loading, called, error }] = useSignUp();
  const handleSubmit = useCallback(
    ({ firstName, lastName, email, password }) => {
      signup(firstName, lastName, email, password);
    },
    [signup]
  );

  const created = !loading && called && isEmpty(error);

  useEffect(() => {
    if (created) {
      onSuccess();
    }
  }, [onSuccess, created]);

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ dirty, values: { password } }) => {
        const isSubmitDisabled = !dirty || loading || created;
        return (
          <StyledForm>
            <Field
              name="firstName"
              placeholder="First Name"
              component={TextField}
              autoFocus
            />
            <Field
              name="lastName"
              placeholder="Last Name"
              component={TextField}
            />
            <Field
              name="email"
              placeholder="Email Address"
              component={TextField}
            />
            <StyledField
              name="password"
              placeholder="Create a Password"
              type="password"
              component={TextField}
            />
            <PasswordStrengthMeter password={password} />
            <StyledNote>
              Choose a strong password that has at least 8 characters and
              contains numbers and symbols.
            </StyledNote>
            <StyledButton block type="submit" disabled={isSubmitDisabled}>
              Create Your Account
            </StyledButton>
            <ErrorMessage error={error} />
          </StyledForm>
        );
      }}
    </Formik>
  );
}

SignupForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

const StyledNote = styled.p`
  font-size: 12px;
  margin-bottom: ${rem(56)};
  max-width: ${rem(335)};
`;

const StyledForm = styled(Form)`
  margin-top: ${rem(48)};
`;

const StyledField = styled(Field)`
  margin-bottom: ${rem(12)};
`;

const StyledButton = styled(Button)`
  margin-top: ${rem(36)};
  margin-bottom: ${rem(53)};
`;
