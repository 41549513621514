import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import get from 'lodash/get';

/**
 * Called every time a file's `status` changes
 */
export const handleChangeStatus = ({
  setFieldValue,
  fieldName,
  setFieldError,
  setFieldTouched,
}) => (fileWithMeta, status, files) => {
  switch (status) {
    case 'preparing': {
      if (files.length > 1) {
        // We only allow a single file upload so any existing files in the queue
        // need to be removed.  It's done with this meta flag instead of limiting
        // maxFiles to 1 because we also need to replace an existing uploaded file.
        files.forEach(file => {
          if (file.meta.isUploadStarted) {
            file.cancel();
            file.remove();
          }
        });

        return {
          meta: {
            isUploadStarted: true,
          },
        };
      }
      break;
    }

    case 'done':
      {
        const res = fileWithMeta.xhr.response
          ? JSON.parse(fileWithMeta.xhr.response)
          : {};

        // @Sphere backend people: when we upload image,
        // we get here before an image is ready to be downloaded, thus
        // when we set to new value, browser will re-download old image.
        // Adding a delay before we set field to a new value will prevent
        // browser caching an old image, based on assumption that  2 seconds
        // is enough for backend to finish preparing a new image.

        setTimeout(() => {
          const uploadedFile = get(res, 'filename', '');
          // Re-download image after 1.5sec uploading finished to prevent flicker.
          new Image().src = uploadedFile;

          // Set the field value in Formik.
          // This will cause re-render and showing new image
          setFieldValue(fieldName, uploadedFile);

          // After additional 500ms, hopefully the image is downloaded/cached
          // and is ready to be displayed without flickering.
          setTimeout(() => {
            // Remove existing files from the list now that they are uploaded
            // This causes progress bar to disappear.
            files.forEach(file => {
              if (file.meta.isUploadStarted) {
                file.cancel();
              }
              file.remove();
            });
          }, 500);
        }, 1500);
      }
      break;

    case 'error_upload':
    case 'exception_upload':
      if (setFieldTouched) {
        setFieldTouched(fieldName, true, false);
      }
      if (setFieldError) {
        setFieldError(fieldName, 'Failed to upload. Try again...');
      }
      // File failed to upload, let just remove it for now
      fileWithMeta.remove();
      break;

    default:
  }

  return null;
};

export const getFilesFromEvent = e => {
  return new Promise(resolve => {
    getDroppedOrSelectedFiles(e).then(chosenFiles => {
      resolve(chosenFiles.map(f => f.fileObject));
    });
  });
};
