import styled, { css } from 'styled-components';
import { color, rem } from 'theme/lib';

const paddingSizeVariants = {
  default: `${rem(8)} 0`,
  large: `${rem(15)} 0`,
};

export const InputWrapper = styled.div`
  display: flex;
  background-color: ${color('white')};
  border-bottom: ${({ borderSize }) => `${borderSize}px`} solid
    ${color('form.inputBorder')};

  ${props =>
    props.isFocused &&
    css`
      border-color: ${color('form.inputBorderFocus')};
    `}

  .has-error & {
    color: ${color('brand.error')};
    border-color: ${color('brand.error')};
  }
`;

export const InputAdornmentEnd = styled.div`
  display: flex;
  align-items: ${({ align }) => align};
  padding-left: ${rem(10)};
`;

export const StyledInput = styled.input`
  flex: 1;
  display: block;
  border: 0;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25;
  background-color: transparent;

  ${({ paddingSize }) => {
    const padding = paddingSizeVariants[paddingSize];
    if (padding) {
      return `padding: ${padding};`;
    }

    return null;
  }}

  /* Simulate the look of a placeholder field when a date is empty */
  &[type='date'][value=''] {
    color: ${color('form.inputPlaceholder')};
  }

  &:focus {
    outline: 0;
  }

  &::placeholder {
    font-weight: normal;
    color: ${color('form.inputPlaceholder')};
  }
`;

export const StyledTextarea = styled(StyledInput)`
  padding-top: ${rem(11)};
  padding-bottom: ${rem(11)};
  resize: none;
`;
