import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { color, rem } from 'theme/lib';
import { ButtonBase } from 'shared/components/styled/buttons';
import { ReactComponent as IconClose } from 'assets/svg/close.svg';

const modalRoot = document.getElementById('modal-portal');

function MobileModal({ children, flexAlignment, bg, onClose }) {
  const portalContainer = useRef(document.createElement('div'));

  useEffect(() => {
    const portalEl = portalContainer.current;
    modalRoot.appendChild(portalEl);
    // Prevents scrolling on the background on mount
    document.body.style.overflow = 'hidden';
    return () => {
      modalRoot.removeChild(portalEl);
      document.body.style.overflow = 'unset';
    };
  }, [portalContainer]);

  // A portal is needed because this modal is rendered within an element that is
  // transformed, which restricts fixed position children within that container.
  return createPortal(
    <Overlay flexAlignment={flexAlignment} bg={bg}>
      <Modal flexAlignment={flexAlignment}>
        <CloseButton onClick={onClose}>
          <IconClose />
        </CloseButton>
        <ModalBody>{children}</ModalBody>
      </Modal>
    </Overlay>,
    portalContainer.current
  );
}

MobileModal.propTypes = {
  children: PropTypes.node,
  /** Flexbox alignment for the Modal content */
  flexAlignment: PropTypes.oneOf(['flex-start', 'center']),
  /** Background color of the Overlay component */
  bg: PropTypes.oneOf(['solid', 'transparent']),
  /** Event handler to close the modal. */
  onClose: PropTypes.func.isRequired,
};

MobileModal.defaultProps = { flexAlignment: 'center', bg: 'solid' };

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  overflow: auto;

  ${({ bg }) => {
    if (bg === 'transparent') {
      return css`
        background-color: rgba(26, 26, 26, 0.9);
      `;
    }
    return css`
      background-color: ${color('blacks.200')};
    `;
  }};

  ${({ flexAlignment }) => {
    if (flexAlignment === 'flex-start') {
      return css`
        justify-content: flex-start;
        align-items: flex-start;
      `;
    }

    return css`
      justify-content: center;
      align-items: center;
    `;
  }}
`;

const Modal = styled.div`
  position: relative;
  ${({ flexAlignment }) => {
    if (flexAlignment === 'flex-start') {
      return css`
        margin-top: ${rem(35)};
      `;
    }

    return css`
      margin: auto;
    `;
  }}
  max-width: ${rem(1140)};
  width: 100%;
  padding: ${rem(10)};
`;

const ModalBody = styled.div`
  pointer-events: inherit;
  width: 100%;
`;

const CloseButton = styled(ButtonBase)`
  color: ${color('white')};
  position: fixed;
  top: ${rem(25)};
  right: ${rem(25)};
  cursor: pointer;
  z-index: 999;

  &::after {
    display: block;
    content: '';
    position: absolute;
    border: 1px solid white;
    border-radius: 50%;
    top: ${rem(-10)};
    right: ${rem(-10)};
    bottom: ${rem(-10)};
    left: ${rem(-10)};
  }

  svg {
    display: block;
    width: ${rem(14)};
    height: ${rem(14)};
  }
`;

export default MobileModal;
