import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { color, rem, zIndex } from 'theme/lib';
import media from 'theme/media';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';

export const footerLayoutHeightDefault = 90;
export const footerLayoutHeightMedium = 120;

export const ScreenWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledLayout = styled(SplitLayout)`
  position: relative;
  min-height: 100vh;
  height: 100%;
  padding-bottom: ${rem(footerLayoutHeightDefault)};

  ${media.up('medium')} {
    padding-bottom: ${rem(footerLayoutHeightMedium)};
  }
`;

// This layout is reused for the footer because it shares the same width and
// padding requirements as the scene layout.
export const StyledFooterLayout = styled(SplitLayout).attrs(() => ({
  showLogo: false,
}))`
  display: block;
  position: fixed;
  bottom: 0;
  /* Center the width constrained container in the viewport */
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  min-height: auto;
  height: ${rem(footerLayoutHeightDefault)};
  background-color: ${color('white')};
  z-index: ${zIndex('fixedScrollProgress')};

  ${media.up('medium')} {
    height: ${rem(footerLayoutHeightMedium)};
  }
`;

export const Footer = styled.div`
  position: relative;
  /* Apply margin offset with padding to make flush with viewport. */
  margin-left: ${rem(-15)};
  margin-right: ${rem(-15)};
  padding-left: ${rem(15)};
  padding-right: ${rem(15)};
  padding-top: ${rem(5)};
  height: 100%;

  ${media.up('medium')} {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    width: ${({ width }) => rem(width)};
  }
`;

Footer.propTypes = {
  width: PropTypes.number,
};

Footer.defaultProps = {
  width: 540,
};
