import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

function DropzoneInput({
  className,
  isUploadDisabled,
  accept,
  onFiles,
  getFilesFromEvent,
  id,
}) {
  return (
    <StyledDropzoneInput
      className={className}
      isUploadDisabled={isUploadDisabled}
    >
      <StyledInput
        id={id}
        type="file"
        accept={accept}
        multiple={false}
        onChange={e => {
          getFilesFromEvent(e).then(chosenFiles => {
            onFiles(chosenFiles);
          });
        }}
      />
    </StyledDropzoneInput>
  );
}

DropzoneInput.propTypes = {
  isUploadDisabled: PropTypes.bool,
  accept: PropTypes.string.isRequired,
  onFiles: PropTypes.func.isRequired,
  getFilesFromEvent: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

DropzoneInput.defaultProps = {
  isUploadDisabled: false,
};

const StyledDropzoneInput = styled.label`
  /* Override .dzu-input styles */
  && {
    cursor: pointer;
    display: block;
    pointer-events: auto;

    ${({ isUploadDisabled }) =>
      isUploadDisabled &&
      css`
        pointer-events: none;
      `}
  }
`;

const StyledInput = styled.input`
  display: none;
`;

export default DropzoneInput;
