// Allow props spreading because this is a wrapper compponent
/* eslint-disable  react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import cx from 'classnames';
import { color, rem } from 'theme/lib';
import { ErrorMessage } from 'formik';
import {
  FieldGroup,
  FieldError,
  FieldLabel,
} from 'shared/components/formik/styled';
import iconDropdown from 'assets/svg/icon-dropdown.svg';
import { isFieldError } from './helpers';
import { formikFieldPropTypes, formikFormPropTypes } from './propTypes';

function SelectField({
  className,
  label,
  options,
  field,
  form,
  placeholderLabel,
  ...props
}) {
  const { name, value } = field;
  const hasError = isFieldError(field, form);

  return (
    <FieldGroup
      className={cx(className, { 'has-error': hasError })}
      hasError={hasError}
    >
      {label && <FieldLabel>{label}</FieldLabel>}
      <SelectWrapper>
        <StyledSelect {...field} value={value} {...props}>
          <option value="">{placeholderLabel}</option>
          {options.map(choice => (
            <StyledOption key={`choice-${choice.code}`} value={choice.code}>
              {choice.name}
            </StyledOption>
          ))}
        </StyledSelect>
      </SelectWrapper>
      <ErrorMessage component={FieldError} name={name} />
    </FieldGroup>
  );
}

const optionPropTypes = PropTypes.shape({
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

SelectField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(optionPropTypes).isRequired,
  form: formikFormPropTypes.isRequired,
  field: formikFieldPropTypes.isRequired,
  /** Hack to simulate a placeholder value */
  placeholderLabel: PropTypes.string,
};

SelectField.defaultProps = {
  label: '',
  placeholderLabel: '',
};

export default SelectField;

const StyledSelect = styled.select`
  display: flex;
  background-color: ${color('white')};
  border: none;
  border-radius: 0;
  border-bottom: 2px solid ${color('form.inputBorder')};
  width: 100%;
  appearance: none;
  outline: 0;
  padding: 0.5rem 0;
  color: ${color('form.inputPlaceholder')};
  ${({ value }) =>
    value &&
    css`
      color: inherit;
    `}

  .has-error & {
    border-color: ${color('brand.error')};
  }
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 ${color('black')};
  }
`;
const StyledOption = styled.option`
  color: ${color('black')};
`;
const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  &::after{
      pointer-events: none;
      display: block;
      align-self: flex-end;
      content: '';
      background-image: url('${iconDropdown}');
      background-color: ${color('white')};
      position: absolute;
      width: ${rem(40)};
      height: ${rem(32)};
    }
`;
