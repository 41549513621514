import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const CANCEL_APPLICATION = gql`
  mutation CancelApplication($reason: String!, $details: String) {
    cancelApplication(reason: $reason, detailed_explanation: $details)
  }
`;

export default function useCancelApplication() {
  const [mutate, queryHandler] = useMutation(CANCEL_APPLICATION);

  const cancelApplication = useCallback(
    async (reason, details, onSuccess) => {
      try {
        await mutate({
          variables: {
            reason,
            details,
          },
        });
      } catch (err) {
        // Do nothing but do not trigger success handler
        return;
      }

      if (onSuccess) {
        onSuccess();
      }
    },
    [mutate]
  );

  return [cancelApplication, queryHandler];
}
