import styled, { css } from 'styled-components';
import { ReactComponent as CheckmarkFilled } from 'assets/svg/checkmark-filled.svg';
import { rem, color, fontSize, zIndex } from 'theme/lib';
import media from 'theme/media';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  background-color: transparent;
  visibility: hidden;
  padding-right: ${rem(20)};
  padding-left: ${rem(20)};
  z-index: ${zIndex('modal')};
  transition: background-color 300ms ease-in-out;
  ${({ isOpen }) =>
    isOpen &&
    css`
      background-color: rgba(26, 26, 26, 0.33);
      visibility: visible;
    `}
`;

export const Modal = styled.div`
  opacity: 0;
  position: relative;
  margin: auto;
  background: ${color('white')};
  overflow: auto;
  max-width: ${rem(400)};
  height: ${rem(400)};
  padding: ${rem(25)} 0 ${rem(5)};
  transition: opacity 300ms ease-in-out;
  border-radius: ${rem(10)};
  border: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
    `}
  ${media.up('medium')} {
    width: ${rem(400)};
  }
`;

export const StyledText = styled.p`
  font-size: ${fontSize(5)};
  color: ${color('black')};
  margin-top: ${rem(4)};
  margin-bottom: 0;
`;

export const StyledCheckboxFilled = styled(CheckmarkFilled)`
  color: ${color('brand.primary')};
  margin-top: ${rem(22)};
`;
