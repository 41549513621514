import React from 'react';
import { Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import compact from 'lodash/compact';
import { EmptyValueRejector } from 'shared/utils/yup';
import TextField from 'shared/components/formik/TextField/TextField';
import CharCountAdornment from 'shared/components/formik/CharCountAdornment/CharCountAdornment';
import { FieldError } from 'shared/components/formik/styled';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
  QuestionRequirement,
} from 'shared/components/WizardQuestionForm';
import { APPROACH_MAX_LENGTH } from 'guideProfile/constants';

const MIN_FIELDS_REQUIRED = 3;

const initialValues = {
  approach: ['', '', ''],
};

const validationSchema = Yup.object().shape({
  approach: Yup.array()
    .of(
      // Pass `true` as error string to trigger error but not render a message.
      Yup.string().max(APPROACH_MAX_LENGTH, true)
    )
    .compact(EmptyValueRejector)
    .min(MIN_FIELDS_REQUIRED, 'Please fill in all fields'),
});

const fieldPlaceholders = ['Challenging', 'Thought-provoking', 'Candid'];

// eslint-disable-next-line react/prop-types
const ApproachField = ({ index }) => {
  return (
    <Field
      name={`approach.${index}`}
      type="text"
      component={TextField}
      placeholder={fieldPlaceholders[index]}
      maxLength={APPROACH_MAX_LENGTH + 1} // Allow user to exceed to trigger validation feedback.
      hideFieldError
      renderAdornmentEnd={({ value }) => (
        <CharCountAdornment value={value} limit={APPROACH_MAX_LENGTH} />
      )}
    />
  );
};

export default function Approach(props) {
  return (
    <WizardQuestionForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValues={initialValues}
      validationSchema={validationSchema}
      forceEnableSubmit
      continueInFooter
    >
      {({ touched }) => {
        // Prevent the field array error message from displaying until the
        // minimum number of required fields are touched.
        const isMinRequiredTouched =
          compact(touched.approach).length >= MIN_FIELDS_REQUIRED;

        return (
          <FormContainer>
            <FormContentHeader>
              <QuestionHeading as="h1">Style and approach</QuestionHeading>

              <QuestionDescription>
                How have clients described your coaching practice? Make it one
                word.
              </QuestionDescription>
            </FormContentHeader>

            <FormContent>
              <QuestionRequirement>
                Please fill in all fields
              </QuestionRequirement>
              <ApproachField index={0} />
              <ApproachField index={1} />
              <ApproachField index={2} />

              {isMinRequiredTouched && (
                <ErrorMessage component={FieldError} name="approach" />
              )}
            </FormContent>
          </FormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

Approach.propTypes = withNavigationPropTypes;
