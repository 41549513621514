import React from 'react';
import useLogout from 'auth/hooks/useLogout';
import {
  StyledSignOut,
  SignOutContainer,
  SupportWrapper,
  SignOutMessage,
  SignOutLink,
} from './styled';

function SignOut({ className }) {
  const logout = useLogout();
  return (
    <StyledSignOut className={className}>
      <SignOutContainer>
        <SupportWrapper>
          <SignOutMessage>Have Questions?</SignOutMessage>
          <a
            href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=Becoming a Sphere Coach`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Email our support team
          </a>
        </SupportWrapper>
        <SignOutLink to="#" onClick={logout}>
          Sign Out
        </SignOutLink>
      </SignOutContainer>
    </StyledSignOut>
  );
}

export default SignOut;
