import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components/macro';
import { rem } from 'theme/lib';
import Button from 'shared/components/Button/Button';
import ErrorMessage from 'shared/components/ErrorMessage/ErrorMessage';
import TextField from 'shared/components/formik/TextField/TextField';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .email('Invalid email address'),
});

const StyledTextField = styled(TextField)`
  margin-bottom: ${rem(0)};
  /*HACK: Apply a fixed height so that the validation errors don't cause the button to move */
  height: ${rem(60)};
`;

const StyledButton = styled(Button)`
  margin-top: ${rem(36)};
`;

const StyledForm = styled(Form)`
  margin-top: ${rem(35)};
`;

export default function ForgotPasswordForm({
  emailAddress,
  onSubmit,
  loading,
  error,
}) {
  // Memoize initial values to avoid validation messages flashing on submit
  const initialValues = useMemo(
    () => ({
      email: emailAddress,
    }),
    [emailAddress]
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ isValid }) => {
        const isSubmitDisabled = !isValid || loading;
        return (
          <StyledForm>
            <Field
              name="email"
              placeholder="Email Address"
              errorSize="small"
              component={StyledTextField}
              autoFocus
            />

            <StyledButton block type="submit" disabled={isSubmitDisabled}>
              Reset Password
            </StyledButton>
            <ErrorMessage error={error} />
          </StyledForm>
        );
      }}
    </Formik>
  );
}

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  emailAddress: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.instanceOf(Error),
};

ForgotPasswordForm.defaultProps = {
  emailAddress: '',
  loading: false,
  error: undefined,
};
