import React from 'react';
import PropTypes from 'prop-types';
import { Container, StyledLogo, StyledProgressBar, Title } from './styled';

function ProgressHeader({
  className,
  title,
  progressPercent,
  progressPreload,
  enableProgressBar,
  renderActions,
}) {
  return (
    <div className={className}>
      <Container>
        <StyledLogo />
        <Title>{title}</Title>
        {renderActions()}
      </Container>
      {enableProgressBar && (
        <StyledProgressBar
          percent={progressPercent}
          preload={progressPreload}
        />
      )}
    </div>
  );
}

ProgressHeader.propTypes = {
  progressPercent: PropTypes.number,
  progressPreload: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  title: PropTypes.string.isRequired,
  /** Renders HeaderActions components */
  renderActions: PropTypes.func,
  /** Renders ProgressBar */
  enableProgressBar: PropTypes.bool,
};

ProgressHeader.defaultProps = {
  progressPercent: 0,
  progressPreload: false,
  renderActions: () => {},
  enableProgressBar: false,
};

export default ProgressHeader;
