import styled, { css } from 'styled-components/macro';
import { rem } from 'theme/lib';
import media from 'theme/media';

export const ApplicationStatusLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  padding-bottom: ${rem(33)};

  ${media.up('medium')} {
    margin-top: 8vh;
    margin-right: ${rem(20)};
  }

  ${media.up('large')} {
    flex-flow: row nowrap;
  }

  ${media.up('huge')} {
    margin-right: ${rem(120)};
  }
`;

export const ChecklistContainer = styled.div`
  ${media.up('large')} {
    flex: 1 1 60%;
  }
  ${media.up('huge')} {
    flex: 1 1 62%;
  }
`;

export const CloudSelectorContainer = styled.div`
  display: none;

  ${({ showDisclaimer }) =>
    !showDisclaimer &&
    css`
      display: block;
      margin-top: ${rem(50)};
      ${media.up('large')} {
        margin-top: ${rem(0)};
        flex: 1 1 40%;
      }
      ${media.up('huge')} {
        flex: 1 1 38%;
        margin-left: 0;
      }
    `}
`;
