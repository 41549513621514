import React from 'react';
import { Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { EmptyValueRejector } from 'shared/utils/yup';
import styled from 'styled-components';
import { color, rem } from 'theme/lib';
import TextField from 'shared/components/formik/TextField/TextField';
import CharCountAdornment from 'shared/components/formik/CharCountAdornment/CharCountAdornment';
import { FieldError } from 'shared/components/formik/styled';
import Button from 'shared/components/Button/Button';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
} from 'shared/components/WizardQuestionForm';
import { BENEFIT_MAX_LENGTH } from 'guideProfile/constants';

const MAX_INPUTS = 6;

const validationSchema = Yup.object().shape({
  benefits: Yup.array()
    .of(
      // Pass `true` as error string to trigger error but not render a message.
      Yup.string().max(BENEFIT_MAX_LENGTH, true)
    )
    .compact(EmptyValueRejector)
    .min(1, 'Please provide at least one example.'),
});

const fieldPlaceholders = [
  'Reduce stress',
  'Increase your confidence, productivity, and clarity',
  'Effective and realistic goal setting',
  'First-hand entrepreneurial experience',
  'Outcomes #5',
  'Outcomes #6',
];

export default function Benefits(props) {
  return (
    <WizardQuestionForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValues={{
        benefits: ['', '', '', ''],
      }}
      validationSchema={validationSchema}
      continueInFooter
    >
      {({ values }) => {
        return (
          <FormContainer>
            <FormContentHeader>
              <QuestionHeading as="h1">Client outcomes</QuestionHeading>

              <QuestionDescription>
                What will the client get from you? What are your past and proven
                outcomes?
              </QuestionDescription>
            </FormContentHeader>

            <FormContent>
              <FieldArray
                name="benefits"
                render={arrayHelpers => (
                  <>
                    {values.benefits.map((item, index) => (
                      <Field
                        key={index} // eslint-disable-line react/no-array-index-key
                        name={`benefits.${index}`}
                        type="text"
                        component={TextField}
                        placeholder={fieldPlaceholders[index]}
                        maxLength={BENEFIT_MAX_LENGTH + 1} // Allow user to exceed to trigger validation feedback.
                        hideFieldError
                        renderAdornmentEnd={({ value }) => (
                          <CharCountAdornment
                            value={value}
                            limit={BENEFIT_MAX_LENGTH}
                          />
                        )}
                      />
                    ))}
                    <AddAnotherContent>
                      {values.benefits.length < MAX_INPUTS ? (
                        <>
                          <AddAnotherButton
                            onClick={() => arrayHelpers.push('')}
                          >
                            Add Another
                          </AddAnotherButton>
                          Maximum {MAX_INPUTS}
                        </>
                      ) : (
                        <>Max Number of Outcomes</>
                      )}
                    </AddAnotherContent>
                  </>
                )}
              />
              <ErrorMessage component={FieldError} name="benefits" />
            </FormContent>
          </FormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

Benefits.propTypes = withNavigationPropTypes;

const AddAnotherButton = styled(props => (
  <Button
    shape="pill"
    color="default"
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  />
))`
  min-width: auto;
  font-size: ${rem(20)};
  padding-left: ${rem(13)};
  padding-right: ${rem(13)};
`;

const AddAnotherContent = styled.div`
  display: flex;
  align-items: center;
  font-size: ${rem(13)};
  line-height: ${20 / 13};
  color: ${color('textSecondary')};

  ${AddAnotherButton} {
    margin-right: ${rem(13)};
  }
`;
