import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem, color } from 'theme/lib';
import { H4, H5 } from 'shared/components/styled/typography';
import GuidelinesSectionHeading from './GuidelinesSectionHeading';
import GuidelinesList, { StyledListItem } from './GuidelinesList';

function Guidelines({ className, children }) {
  return <StyledGuidelines className={className}>{children}</StyledGuidelines>;
}

Guidelines.propTypes = {
  children: PropTypes.node,
};

Guidelines.defaultProps = {
  children: null,
};

const StyledGuidelines = styled.div`
  max-width: ${rem(445)};
  background-color: ${color('white')};
  border-radius: ${rem(10)} ${rem(10)} 0 0;
  overflow: hidden;
`;

Guidelines.Header = styled.header`
  padding: ${rem(20)} ${rem(20)} ${rem(30)};
  background-color: ${color('brand.primary')};
  color: ${color('white')};
`;

Guidelines.Title = styled(H4)`
  margin-top: 0;
  margin-bottom: ${rem(15)};
`;

Guidelines.Subtitle = styled(H5)`
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
`;

Guidelines.Content = styled.div`
  background-color: ${color('white')};
`;

Guidelines.Section = styled.section`
  &:first-of-type {
    border-bottom: 1px solid ${color('grays.200')};
  }
`;

Guidelines.SectionHeading = GuidelinesSectionHeading;
Guidelines.List = GuidelinesList;
Guidelines.ListItem = StyledListItem;

export default Guidelines;
