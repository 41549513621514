import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { animationControlsPropTypes } from 'shared/motion/propTypes';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import useRectAfterWindowResize from 'shared/hooks/useRectAfterWindowResize';
import { MainInner, ContentContainer, LayoutSidebar } from './styled';
import SidebarImage from './SidebarImage';
import RequirementsContent from './RequirementsContent';
import JoinContent from './JoinContent';
import imgRequirementsSidebar from './requirements-sidebar.jpg';
import imgSeeker1 from './images/seeker-1.jpg';
import imgSeeker2 from './images/seeker-2.jpg';
import imgSeeker3 from './images/seeker-3.jpg';
import imgSeeker4 from './images/seeker-4.jpg';
import imgSeeker5 from './images/seeker-5.jpg';
import imgSeeker6 from './images/seeker-6.jpg';
import imgSeeker7 from './images/seeker-7.jpg';
import imgSeeker8 from './images/seeker-8.jpg';

function SidebarScene({
  sceneIndex,
  requirementsContentAnimation,
  requirementsImageAnimation,
  joinContentAnimation,
  joinImageAnimation,
  isDirectionReversed,
  onContentLayoutChange,
}) {
  const mainInnerRef = useRef(null);
  const mainInnerRect = useRectAfterWindowResize(mainInnerRef);

  useLayoutEffect(() => {
    onContentLayoutChange(mainInnerRect.width);
  }, [mainInnerRect.width, onContentLayoutChange]);

  return (
    <>
      <SplitLayout.Main>
        <MainInner ref={mainInnerRef}>
          <ContentContainer>
            {sceneIndex === 2 && (
              <RequirementsContent
                animate={requirementsContentAnimation}
                isDirectionReversed={isDirectionReversed}
              />
            )}
            {sceneIndex === 3 && (
              <JoinContent
                animate={joinContentAnimation}
                isDirectionReversed={isDirectionReversed}
              />
            )}
          </ContentContainer>
        </MainInner>
      </SplitLayout.Main>

      <LayoutSidebar fixedContent>
        {sceneIndex === 2 && (
          <SidebarImage
            isDirectionReversed={isDirectionReversed}
            animate={requirementsImageAnimation}
            src={imgRequirementsSidebar}
          />
        )}

        {sceneIndex === 3 && (
          <SidebarImage
            isDirectionReversed={isDirectionReversed}
            animate={joinImageAnimation}
            profiles={[
              {
                src: imgSeeker1,
                name: 'Juno',
                career: 'Chef',
              },
              {
                src: imgSeeker2,
                name: 'Katie',
                career: 'Co-Founder',
              },
              {
                src: imgSeeker3,
                name: 'Ludo',
                career: 'Finance Professional',
              },
              {
                src: imgSeeker4,
                name: 'Tomo',
                career: 'Freelancer',
              },
              {
                src: imgSeeker5,
                name: 'Molly',
                career: 'PR Professional',
              },
              {
                src: imgSeeker6,
                name: 'Christina',
                career: 'Business Owner',
              },
              {
                src: imgSeeker7,
                name: 'Jaryd',
                career: 'MBA Student',
              },
              {
                src: imgSeeker8,
                name: 'Scott',
                career: 'Entrepreneur',
              },
            ]}
          />
        )}
      </LayoutSidebar>
    </>
  );
}

SidebarScene.propTypes = {
  sceneIndex: PropTypes.number.isRequired,
  isDirectionReversed: PropTypes.bool.isRequired,
  /** Framer Motion AnimationControls. */
  requirementsContentAnimation: animationControlsPropTypes.isRequired,
  /** Framer Motion AnimationControls. */
  requirementsImageAnimation: animationControlsPropTypes.isRequired,
  /** Framer Motion AnimationControls. */
  joinContentAnimation: animationControlsPropTypes.isRequired,
  /** Framer Motion AnimationControls. */
  joinImageAnimation: animationControlsPropTypes.isRequired,
  /** Trigger event when the width of the content container changes. */
  onContentLayoutChange: PropTypes.func,
};

SidebarScene.defaultProps = {
  onContentLayoutChange: () => {},
};

export default SidebarScene;
