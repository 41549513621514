/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import isEqual from 'lodash/isEqual';
import { ErrorMessage } from 'formik';
import * as Yup from 'yup';
import trim from 'lodash/trim';
import styled from 'styled-components';
import { color, rem } from 'theme/lib';
import media, { breakpoints } from 'theme/media';
import { useMediaQuery } from 'react-responsive';
import { FieldError } from 'shared/components/formik/styled';
import { withNavigationPropTypes } from 'shared/wizardNavigation';
import WizardQuestionForm, {
  FormContainer,
  FormContentHeader,
  FormContent,
  QuestionHeading,
  QuestionDescription,
  QuestionRequirement,
} from 'shared/components/WizardQuestionForm';
import { TESTIMONIAL_MAX_LENGTH } from 'guideProfile/constants';
import { TextLinkButton } from 'shared/components/styled/buttons';
import MobileModal from 'shared/components/MobileModal/MobileModal';
import TestimonialsGuidelines from '../mobile/TestimonialsGuidelines';
import TestimonialsFields from './TestimonialsFields/TestimonialsFields';

const testimonialInitialValue = {
  __typename: 'Testimonial',
  id: '',
  first_name: '',
  last_initial: '',
  text: '',
};

const initialValues = {
  allTestimonials: [
    testimonialInitialValue,
    testimonialInitialValue,
    testimonialInitialValue,
  ],
};

const existingTestimonialSchema = Yup.object().shape({
  first_name: Yup.string()
    .trim()
    .when('text', (text, schema) => {
      return trim(text) ? schema.required('Required') : schema.notRequired();
    }),
  last_initial: Yup.string()
    .trim()
    .when('text', (text, schema) => {
      return trim(text) ? schema.required('Required') : schema.notRequired();
    }),
  text: Yup.string()
    .trim()
    .max(TESTIMONIAL_MAX_LENGTH, true),
});

const newTestimonialSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('Required')
    .trim(),
  last_initial: Yup.string()
    .required('Required')
    .trim(),
  text: Yup.string()
    .required('Required')
    .trim()
    .max(TESTIMONIAL_MAX_LENGTH, true),
});

function validationFunction(values) {
  const filledOutTestimonials = values.allTestimonials.filter(
    t => !isEqual({ ...t, id: '' }, testimonialInitialValue)
  );
  if (!filledOutTestimonials.length) {
    return {
      allTestimonials: 'Please provide at least one testimonial',
    };
  }

  const testimonialErrors = values.allTestimonials.map(testimonial => {
    if (isEqual(testimonial, testimonialInitialValue)) {
      // Ignore have not been filled out
      return;
    }

    try {
      // Existing testimonials have different validation rules to allow the
      // user to remove all text, therefore deleting the testimonial.
      const testimonialSchema = testimonial.id
        ? existingTestimonialSchema
        : newTestimonialSchema;
      testimonialSchema.validateSync(testimonial, { abortEarly: false });
    } catch (err) {
      // eslint-disable-next-line consistent-return
      return err.inner.reduce((acc, curr) => {
        acc[curr.path] = curr.message;
        return acc;
      }, {});
    }
  });

  if (testimonialErrors.filter(t => t).length) {
    return {
      allTestimonials: testimonialErrors,
    };
  }

  return {};
}

export default function Testimonials(props) {
  const canShowMobileModal = useMediaQuery({
    query: `(max-width: ${breakpoints.large}px)`,
  });

  const [isModalOpen, setModalOpen] = useState(false);

  const handleViewGuidelines = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <WizardQuestionForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValues={initialValues}
      validate={validationFunction}
      continueInFooter
    >
      {({ values, errors }) => {
        return (
          <FormContainer padding="small">
            <FormContentHeader>
              <QuestionHeading as="h1">Client testimonials</QuestionHeading>
              <QuestionDescription>
                What others say matters most.
              </QuestionDescription>
              <RequirementWrapper>
                <StyledQuestionRequirement as="span">
                  Post up to 3 testimonials below.
                </StyledQuestionRequirement>

                {canShowMobileModal && (
                  <TextLinkButton type="button" onClick={handleViewGuidelines}>
                    View Guidelines
                  </TextLinkButton>
                )}
              </RequirementWrapper>

              {isModalOpen && (
                <MobileModal
                  onClose={handleModalClose}
                  flexAlignment="flex-start"
                >
                  <TestimonialsGuidelines />
                </MobileModal>
              )}
            </FormContentHeader>

            <FormContent width={540}>
              {values.allTestimonials.map((item, index) => (
                <TestimonialsFields
                  key={`allTestimonials-${index}`} // eslint-disable-line react/no-array-index-key
                  index={index}
                />
              ))}

              <ContentFooter>
                {typeof errors.allTestimonials === 'string' && (
                  <ErrorMessage component={FieldError} name="allTestimonials" />
                )}

                <Instructions>
                  These words are displayed on your profile. We only ask for the
                  Last Name Initial, as to keep client confidentiality in check.
                </Instructions>
              </ContentFooter>
            </FormContent>
          </FormContainer>
        );
      }}
    </WizardQuestionForm>
  );
}

Testimonials.propTypes = withNavigationPropTypes;

const ContentFooter = styled.div`
  ${media.up('medium')} {
    max-width: ${rem(500)};
  }
`;

const Instructions = styled.p`
  font-weight: 500;
  color: ${color('brand.primary')};
`;

const RequirementWrapper = styled.div`
  margin-top: ${rem(30)};
  margin-bottom: ${rem(30)};
  padding-right: ${rem(10)};
  font-size: ${rem(16)};
`;

const StyledQuestionRequirement = styled(QuestionRequirement)`
  padding-right: ${rem(8)};
`;
