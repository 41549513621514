/**
 * NOTE: these arrays must not change in between render cycles,
 * since we iterate over them and call hooks!
 */

export const FOCUS_PREVIEW_IDS = [
  '1073',
  '535',
  '996',
  '1590',
  '1302',
  '634',
  '1294',
  '1062',
  '1070',
  '1592',
  '612',
];

export const PROFILE_PREVIEW_IDS = [
  null, // will use mockProfile when id is null
  '1301',
  '1289',
  '1299',
  '1283',
  '624',
  '625',
  '622',
  '644',
];
