import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem, fontSize } from 'theme/lib';
import backgroundBullet from 'assets/svg/bullet.svg';
import media from 'theme/media';

function NumberedList({ className, children }) {
  return (
    <StyledNumberedList className={className}>{children}</StyledNumberedList>
  );
}

NumberedList.propTypes = {
  children: PropTypes.node,
};

NumberedList.defaultProps = {
  children: null,
};

const StyledNumberedList = styled.ol`
  list-style-type: none;
  padding: 0;
  counter-reset: item;
`;

NumberedList.Item = styled.li`
  display: flex;
  align-items: baseline;
  padding: 0;
  margin-bottom: ${rem(16)};
  /* Disable normal item numbering */
  list-style: none;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.up('small')} {
    align-items: center;
  }

  &::before {
    display: block;
    /* Use the counter as content */
    content: counter(item);
    /* Increment the counter by 1 */
    counter-increment: item;
    background-image: url('${backgroundBullet}');
    width: ${rem(19)};
    height: ${rem(19)};
    font-size: ${fontSize(0)};
    margin-right: ${rem(10)};
    line-height: ${19 / 10};
    text-align: center;
    flex: 0 0 ${rem(19)};
    -webkit-font-smoothing: subpixel-antialiased;
  }
`;

export default NumberedList;
