import { useState, useCallback, useEffect } from 'react';
import mockProfile from 'storybook/mockProfile';
import { useGuideProfile, useGuideInfo } from 'guideProfile/hooks/queries';
import { PROFILE_PREVIEW_IDS as SAMPLE_PROFILE_IDS } from 'sampleProfiles';

export default function useProfilePreview() {
  const ownProfile = useGuideProfile().data;

  const canShowOwnProfile = !!(ownProfile && ownProfile.header_image);
  const [previewState, setPreviewState] = useState({
    // Default to showing own profile if there is a profile photo
    showOwnProfile: canShowOwnProfile,

    // Always show the mock (Noah) profile as the first example
    sampleIndex: canShowOwnProfile ? -1 : 0,
  });

  // Handle loading state

  useEffect(() => {
    setPreviewState(state => {
      return {
        ...state,
        showOwnProfile: canShowOwnProfile,
      };
    });
  }, [canShowOwnProfile]);

  let sampleLoading;
  let sampleProfile;
  // Accomplishes pre-fetching data, so that switching between preview accounts is fast.
  // It's safe to do this in loop, since we are not changing order nor how many hooks are called,
  // so we are not breaking the rules of hooks.
  for (let i = 0; i < SAMPLE_PROFILE_IDS.length; i++) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { loading, data } = useGuideInfo(SAMPLE_PROFILE_IDS[i]);
    if (i === previewState.sampleIndex) {
      sampleLoading = loading;
      sampleProfile = data;
    }
    if (data && data.header_image) {
      (async url => {
        new Image().src = url;
      })(data.header_image);
    }
  }

  const handleSwitchPreviewButtonClick = useCallback(e => {
    setPreviewState(state => {
      return {
        ...state,
        showOwnProfile: !state.showOwnProfile,
        sampleIndex: state.showOwnProfile
          ? roundRobinIndex(state.sampleIndex, SAMPLE_PROFILE_IDS)
          : state.sampleIndex,
      };
    });
    // Prevents clicking bubbling to parent
    e.stopPropagation();
  }, []);

  let profileToShow;
  let switchPreviewButtonLabel;

  if (previewState.showOwnProfile && canShowOwnProfile) {
    profileToShow = ownProfile;
    switchPreviewButtonLabel = 'View Example Bio';
  } else {
    if (previewState.sampleIndex === 0) {
      profileToShow = mockProfile;
    } else if (!sampleLoading) {
      profileToShow = sampleProfile;
    } else if (sampleLoading && canShowOwnProfile) {
      // When loading a new sample profile,
      // for duration of loading, show existing profile,
      // so that we don't lose scroll position
      // between switching a to different profile.
      profileToShow = ownProfile;
    }

    if (canShowOwnProfile) {
      switchPreviewButtonLabel = 'Go Back to Bio';
    }
  }
  return {
    profileToShow,
    switchPreviewButtonLabel,
    canShowOwnProfile,
    handleSwitchPreviewButtonClick,
  };
}

function roundRobinIndex(currentIndex, items) {
  const nextIndex = currentIndex + 1;
  return nextIndex < items.length ? nextIndex : 0;
}
