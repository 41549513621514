/* eslint-disable import/prefer-default-export */

/**
 * Remove null and undefined properties from an object
 * @param {Object} ob - The source object
 * @param {boolean} recursive - Whether or not to remove the null properties from nested objects
 * @returns {Object} A clean object
 */
export function removeNullElements(ob = {}, recursive = false) {
  if (Array.isArray(ob)) {
    return ob.reduce((acc, curr) => {
      if (curr !== undefined && curr !== null) {
        let el = curr;
        if (recursive && typeof curr === 'object') {
          el = removeNullElements(curr, true);
        }

        acc.push(el);
      }
      return acc;
    }, []);
  }

  return Object.keys(ob).reduce((acc, key) => {
    if (ob[key] !== undefined && ob[key] !== null) {
      acc[key] = ob[key];

      if (recursive && typeof ob[key] === 'object') {
        acc[key] = removeNullElements(ob[key], true);
      }
    }
    return acc;
  }, {});
}

/**
 * Remove __typename properties recursively
 * @param {Object} v - The source
 * @returns {Object} The cleaned value
 */
export function removeTypename(v) {
  if (typeof v === 'object') {
    // Could be an array
    if (Array.isArray(v)) {
      return v.map(removeTypename);
    }

    // Could be null
    if (v === null) {
      return v;
    }

    // Must be a normal object
    const clean = {};
    Object.keys(v).forEach(k => {
      clean[k] = removeTypename(v[k]);
    });

    // eslint-disable-next-line no-underscore-dangle
    delete clean.__typename;
    return clean;
  }

  // Must be a primitive
  return v;
}

/**
 * Generate an image srcSet string from a structured object.
 * @param {Object} srcSetObject Key value pair assigning image path to image densities.
 *   Example:
 *   {
 *     '1x': 'image.jpg',
 *     '2x': 'image@2x.jpg',
 *     '3x': 'image@3x.jpg',
 *   }
 * @returns {String} Value that can be assigned to an img srcSet.
 */
export function makeSrcSetFromObject(srcSetObject) {
  if (!srcSetObject) {
    return '';
  }

  return Object.keys(srcSetObject)
    .reduce((result, key) => {
      const img = srcSetObject[key];
      return [...result, `${img} ${key}`];
    }, [])
    .join(', ');
}
