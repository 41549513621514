import React from 'react';
import get from 'lodash/get';
import { Route, useHistory, useLocation } from 'react-router-dom';

export default function withNavigation(
  WrappedComponent,
  {
    route,
    nextRoute,
    previousRoute,
    localStep,
    globalStep,
    isDirectionReversed,
    setDirectionReversed,
    setNavigationFunctions,
  }
) {
  return props => {
    const history = useHistory();
    const location = useLocation();
    const { from, entry } = get(location, 'state', {});

    const next = () => {
      setDirectionReversed(false);
      history.push(nextRoute, {
        from: route,
        entry,
      });
    };

    const goBack = () => {
      setDirectionReversed(true);
      if (from === previousRoute || entry === previousRoute) {
        /**
         * Came from the previous wizard screen or the screen that launched the
         * wizard. We can use the browser's 'back' functionality.
         *
         * This allows us to go forward through the browser and avoids the 'back'
         * button returning to this screen later.
         */
        history.goBack();
      } else {
        /**
         * Arrived at this screen from outside the wizard navigation. Go to the
         * previous screen within the wizard navigation instead of using the browser
         * 'back' button.
         */
        history.replace(previousRoute);
      }
    };

    /**
     * Notify the parent that this step in the one that is currently being displayed.
     * This relies on all the child routes being unique.
     */
    if (location.pathname === route) {
      setNavigationFunctions({
        goBack,
        goNext: next,
      });
    }

    /* Disabled eslint rule because Higher Order Components should allow for props spreading */
    return (
      <Route path={route}>
        <WrappedComponent
          goNext={next}
          goBack={goBack}
          localStep={localStep}
          globalStep={globalStep}
          route={route}
          isDirectionReversed={isDirectionReversed}
          {...props} // eslint-disable-line react/jsx-props-no-spreading
        />
      </Route>
    );
  };
}
