import React, { useState, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { css } from 'styled-components/macro';
import { rem } from 'theme/lib';
import routes from 'shared/routes';
import useCancelApplication from 'guideProfile/hooks/useCancelApplication';
import { H1, H3 } from 'shared/components/styled/typography';
import ArrowNavButton from 'shared/components/ArrowNavButton/ArrowNavButton';
import Button from 'shared/components/Button/Button';
import ErrorMessage from 'shared/components/ErrorMessage/ErrorMessage';
import CharCountAdornment from 'shared/components/formik/CharCountAdornment/CharCountAdornment';
import { QuestionDescription } from 'shared/components/WizardQuestionForm/styled';
import {
  StyledFormContentHeader,
  StyledForm,
  FieldGroup,
  StyledRadioField,
  StyledOutlinedTextareaField,
  FormActions,
} from './CancelApplicationForm.styled';

const DETAILED_EXPLANATION_LIMIT = 300;
const REASON_NOT_GOOD_FIT = 'I don’t think I’d be a good fit for Sphere';
const REASON_CHANGED_MIND = 'Changed my mind';
const REASON_NOT_SERIOUS = 'Just browsing, not serious about it';
const REASON_OTHER = 'Other';

const reasonOptions = {
  [REASON_NOT_GOOD_FIT]: REASON_NOT_GOOD_FIT,
  [REASON_CHANGED_MIND]: REASON_CHANGED_MIND,
  [REASON_NOT_SERIOUS]: REASON_NOT_SERIOUS,
  [REASON_OTHER]: (
    <>
      Other <em>(write-in)</em>
    </>
  ),
};

const initialValues = {
  reason: '',
  details: '',
};

const validationSchema = Yup.object().shape({
  reason: Yup.string().required(),
  details: Yup.string().max(DETAILED_EXPLANATION_LIMIT, true),
});

const labelCSS = css`
  font-size: ${rem(16)};
  font-weight: 500;
`;

function CancelApplicationForm() {
  const [cancelApplication, { loading, error }] = useCancelApplication();
  const [isApplicationCancelled, setApplicationCancelled] = useState(false);
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  const handleGoHome = () => {
    history.push('/');
  };

  const handleSubmit = useCallback(
    ({ reason, details }) => {
      cancelApplication(reason, details, () => {
        // Set the state after a successful cancellation
        setApplicationCancelled(true);
      });
    },
    [cancelApplication]
  );

  if (isApplicationCancelled) {
    return (
      <div>
        <StyledFormContentHeader>
          <H1 as={H3}>Application cancelled</H1>
          <QuestionDescription>
            If you would like to apply again,{' '}
            <Link to={routes.signup}>start here</Link>.
          </QuestionDescription>
        </StyledFormContentHeader>

        <Button shape="pill" onClick={handleGoHome}>
          Go Home
        </Button>
      </div>
    );
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ dirty, isValid, values }) => {
        const isSubmitDisabled = !dirty || !isValid || loading;
        return (
          <>
            <StyledFormContentHeader>
              <H1 as={H3}>Cancel your application</H1>
              <QuestionDescription>
                We’re sorry to see you go. Let us know why you are cancelling
                your application so we can better improve the experience for
                future Guides.
              </QuestionDescription>
            </StyledFormContentHeader>

            <StyledForm>
              <div>
                {Object.keys(reasonOptions).map(reason => {
                  const label = reasonOptions[reason];
                  const canShowDetails = values.reason === reason;
                  return (
                    <FieldGroup key={reason}>
                      <Field
                        id={reason}
                        name="reason"
                        component={StyledRadioField}
                        labelCSS={labelCSS}
                      >
                        {label}
                      </Field>

                      {canShowDetails && (
                        <Field
                          name="details"
                          component={StyledOutlinedTextareaField}
                          placeholder="Explain further (optional)"
                          renderAdornmentEnd={() => (
                            <CharCountAdornment
                              value={values.details}
                              limit={DETAILED_EXPLANATION_LIMIT}
                            />
                          )}
                        />
                      )}
                    </FieldGroup>
                  );
                })}
              </div>

              <ErrorMessage error={error} />
              <FormActions>
                <ArrowNavButton className="back-button" onClick={handleGoBack}>
                  Go back
                </ArrowNavButton>

                <Button
                  className="submit-button"
                  type="submit"
                  shape="pill"
                  disabled={isSubmitDisabled}
                >
                  Cancel Application
                </Button>
              </FormActions>
            </StyledForm>
          </>
        );
      }}
    </Formik>
  );
}

CancelApplicationForm.propTypes = {};

export default CancelApplicationForm;
