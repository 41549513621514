// eslint-disable-next-line import/prefer-default-export
export const CODE_OF_CONDUCT_QUESTIONS = [
  `The Golden Rule: A Seeker who finds me on Sphere stays on Sphere, and all communications with them are maintained in the app. I will not promote any other coaching or professional services to clients from Sphere, or solicit business from them in any way. I will not behave or communicate in a way that could be damaging to the Sphere brand or community. I will facilitate coaching in Sphere with the same level of robustness, quality, and care as I do in my own practice;`,
  `I understand that Sphere is actively driving business for me, and I hereby commit to maintaining a minimum of 5-hours of bookable availability each week for a period of at least 18-months from publishing;`,
  `Should I need to go offline temporarily, or if I face extenuating circumstances, I will follow Sphere’s Guide OOO processes outlined in Sphere Guide Help Center, and notify Sphere immediately of my scheduled absence;`,
  `I will be charged a $100 flat-fee if I no-show a Sphere booking of any kind, cancel without immediately rescheduling, or prompt more than two reschedules of the same session [for the first two reschedules there are no fees associated];`,
  `I understand that I am not required to pay a membership to join Sphere. I am not being asked for exclusivity and can continue to coach clients privately in my own practice, so long as I am adhering to 'The Golden Rule';`,
  `Should I need to leave the platform prior to the end of my term of commitment (18 months from publishing), Sphere charges a fare-well fee of $489, which is reflective of the significant level of effort and costs for Sphere in initial on-boarding, creating and managing my profile and account, marketing and promotion efforts in-app, and outside of the app, as well as for ongoing direct exposure to potential clients;`,
  `I give Sphere permission to talk about, promote and feature me within, or outside of, the Sphere platform using any materials that I have provided to Sphere including but not limited to copy and imagery. Additionally, I give Sphere permission to edit my profile at any time, with or without notice, to best serve the ecosystem;`,
  `I will commit to ensuring that I am always in a quiet, professional environment for my bookings, and I will always bring my A-Game because I care deeply about the experience;`,
  `I will maintain integrity & Seeker (client) confidentiality at all times;`,
  `Should I breach this Code of Conduct in any way, I understand that I am subject to immediate deactivation, review and subsequent fare-well charges in order to maintain integrity over the Sphere experience.`,
];

export const FAQ_LINK = 'https://sphere-guides.helpscoutdocs.com/';

export const LANDING_PAGE_LINK = 'https://sphere.guide';

export const PRIVACY_POLICY_LINK = `${LANDING_PAGE_LINK}/privacy-policy`;

export const CAL_INTEGRATION_LINK =
  'https://guide-help.sphere.guide/category/55-calendar';

export const LEARN_LINK = `${LANDING_PAGE_LINK}/become-a-coach`;

export const APP_STORE_LINK =
  'https://apps.apple.com/us/app/sphere-guidance/id1455792448';

export const GOOGLE_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=com.sphereguidance.sphere.prod.release';
