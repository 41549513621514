import React from 'react';
import { useLocation } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import queryString from 'query-string';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import UpdatePasswordContent from 'auth/components/UpdatePasswordContent/UpdatePasswordContent';
import updatePasswordImg1x from './updatePassword.jpg';
import updatePasswordImg2x from './updatePassword@2x.jpg';
import updatePasswordImg3x from './updatePassword@3x.jpg';
import {
  UpdatePasswordLayout,
  ContentWrapper,
  ImageContainer,
  StyledImg,
} from './styled';

function UpdatePasswordScreen({ className }) {
  const location = useLocation();
  const { token } = queryString.parse(location.search);

  let decoded = {};
  try {
    decoded = jwt.decode(token) || {};
  } catch (err) {
    // Corrupted token. Do nothing as this will be treated the same as an expired token.
  }

  const expired = !decoded.exp || decoded.exp < Date.now() / 1000;

  return (
    <SplitLayout className={className}>
      <SplitLayout.Main>
        <SplitLayout.MainContent>
          <UpdatePasswordLayout>
            <ContentWrapper>
              <UpdatePasswordContent
                emailAddress={decoded.email}
                token={token}
                tokenExpired={expired}
              />
            </ContentWrapper>
            <ImageContainer>
              <StyledImg
                className={className}
                src={updatePasswordImg1x}
                srcSet={`${updatePasswordImg1x} 1x, ${updatePasswordImg2x} 2x, ${updatePasswordImg3x} 3x`}
                alt="Update Password"
              />
            </ImageContainer>
          </UpdatePasswordLayout>
        </SplitLayout.MainContent>
      </SplitLayout.Main>
    </SplitLayout>
  );
}

UpdatePasswordScreen.propTypes = {};
UpdatePasswordScreen.defaultProps = {};

export default UpdatePasswordScreen;
