/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { StyledLayout } from './Layout.styled';

function DropzoneLayout({ input, previews, dropzoneProps, files }) {
  const canShowPreview = files.length > 0;

  if (canShowPreview) {
    return previews;
  }

  return (
    <StyledLayout
      {...dropzoneProps} // eslint-disable-line react/jsx-props-no-spreading
    >
      {input}
    </StyledLayout>
  );
}

DropzoneLayout.propTypes = {
  input: PropTypes.node,
  previews: PropTypes.arrayOf(PropTypes.node),
  dropzoneProps: PropTypes.shape({
    ref: PropTypes.any.isRequired,
    className: PropTypes.string.isRequired,
    style: PropTypes.object,
    onDragEnter: PropTypes.func.isRequired,
    onDragOver: PropTypes.func.isRequired,
    onDragLeave: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
  }).isRequired,
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
};

DropzoneLayout.defaultProps = {
  input: null,
  previews: [],
};

export default DropzoneLayout;
