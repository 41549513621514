import React from 'react';
import get from 'lodash/get';
import { useHistory, useLocation } from 'react-router-dom';
import routes from 'shared/routes';
import SplitLayout from 'shared/layouts/SplitLayout/SplitLayout';
import ArrowNavButton from 'shared/components/ArrowNavButton/ArrowNavButton';
import ForgotPasswordContent from 'auth/components/ForgotPasswordContent/ForgotPasswordContent';
import forgotPasswordImg1x from './forgotPassword.png';
import forgotPasswordImg2x from './forgotPassword@2x.png';
import forgotPasswordImg3x from './forgotPassword@3x.png';
import {
  ForgotPasswordLayout,
  Container,
  ContentWrapper,
  ImageContainer,
  StyledImg,
} from './styled';

function ForgotPasswordScreen({ className }) {
  const history = useHistory();
  const location = useLocation();
  const emailAddress = get(location, 'state.email');
  const cameFromApp = emailAddress !== undefined;
  const handleGoBack = () => {
    // Only use the goBack functionality if the user got here from our app.
    // If they manually entered the route, send them directly to the login screen.
    return cameFromApp ? history.goBack() : history.push(routes.login);
  };

  return (
    <SplitLayout className={className}>
      <SplitLayout.Main>
        <SplitLayout.MainContent>
          <ForgotPasswordLayout>
            <Container>
              <ContentWrapper>
                <ForgotPasswordContent initialEmailAddress={emailAddress} />
              </ContentWrapper>
              <ArrowNavButton onClick={handleGoBack}>
                Return to login
              </ArrowNavButton>
            </Container>
            <ImageContainer>
              <StyledImg
                className={className}
                src={forgotPasswordImg1x}
                srcSet={`${forgotPasswordImg1x} 1x, ${forgotPasswordImg2x} 2x, ${forgotPasswordImg3x} 3x`}
                alt="Forgot Password"
              />
            </ImageContainer>
          </ForgotPasswordLayout>
        </SplitLayout.MainContent>
      </SplitLayout.Main>
    </SplitLayout>
  );
}

ForgotPasswordScreen.propTypes = {};
ForgotPasswordScreen.defaultProps = {};

export default ForgotPasswordScreen;
